import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints } from 'styles/variables/media-queries';

const gutters = guttersWithRem;

const Wrapper = styled.header`
	background-color: ${({ theme }) => theme.background};
	transition: background-color 0.2s ease-in-out;
	padding: 1rem;
	@media (min-width: ${breakpoints.md}) {
		padding: 1rem;
	}

	&.bold-home-text {
		font-weight: 700;
	}
`;

const HeaderBottom = styled.div`
	display: flex;
	align-items: end;
	@media (min-width: ${breakpoints.md}) {
		display: flex;
	}
`;

const PageHeadingWrapper = styled.div`
	padding-right: ${gutters.m};
`;

const PageHeading = styled.h1`
	margin-bottom: 16px;
	font-size: 1.0625rem;
	font-weight: 600;
	white-space: nowrap;
	@media (min-width: ${breakpoints.md}) {
		font-weight: 700;
		font-size: 1.5rem;
		margin-bottom: 0;
	}
`;

const ComponentContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: end;
`;

const DateWrapper = styled.div`
	margin-bottom: ${guttersWithRem.s};
`;

const DateText = styled.span``;

const GreetingWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	background-color: ${colors.paleGreenTint};
	padding: 0.5rem 1rem;
	border-radius: 10px;
	gap: 8px;
`;

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const SettingsLink = styled(Link)`
	font-size: 0.875rem;
	color: ${colors.forestGreen};
	cursor: pointer;

	&:hover {
		color: ${colors.black};
	}
`;

export const GreetingContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Styled = {
	SettingsLink,
	GreetingWrapper,
	Wrapper,
	HeaderBottom,
	PageHeadingWrapper,
	PageHeading,
	ComponentContainer,
	DateWrapper,
	DateText,
	HeadingContainer,
	GreetingContainer,
};

export default Styled;
