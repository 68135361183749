import { Link } from 'react-router-dom';

import { Heading } from 'components/Heading';
import AuthContainer from 'views/Auth/AuthContainer/AuthContainer';

import Styled from './SignuoContainer.style';
import Steps from './components/Steps';

/**
 * SignUpContainer for publishers and influencers/agents
 * @returns {JSX.Element}
 */
const SignUpContainer = ({
	children,
	steps,
	currentStep,
}: {
	inviteToken?: string;
	children: React.ReactNode;
	steps: number;
	currentStep: number;
	termsAccepted: boolean;
}): JSX.Element => {
	return (
		<AuthContainer>
			<Styled.Wrapper data-testid='signup-container'>
				<Styled.HeaderWrapper>
					<Heading as='h4'>Create account</Heading>
					{currentStep === 0 && (
						<div>
							<Link to='/login'>or log in</Link>
						</div>
					)}
				</Styled.HeaderWrapper>
				<Steps steps={steps} currentStep={currentStep} />
				{children}
			</Styled.Wrapper>
		</AuthContainer>
	);
};

export default SignUpContainer;
