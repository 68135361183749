import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	text-align: center;
	margin-top: 16px;
`;

const Styled = {
	Wrapper,
};

export default Styled;
