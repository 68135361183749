import { isEqual } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BrandModel, ClientModel, PublisherModel } from 'api-models';
import InlineEditButton from 'components/InlineEditButton/InlineEditButton';
import Pill from 'components/Pill';
import Styled from 'components/Settings/Components/EditClientName/EditClientName.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { pathSettingsClientShow } from 'routing/PathLookup';
import BrandManager from 'services/ApiManager/Brand.manager';
import toast from 'services/Toast';

type EditBrandNameProps = {
	brand: BrandModel;
	client?: ClientModel;
	canEdit: boolean;
	publisher?: PublisherModel;
};

const EditBrandName = ({ brand, client, canEdit, publisher }: EditBrandNameProps) => {
	const manager = useInjection<BrandManager>(BrandManager);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [newName, setNewName] = useState<string>('');

	const navigate = useNavigate();

	const updateClientName = () => {
		const placeholder = structuredClone(brand);
		placeholder.attributes.name = newName;

		return manager
			.update(brand.id, { name: newName }, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				setIsEditMode(false);
				!isEqual(brand.attributes.name, newName) && toast.success(`Brand name updated`);
				setNewName('');
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost('updating the brand'));
			});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewName(e.target.value);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			updateClientName();
		}
		if (e.key === 'Escape') {
			setIsEditMode(false);
			setNewName('');
		}
	};

	return (
		<Styled.ClientDetails>
			<Styled.ClientNameWrapper>
				{publisher && (
					<>
						<Styled.Name className='border-bottom' onClick={() => navigate('/settings/clients')}>
							<h4>{publisher.attributes.name}</h4>
						</Styled.Name>
						<Styled.Margin>/</Styled.Margin>
					</>
				)}
				<Styled.Name
					className='border-bottom'
					onClick={() => {
						client ? navigate(pathSettingsClientShow(client.id)) : navigate('/settings/brands');
					}}
				>
					<h4>{client?.attributes.name ?? 'Brand management'}</h4>
				</Styled.Name>
				<Styled.Margin>/</Styled.Margin>
				{!isEditMode ? (
					<>
						<Styled.Name className='client-name' data-testid='client-name-heading'>
							<h4>{brand.attributes?.name} </h4>
							<span>
								<Pill title='Brand' className='pink-light' />
							</span>
						</Styled.Name>
					</>
				) : (
					<Styled.InlineInput
						autoFocus={true}
						value={newName}
						onChange={(e) => handleChange(e)}
						onKeyDown={(e) => handleOnKeyDown(e)}
						data-testid='brand-name-input'
					/>
				)}
			</Styled.ClientNameWrapper>
			{canEdit && (
				<Styled.EditButtonWrapper>
					<InlineEditButton
						label='Edit name'
						editMode={isEditMode}
						setEditMode={() => {
							setIsEditMode(true);
							setNewName(brand.attributes?.name);
						}}
						saveChanges={updateClientName}
					/>
				</Styled.EditButtonWrapper>
			)}
		</Styled.ClientDetails>
	);
};
export default EditBrandName;
