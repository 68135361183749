import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import CMStartImage from 'assets/img/app/CM-start.svg';
import { SecondaryButton } from 'components/Button';
import { customValidator } from 'components/ContentManagement/Utils';

import Styled from './UploadMedia.style';

type UploadMedia = {
	onChange: (Files: Array<File>) => void;
	onEdit?: boolean;
};

/**
 * UploadMedia
 * Component to let users drag and drop files into a input
 * react-dropzone are used to make this possible.
 * @todo better typing
 * @param {UploadMedia}
 * @returns {JSX.Element}
 */
const UploadMedia = ({ onChange, onEdit }: UploadMedia): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);

	const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
		noClick: true,
		noKeyboard: true,
		validator: customValidator,
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<Styled.ErrorMessagesListItem key={file.name}>
			{file.name}
			<Styled.ErrorMessagesList>
				{errors.map((e) => (
					<Styled.ErrorMessagesListItem key={e.code}>
						{e.message} {e.code}
					</Styled.ErrorMessagesListItem>
				))}
			</Styled.ErrorMessagesList>
		</Styled.ErrorMessagesListItem>
	));

	useEffect(() => {
		if (Array.isArray(fileRejections) && !fileRejections.length) {
			onChange(acceptedFiles);
		}
	}, [acceptedFiles, fileRejections]);

	return (
		<Styled.UploadWrapper className={classNames({ onEdit: onEdit })}>
			<Styled.DndArea {...getRootProps({ className: 'dropzone' })}>
				<Styled.DndContent>
					{!onEdit && (
						<>
							<img src={CMStartImage} alt='Upload content' />
							<Styled.DndText>Drop your moodboard images here</Styled.DndText>
						</>
					)}
					<SecondaryButton onClick={() => open()}>Browse files</SecondaryButton>

					{fileRejectionItems.length > 0 ? (
						<Styled.ErrorMessagesList data-testid='error-messages'>{fileRejectionItems}</Styled.ErrorMessagesList>
					) : (
						<>
							<Styled.SupportedFormats>The Supported formats include JPEG, PNG, HEIC</Styled.SupportedFormats>
							<Styled.SupportedFormats>Recommended size 640x640px </Styled.SupportedFormats>
						</>
					)}
					<Styled.UploadInput data-testid='drop-input' accept='image/*' type='file' ref={inputRef} {...getInputProps()} />
				</Styled.DndContent>
			</Styled.DndArea>
		</Styled.UploadWrapper>
	);
};

export default UploadMedia;
