/* eslint-disable import/extensions */
import { Player } from '@lottiefiles/react-lottie-player';
import { useContext, useEffect, useState } from 'react';

import FetchinTiktok from 'assets/animations/No-result-TT.json';
import FetchinInstagram from 'assets/animations/No-result-ig.json';
import EmptyStateSearch from 'assets/img/empty-state-search.svg';
import { AutoCompleteOption } from 'components/Discovery/Components/AutoCompleteList/AutoCompleteList';
import ResultTable from 'components/Discovery/Components/ResultTable';
import EmptyState from 'components/EmptyState/V2';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './ResultContainer.style';

type ResultContainerProps = {
	isInfluencerSearch: boolean;
	isLoadingNext: boolean;
	tableList: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
	selectedInfluencers: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
	onSelectAll: () => void;
	onSelectOne: () => (id: string) => void;
	setShowSelectedInfluencers: (show: boolean) => void;
	clearSearch: () => void;
	fuzzySearchOptions: AutoCompleteOption[];
	handleSelectOption: (id: string, text: string, network: Network) => void;
};

const ResultContainer = ({
	isLoadingNext,
	tableList,
	selectedInfluencers,
	onSelectAll,
	onSelectOne,
	setShowSelectedInfluencers,
	clearSearch,
	fuzzySearchOptions,
	handleSelectOption,
}: ResultContainerProps) => {
	const { isLoading, searchText, filter } = useContext(DiscoveryContext);
	const [showEmptyState, setShowEmptyState] = useState(false);

	useEffect(() => {
		let delayTimeout: NodeJS.Timeout;

		// Only show the empty state if there was an active search and no results were found
		if (!isLoading && tableList.length === 0) {
			delayTimeout = setTimeout(() => {
				setShowEmptyState(true);
			}, 400);
		} else {
			setShowEmptyState(false);
		}
		return () => clearTimeout(delayTimeout);
	}, [isLoading, tableList, searchText]);

	return (
		<Styled.Wrapper>
			{isLoading || tableList.length > 0 ? (
				<ResultTable
					setShowSelectedInfluencers={setShowSelectedInfluencers}
					isLoadingNext={isLoadingNext}
					list={tableList}
					selectedItems={selectedInfluencers}
					onSelectAll={onSelectAll}
					onSelectOne={onSelectOne}
				/>
			) : showEmptyState ? (
				!searchText ? (
					<>
						<Styled.EmptyResultImgWrapper>
							<EmptyState
								altText='No result found'
								testId='no-result-found'
								image={EmptyStateSearch}
								text={
									<>
										<p>No results, try remove some filters.</p>
									</>
								}
							/>
						</Styled.EmptyResultImgWrapper>
					</>
				) : (
					<>
						<Styled.EmptyResultImgWrapper>
							<EmptyState
								altText='No result found'
								testId='no-result-found'
								text={
									<>
										<Player
											src={filter.networks === Network.INSTAGRAM ? FetchinInstagram : FetchinTiktok}
											autoplay={true}
											loop
											style={{ height: '200px', width: '200px' }}
										/>
										<p>
											We couldn’t find <Styled.Network>{filter.networks}</Styled.Network> match for <strong>'{searchText}'</strong>. Don't worry, we are
											fetching their details for you now, if available.
										</p>
										<br />
										<p>
											<strong>Pro tip!</strong> Copy and paste the profile's {filter.networks} URL to avoid typos.
										</p>
										<Styled.CTAButton onClick={clearSearch}>Try a new search</Styled.CTAButton>
									</>
								}
							/>
						</Styled.EmptyResultImgWrapper>
						{fuzzySearchOptions.length > 0 && (
							<Styled.FuzzyWrapper>
								<h5>Or did you mean?</h5>
								<Styled.FuzzyContainer>
									{fuzzySearchOptions?.map((result, index) => {
										return (
											<Styled.FyzzyOption key={index} onClick={() => handleSelectOption(result.id, result.label, result.influencer.network)}>
												<div>
													<InfluencerAvatar
														size='lg'
														userName={result.influencer.username}
														profileImageUrl={result.influencer.links.profilePictureUrl}
														displayNetwork
														network={result.influencer.network}
													/>
													<strong>{result.label}</strong>
												</div>
												<Styled.OptionButton>View profile</Styled.OptionButton>
											</Styled.FyzzyOption>
										);
									})}
								</Styled.FuzzyContainer>
							</Styled.FuzzyWrapper>
						)}
					</>
				)
			) : null}
		</Styled.Wrapper>
	);
};

export default ResultContainer;
