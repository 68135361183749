import Icon from 'components/Icon';

import Styled from './BackButton.style';

const BackButton = (props: { onCancel: () => void; innerText?: string }) => (
	<Styled.BackDiv onClick={props.onCancel}>
		<Icon name='arrow-left' size='12' />
		<Styled.Title>{props.innerText ?? 'Back'}</Styled.Title>
	</Styled.BackDiv>
);

export default BackButton;
