import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SecondaryButton } from 'components/Button/Button';
import { CreateFolderProps } from 'components/InfluencerManagement/Folders/types';
import { RouteParams } from 'components/InfluencerManagement/Lists/types';
import { FileType, Filter } from 'components/InfluencerManagement/types';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import InfluencerListManager from 'services/ApiManager/InfluencerList.manager';
import toast from 'services/Toast';

/**
 * Create folder component
 * @param CreateFolderProps
 * @returns {JSX.Element}
 */
const CreateFolder = ({ isLoading, mutate, setFolderToEdit, setSelectedFilter, setSelectedTab }: CreateFolderProps): JSX.Element => {
	const [isCreating, setIsCreating] = useState(false);

	const { folderId } = useParams<RouteParams>();
	const manager = useInjection<InfluencerListManager>(InfluencerListManager);

	const handleCreate = async () => {
		setSelectedFilter(Filter.ALL);
		setSelectedTab(FileType.ALL);
		setIsCreating(true);
		try {
			const folder = await manager.createFolder(
				{
					name: 'New folder',
					parent: folderId,
					users: [],
				},
				undefined,
				{
					mutateFn: mutate,
				},
			);
			setIsCreating(false);
			const folderWithoutName = { ...folder, attributes: { ...folder.attributes, name: '' } };
			setFolderToEdit(folderWithoutName);
		} catch (error) {
			toast.error(getSomethingWentWrongMessage());
			console.error(error);
		}
	};

	return (
		<SecondaryButton disabled={isLoading} onClick={() => handleCreate()} data-testid='create-folder-button' icon='folder' isLoading={isCreating}>
			New folder
		</SecondaryButton>
	);
};

export default CreateFolder;
