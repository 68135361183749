import styled from 'styled-components';

import { Accordion } from 'components';
import Grid from 'styles/grid/grid';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints, devices } from 'styles/variables/media-queries';

const GridColumn = Grid.Column;
const Wrapper = styled(GridColumn)`
	height: auto;
`;

const InnerWrapper = styled.div`
	width: 100%;
	height: fit-content;
	padding: ${guttersWithRem.m};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: ${borderRadius.m};
	background-color: #333333c2;
	color: ${newColors.snow};
	backdrop-filter: blur(10px);
	margin-bottom: 32px;
	margin-top: 40px;
`;

const CampaignInfoWrapper = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};
	margin-bottom: ${guttersWithRem.xxl};
	padding-bottom: 2rem;
	border-bottom: 1px solid ${newColors.ash};

	@media (max-width: ${breakpoints.sm}) {
		flex-direction: column;
		row-gap: ${guttersWithRem.m};
	}
`;

const CampaignInfo = styled.div`
	padding-top: ${guttersWithRem.xs};
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-height: 200px;
	${scrollbarY}
	& > div {
		&.title {
			font-size: 1.0625rem;
			font-weight: 600;
			@media screen and (${devices.md}) {
				font-size: 1.1875rem;
				font-weight: 700;
			}
		}

		&.brand {
			margin-top: 5px;
			margin-bottom: 16px;
		}

		&.info-box {
			flex: 1;
			display: flex;
			align-items: center;
			text-align: center;

			padding: ${guttersWithRem.xs};
			background-color: ${colors.mainWhite};
			border: 1px solid ${colors.lightGray};
			border-radius: 4px;
			margin-top: 16px;

			& > div {
				flex: 1;

				& > div {
					&.commission,
					&.date {
						display: flex;
						align-items: center;
						justify-content: center;

						font-weight: 700;
					}
				}

				&.divider {
					flex: none;
					width: 2px;
					height: 100%;
					border-radius: 10px;

					background-color: ${colors.lightGray};
				}
			}
		}
	}
`;

const Dot = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;

	&.isVisible {
		border: 1px solid ${colors.influencerDashboard.campaign.dotBorder};
		background-color: ${colors.influencerDashboard.campaign.dotBackground};
		margin-right: ${guttersWithRem.xs};
	}
`;

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.xs};

	& > button {
		flex: 1;

		padding-top: ${guttersWithRem.xs};
		padding-bottom: ${guttersWithRem.xs};

		&.bright {
			background-color: ${colors.transparent};
		}

		&.dark {
			background-color: ${colors.influencerDashboard.campaign.PrimaryButton.background};
			color: ${colors.influencerDashboard.campaign.PrimaryButton.color};
		}
	}
`;

const Text = styled.div`
	line-height: 1.2;
	margin-top: 16px;
	&.title {
		max-width: 250px;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: ${breakpoints.sm}) {
			max-width: 150px;
		}
	}

	&.assignment-title {
		margin-left: 8px;
		font-weight: 700;
		margin-bottom: 8px;
	}

	&.brand {
	}

	&.commission-label {
	}

	&.commission-value {
		font-weight: 700;
		margin-bottom: 16px;
	}
`;

const AssignmentLink = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	padding: 0.5rem;
	font-weight: ${fontWeights.semiBold};
	margin-bottom: 4px;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	&:hover {
		background-color: ${newColors.ash};
		color: ${newColors.smoke};
	}
`;

const FlexDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	@media screen and (${devices.md}) {
		flex-direction: row;
		flex-direction: row-reverse;
	}
`;

const AssignmentTitle = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	font-size: 0.875rem;
	span {
		line-height: 2;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const CustomAccordion = styled(Accordion)`
	.accordion__header {
		margin-bottom: 0;
	}
	.accordion__title {
		width: fit-content;
		h5 {
			color: ${newColors.white};
			margin-bottom: 0;
		}
	}

	.icon path {
		fill: ${newColors.white};
	}
`;

const Styled = {
	CustomAccordion,
	AssignmentTitle,
	Text,
	Wrapper,
	InnerWrapper,
	CampaignInfoWrapper,
	CampaignInfo,
	ButtonsWrapper,
	Dot,
	AssignmentLink,
	FlexDiv,
};

export default Styled;
