import { useState } from 'react';
import { toast } from 'sonner';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import BlastChatInput from 'components/IntegratedInbox/Components/BlastChatInput';
import { BlastMessageInfluencerModel } from 'components/Lists/Components/ListContent/types';
import useBlastMessage from 'components/Lists/hooks';
import { SideDrawer } from 'components/SideDrawer';
import Tooltip from 'components/Tooltip';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { isSuccessfulResponse } from 'services/Response.types';

import Styled from './BlastDirectMessage.style';

type Props = {
	selectedInfluencers: BlastMessageInfluencerModel[];
};

const BlastDirectMessage = ({ selectedInfluencers }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [message, setMessage] = useState('');
	const [subject, setSubject] = useState<string | null>(null);
	const [isSending, setIsSending] = useState(false);

	const { createBlastDirectMessage } = useBlastMessage();

	const sendHandler = async () => {
		setIsSending(true);
		const influencerIds = selectedInfluencers.map((influencer) => influencer.id);
		return await createBlastDirectMessage(influencerIds, subject, message)
			.then((res) => {
				if (isSuccessfulResponse(res.status)) {
					toast.success(`Message has been sent to ${influencerIds.length} influencers`);
				}
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost('sending this message'));
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	return (
		<Styled.Wrapper>
			<Tooltip content='Message'>
				<Styled.IconWrapper
					onClick={() => {
						setIsModalOpen(true);
					}}
					data-testid='blast-icon-button'
				>
					<Icon name='private-message' />
				</Styled.IconWrapper>
			</Tooltip>
			<SideDrawer
				sidebarIsOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				dataTestId=''
				title='Send private message'
				isExpandable
				saveButtonText='Send'
				onSave={sendHandler}
				isSubmitting={isSending}
				isDisabled={message.length === 0}
			>
				<Styled.SideDrawerContent>
					{selectedInfluencers.length > 0 && (
						<>
							<label>To</label>
							<Styled.SelectedInfluencers>
								<div className='influencers'>
									{selectedInfluencers.map((campaignInstagramOwner) => {
										return (
											<Styled.SelectedInfluencerTag key={campaignInstagramOwner.id}>
												<Avatar imageUrl={campaignInstagramOwner.links.profilePictureUrl} name={campaignInstagramOwner.username} size='sm' />
												<div className='name'>{campaignInstagramOwner.username}</div>
											</Styled.SelectedInfluencerTag>
										);
									})}
								</div>
							</Styled.SelectedInfluencers>
						</>
					)}
					<Styled.MessageInputWrapper>
						<BlastChatInput
							getMessage={(subject, message) => {
								setMessage(message), setSubject(subject);
							}}
						/>
					</Styled.MessageInputWrapper>
				</Styled.SideDrawerContent>
			</SideDrawer>
		</Styled.Wrapper>
	);
};

export default BlastDirectMessage;
