import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import { InputFieldV2 } from 'styles/formElements/input';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.tr`
	width: 100%;
	overflow-x: auto;
	border-left: none;
	border-right: none;
	border-bottom: none;
	vertical-align: middle;
	transition: background-color ease-in-out 0.2s;
	&:nth-child(even) {
		background-color: #f2f2f2;
	}

	&:hover {
		background-color: #c5c5c5;
	}

	&.show-detail {
		border: 1px solid ${colors.gray6};
	}
`;

const Td = styled.td<CSSProperties>`
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	min-width: ${(props) => props.minWidth};
	width: ${(props) => props.width};
	height: ${(props) => props.maxHeight || '50px'};
	padding: 1rem;
	&.space-left {
		min-width: 120px;
	}

	&.no-min-width {
		min-width: unset;
	}

	.select-input-wrapper {
		margin-bottom: 0;
	}

	.d-flex {
		gap: 8px;
	}
	&.show-cursor {
		cursor: pointer;
	}
`;

const ExpandWrapper = styled.div``;

const Div = styled.div<CSSProperties>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	column-gap: ${(props) => props.columnGap};
	row-gap: ${(props) => props.rowGap};
	font-family: ${typography.list.fontFamily};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
`;

const Title = styled(Div)`
	font-weight: 600;
	font-size: 1rem;
	padding-left: 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 320px;
	div {
		font-weight: 400;
		font-size: 0.75rem;
	}
	&.disabled {
		color: ${colors.gray5};
	}
`;

const Data = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.medium.fontSize};
	white-space: nowrap;
`;

const IconContainer = styled.div`
	position: relative;
	max-height: 40px;
	margin-top: 5px;
	margin-right: 5px;
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
	margin-right: 10px;
	margin-bottom: 3px;
`;

const Text = styled.div`
	margin-top: 4px;
`;

const ExpandInnerWrapper = styled.div`
	border-radius: ${borderRadius.m};
	background-color: #fafafa;
`;

const DetailsList = styled.ul`
	list-style: none;
	width: 100%;
	margin-bottom: 40px;
	li {
		padding: 0.5rem;
		width: 100%;
		border-radius: 4px;
		&:nth-child(even) {
			background-color: #f2f2f2;
		}
		label {
			font-size: 0.875rem;
			line-height: 1.5;
			margin-bottom: 4px;
		}
	}
`;

const UserInfo = styled.div`
	width: 92vw;
	background-color: ${colors.payment.detailTable.background};
	border-radius: 10px;
	padding: 1rem 0.5rem;

	${mediaQueries.medium`
		padding: 2.5rem 1rem 1rem 1rem;
		margin-bottom: 24px;
		width: 100%;
	`};
`;

const CustomColumn = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	min-height: 30px;
`;

const Status = styled.div`
	font-size: 0.875rem;
	&.connected {
		color: ${colors.admin.active};
	}
	color: ${colors.error};
	font-weight: 500;
`;

const List = styled.ul`
	list-style: none;
	padding-left: 0;
	width: 100%;
	li {
		padding-left: 0;
	}
`;

const Role = styled.div`
	padding: 0.5rem 0;
	font-weight: 400;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	div {
		cursor: pointer;
		height: 30px;
		width: auto;
		padding: 0 0.5rem;
		background-color: ${newColors.mist};
		border-radius: ${borderRadius.s};
		display: flex;
		justify-content: center;
		align-items: center;
		.icon {
			margin-top: 5px;
		}
		&:hover {
			background-color: ${newColors.black};
			color: ${newColors.white};
			.icon path {
				fill: ${newColors.white};
			}
		}
	}
`;

const Input = styled.input`
	${InputFieldV2};
	height: 40px;
	max-width: 600px;
	width: 100%;
	transition: border-color 0.2s ease-in-out;
	border: 1px solid transparent;

	&.error {
		border-color: ${colors.error};
	}
`;

const EditButton = styled.button`
	background-color: transparent;
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;
	color: ${colors.influencerBlue};
`;

const AvatarWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 8px;
	margin: 4px 0;
`;

const ValueWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const UserDetailsWrapper = styled.div`
	padding-top: 2rem;
`;

const DisplayPaymentsWrapper = styled.div`
	width: 100%;
	margin: 24px 0;
	button {
		width: 100%;
	}
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 40px;
	background-color: ${newColors.ash};
	border-radius: 4px;
	cursor: pointer;
	.icon {
		margin-top: 4px;
	}
	&:hover {
		background-color: ${newColors.black};
		.icon path {
			fill: ${newColors.white};
		}
	}
`;

const InputWrapper = styled.div`
	gap: 4px;
`;

const InfluencerListItem = styled.li`
	width: 100%;
	display: flex;
	gap: 8px;
`;

const InfluencerAvatarWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	h5 {
		margin-bottom: 0;
	}
`;

const RoleTitle = styled.h5`
	margin-bottom: 0;
	margin-top: 16px;
`;

const VatWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const Styled = {
	VatWrapper,
	RoleTitle,
	InfluencerAvatarWrapper,
	InfluencerListItem,
	InputWrapper,
	IconWrapper,
	AvatarWrapper,
	Role,
	List,
	CustomColumn,
	Wrapper,
	ExpandWrapper,
	Td,
	Div,
	Title,
	Data,
	IconContainer,
	CustomAvatar,
	Text,
	ExpandInnerWrapper,
	DetailsList,
	UserInfo,
	Status,
	Input,
	InputContainer,
	EditButton,
	ValueWrapper,
	UserDetailsWrapper,
	DisplayPaymentsWrapper,
};

export default Styled;
