import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';
type T = { theme: DefaultThemeV2 };

const BrandItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${colors.lavenderHaze};
	border: 2px solid ${colors.lavenderHaze};

	height: 140px;
	width: 100%;
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1rem;
	cursor: pointer;
	@media screen and (${devices.md}) {
		width: 140px;
	}
	img {
		display: block;
		margin-bottom: 16px;
	}
	p {
		font-size: 0.875rem;
		margin-top: 5px;
		margin-bottom: 0;
		text-align: center;
		max-width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&.hide {
		display: none;
		opacity: 0;
	}
	transition: opacity 0.2s;

	&:hover {
		border: 2px solid ${colors.skyBlue};
	}
`;

const IconContainer = styled.div`
	border-radius: ${(props: T) => props.theme.radius.small};
	background-color: ${colors.lavenderHaze};
	display: flex;
	width: 20px;
	height: 20px;
	justify-content: center;
	align-items: center;
`;

const Link = styled.div`
	width: 56px;
	height: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LogoText = styled.p`
	margin-bottom: 0;
	font-weight: ${fontWeights.bold};
	color: ${colors.smoke};
`;

const Logo = styled.div<{ logo?: string }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	width: 56px;
	border-radius: ${(props: T) => props.theme.radius.default};
	background-image: url(${(p) => p.logo});
	background-color: ${(p) => (p.logo ? 'transparent' : colors.ash)};
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
`;

export default {
	BrandItem,
	IconContainer,
	Link,
	LogoText,
	Logo,
};
