import DeclineButton from 'components/NewBriefPage/Components/DeclineButton';
import JoinButton from 'components/NewBriefPage/Components/JoinButton/JoinButton';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';

import Styled from './CampaignSummary.style';

type CampaignSummaryProps = {
	campaign: Campaign;
	campaignInstagramOwner: CampaignInstagramOwner;
	onPreview: boolean;
};
/**
 */
const CampaignSummary = ({ campaign, campaignInstagramOwner }: CampaignSummaryProps) => {
	if (campaignInstagramOwner.joined) return null;

	return (
		<Styled.SummaryWrapper data-testid='campaign-summary'>
			<Styled.CampaginName>{campaign.name}</Styled.CampaginName>
			<Styled.Wrapper>
				{campaignInstagramOwner?.links?.decline && <DeclineButton link={campaignInstagramOwner?.links?.decline} />}
				{campaignInstagramOwner && <JoinButton campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />}
			</Styled.Wrapper>
		</Styled.SummaryWrapper>
	);
};

export default CampaignSummary;
