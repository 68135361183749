import classNames from 'classnames';
import { Model } from 'json-api-models';
import moment from 'moment';

import Avatar from 'components/Avatar';
import { conversationMetaData } from 'shared/Types/Campaign/ConversationMetaData';

import Styled from './ConversationItem.style';

type Props = {
	coverImage?: string;
	title: string;
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
	isSelected: boolean;
	hasUnreadMessages: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	latestMessageObj: { createdAt: string; title: string; message: string } | undefined;
};

const ConversationItem = ({ coverImage, title, onClick, isSelected, hasUnreadMessages, latestMessageObj }: Props) => {
	return (
		<Styled.ListItem onClick={(e) => onClick(e)} className={classNames({ selected: isSelected })} data-testid='inbox-influencer-conversation-item'>
			{
				<Styled.Wrapper>
					<Styled.ImageWrapper>
						<Avatar imageUrl={coverImage} name={title} />
						{hasUnreadMessages ? <Styled.UnreadMark /> : null}
					</Styled.ImageWrapper>
					{latestMessageObj ? (
						<Styled.LatestMessageWrapper>
							<Styled.LatestMessageHeader>
								<Styled.CampaignName className='has-messages'>
									<span>{title}</span>
								</Styled.CampaignName>
								{latestMessageObj.createdAt ? <div className='date'>{moment(latestMessageObj.createdAt).format('YYYY-MM-DD')}</div> : null}
							</Styled.LatestMessageHeader>
							<Styled.LatestMessageContent>
								<span>{latestMessageObj.message}</span>
							</Styled.LatestMessageContent>
						</Styled.LatestMessageWrapper>
					) : (
						<Styled.CampaignName>
							<span>{title}</span>
						</Styled.CampaignName>
					)}
				</Styled.Wrapper>
			}
		</Styled.ListItem>
	);
};

export class ConversationMetaData {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(conversationMetaData: conversationMetaData) {
		this.sender = conversationMetaData.latestMessageName ?? '';
		this.message = conversationMetaData.latestMessageContent ?? '';
		this.createdAt = conversationMetaData.latestMessageDateTime ? moment(conversationMetaData.latestMessageDateTime).format('YY-MM-DD') : '';
		this.isRead = !!conversationMetaData.unread;
	}
}

export class LatestMessage {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(latestMessage: Model) {
		this.sender = this.isUser(latestMessage) ? latestMessage.user?.firstName : latestMessage.influencer?.username;
		this.message = latestMessage.message;
		this.createdAt = moment(latestMessage.createdAt).format('YY-MM-DD');
		this.isRead = !!latestMessage.readAt;
	}

	isUser(latestMessage: Model) {
		return !!latestMessage.user;
	}
}

export default ConversationItem;
