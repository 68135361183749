import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const cm = colors.contentManagement;

const Wrapper = styled.div``;

const StatusTag = styled.div`
	background-color: ${cm.reviewStatusTag.backgroundColor};
	border: 1px solid ${cm.reviewStatusTag.color};
	font-size: 14px;
	font-weight: 500;
	color: ${cm.reviewStatusTag.color};
	border-radius: 4px;
	padding: 0.5rem 0.75rem;
	line-height: 0.8;
	div {
		display: inline-block;
		width: fit-content;
	}
`;

const ActionBar = styled.div`
	width: 100%;
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0;

	${mediaQueries.medium`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	`};
`;

const ActionsGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	z-index: 10;
	h6 {
		margin-bottom: 16px;
	}
	div {
		&.buttons {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	@media screen and (min-width: 992px) {
		padding: 1rem;
		position: absolute;
		bottom: 24px;
		width: calc(100% - 112px);
		right: 55px;
		border-radius: ${borderRadius.m};
		h6 {
			color: ${newColors.white};
			text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
		}
		background: rgba(139, 139, 139, 0.6);
		backdrop-filter: blur(9px);
	}
`;

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	width: auto;
	button {
		margin-left: auto;
	}
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 16px;
	div {
		&.name-status {
			flex-direction: column;
			align-items: flex-start;
			white-space: nowrap;
			@media screen and (${devices.lg}) {
				align-items: center;
				flex-direction: row;
			}
		}
	}
	h5 {
		margin-right: 16px;
		margin-bottom: 0;
	}
	p {
		margin: 0;
	}
`;

const ErrorMessage = styled.div`
	margin-top: 5px;
	background-color: ${colors.errorLight};
	border-radius: ${borderRadius.s};
	padding: 1rem;
	color: ${colors.errorDarkRed};
	font-size: 0.75rem;
	display: flex;
	cursor: pointer;

	.icon {
		margin-right: 5px;
		svg path {
			fill: ${colors.errorDarkRed} !important;
		}
	}
`;

const ErrorMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const SubmitSection = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: ${colors.mainBlack};
	z-index: 10;
	bottom: 0;
	width: 100%;
	padding: 1rem;
	padding-bottom: 3rem;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	hr {
		width: 100%;
	}
`;

const AssignmentName = styled.div`
	color: ${colors.white};
	font-size: 0.938rem;
	font-weight: 700;
	line-height: 24px;
`;

const FileText = styled.div`
	font-weight: 500;
	font-size: 0.75rem;
	color: ${colors.borderGray};
	margin-bottom: 16px;
`;

const IconButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${borderRadius.s};
	width: 40px;
	height: 40px;
	cursor: pointer;
	&:hover {
		background-color: ${colors.buttonGray} !important;
	}
`;

const CompletedWrapper = styled.div`
	text-align: center;
	margin-bottom: 16px;
	padding: 1rem;
	p {
		color: ${colors.contentManagement.grayText};
		margin-top: 0;
	}

	&.error {
		border: 1px solid ${cm.errorText};
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		border-radius: 10px;

		path {
			fill: ${cm.errorText};
		}
	}
`;

const Title = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 1.063rem;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0em;
	margin-top: 8px;
`;

const HeadingWrapper = styled.div`
	margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`;

const DFlexW100 = styled.div`
	display: flex;
	width: 100%;
	&.min-height {
		min-height: 44px;
	}
`;

const DFlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	& > div {
		width: 100%;
		&.buttons-wrapper {
			display: flex;
			gap: 8px;
		}
	}
	&.onStats {
		position: fixed;
		z-index: 10;
		bottom: 24px;
		width: 721px;
		padding: 1rem;
		border-radius: ${borderRadius.m};
		background: rgba(139, 139, 139, 0.6);
		backdrop-filter: blur(9px);
		color: ${newColors.snow};
	}
`;

const SubmitSectionContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	justify-content: space-between;
	div {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: space-between;
		gap: 8px;
	}
`;

const InfoText = styled.p`
	font-size: 0.75rem;
	color: ${colors.mainWhite};
	margin: 0;
	font-weight: 500;
`;

const TitleWrapper = styled.div`
	display: none;
	@media screen and (${devices.md}) {
		display: flex;
		gap: 4px;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
`;

const GraySubmitSection = styled.div`
	padding: 1rem;
	position: absolute;
	bottom: 24px;
	width: 81%;
	right: 80px;
	border-radius: ${borderRadius.m};
	background: rgba(139, 139, 139, 0.6);
	backdrop-filter: blur(9px);
	h6 {
		color: ${newColors.white};
		text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
	}
`;

const SpanLink = styled.span`
	color: ${newColors.oceanBlue};
	cursor: pointer;
`;

const AutoApproveText = styled.p`
	margin-top: 16px;
	color: ${colors.white};
`;

const Styled = {
	AutoApproveText,
	SpanLink,
	GraySubmitSection,
	TitleWrapper,
	InfoText,
	DFlexW100,
	DFlexColumn,
	SubmitSectionContent,
	ButtonContainer,
	HeadingWrapper,
	Title,
	SubmitSection,
	Wrapper,
	StatusTag,
	ActionBar,
	ActionsGroup,
	Heading,
	ErrorMessage,
	ErrorMessageContainer,
	StatusContainer,
	AssignmentName,
	FileText,
	IconButton,
	CompletedWrapper,
};

export default Styled;
