import classNames from 'classnames';
import { FormikErrors, FormikValues } from 'formik';
import { ChangeEvent } from 'react';

import NumberInput from 'components/Form/Elements/NumberInput';
import Field from 'components/Forms/Field';
import Tooltip from 'components/Tooltip';
import { FEATURE_FLAG_MEDIA_AGENCY_FEE } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';

import Styled from './PublisherOptions.style';

type PublisherOptionsProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>;
	values: FormikValues;
};

export enum PublisherType {
	MEDIA_AGENCY = 'media_agency',
	AGENCY = 'agency',
	DIRECT_CLIENT = 'direct_client',
}

type PublisherOptionProps = {
	type: PublisherType;
	label: string;
	description: string;
	setFieldValue: PublisherOptionsProps['setFieldValue'];
	values: FormikValues;
	isSelected: boolean;
};

const PublisherOption = ({ type, label, description, setFieldValue, values, isSelected }: PublisherOptionProps) => {
	const [isEnabled] = useFeatureToggle();

	return (
		<Styled.OptionBox className={classNames({ selected: isSelected })} onClick={() => setFieldValue('kind', type)}>
			<input data-testid='payment-method-radio' type='radio' name='kind' className='radio-button__input' checked={isSelected} readOnly />
			<div className='content'>
				<p>{label}</p>
				<Tooltip delayShow={200} content={description}>
					<Styled.HelpText>{description}</Styled.HelpText>
				</Tooltip>
				{type === PublisherType.MEDIA_AGENCY && isSelected && isEnabled(FEATURE_FLAG_MEDIA_AGENCY_FEE) && (
					<Styled.MediaAgencyWrapper>
						<Field label='Media agency fee (typically 6%)'>
							<NumberInput
								id='mediaAgencyFee'
								name='mediaAgencyFeePercentage'
								value={values.mediaAgencyFeePercentage}
								onChangeWithButton={(fieldName, enteredValue) => setFieldValue(fieldName, enteredValue)}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('mediaAgencyFeePercentage', e.target.value)}
								min={0}
								max={100}
								unit='%'
							/>
						</Field>
					</Styled.MediaAgencyWrapper>
				)}
			</div>
		</Styled.OptionBox>
	);
};

const PublisherOptions = ({ setFieldValue, values }: PublisherOptionsProps) => {
	const options = [
		{
			type: PublisherType.MEDIA_AGENCY,
			label: 'Media Agency',
			description:
				'Media agencies can add an optional fee to campaigns, create clients, and manage their brands. They will receive a single invoice once all influencers have joined.',
		},
		{
			type: PublisherType.AGENCY,
			label: 'Agency',
			description: 'Agencies can create clients and manage their brands. They will receive a weekly invoice for any newly joined influencers.',
		},
		{
			type: PublisherType.DIRECT_CLIENT,
			label: 'Direct client',
			description: 'Direct clients can create and manage their brands. They will receive a weekly invoice for any newly joined influencers.',
		},
	];

	return (
		<Styled.OptionsWrapper>
			{options.map((option) => (
				<PublisherOption
					key={option.type}
					type={option.type}
					label={option.label}
					description={option.description}
					setFieldValue={setFieldValue}
					values={values}
					isSelected={values.kind === option.type}
				/>
			))}
		</Styled.OptionsWrapper>
	);
};

export default PublisherOptions;
