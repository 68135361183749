import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { breakpoints } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	transition:
		transform 0.2s ease-in-out,
		opacity 0.4s ease-in-out;
	transform: translateX(100%);
	height: 0;
	opacity: 0;
	&.visible {
		transform: translateX(0);
		opacity: 1;
		height: auto;
	}
`;

const InnerWrapper = styled.div`
	height: calc(100% - 220px);
	h4 {
		margin-top: 40px;
	}
`;

const ListWrapper = styled.div`
	padding: 0;
	padding-bottom: 3rem;
	flex: 1;
	margin-top: 24px;
	.no-assignments {
		text-align: center;
		font-weight: 700;
		font-size: 0.875rem;
	}
`;

const Divider = styled.div`
	border-top: 1px solid ${colors.mist};
	margin-top: 24px;
	@media (max-width: ${breakpoints.sm}) {
		margin-left: 65px;
		height: 1px;
	}
`;

const Status = styled.span`
	color: ${colors.slate};
	margin-top: 24px;
	margin-bottom: 6px;
`;

const FixedItem = styled.div`
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
	background-color: ${colors.snow};
`;

const SkeletonLoaderWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Styled = {
	SkeletonLoaderWrapper,
	FixedItem,
	Status,
	Wrapper,
	InnerWrapper,
	ListWrapper,
	Divider,
};

export default Styled;
