import styled from 'styled-components';

import Checkbox from 'components/Checkbox/V2';
import InfluencerAvatarWithFollowers from 'components/InfluencerAvatarWithFollowers/InfluencerAvatarWithFollowers';
import { Network } from 'constants/socialMedia';
import { InfluencerListItemType } from 'contexts/Discovery/types';

const ProfileNameContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	padding-bottom: 1rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExpandedTitle = (props: { influencerData: InfluencerListItemType; data: any; isSelected: boolean; onSelect: () => void }) => {
	const { influencerData } = props;
	return (
		<>
			<ProfileNameContainer>
				<Checkbox
					checked={props.isSelected}
					onChange={(e) => {
						e.stopPropagation();
						props.onSelect();
					}}
				/>
				<InfluencerAvatarWithFollowers
					inDiscovery
					username={influencerData.username}
					network={influencerData.network as Network}
					networkLink={influencerData.networkLink ?? influencerData.networkLinks[influencerData.network]}
					profileImageUrl={influencerData.profileImageUrl ?? ''}
					followersCount={influencerData.followersCount}
					hideEmail
				/>
			</ProfileNameContainer>
		</>
	);
};

export default ExpandedTitle;
