import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import { v4 as uuidV4 } from 'uuid';

import BrowserStorage, { StorageType } from 'shared/helpers/BrowserStorage/BrowserStorage';

import LogErrorsInterceptor from './Interceptor/LogErrors';
import RequestDecoratorInterceptor from './Interceptor/RequestDecorator';
import RetryRequestInterceptor from './Interceptor/RetryRequest';
import ToastOnBadRequestInterceptor from './Interceptor/ToastOnBadRequest';
import UnauthorizedErrorInterceptor from './Interceptor/UnauthorizedError';
import InternalHttpClientFactory from './InternalHttpClientFactory';
import { RetryConfig } from './types';

/**
 * A simple factory method to create HTTP client
 */

@injectable()
export default class HttpClientFactory {
	private httpClient: AxiosInstance | null = null;

	public constructor(
		@inject(InternalHttpClientFactory) private factory: InternalHttpClientFactory,
		@inject(LogErrorsInterceptor) private logErrorsInterceptor: LogErrorsInterceptor,
		@inject(RequestDecoratorInterceptor) private requestDecoratorInterceptor: RequestDecoratorInterceptor,
		@inject(RetryRequestInterceptor) private retryInterceptor: RetryRequestInterceptor,
		@inject(ToastOnBadRequestInterceptor) private toastInterceptor: ToastOnBadRequestInterceptor,
		@inject(UnauthorizedErrorInterceptor) private unauthorizedInterceptor: UnauthorizedErrorInterceptor,
	) {}

	/**
	 * This is for new HTTP request to collabs API.
	 * It decorates the request and add a nice toast time to time.
	 */
	public create(): AxiosInstance {
		if (this.httpClient !== null) {
			return this.httpClient;
		}

		const client = this.createLegacyClient();
		client.interceptors.response.use(
			(response) => response,
			(error) => this.toastInterceptor.onError(error),
		);

		// Request interceptors
		if (process.env.NODE_ENV !== 'production') {
			client.interceptors.request.use(this.requestDecoratorInterceptor.blockNonHateoas);
		}
		client.interceptors.request.use(this.requestDecoratorInterceptor.addExcludeToRequest);

		// Save for later
		this.httpClient = client;

		return client;
	}

	public createPinebucketClient() {
		const apiClient = this.factory.create({
			baseURL: process.env.VITE_APP_COLLABS_PAYOUT_API,
		});

		apiClient.interceptors.request.use(
			(config: InternalAxiosRequestConfig) => {
				const storage = new BrowserStorage(StorageType.LOCAL);
				const pinebucketJWT = storage.getItem('pinebucket') ?? '';
				const uuid = uuidV4();

				config.headers!['Authorization'] = `Bearer ${pinebucketJWT}`;
				config.headers!['x-request-id'] = `${uuid}`;
				config.headers['Content-Type'] = 'application/vnd.api+json';
				config.headers['Accept'] = 'application/vnd.api+json';

				return config;
			},
			(error) => this.logErrorsInterceptor.onError(error),
		);

		apiClient.interceptors.response.use(undefined, (error) => this.retryInterceptor.onError(error, apiClient));
		apiClient.interceptors.response.use(undefined, (error) => this.unauthorizedInterceptor.onError(error));

		return apiClient;
	}

	/**
	 * @deprecated use `HttpClientFactory::create()` instead
	 * @param baseConfig
	 */
	public createLegacyClient(baseConfig: RetryConfig = {}): AxiosInstance {
		const apiClient = this.factory.createCollabsApiClient(baseConfig);

		apiClient.interceptors.request.use(undefined, (error) => this.logErrorsInterceptor.onError(error));
		apiClient.interceptors.response.use(undefined, (error) => this.retryInterceptor.onError(error, apiClient));
		apiClient.interceptors.response.use(undefined, (error) => this.unauthorizedInterceptor.onError(error));

		return apiClient;
	}
}
