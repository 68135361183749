import { Model } from 'json-api-models';

import { CampaignModel } from 'api-models';
import { StatisticsPayloadForSelectorType } from 'components/ContentManagement/hooks';
import { InstagramUserStory } from 'components/ContentManagement/types';
import { CREATE_INSTAGRAM_POST, CREATE_INSTAGRAM_REEL, CREATE_INSTAGRAM_STORY, CREATE_TIKTOK } from 'constants/hateoas-keys';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import IRepository from 'utils/Repository/IRepository';

type userCanFn = (action: string) => boolean;
export function getUploadStatisticsLink(userCan: userCanFn, links: Record<string, string>): string {
	for (const action of [CREATE_TIKTOK, CREATE_INSTAGRAM_STORY, CREATE_INSTAGRAM_REEL]) {
		if (userCan(action)) {
			return links[action];
		}
	}

	return links[CREATE_INSTAGRAM_POST];
}

export type StatisticsProps = {
	selectedCampaign: CampaignModel;
	selectedCIO: Model;
	CIOAssignment: Model;
	review: Model;
	assignment: Model;
	assignmentType: AssignmentType;
	influencer: Model;
	refresh: () => Promise<void>;
	getUnsavedChanges: (unsavedChanges: boolean) => void;
	goToSummary: () => void;
	repository: IRepository;
};

export type StatisticsFormValues = {
	postedAt: string;
	url?: string;
	reach: number;
	reachLastFrame?: number;
	impressions: number;
	comments?: number;
	likes?: number;
	saves?: number;
	shares?: number;
	stickerLinkClicks?: number;
	stickerTaps?: number;
	otherInteractions?: number;
	screenshot?: string;
	frames?: string[];
};

export const defaultValues = {
	postedAt: '',
	impressions: 0,
	reach: 0,
	comments: 0,
	likes: 0,
	saves: 0,
	url: '',
	shares: 0,
	screenshot: '',
	frames: [],
};

export const mapFormValues = (
	formValues: StatisticsFormValues,
	selectedStoryItems: InstagramUserStory[],
	renderFrameSelector: boolean,
): StatisticsFormValues | StatisticsPayloadForSelectorType => {
	const storySelectorValues = {
		stickerLinkClicks: Number.isInteger(formValues.stickerLinkClicks) ? formValues.stickerLinkClicks : 0,
		stickerTaps: Number.isInteger(formValues.stickerTaps) ? formValues.stickerTaps : 0,
		otherInteractions: Number.isInteger(formValues.otherInteractions) ? formValues.otherInteractions : 0,
		// We need to be able to save these!
		// reachLastFrame: Number.isInteger(formValues.reachLastFrame) ? formValues.reachLastFrame : 0,
		// impressions: Number.isInteger(formValues.impressions) ? formValues.impressions : 0,
		// reach: Number.isInteger(formValues.reach) ? formValues.reach : 0,
		frames: selectedStoryItems.map((item) => item.id),
	};

	const statisticsValues = {
		postedAt: formValues.postedAt,
		url: formValues.url,
		reach: Number.isInteger(formValues.reach) ? formValues.reach : 0,
		reachLastFrame: Number.isInteger(formValues.reachLastFrame) ? formValues.reachLastFrame : 0,
		impressions: Number.isInteger(formValues.impressions) ? formValues.impressions : 0,
		comments: Number.isInteger(formValues.comments) ? formValues.comments : 0,
		likes: Number.isInteger(formValues.likes) ? formValues.likes : 0,
		saves: Number.isInteger(formValues.saves) ? formValues.saves : 0,
		shares: Number.isInteger(formValues.shares) ? formValues.shares : 0,
		screenshot: formValues.screenshot,
		stickerLinkClicks: Number.isInteger(formValues.stickerLinkClicks) ? formValues.stickerLinkClicks : 0,
		stickerTaps: Number.isInteger(formValues.stickerTaps) ? formValues.stickerTaps : 0,
		otherInteractions: Number.isInteger(formValues.otherInteractions) ? formValues.otherInteractions : 0,
	};

	return renderFrameSelector ? storySelectorValues : statisticsValues;
};

export const mockedCIO = {
	type: 'campaignInstagramOwner',
	id: '31751',
	attributes: {
		createdAt: '2022-12-30T12:17:04+00:00',
		joined: true,
		joinedAt: '2022-12-30T12:17:04+00:00',
		declined: false,
		declinedAt: null,
		discountCode: null,
		invoicedAt: null,
		cancelledAt: null,
		collabsId: 'afff24b5-9451-41f7-bf68-83ab6715f3b3',
		contentUsageRightsEnabled: false,
		contentUsageRightsManuallyUpdated: false,
		contentUsageRightsText: null,
		joinStatus: 'joined',
		fromListName: null,
	},
	links: [],
	relationships: {
		influencer: {
			data: {
				type: 'influencer',
				id: '16d36e0a-40ee-48e8-9bd5-461135497fad',
			},
		},
		campaignInstagramOwnerCommission: {
			data: {
				type: 'campaignInstagramOwnerCommission',
				id: 'b8ed75bf-d15f-41f5-b992-50fbf3d84ef4',
			},
		},
		campaign: [],
		campaignInstagramOwnerAssignments: [],
		campaignInstagramOwnerCommissions: [],
		campaignInstagramOwnerProducts: [],
		invite: [],
		group: [],
		agreement: [],
		segment: [],
		fromList: [],
		prePayments: [],
		influencerAssignmentOverviews: [],
	},
};
