import { inject, injectable } from 'inversify';

import { UserModel } from 'api-models';
import { ListUsersQuery } from 'api-queries';
import { UserCollectionResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';

import type UserApiClientInterface from 'services/ApiClient/UserApiClientInterface';

type Repository = ModelRepository<UserModel>;

@injectable()
class UserManager extends ApiManager<Repository> {
	private readonly client: UserApiClientInterface;

	constructor(@inject(ApiCacheManager) cacheManager: ApiCacheManager, @inject(ApiClientService.UserApiClientInterface) client: UserApiClientInterface) {
		super(cacheManager, new ModelRepository<UserModel>('user'));
		this.client = client;
	}

	public listUsers(queryBuilder = RequestQueryBuilder.create<ListUsersQuery>()) {
		const key = `listUsers::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listUsers(queryBuilder.toQuery());

		return this.swr<UserCollectionResponse>(key, fetcher);
	}
}

export default UserManager;
