import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	width: calc(100%);
	display: flex;
	gap: 2rem;
	align-items: center;
	line-height: 1.5;
	a,
	p {
		font-size: 0.7rem;
		color: ${colors.black};
		font-weight: 400;
		text-decoration: none;
		border-bottom: none !important;
		height: 12px;
		margin-top: 0;
		margin-bottom: 0;
		white-space: nowrap;
	}
	p {
		display: block;
	}
	@media screen and (${devices.md}) {
		padding: 1rem;
		padding-left: 0;
		p {
			display: none;
		}
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
