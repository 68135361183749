import styled from 'styled-components';

import colors from 'styles/theme/colors';

const StepsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
`;

const Step = styled.div<{ isActive: boolean; isFirst: boolean }>`
	flex: 1;
	height: 4px;
	background-color: ${({ isActive }) => (isActive ? colors.oceanBlue : colors.ash)};
	border-radius: 2px;
	margin-left: ${({ isFirst }) => (isFirst ? '0' : '8px')};
`;

const Steps = ({ steps, currentStep }: { steps: number; currentStep: number }) => {
	return (
		<StepsContainer>
			{[...Array(steps)].map((_, index) => (
				<Step key={index} data-testid='progress-step' data-active={currentStep > index} isActive={currentStep > index} isFirst={index === 0} />
			))}
		</StepsContainer>
	);
};

export default Steps;
