import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import typography from 'styles/variables/typography';

const Wrapper = styled.tr`
	cursor: pointer;
	width: 100%;
	border: 1px solid #888;
	border-left: none;
	border-right: none;
	border-bottom: none;

	background-color: ${({ theme }) => theme.table.backgroundOdd};

	&:nth-child(even) {
		background-color: ${({ theme }) => theme.table.backgroundEven};
	}

	&.noData {
		opacity: 0.5;
	}
`;

const Td = styled.td<CSSProperties>`
	padding: 0.5rem;
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	width: ${(props) => props.width};
	height: ${(props) => props.maxHeight || '50px'};

	&.assignment-name {
		padding-left: 2rem;
	}
`;

const ExpendWrapper = styled(Wrapper)`
	border-top: none;

	& > ${Td} {
		padding-top: 0px;
	}
`;

const Div = styled.div<CSSProperties>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	column-gap: ${(props) => props.columnGap};
	row-gap: ${(props) => props.rowGap};
	font-family: ${typography.list.fontFamily};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
`;

const InfluencerName = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.fontSize};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 320px;
`;

const Data = styled(Div)`
	font-weight: ${typography.list.fontWeight};
	font-size: ${typography.list.medium.fontSize};
	white-space: nowrap;
`;

const IconContainer = styled.div`
	position: relative;
	max-height: 40px;
	margin-top: 5px;
	margin-right: 5px;
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
	margin-right: 15px;
	margin-bottom: 3px;
`;

const InfluencerSubText = styled(Div)`
	font-family: ${typography.list.accessory.fontFamilies};
	font-size: ${typography.list.small.fontSize};
	white-space: nowrap;
`;

const IconWrapper = styled.div`
	display: flex;

	&.clickable {
		cursor: pointer;
	}
`;

const Styled = {
	Wrapper,
	ExpendWrapper,
	Td,
	Div,
	InfluencerName,
	InfluencerSubText,
	Data,
	IconContainer,
	CustomAvatar,
	IconWrapper,
};

export default Styled;
