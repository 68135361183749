import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';

import Heart from 'assets/animations/Error404.json'; // eslint-disable-line import/extensions
import { useAppSelector } from 'hooks/useUserAppSelector';
import Grid from 'styles/grid/grid';

import Styled from './PageNotFound.style';

/**
 */

type Props = {
	previousUrl?: string;
};

const PageNotFound = ({ previousUrl }: Props) => {
	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const userType = isInfluencer ? 'influencer' : 'publisher';
	const hasAcceptedTOC = !!user[`${userType}TermsOfConditionsAcceptedAt`];

	const redirectUrl = () => {
		if (previousUrl) {
			return hasAcceptedTOC ? previousUrl : '/login';
		} else {
			return hasAcceptedTOC ? '/' : '/login';
		}
	};

	return (
		<Styled.Container data-testid='404-page' role='main'>
			<Styled.Wrapper>
				<Styled.GridContainer>
					<Grid.Column xs={1} lg={2} />
					<Grid.Column xs={10} lg={4}>
						<Styled.HideOnLargeDevice>
							<Player
								src={Heart}
								autoplay={true}
								loop
								style={{ height: '350px', width: '350px' }}
								aria-label='404 error animation'
								aria-hidden='true' // Since this is decorative
							/>
						</Styled.HideOnLargeDevice>
						<Styled.Title>
							<h1>404 Error</h1>
							<h2>No likes for this page.</h2>
						</Styled.Title>
						<Styled.Description>
							<p>
								Sorry, but we could not find the page you were looking for. <br />
								<Link to={redirectUrl()} aria-label='Return to homepage'>
									Return to homepage
								</Link>
							</p>
						</Styled.Description>
					</Grid.Column>
					<Grid.Column xs={1} lg={1} />
					<Grid.Column md={0} lg={2}>
						<Styled.HideOnSmallDevice>
							<Player
								src={Heart}
								autoplay={true}
								loop
								style={{ height: '350px', width: '350px' }}
								aria-label='404 error animation'
								aria-hidden='true' // Since this is decorative
							/>
						</Styled.HideOnSmallDevice>
					</Grid.Column>
				</Styled.GridContainer>
			</Styled.Wrapper>
			<Styled.Footer role='contentinfo'>
				<p>© {new Date().getFullYear()} Collabs AB</p>
				<div>
					<a href='https://www.collabs.se/' target='_blank' rel='noreferrer' aria-label='Visit Collabs website (opens in new tab)'>
						Collabs.se
					</a>
				</div>
			</Styled.Footer>
		</Styled.Container>
	);
};

export default PageNotFound;
