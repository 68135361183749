import classNames from 'classnames';
import moment from 'moment';

import colors from 'styles/theme/colors';

import Styled from './Message.styles';

interface MessageProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	comment: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	user: any;
	isLast: boolean;
	lastMessageRef?: React.RefObject<HTMLDivElement>;
}
const formatDate = (dateString: string) => {
	const date = moment(dateString);
	return date.isSame(moment(), 'day') ? `Today, ${date.format('HH:mm')}` : date.format('DD MMM, HH:mm');
};

const Message = ({ comment, user, isLast, lastMessageRef }: MessageProps) => (
	<Styled.MessageRow
		role='row'
		data-testid={!comment.user.uuid ? 'temp' : `fc-comment-${isLast}`}
		className={classNames({ latest: isLast })}
		ref={isLast ? lastMessageRef : null}
	>
		<Styled.UserAvatar role='presentation'>
			<Styled.CommentAvatar
				name={!comment.user.uuid ? user.initials : comment.user.initials}
				backgroundColor={comment.user.uuid === user.uuid || !comment.user.uuid ? colors.paleGreenTint : colors.ash}
			/>
		</Styled.UserAvatar>
		<Styled.UserMessage role='presentation'>
			<div data-testid='cm-comment-text'>{comment.attributes?.text ?? comment.text}</div>
			<Styled.MessageStatus>
				{!comment.user.uuid ? user.firstName : comment.user.name} - {formatDate(comment.createdAt)}
			</Styled.MessageStatus>
		</Styled.UserMessage>
	</Styled.MessageRow>
);

export default Message;
