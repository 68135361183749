import { Colors } from './types';

const colors = {
	// Black and greys
	black: '#333',
	smoke: '#4d4b4c',
	steel: '#5c5c5c',
	slate: '#888',
	silver: '#c1c1c1',
	ash: '#e3e3e3',
	mist: '#f2f2f2',
	dust: '#c5c5c5',
	cloud: 'rgba(249, 249, 249, 0.94)',
	snow: '#fafafa',
	white: '#fff',

	// Blues
	iceBlue: '#edf3f5',
	oceanBlue: '#104cb6',
	skyBlue: '#7ab2fd',
	blueOnBlack: '#accffe',

	// Greens
	forestGreen: '#072602',
	mint: '#c4eed2',
	seafoam: '#97e5ce',
	emerald: '#4ca867',
	paleGreenTint: '#e2eee6',
	lightMist: '#d1e5d9',
	gentleMeadow: '#d2e5d8',
	softSage: '#c9ded0',

	// Yellows and Oranges
	sunshine: '#f5c767',
	butter: '#fbe5b9',
	spice: '#a35018',

	// Reds
	burgundy: '#420b08',
	rose: '#f2c3c2',
	ruby: '#ff474e',
	deeppurple: '#391448',
	aiPurple: '#cdaafa',

	// Misc
	fog: '#f5f5f5',
	swarmTaupe: '#857f72',
	transparent: 'transparent',

	// Segmnet colors
	blushRose: '#FBE9E7',
	blushRoseDark: '#FFCCBC',
	cottonCandy: '#FCE4EC',
	cottonCandyDark: '#F8BBD0',
	crystalBlue: '#E3F2FD',
	crystalBlueDark: '#BBDEFB',
	goldenCream: '#FFF8E1',
	goldenCreamDark: '#FFECB3',
	icySky: '#E0F2F1',
	icySkyDark: '#B2DFDB',
	lavenderHaze: '#F3E5F5',
	lavenderHazeDark: '#E1BEE7',
	mintBreeze: '#E8F5E9',
	mintBreezeDark: '#C8E6C9',
	paleLemon: '#F9FBE7',
	paleLemonDark: '#F0F4C3',
	peachGlow: '#FFF3E0',
	peachGlowDark: '#FFE0B2',
	powderViolet: '#E8EAF6',
	powderVioletDark: '#C5CAE9',
};

export const baseColors = {
	primary: colors.black,
	secondary: colors.snow,
	success: colors.emerald,
	warning: colors.spice,
	error: colors.burgundy,
	warningBackground: colors.butter,
	errorBackground: colors.rose,
	successBackground: colors.gentleMeadow,
};

export const lightColors: Colors = {
	...baseColors,
	background: colors.snow,
	text: colors.black,
	textDisabled: colors.silver,
	inputs: colors.mist,
	placeholder: colors.slate,
	label: colors.steel,

	link: {
		color: colors.black,
		hover: colors.black,
		disabled: colors.black,
		border: colors.black,
		hoverBorder: colors.rose,
	},
	modal: {
		background: colors.white,
		border: colors.black,
	},
	togglePanel: {
		heading: colors.slate,
		headingActive: colors.black,
		headingHover: colors.black,
		borderBottom: colors.silver,
	},
	input: {
		background: colors.white,
		border: colors.slate,
		borderHover: colors.black,
		borderDisabled: colors.mist,
		placeholder: '#e2e2e2',
		placeholderDisabled: colors.silver,
		focus: '3a71e3',
	},
	textarea: {
		background: 'transparent',
		borderBottom: colors.silver,
		heading: colors.slate,
		headingSelected: colors.black,
	},
	select: {
		placeholder: colors.silver,
		indicator: colors.black,
		controlBorder: colors.black,
		optionBackground: colors.mist,
		optionSelected: colors.black,
		controlBoxshadow: colors.black,
		menuBoxshadow: `${colors.black}29`,
	},
	toggle: {
		checked: colors.emerald,
		unchecked: colors.slate,
		slider: colors.snow,
	},
	table: {
		thead: {
			background: colors.mist,
		},
	},
	toasts: {
		background: colors.black,
		border: 'transparent',
		color: colors.snow,
		iconColor: colors.snow,
		buttonBackground: colors.snow,
		buttonColor: colors.black,
		success: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
		error: {
			background: colors.rose,
			border: 'transparent',
			color: colors.burgundy,
			iconColor: colors.smoke,
			buttonBackground: colors.burgundy,
			buttonColor: colors.rose,
		},
		loading: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
		info: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
	},
	userMenu: {
		link: {
			selected: colors.blueOnBlack,
			normal: colors.oceanBlue,
		},
	},
	avatarBackground: colors.mist,
	inputsDarker: colors.ash,
	tags: colors.oceanBlue,
};

export const darkColors: Colors = {
	...baseColors,
	background: colors.snow,
	text: colors.black,
	textDisabled: colors.silver,
	inputs: colors.mist, // replace inputs with input when we're ready
	placeholder: colors.slate,
	label: colors.steel,
	link: {
		color: colors.black,
		hover: colors.black,
		disabled: colors.black,
		border: colors.black,
		hoverBorder: colors.rose,
	},
	// Legacy stuff
	modal: {
		background: colors.white,
		border: colors.black,
	},
	togglePanel: {
		heading: colors.slate,
		headingActive: colors.black,
		headingHover: colors.black,
		borderBottom: colors.silver,
	},
	input: {
		background: colors.white,
		border: colors.slate,
		borderHover: colors.black,
		borderDisabled: colors.mist,
		placeholder: '#e2e2e2',
		placeholderDisabled: colors.silver,
		focus: '3a71e3',
	},
	textarea: {
		background: 'transparent',
		borderBottom: colors.silver,
		heading: colors.slate,
		headingSelected: colors.black,
	},
	select: {
		placeholder: colors.silver,
		indicator: colors.black,
		controlBorder: colors.black,
		optionBackground: colors.mist,
		optionSelected: colors.black,
		controlBoxshadow: colors.black,
		menuBoxshadow: `${colors.black}29`,
	},
	toggle: {
		checked: colors.emerald,
		unchecked: colors.slate,
		slider: colors.snow,
	},
	table: {
		thead: {
			background: colors.mist,
		},
	},
	toasts: {
		background: colors.black,
		border: 'transparent',
		color: colors.snow,
		iconColor: colors.snow,
		buttonBackground: colors.snow,
		buttonColor: colors.black,
		success: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
		error: {
			background: colors.rose,
			border: 'transparent',
			color: colors.burgundy,
			iconColor: colors.black,
			buttonBackground: colors.burgundy,
			buttonColor: colors.rose,
		},
		loading: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
		info: {
			background: colors.black,
			border: 'transparent',
			color: colors.snow,
			iconColor: colors.snow,
			buttonBackground: colors.snow,
			buttonColor: colors.black,
		},
	},
	userMenu: {
		link: {
			selected: colors.blueOnBlack,
			normal: colors.oceanBlue,
		},
	},
	avatarBackground: colors.mist,
	inputsDarker: colors.ash,
	tags: colors.oceanBlue,
};

export default colors;
