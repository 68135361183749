import axios from 'axios';
import { JsonApiDocument, JsonApiIdentifier, JsonApiResource, Store } from 'json-api-models';
import { filter } from 'lodash';
import { useState } from 'react';
import useSWR, { mutate } from 'swr';

import { MessagesService } from 'services/IntegratedInbox/Messages.service';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import { PresignObject } from './types';

export default function useIntegratedInboxData() {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getCampaigns = (includes?: string, statuses?: string) => {
		const conversationMetaData = 'conversationMetaData:hateoas(false)';

		const params = new URLSearchParams({
			includes: `campaignInstagramOwners.influencer,${conversationMetaData}${includes ? `,${includes}` : ',:hateoas(false)'}`,
			exclude: 'defaultIncludes',
		});

		if (statuses) {
			params.append('statuses', statuses);
		}

		const campaignsStore = new Store();
		const url = `/campaigns?${params}`;

		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			try {
				campaignsStore.sync(data);
			} catch (e) {
				console.error(e);
			}
		}
		return {
			campaignsStore,
			mutation: {
				async add(resource: JsonApiDocument) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: !data && !error,
		};
	};

	const getAllConversations = (withArchivedCampaigns = true) => {
		const conversationsStore = new Store();
		const params = new URLSearchParams({
			includes:
				'influencer:hateoas(false),latestMessage:hateoas(false),latestMessage.influencer,latestMessage.user,unreadMessages:hateoas(false),user:hateoas(false),user.publisher',
			archivedCampaigns: withArchivedCampaigns.toString(),
			direct: 'true',
		});
		params.append('limit', '500');

		const url = `/conversations?${params}`;
		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			conversationsStore.sync(data);
		}

		return {
			conversationsStore,
			mutation: {
				async add(resource: JsonApiDocument) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: !data && !error,
		};
	};

	const getAllCampaignsInfluencers = () => {
		const campaignsInfluencersStore = new Store();
		const url = `/campaigns?includes=campaignInstagramOwners`;

		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			campaignsInfluencersStore.sync(data);
		}

		return {
			campaignsInfluencersStore,
			mutation: {
				async add(resource: JsonApiDocument) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: !data && !error,
		};
	};

	const searchProfiles = async (campaignId: string, query: string, joined: boolean = true) => {
		const response = await MessagesService.searchInfluencers(campaignId, query, joined);
		return response.data;
	};

	return {
		getCampaigns,
		getAllConversations,
		getAllCampaignsInfluencers,
		searchProfiles,
	};
}

export function usePresignUrl() {
	const client = createClient();

	const getPresignUrls = async (files: Array<File>) => {
		const response: Array<PresignObject> = [];

		for (const file of files) {
			try {
				const presignUrlResponse = await client.post('/user-uploads/presign-urls', { filename: file.name });
				const model = new Store();
				model.sync(presignUrlResponse.data);
				const presignUrlData = model.findAll('presignUrl')[0];
				response.push({
					key: presignUrlData.key,
					url: presignUrlData.url,
					userUpload: presignUrlData.links?.userUpload || '',
					file: file,
				});
			} catch (error) {
				toast.error(`Cannnot upload ${file.name}`);
			}
		}
		return response;
	};

	return {
		getPresignUrls,
	};
}

export function useUploadFiles() {
	const client = createClient();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [progress, setProgress] = useState<{ [key: string]: number }>({});

	const uploadAndPollFiles = async (presignData: Array<PresignObject>): Promise<Array<string>> => {
		const ids: Array<string> = [];
		const MAX_NUM_404_ERRORS = 20;
		const INTERVAL_TIME = 1000;
		let num404Errors = 0;
		try {
			setIsLoading(true);
			for (const presignItem of presignData) {
				const uploadResponse = await axios.create().put(presignItem.url, presignItem.file, {
					headers: {
						'Content-Type': presignItem.file.type,
						'Cache-Control': 'max-age=31449600,immutable',
					},
					onUploadProgress: (event) => {
						const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
						const oldObj = { ...progress };
						oldObj[presignItem.key] = percentUploaded;

						if (percentUploaded === 100) {
							delete oldObj[presignItem.key];
						}
						setProgress(oldObj);
					},
				});

				await new Promise<void>((resolve, reject) => {
					const interval = setInterval(async () => {
						try {
							const userUploadResponse = await client.get(presignItem.userUpload);
							if (userUploadResponse.status === StatusCode.OK) {
								clearInterval(interval);

								if (uploadResponse.status === StatusCode.OK) {
									if (!ids.includes(presignItem.key)) {
										ids.push(presignItem.key);
									}
									resolve();
								} else {
									toast.error(`Cannot upload ${presignItem.file.name}`);
									setIsLoading(false);
									reject(uploadResponse.statusText);
								}
							}
						} catch (error) {
							if (axios.isAxiosError(error) && error.response?.status === StatusCode.NOT_FOUND) {
								num404Errors++;
								if (num404Errors >= MAX_NUM_404_ERRORS) {
									clearInterval(interval);
									toast.error(`Cannot upload ${presignItem.file.name}`);
									setIsLoading(false);
									reject(error);
								}
							} else {
								clearInterval(interval);
								toast.error(`Cannot upload ${presignItem.file.name}`);
								setIsLoading(false);
								reject(error);
							}
							setIsLoading(false);
						}
					}, INTERVAL_TIME);
				});
			}
		} catch (error) {
			console.error(error);
		}

		return ids;
	};

	return {
		uploadAndPollFiles,
		isLoading,
		progress,
	};
}
