import { NavigateFunction } from 'react-router-dom';
import { toast } from 'sonner';

import { PrimaryButton } from 'components/Button';
import { CREATE_CAMPAIGN } from 'constants/hateoas-keys';
import usePermissions from 'hooks/usePermissions';

import Styled from './ActionBar.style';

type Props = {
	navigate: NavigateFunction;
	createCampaignIsDisabled: boolean;
};

/**
 * ActionBar
 * @param props
 * @returns {JSX.Element}
 */
const ActionBar = (props: Props): JSX.Element => {
	const { navigate, createCampaignIsDisabled } = props;

	const { userCan } = usePermissions();

	return (
		<Styled.Wrapper>
			{userCan(CREATE_CAMPAIGN) && (
				<PrimaryButton
					icon='expand'
					data-testid='ab-new-campaign'
					onClick={() => {
						if (createCampaignIsDisabled) {
							toast.error('Please update your billing information first.');
						} else {
							navigate('/campaigns/create');
						}
					}}
				>
					New campaign
				</PrimaryButton>
			)}
		</Styled.Wrapper>
	);
};

export default ActionBar;
