import { memo, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';

import { CampaignInstagramOwnerModel, CampaignModel, InfluencerModel } from 'api-models';
import CampaignCard from 'components/Dashboard/Components/CampaignCard';
import { isMobile } from 'shared/utils/navigator';
import IRepository from 'utils/Repository/IRepository';
import CMSidebar from 'views/Campaign/Dashboard/ContentManagement/ContentManagementSidebar';
import { RouteParams } from 'views/Campaign/Dashboard/types';
import { InfluencerAssignmentOverview } from 'views/influencer/InfluencerDashboard/Components/CampaignSection/types';

import CampaignItem from './CampaignItem/CampaignItem';
import Styled from './Campaigns.style';

/**
 * @returns {JSX.Element}
 */
const Campaigns = memo(
	({
		campaigns,
		displaySkeletonLoader,
		repository,
		campaignItems,
	}: {
		campaigns: CampaignModel[];
		displaySkeletonLoader: boolean;
		repository: IRepository;
		campaignItems: InfluencerAssignmentOverview[];
	}): JSX.Element => {
		const navigate = useNavigate();
		const [modalIsOpen, setModalIsOpen] = useState(false);
		const { campaignId, assignmentId, ownerId } = useParams<RouteParams>();

		useEffect(() => {
			if (assignmentId && ownerId && campaignId) {
				setModalIsOpen(true);
			} else {
				setModalIsOpen(false);
			}
		}, [assignmentId, ownerId, campaignId]);

		const thisCampaign = campaigns.find((c) => c.id === campaignId || c.attributes.shortId === campaignId);

		const selectedCIOs = thisCampaign && repository.findByRelation<CampaignInstagramOwnerModel, CampaignModel>(thisCampaign, 'campaignInstagramOwners');
		const selectedCIO = selectedCIOs?.find((cio) => cio.id === ownerId);
		const influencerId = selectedCIO ? repository.findOneByRelation<InfluencerModel, CampaignInstagramOwnerModel>(selectedCIO, 'influencer')?.id || '' : '';

		const campaign = useMemo(() => campaigns.find((c) => c.attributes?.shortId === campaignId || c.id === campaignId), [campaigns, campaignId]);

		const renderColumn = (filterFn: (index: number) => boolean) =>
			campaignItems
				.filter((_, index) => filterFn(index))
				.map((item, i) => {
					return displaySkeletonLoader ? (
						<Skeleton height='500px' width='600px' key={'skeleton'} />
					) : (
						<CampaignCard
							key={i}
							index={i}
							userName={item.username}
							profilePictureUrl={item.profilePictureUrl}
							network={item.network}
							influencerId={item.influencerId}
							brandName={item.brandName}
							unreadMessages={item.unreadMessages}
							campaignId={item.campaignId}
							campaignName={item.campaignName}
							repository={repository}
							coverImage={{
								sm: item.coverImage.sm ?? '',
								md: item.coverImage.md ?? '',
								lg: item.coverImage.lg ?? '',
							}}
							inboxNavigation={item.archived ? null : `/influencer/inbox/${item.campaignId}`}
						>
							<CampaignItem
								CIOId={item.campaignInstagramOwnerId}
								campaignId={item.campaignId}
								assignments={item.items}
								setModalIsOpen={() => setModalIsOpen(true)}
							/>
						</CampaignCard>
					);
				});

		return (
			<Styled.Wrapper>
				{isMobile() ? (
					<Styled.Column>
						{campaignItems.map((item, index) => {
							return displaySkeletonLoader ? (
								<Skeleton height='500px' width='600px' key={`skeleton-${index}`} />
							) : (
								<CampaignCard
									key={`${item.campaignId}-${item.campaignInstagramOwnerId}`}
									index={index}
									userName={item.username}
									profilePictureUrl={item.profilePictureUrl}
									network={item.network}
									influencerId={item.influencerId}
									brandName={item.brandName}
									unreadMessages={item.unreadMessages}
									campaignId={item.campaignId}
									campaignName={item.campaignName}
									repository={repository}
									coverImage={{
										sm: item.coverImage.sm ?? '',
										md: item.coverImage.md ?? '',
										lg: item.coverImage.lg ?? '',
									}}
									inboxNavigation={`/influencer/inbox/${item.campaignId}`}
								>
									<>
										<CampaignItem
											CIOId={item.campaignInstagramOwnerId}
											campaignId={item.campaignId}
											assignments={item.items}
											setModalIsOpen={() => setModalIsOpen(true)}
										/>
									</>
								</CampaignCard>
							);
						})}
					</Styled.Column>
				) : (
					<>
						<Styled.Column>{renderColumn((index) => index % 2 === 0)}</Styled.Column>
						<Styled.Column>{renderColumn((index) => index % 2 !== 0)}</Styled.Column>
					</>
				)}
				{modalIsOpen && campaign && (
					<CMSidebar
						selectedCIO={selectedCIO}
						campaign={campaign}
						influencerId={influencerId}
						campaigns={campaigns}
						onClose={() => {
							navigate(-1);
							setModalIsOpen(false);
						}}
						AssignmentsListRef={undefined}
						repository={repository}
					/>
				)}
			</Styled.Wrapper>
		);
	},
);

export default Campaigns;
