import DOMPurify from 'dompurify';

import PromoCodes from 'components/PromoCodes/PromoCodes';
import colors from 'styles/theme/colors';
import { DetailItem } from 'views/Campaign/CreateCampaign/types';

import { Styled } from './DetailSection.style';
import { DetailSectionProps } from './types';

/**
 * DetailSection
 * @param {DetailSectionProps} props
 * @returns {JSX.Element}
 */
const DetailSection = ({ activeCampaign, activeAssignment, campaignCode }: DetailSectionProps): JSX.Element => {
	return (
		<Styled.Wrapper data-testid='detail-section'>
			{activeCampaign && activeAssignment && (
				<>
					{campaignCode && <PromoCodes promoCodes={campaignCode ? [campaignCode] : []} backgroundColor={colors.snow} />}
					<Styled.Container data-testid='brief-container'>
						{activeAssignment?.attributes?.details?.length ? (
							<>
								{activeAssignment?.attributes?.details.map((detail: DetailItem, index: number) => {
									return (
										<Styled.DetailItem key={detail.title}>
											<Styled.DetailTitle>{detail.title}</Styled.DetailTitle>
											<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail.content) }} />
											{index < activeAssignment?.attributes?.details.length - 1 ? <hr /> : null}
										</Styled.DetailItem>
									);
								})}
							</>
						) : (
							<Styled.DetailText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activeAssignment?.attributes.description) }} /> // For old assignments
						)}
					</Styled.Container>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default DetailSection;
