import Styled from './Footer.style';

const Footer = () => {
	return (
		<Styled.Wrapper as='footer' data-testid='footer' aria-label='Site footer'>
			<p>&copy; {new Date().getFullYear()} Collabs AB</p>
			<a href='https://www.collabs.se/terms-of-service' target='_blank' rel='noreferrer' aria-label='Read Collabs Terms of Service (opens in new tab)'>
				Terms of Service
			</a>
			<a href='https://www.collabs.se/privacy-policy' target='_blank' rel='noreferrer' aria-label='Read Collabs Privacy Policy (opens in new tab)'>
				Privacy Policy
			</a>
		</Styled.Wrapper>
	);
};

export default Footer;
