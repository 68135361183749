import classNames from 'classnames';
import { useState } from 'react';

import Icon from 'components/Icon';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { formatAmount } from 'utils/formatters';

import Styled from './PaidMediaItem.style';
import { PaidMediaStatistic } from './types';

/**
 * Paid Media list item
 * @returns {JSX.Element}
 */
const PaidMediaItem = (props: { paidMediaItem: PaidMediaStatistic; i: number }): JSX.Element => {
	const { paidMediaItem, i } = props;
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<number | null>(null);

	const renderTd = (value: number, i: number) => {
		return (
			<Styled.Td
				textAlign='center'
				width='100px'
				onMouseEnter={() => {
					setSelectedValue(i), setDisplayRealValue(true);
				}}
				onMouseLeave={() => {
					setSelectedValue(null), setDisplayRealValue(false);
				}}
			>
				{displayRealValue && i === selectedValue ? value : formatNumber(value, true)}
			</Styled.Td>
		);
	};
	return (
		<Styled.Wrapper className={classNames(i % 2 ? 'dark' : 'light')} data-testid='paid-media-item'>
			<Styled.Td>{paidMediaItem.name}</Styled.Td>
			<Styled.Td textAlign='center'>
				<Icon name={paidMediaItem.platform} size='20' />
			</Styled.Td>
			{renderTd(paidMediaItem.reach, i)}
			{renderTd(paidMediaItem.impressions, i)}
			{renderTd(paidMediaItem.frequency, i)}
			{renderTd(paidMediaItem.cpm, i)}
			{renderTd(paidMediaItem.clicks, i)}
			{renderTd(paidMediaItem.ctr, i)}
			<Styled.Td textAlign='center'>{formatAmount(paidMediaItem.cpc, paidMediaItem.currency)}</Styled.Td>
			{renderTd(paidMediaItem.registrationsCompleted, i)}
			{renderTd(paidMediaItem.conversions, i)}
		</Styled.Wrapper>
	);
};

export default PaidMediaItem;
