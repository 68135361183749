import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { devices } from 'styles/variables/media-queries';
type T = { theme: DefaultThemeV2 };

const Card = styled.div`
	display: flex;
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	gap: 32px;
	flex-direction: column;
	&.client {
		background-color: ${colors.powderViolet};
	}
	@media screen and (${devices.lg}) {
		flex-direction: row;
	}
`;

const Contact = styled.div`
	h4 {
		font-size: 0.875rem;
		font-weight: ${fontWeights.bold};
	}
`;

const UserList = styled.ul`
	padding-left: 0 !important;
	color: ${colors.smoke} !important;
`;

const AdminsTitle = styled.h5`
	font-weight: 600;
	font-size: 0.875rem;
	margin-bottom: 0;
	margin-right: 8px;
	span {
		color: ${colors.smoke};
		font-weight: ${fontWeights.bold};
	}
`;

const NameContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

export default {
	Card,
	Contact,
	UserList,
	AdminsTitle,
	NameContainer,
};
