import classNames from 'classnames';

import Icon from 'components/Icon';
import colors from 'styles/variables/colors';

import Styled from './ErrorMessages.style';

export type StepsType = {
	title: string;
	component?: JSX.Element;
	display: boolean;
	onClick?: () => void;
	showLink?: boolean;
};

export type ErrorMessagesProps = {
	title: string;
	subTitle: string;
	Steps: Array<StepsType>;
};
/**
 * Error message component.
 * @returns {JSX.Element}
 */
const ErrorMessages = ({ title, subTitle, Steps }: ErrorMessagesProps): JSX.Element => {
	return Steps.filter((step: StepsType) => step.display).length > 0 ? (
		<div id='errors' style={{ width: '100%' }}>
			<Styled.Wrapper
				title={
					<Styled.AccordionTitle>
						<span>{title}</span>
					</Styled.AccordionTitle>
				}
				open
				iconSize='16'
				hoverBackgroundColor={colors.errorDarkRed}
				hoverIconColor={colors.mainWhite}
				className='arrow-right-and-down'
			>
				<Styled.SubTitle>{subTitle}</Styled.SubTitle>
				<Styled.StepWrapper>
					{Steps.filter((step: StepsType) => step.display).map((step: StepsType, i: number) => {
						return (
							<Styled.Step key={i} onClick={step.onClick}>
								<Styled.StepContent className={classNames({ clickable: step.showLink })}>
									<div className='align-top'>
										<h5>{step.title}</h5>
										{step.component}
									</div>
									{step.showLink && (
										<div className='align-center'>
											<Icon name='chevron-right' size='24' />
										</div>
									)}
								</Styled.StepContent>
							</Styled.Step>
						);
					})}
				</Styled.StepWrapper>
			</Styled.Wrapper>
		</div>
	) : (
		<></>
	);
};

export default ErrorMessages;
