import styled from 'styled-components';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import newColors from 'styles/theme/colors';

import { FieldProps } from './types';

type fieldProps = Pick<FieldProps, 'orientation'> & {};

const Hint = styled.div`
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.placeholder};
`;

const Field = styled.div<fieldProps>`
	width: 100%;
	display: grid;
	align-items: center;
	${({ theme }) => theme.mediaQueries.md} {
		grid-template-rows: ${({ orientation }) => (orientation === 'horizontal' ? 'auto auto auto 1fr' : undefined)};
		grid-template-columns: ${({ orientation }) => (orientation === 'horizontal' ? '20% 1fr' : undefined)};
	}

	> label {
		margin-bottom: 4px;
		color: ${newColors.steel};
		${({ theme }) => theme.mediaQueries.md} {
			margin-top: ${({ orientation }) => (orientation === 'horizontal' ? '-16px' : undefined)};
			grid-row-end: ${({ orientation }) => (orientation === 'horizontal' ? -1 : undefined)};
			grid-row-start: ${({ orientation }) => (orientation === 'horizontal' ? 1 : undefined)};
		}
	}
`;

const FieldErrorMessage = styled(ErrorMessage)`
	white-space: nowrap;
`;

const FieldComponent = styled.div`
	display: grid;
	gap: 8px;
	&.display-error-message-after {
		display: flex !important;
		min-width: 350px;
		width: 100%;
		max-height: 30px !important;
	}
`;

const Styled = {
	Field,
	Hint,
	FieldErrorMessage,
	FieldComponent,
};

export default Styled;
