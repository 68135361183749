/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateBrandPayload, UpdateBrandPrivilegePayload, UpdateBrandNewPayload, CreateBrandInviteNewPayload, UpdateBrandLogoPayload } from 'api-payloads';
import {
	ListBrandsQuery,
	CreateBrandQuery,
	UpdateBrandPrivilegeQuery,
	UpdateBrandNewQuery,
	ListBrandInvitesQuery,
	UpdateBrandLogoQuery,
	ListBrandPrivilegesQuery,
} from 'api-queries';
import {
	BrandCollectionResponse,
	BrandResponse,
	BrandPrivilegeResponse,
	OrganizationUserCollectionResponse,
	BrandInviteCollectionResponse,
	BrandPrivilegeCollectionResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import BrandApiClientInterface from './BrandApiClientInterface';

@injectable()
export default class BrandApiClient implements BrandApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Delete brand invite
	 */
	async deleteInvite(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/brand-invites/${id}`);
		return response.data;
	}

	/**
	 * List brands
	 */
	async listBrands(params: ListBrandsQuery): Promise<BrandCollectionResponse> {
		const response = await this.client.get<BrandCollectionResponse>(`/brands`, { params });
		return response.data;
	}

	/**
	 * Create brand
	 */
	async create(payload: CreateBrandPayload, params: CreateBrandQuery): Promise<BrandResponse> {
		const response = await this.client.post<BrandResponse>(`/brands`, payload, { params });
		return response.data;
	}

	/**
	 * Delete brand privilege
	 */
	async deletePrivilege(brandId: string, userId: string): Promise<void> {
		const response = await this.client.delete<void>(`/brands/${brandId}/users/${userId}`);
		return response.data;
	}

	/**
	 * Update brand privilege
	 */
	async updatePrivilege(
		brandId: string,
		userId: string,
		payload: UpdateBrandPrivilegePayload,
		params: UpdateBrandPrivilegeQuery,
	): Promise<BrandPrivilegeResponse> {
		const response = await this.client.patch<BrandPrivilegeResponse>(`/brands/${brandId}/users/${userId}`, payload, { params });
		return response.data;
	}

	/**
	 * Delete brand
	 */
	async deleteNew(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/brands/${id}`);
		return response.data;
	}

	/**
	 * Update brand
	 */
	async updateNew(id: string, payload: UpdateBrandNewPayload, params: UpdateBrandNewQuery): Promise<BrandResponse> {
		const response = await this.client.patch<BrandResponse>(`/brands/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * List users available
	 */
	async listUsersAvailable(id: string, params: { include?: string }): Promise<OrganizationUserCollectionResponse> {
		const response = await this.client.get<OrganizationUserCollectionResponse>(`/brands/${id}/available-users`, { params });
		return response.data;
	}

	/**
	 * List brand invites
	 */
	async listInvites(id: string, params: ListBrandInvitesQuery): Promise<BrandInviteCollectionResponse> {
		const response = await this.client.get<BrandInviteCollectionResponse>(`/brands/${id}/brand-invites`, { params });
		return response.data;
	}

	/**
	 * Create brand invite
	 */
	async createInviteNew(id: string, payload: CreateBrandInviteNewPayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/brands/${id}/invites`, payload, { params });
		return response.data;
	}

	/**
	 * Update brand logo
	 */
	async updateLogo(id: string, payload: UpdateBrandLogoPayload, params: UpdateBrandLogoQuery): Promise<BrandResponse> {
		const payloadForm = new FormData();
		payloadForm.set('file', payload);

		const response = await this.client.post<BrandResponse>(`/brands/${id}/logo`, payloadForm, {
			...{ params },
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		return response.data;
	}

	/**
	 * Delete brand logo
	 */
	async deleteLogo(id: string): Promise<void> {
		const response = await this.client.delete<void>(`/brands/${id}/logo`);
		return response.data;
	}

	/**
	 * List privileges
	 */
	async listPrivileges(id: string, params: ListBrandPrivilegesQuery): Promise<BrandPrivilegeCollectionResponse> {
		const response = await this.client.get<BrandPrivilegeCollectionResponse>(`/brands/${id}/privileges`, { params });
		return response.data;
	}

	/**
	 * @deprecated
	 */
	async delete(publisherId: string, clientId: string, brandId: string): Promise<void> {
		const response = await this.client.delete<void>(`/publishers/${publisherId}/clients/${clientId}/brands/${brandId}`);
		return response.data;
	}
}
