/* eslint-disable unused-imports/no-unused-vars */
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Icon from 'components/Icon';
import CampaignList from 'components/IntegratedInbox/Components/CampaignList';
import InfluencerList from 'components/IntegratedInbox/Components/InfluencerList';
import ConversationList from 'components/IntegratedInbox/influencer/ConversationList';
import { ConversationOverview } from 'components/IntegratedInbox/types';
import SearchInput from 'components/SearchInput';
import Tooltip from 'components/Tooltip';
import Grid from 'styles/grid/grid';

import Styled from './CampaignSection.style';
import { CampaignSectionProps } from './types';

/**
 * It renders CampaignList and InfluencerList for campaign manager and ConversationList for influencer on the left side of integrated inbox view
 *
 */
const NavigationSection = ({
	directConversations,
	selectedDirectConversation,
	onSelectDirectConversation,
	campaigns,
	onSelectCampaign,
	selectedCampaign,
	onReset,
	onClickTabs,
	selectedCampaignInfluencers,
	selectedCampaignConversations,
	isFetchingCampaigns,
	isFetchingInfluencers,
	onOpenBlast,
	onSelectInfluencer,
	selectedInfluencer,
	isInfluencer,
	next,
	isLoadingNext,
	onSearch,
	conversationOverview,
	selectConversation,
}: CampaignSectionProps) => {
	const [isLeftColVisible, setIsLeftColVisible] = useState<boolean>(true);
	const [isInfluencerListVisible, setIsInfluencerListVisible] = useState<boolean>(false);
	const [search, setSearch] = useState('');
	const [filteredConversations, setFilteredConversations] = useState<ConversationOverview[]>(conversationOverview);

	const params = useParams();
	const publisherHasSelectedCampaign = !isInfluencer && selectedCampaign;

	useEffect(() => {
		setFilteredConversations(conversationOverview);
	}, [conversationOverview?.length]);

	useEffect(() => {
		if (selectedCampaign) {
			setIsInfluencerListVisible(true);
		} else {
			setIsInfluencerListVisible(false);
		}
	}, [selectedCampaign]);

	useEffect(() => {
		if (isInfluencer) {
			if (params.campaignId) {
				setIsLeftColVisible(false);
			} else {
				setIsLeftColVisible(true);
			}
		} else {
			if (params.conversationId || selectedInfluencer) {
				setIsLeftColVisible(false);
			} else {
				setIsLeftColVisible(true);
			}
		}
	}, [params.campaignId, params.conversationId, selectedInfluencer]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		if (search.length > 0) {
			const filtered = conversationOverview.filter((conversation) => {
				return conversation.attributes.campaignName?.toLowerCase().includes(search.toLowerCase());
			});
			setFilteredConversations(filtered);
		} else {
			setFilteredConversations(conversationOverview);
		}
	}, [search]);

	const onResetSearch = () => {
		setSearch('');
	};
	return (
		<Grid.Column sm={3} className={classNames('three-col', { 'active-col': isLeftColVisible })}>
			<Styled.SideNavContainer>
				{publisherHasSelectedCampaign ? (
					<Styled.SideNavHeading className={classNames({ back: !isInfluencer && isInfluencerListVisible })}>
						{!isInfluencer && (
							<Styled.BackButton onClick={onReset} className={classNames({ visible: isInfluencerListVisible })}>
								<Icon name='arrow-left' size='18' />
							</Styled.BackButton>
						)}
						<h1 onClick={onReset}>{selectedCampaign?.name}</h1>
						{publisherHasSelectedCampaign ? (
							<Tooltip content='Send campaign message'>
								<Styled.BlastIconWrapper onClick={onOpenBlast}>
									<Icon name='new-message' size='20' />
								</Styled.BlastIconWrapper>
							</Tooltip>
						) : null}
					</Styled.SideNavHeading>
				) : null}

				<Styled.Content>
					{isInfluencer && selectConversation ? (
						<>
							<Styled.InputWrapper>
								<SearchInput value={search} onKeyUp={() => {}} onChange={handleSearchChange} placeholder='Search campaign or sender' onReset={onResetSearch} />
							</Styled.InputWrapper>
							<ConversationList isFetching={isFetchingCampaigns} conversationOverview={filteredConversations} selectConversation={selectConversation} />
						</>
					) : (
						<>
							<CampaignList
								isFetching={isFetchingCampaigns}
								campaigns={campaigns}
								selectedCampaign={selectedCampaign}
								onSelect={onSelectCampaign!}
								directConversations={directConversations!}
								selectedDirectConversation={selectedDirectConversation!}
								onSelectDirect={onSelectDirectConversation!}
							/>
							{selectedCampaignInfluencers && (
								<InfluencerList
									campaignInstagramOwners={selectedCampaignInfluencers}
									conversations={selectedCampaignConversations!}
									isVisible={isInfluencerListVisible}
									selectedCampaign={selectedCampaign}
									isLoading={isFetchingInfluencers!}
									onSelect={onSelectInfluencer!}
									selectedInfluencer={selectedInfluencer}
									onClickTab={onClickTabs!}
									next={next!}
									isLoadingNext={isLoadingNext}
									onSearch={onSearch!}
								/>
							)}
						</>
					)}
				</Styled.Content>
			</Styled.SideNavContainer>
		</Grid.Column>
	);
};

export default NavigationSection;
