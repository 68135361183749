import classNames from 'classnames';
import { Model, Store } from 'json-api-models';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
import TableComponent, { Column, GenericData } from 'components/Table/V2/Table';
import Tabs from 'components/Tabs/Tabs';
import useModels from 'hooks/useModels';
import { pathCampaignDashboard } from 'routing/PathLookup';
import PublisherService from 'services/Publisher';
import BillingInformation from 'views/Auth/PublisherSignupContainer/components/BillingInformation/BillingInformation';

import ActionBar from './Components/ActionBar';
import Campaigns from './Components/Campaigns';
import { CampaignCardType } from './Components/Campaigns/types';
import Styled from './DashboardContainer.style';
import { TabType, Status } from './types';

/**
 * DashboardContainer
 * @returns {JSX.Element}
 */
const DashboardContainer = (): JSX.Element => {
	const { models: featuredCampaigns, mutation } = useModels('/campaigns/featured?includes=brand,conversationMetaData');
	const {
		models: campaigns,
		isFetching: isFetchingAll,
		mutation: allCampaignsMutation,
	} = useModels('/campaigns?includes=brand,conversationMetaData&statuses=draft,active&order=createdAt&sort=DESC');

	const { models: archivedCampaigns, mutation: archivedMutation } = useModels('/campaigns?includes=brand&statuses=archived&order=archivedAt&sort=DESC');

	const [publisherHasMissingBillingInfo, setPublisherHasMissingBillingInfo] = useState<Model | undefined>(undefined);
	const [isFetchingPublishers, setIsFetchingPublishers] = useState(false);
	const fetchPublishers = () => {
		setIsFetchingPublishers(true);
		PublisherService.getPublishersByRole().then((res) => {
			const models = new Store();
			models.sync(res.data);
			const publishers = models.findAll('publisher');
			const activePublishers = publishers.filter((publisher: Model) => !publisher.attributes?.disabledAt);

			// Check if any active publisher is missing billing email or VAT number
			const missingInfo = activePublishers.find((publisher: Model) => !publisher.attributes?.invoiceEmail || !publisher.attributes?.vatNumber);
			setPublisherHasMissingBillingInfo(missingInfo);
			setIsFetchingPublishers(false);
		});
	};

	useEffect(() => {
		fetchPublishers();
	}, []);

	const featuredCampaignItems = featuredCampaigns.findAll('campaign') ?? [];
	const archived = archivedCampaigns.findAll('campaign') ?? [];

	const all = campaigns.findAll('campaign') ?? [];
	const drafts = all?.filter((campaign) => campaign.status === Status.DRAFT) ?? [];
	const active = all?.filter((campaign) => campaign.status === Status.ACTIVE) ?? [];

	const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ALL);
	const [dataToDisplay, setDataToDisplay] = useState<CampaignCardType[]>(all);

	const columns: Column<CampaignCardType>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ row }) => <Styled.Status className={classNames({ active: row.original.status === Status.ACTIVE })}>{row.original.status}</Styled.Status>,
			},
			{
				Header: 'Brand',
				accessor: 'brand',
				Cell: ({ row }) => <div>{row.original.brand?.name}</div>,
			},
			{
				Header: 'Created',
				accessor: 'createdAt',
				Cell: ({ row }) => moment(row.original.createdAt).format('YYYY-MM-DD'),
			},
			{
				Header: '',
				accessor: 'id',
				disableSortBy: true,
				Cell: () => (
					<div className='align-right'>
						<Icon name='arrow-right' size='12' />
					</div>
				),
			},
		],
		[],
	);

	const navigate = useNavigate();

	useEffect(() => {
		switch (selectedTab) {
			case TabType.ALL:
				return setDataToDisplay(all);
			case TabType.ACTIVE:
				return setDataToDisplay(active);
			case TabType.DRAFTS:
				return setDataToDisplay(drafts);
			case TabType.ARCHIVED:
				return setDataToDisplay(archived);
			default:
				setDataToDisplay(all);
		}
	}, [selectedTab]);

	useEffect(() => {
		setDataToDisplay(all);
	}, [isFetchingAll]);

	const handleSelectedRow = (values: GenericData) => {
		return navigate(pathCampaignDashboard(`${values.shortId}`));
	};

	return (
		<Styled.Wrapper>
			<PageHeader userGreeting showBreadcrumb={false} customHeadline={<>My campaigns</>}>
				<ActionBar navigate={navigate} createCampaignIsDisabled={!!publisherHasMissingBillingInfo || isFetchingPublishers} />
			</PageHeader>
			<Styled.Content>
				{publisherHasMissingBillingInfo && (
					<div style={{ width: '500px' }}>
						<ErrorMessages
							title='Billing information missing'
							subTitle=''
							Steps={[
								!publisherHasMissingBillingInfo.attributes?.invoiceEmail
									? {
											title: '',
											component: (
												<BillingInformation
													publisher={publisherHasMissingBillingInfo}
													onDashboard={true}
													publisherEdit={`/publishers/${publisherHasMissingBillingInfo.id}`}
													onSubmit={() => {
														fetchPublishers();
														toast.success('Billing information updated');
													}}
												/>
											),
											display: true,
										}
									: null,
							]?.filter(Boolean)}
						/>
					</div>
				)}
				<Campaigns
					dataTestId='featured-campaigns'
					campaigns={featuredCampaignItems}
					refresh={mutation.refresh}
					refreshAll={allCampaignsMutation.refresh}
					refreshArchived={archivedMutation.refresh}
				/>
				<Styled.SearchSection>
					<Tabs
						id='tab-section'
						tabs={[
							{
								title: TabType.ALL,
								amount: all.length,
							},
							{
								title: TabType.ACTIVE,
								amount: active.length,
							},
							{
								title: TabType.DRAFTS,
								amount: drafts.length,
							},
							{
								title: TabType.ARCHIVED,
								amount: archived.length,
							},
						]}
						selectedTab={selectedTab}
						setSelectedTab={(tab: string) => setSelectedTab(tab as TabType)}
					/>
					{isFetchingAll && dataToDisplay.length === 0 ? (
						<Styled.SpinnerContainer>
							<LoadingSpinner />
						</Styled.SpinnerContainer>
					) : (
						<TableComponent
							dataTestId='campaign-table'
							className='on-start-page'
							showSearch
							columns={columns}
							data={dataToDisplay}
							enableSorting={true}
							getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
						/>
					)}
				</Styled.SearchSection>
			</Styled.Content>
		</Styled.Wrapper>
	);
};

export default DashboardContainer;
