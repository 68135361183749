import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import Field from 'components/Forms/Field';
import Navigation from 'components/Navigation/Navigation';
import PageHeader from 'components/PageHeader';
import SelectMenu from 'components/SelectMenu';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import Billing from './Billing/Billing';
import BrandManagement from './BrandManagement/BrandManagement';
import ClientManagement from './ClientManagement/ClientManagement';
import BrandDetails from './Components/BrandDetails/BrandDetails';
import ClientDetails from './Components/ClientDetails/ClientDetails';
import DeliveryInfoSection from './DeliveryInfo';
import MyAccountSection from './MyAccount/MyAccountSection';
import MyCompany from './MyCompany/MyCompany';
import ProfilesSection from './Profiles';
import Styled from './Settings.style';
import { OrganizationHierarchyRole, Titles } from './types';

const InfluencerSettings = () => {
	const location = useLocation();
	const influencerMenu = [
		{ title: Titles.ACCOUNT, icon: 'user', url: '/influencer/settings/account', testId: 'account', disabled: false },
		{ title: Titles.DELIVERYINFO, icon: 'credit-card', url: '/influencer/settings/delivery-info', testId: 'delivery-info', disabled: false },
		{ title: Titles.INSTAGRAM, icon: 'instagram', url: '/influencer/settings/instagram', testId: 'instagram', disabled: false },
	];

	const renderContent = useMemo(() => {
		switch (location.pathname) {
			case '/influencer/settings/account':
				return <MyAccountSection />;
			case '/influencer/settings/profiles':
			case '/influencer/settings/instagram':
			case '/influencer/settings/tiktok':
				return <ProfilesSection />;
			case '/influencer/settings/delivery-info':
				return <DeliveryInfoSection />;
			default:
				return <div>No settings selected.</div>;
		}
	}, [location.pathname]);

	return (
		<Styled.Wrapper data-testid='settings-page'>
			<Grid.Container>
				<Styled.HeaderColumn lg={12}>
					<PageHeader headline='Settings' showBreadcrumb={false} showCurrentDate={false} />
				</Styled.HeaderColumn>
			</Grid.Container>
			<Styled.StyledGridContainer>
				<Styled.FixedColumn lg={3} xs={12}>
					<Navigation Menu={influencerMenu} />
				</Styled.FixedColumn>
				<Styled.ScrollableColumn lg={9} xs={12}>
					<div style={{ marginTop: '60px' }}>{renderContent}</div>
				</Styled.ScrollableColumn>
			</Styled.StyledGridContainer>
		</Styled.Wrapper>
	);
};

const NonInfluencerSettings = () => {
	const [publisher, setPublisher] = useState<PublisherModel | undefined>(undefined);
	const user = useAppSelector((state) => state.user);
	const { clientId, brandId } = useParams();
	const location = useLocation();
	const manager = useInjection<PublisherManager>(PublisherManager);

	const { repository, mutate: mutatePublishers } = manager.listPublishers(
		RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'createInvitation', 'createClient', 'deleteLogo']),
	);
	const publishers = repository.findAll('publisher') as PublisherModel[];
	const activePublishers = publishers?.filter((publisher) => !publisher.attributes?.disabledAt);
	const userHasRoleOnPublishers = activePublishers?.filter((publisher) => user.permissions.entities?.[publisher.id]?.role);
	const isAdminOnPublisher = activePublishers?.some((publisher) => user.permissions.entities?.[publisher.id]?.role === OrganizationHierarchyRole.ADMINISTRATOR);

	useEffect(() => {
		if (userHasRoleOnPublishers && !publisher) {
			setPublisher(userHasRoleOnPublishers[0] as PublisherModel);
		}
	}, [userHasRoleOnPublishers]);

	useEffect(() => {
		if (publisher) {
			const updatedPublisher = repository.find('publisher', publisher.id);
			if (updatedPublisher) {
				setPublisher(updatedPublisher as PublisherModel);
			}
		}
	}, [repository, publisher]);

	const adminMenu = useMemo(
		() =>
			[
				{ title: Titles.ACCOUNT, icon: 'user', url: '/settings/account', testId: 'account', disabled: false },
				user.links?.publishers ? { title: Titles.COMPANY, icon: 'store', url: '/settings/company', testId: 'company', disabled: false } : null,
				isAdminOnPublisher ? { title: Titles.BILLING, icon: 'credit-card', url: '/settings/billing', testId: 'billing', disabled: false } : null,
				user.links?.clients ? { title: Titles.CLIENTS, icon: 'influencer', url: '/settings/clients', testId: 'clients', disabled: false } : null,
				user.links?.brands ? { title: Titles.BRANDS, icon: 'brand', url: '/settings/brands', testId: 'brands', disabled: false } : null,
			].filter(Boolean),
		[user, isAdminOnPublisher],
	);

	const renderContent = useMemo(() => {
		if (brandId) return <BrandDetails />;
		if (clientId) return <ClientDetails />;

		switch (location.pathname) {
			case '/settings/clients':
				return publisher && <ClientManagement publisher={publisher} />;
			case '/settings/brands':
				return publisher && <BrandManagement publisher={publisher} />;
			case '/settings/account':
				return <MyAccountSection />;
			case '/settings/delivery-info':
				return <DeliveryInfoSection />;
			case '/settings/company':
				return (
					publisher && (
						<MyCompany
							setPublisher={setPublisher}
							publisher={publisher}
							publishers={userHasRoleOnPublishers as PublisherModel[]}
							mutatePublishers={mutatePublishers}
						/>
					)
				);
			case '/settings/billing':
				return publisher && <Billing setPublisher={setPublisher} publisher={publisher} publishers={userHasRoleOnPublishers as PublisherModel[]} />;
			default:
				return <div>No settings selected.</div>;
		}
	}, [brandId, clientId, location.pathname, publisher, userHasRoleOnPublishers]);

	return (
		<Styled.Wrapper data-testid='creation-form'>
			<Grid.Container>
				<Styled.HeaderColumn lg={12}>
					<PageHeader headline='Settings' showBreadcrumb={false} showCurrentDate={false} />
				</Styled.HeaderColumn>
			</Grid.Container>
			<Styled.StyledGridContainer>
				<Styled.FixedColumn lg={3} xs={12}>
					{userHasRoleOnPublishers.length > 1 && (
						<div style={{ marginBottom: '16px' }}>
							<Field label='Select organization'>
								<SelectMenu
									placeholder='Select organization'
									onChange={(option) => {
										setPublisher(option?.value);
									}}
									options={userHasRoleOnPublishers.map((pub: PublisherModel) => ({
										value: pub,
										label: pub.attributes.name,
									}))}
									value={{ value: publisher, label: publisher?.attributes.name }}
								/>
							</Field>
						</div>
					)}
					<Navigation Menu={adminMenu} />
				</Styled.FixedColumn>
				<Styled.ScrollableColumn lg={9} xs={12}>
					{renderContent}
				</Styled.ScrollableColumn>
			</Styled.StyledGridContainer>
		</Styled.Wrapper>
	);
};

const SettingsContainer = (): JSX.Element => {
	const user = useAppSelector((state) => state.user);
	return user.permissions.isInfluencer ? <InfluencerSettings /> : <NonInfluencerSettings />;
};

export default SettingsContainer;
