import Styled from './LinkAsSecondaryButton.style';

/**
 * Link that looks like ghost button
 * @returns {JSX.Element}
 */
const LinkAsSecondaryButton = (props: {
	to: string;
	children: string | JSX.Element;
	onClick?: () => void;
	state?: object;
	className?: string;
}): JSX.Element => {
	return (
		<Styled.StyledLink role='button' state={props.state} to={props.to} onClick={props.onClick && props.onClick} className={props.className}>
			<span>{props.children}</span>
		</Styled.StyledLink>
	);
};

export default LinkAsSecondaryButton;
