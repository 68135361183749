import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
	h4 {
		margin-bottom: 0;
	}
`;

export default {
	Wrapper,
	HeaderWrapper,
};
