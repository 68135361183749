import { Model } from 'json-api-models';

import { ApiStatus, Network } from 'constants/socialMedia';
import { useAppSelector } from 'hooks/useUserAppSelector';
import IRepository from 'utils/Repository/IRepository';

export const useInfluencer = ({ selectedCIO, repository, assignmentNetwork }: { selectedCIO: Model; repository: IRepository; assignmentNetwork: Network }) => {
	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const influencer = selectedCIO && repository.findOneByRelation(selectedCIO, 'influencer');

	const INFLUENCER_NETWORK = influencer?.attributes?.network;
	const API_STATUS = influencer?.attributes?.apiStatus;

	const INFLUENCER_PROFILE_CONNECTED = API_STATUS === ApiStatus.CONNECTED && assignmentNetwork === INFLUENCER_NETWORK;
	const FETCH_STORIES_URL = influencer?.links?.instagramStories;

	return {
		isInfluencer,
		influencer,
		INFLUENCER_NETWORK,
		API_STATUS,
		INFLUENCER_PROFILE_CONNECTED,
		FETCH_STORIES_URL,
	};
};
