import { Model } from 'json-api-models';

export class DirectConversationData {
	id: string;
	creator: Model;
	creatorInitials: string;
	creatorProfilePictureUrl: string;
	recipient: Model;
	name: string;
	profilePictureUrl: string;
	latestMessage: Model;
	hasUnreadMessages: boolean;
	directConversation: Model;

	constructor(directConversation: Model) {
		this.id = directConversation.id;
		this.creator = directConversation.createdBy;
		this.creatorInitials = this.creator?.initials || '';
		this.creatorProfilePictureUrl = this.creator?.profilePictureUrl || '';
		this.recipient = directConversation.influencer;
		this.name = this.recipient?.username;
		this.profilePictureUrl = this.recipient.profilePictureUrl;
		this.latestMessage = directConversation.latestMessage;
		this.hasUnreadMessages = directConversation.unreadMessages && directConversation.unreadMessages.length > 0 ? true : false;
		this.directConversation = directConversation;
	}
}
