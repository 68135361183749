import { Model } from 'json-api-models';

import { UserType } from 'reducers/UserReducers/types';

export const getCampaignInvitesUri = () => {
	const includes = ['commission', 'influencer'];

	const query = new URLSearchParams({
		includes: includes.join(','),
		used: 'false',
	});

	return `/campaign-invites?${query}`;
};

export const getSelectedProfile = (campaign: Model, user?: UserType) => {
	return campaign.campaignInstagramOwners.find((CIO: Model) => CIO.influencer?.id === user?.influencer?.id);
};

export class CampaignCompensation {
	commissions: Model[];
	products: Model[];
	totalCommission: number;
	currency: string;

	constructor(campaignInstagramOwner: Model) {
		if (campaignInstagramOwner && campaignInstagramOwner.campaignInstagramOwnerCommissions.length > 0) {
			this.commissions = campaignInstagramOwner.campaignInstagramOwnerCommissions;
			this.totalCommission = campaignInstagramOwner.campaignInstagramOwnerCommissions?.reduce(
				(prev: number, current: Model) => prev + current.commission.fixedAmount,
				0,
			);
			this.currency = campaignInstagramOwner.campaignInstagramOwnerCommissions[0]?.commission?.fixedAmountCurrency;
			this.products = campaignInstagramOwner.campaignInstagramOwnerProducts ? campaignInstagramOwner.campaignInstagramOwnerProducts : [];
		} else {
			this.commissions = [];
			this.totalCommission = 0;
			this.currency = '';
			this.products = [];
		}
	}
}
