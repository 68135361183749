import axios, { AxiosError } from 'axios';
import { JsonApiDocument, Store } from 'json-api-models';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import DownloadPDFLink from 'components/NewBriefPage/Components/JoinModal/TermsAndConditions/DownloadPDFLink';
import { UserUploadObj } from 'components/NewBriefPage/types';
import { getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import CampaignsService from 'services/Campaign';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import { usePresignUrl } from 'views/Campaign/CreateCampaign/hooks';

import Styled from './UploadTermsAndConditions.style';

const UploadTermsAndConditions = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [file, setFile] = useState<File | null>();
	const [uploadedPdf, setUploadedPdf] = useState<UserUploadObj | null>(null);

	const { getPresignUrls } = usePresignUrl();
	const params = useParams();
	const client = createClient();
	const inputRef = useRef<HTMLInputElement | null>(null);

	const fetchCampaign = () => {
		createClient()
			.get<JsonApiDocument>(`/campaigns/${params.campaignId}`, { params: { includes: 'brand,users,termsAndConditionsFile' } })
			.then(({ data }) => {
				const models = new Store();
				models.sync(data);
				const pdf = models.findAll('userUpload');
				pdf && setUploadedPdf(pdf[0]);
			})
			.catch((e: AxiosError) => {
				console.error(e);
			});
	};

	useEffect(() => {
		return () => {
			setIsLoading(false);
			setFile(null);
		};
	}, []);

	const uploadFile = async (file: File) => {
		setIsLoading(true);
		try {
			const response = await getPresignUrls(file);
			const uploadResponse = await axios.create().put(response?.attributes.url, file, {
				headers: {
					'Content-Type': 'application/pdf',
					'Cache-Control': 'max-age=31449600,immutable',
				},
			});

			if (uploadResponse && response) {
				const INTERVAL_TIME = 1000;
				const MAX_NUM_404_ERRORS = 10;
				let num404Errors = 0;
				const interval = setInterval(async () => {
					try {
						const userUploadResponse = await client.get(response?.links.userUpload);
						if (userUploadResponse.status === 200) {
							clearInterval(interval);
							CampaignsService.addTermsAndConditionsPdf(params.campaignId || '', response?.id)
								.then(() => {
									fetchCampaign();
									setIsLoading(false);
									toast.success(`${file.name} uploaded to campaign`);
								})
								.catch((error: AxiosError) => {
									console.error(error);
									toast.error(getSomethingWentWrongMessage());
								});
						}
					} catch (error) {
						if (axios.isAxiosError(error) && error?.response?.status === StatusCode.NOT_FOUND) {
							num404Errors++;
							if (num404Errors >= MAX_NUM_404_ERRORS) {
								toast.error(`Failed to upload ${file.name}`);
								setIsLoading(false);
								clearInterval(interval);
							}
						}
					}
				}, INTERVAL_TIME);
			}
		} catch (e) {
			toast.error(getSomethingWentWrongMessage());
			console.error('Unexpected error: %O', e);
		}
	};

	const removePdf = () => {
		setIsLoading(true);

		if (inputRef && inputRef.current) {
			inputRef.current.value = '';
		}

		CampaignsService.addTermsAndConditionsPdf(params.campaignId || '', '')
			.then(() => {
				toast.success('PDF deleted');
				setUploadedPdf(null);
				setFile(null);
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error(getErrorMessageOnPost('saving the pdf'));
			});
		setIsLoading(false);
	};

	useEffect(() => {
		if (file) {
			uploadFile(file);
		}
	}, [file]);

	return (
		<Styled.Wrapper>
			<Styled.Input
				ref={inputRef}
				type='file'
				accept='application/pdf'
				onChange={(e) => {
					setFile(e.target.files![0]);
				}}
			/>
			{uploadedPdf && (
				<Styled.FileWrapper>
					<DownloadPDFLink userUpload={uploadedPdf} />
					<Styled.Delete onClick={() => removePdf()}>
						<Icon name='cross' size='16' />
					</Styled.Delete>
				</Styled.FileWrapper>
			)}
			{isLoading && (
				<Styled.SpinnerContainer>
					<LoadingSpinner size='sm' />
				</Styled.SpinnerContainer>
			)}
		</Styled.Wrapper>
	);
};

export default UploadTermsAndConditions;
