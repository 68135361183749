import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PublisherInviteModel, PublisherPrivilegeModel, UserModel, PublisherModel } from 'api-models';
import { ListPublishersQuery } from 'api-queries';
import { SecondaryButton } from 'components/Button';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import Styled from 'components/Settings/Components/ClientDetails/ClientDetails.style';
import EditPublisherName from 'components/Settings/Components/EditPublisherName/EditPublisherName';
import Hr from 'components/Settings/Components/Hr/Hr';
import InvitationList from 'components/Settings/Components/InvitationList/InvitationList';
import InviteUsers from 'components/Settings/Components/InviteUsers/InviteUsers';
import LogoAndAdmins from 'components/Settings/Components/LogoAndAdmins/LogoAndAdmins';
import SubTitle from 'components/Settings/Components/SubTitle/SubTitle';
import UserListCard from 'components/Settings/Components/UserListCard/UserListCard';
import UsersWithRolesList from 'components/Settings/Components/UsersWithRolesList/UsersWithRolesList';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { CREATE_INVITATION, EDIT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { pathSettingsClients } from 'routing/PathLookup';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import toast from 'services/Toast';
import Grid from 'styles/grid/grid';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

const PublisherDetails = ({ setEditMode }: { setEditMode: (editMode: boolean) => void }) => {
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const { publisherId } = useParams();

	const manager = useInjection<PublisherManager>(PublisherManager);
	const queryBuilder = RequestQueryBuilder.create<ListPublishersQuery>(['logo', 'edit', 'delete', 'createInvitation', 'deleteLogo']).withInclude('brands', [
		'logo',
		'createInvitation',
		'edit',
		'delete',
	]);
	const { repository: publisherRepo, isLoading: isLoadingClients, mutate: mutateFn } = manager.listPublishers(queryBuilder);
	const publisher = publisherId ? publisherRepo.find<PublisherModel>('publisher', publisherId) : undefined;

	const { repository, mutate } = manager.listUsersAndInvitations(publisherId!);

	const invites: PublisherInviteModel[] = repository.findAll<PublisherInviteModel>('publisherInvite');
	const userPrivileges: PublisherPrivilegeModel[] = repository.findAll<PublisherPrivilegeModel>('publisherPrivilege');

	const { userCan } = useFeaturePermissions(publisher?.links);

	function getUserFromPublisherPrivilege(privilege: PublisherPrivilegeModel): UserModel {
		return repository.findOneByRelation<UserModel, PublisherPrivilegeModel>(privilege, 'user') as UserModel;
	}

	const listAdmins = (): UserModel[] => {
		return userPrivileges
			.filter((privilege: PublisherPrivilegeModel) => privilege.attributes.role === OrganizationHierarchyRole.ADMINISTRATOR)
			.map((privilege: PublisherPrivilegeModel) => getUserFromPublisherPrivilege(privilege));
	};

	const inviteUsersToPublisher = (emails: string[], role: OrganizationHierarchyRole): Promise<void> => {
		return manager.inviteUsers(publisher!.id, { role: role, emails: emails }).then(() => mutate());
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const changeUserRole = async (target: EventTarget & HTMLSelectElement, user: UserModel) => {
		target.disabled = true;
		setIsLoading(true);

		try {
			if (target.value === 'delete') {
				await manager.deleteUser(publisher!.id, user.id);
				toast.success('Member removed');
			} else {
				await manager.updateRole(publisher!.id, user.id, { role: target.value });
				toast.success('Role updated');
			}
			mutate();
		} catch (e) {
			if (target.value !== 'delete') {
				console.error(e);
				toast.error(getErrorMessageOnPost('changing the role'));
			}
		} finally {
			setIsLoading(false);
			target.disabled = false;
		}
	};

	const deleteInvite = (inviteId: string) => {
		manager
			.deleteInvite(publisher!.id, inviteId)
			.then(() => {
				toast.success('Invite deleted');
				mutate();
			})
			.catch((e: AxiosError) => {
				console.error(e);
				toast.error(getErrorMessageOnPost('deleting the invite'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const deleteImage = () => {
		publisher &&
			manager.deleteLogo(publisher.id).then(() => {
				mutateFn();
				toast.success('Logo deleted');
			});
	};

	useEffect(() => {
		if (!publisherId) {
			navigate(pathSettingsClients());
		}
	}, [publisherId]);

	return isLoadingClients || !publisher ? (
		<CenteredSpinner />
	) : (
		publisher && (
			<>
				<Styled.ButtonsWrapper>
					<EditPublisherName publisher={publisher} canEdit={userCan(EDIT)} />
					<SecondaryButton onClick={() => setEditMode(true)}>Manage publisher</SecondaryButton>
				</Styled.ButtonsWrapper>
				<Grid.Container gap='16'>
					<Grid.Column xl={12}>
						<Styled.StyledCard>
							<LogoAndAdmins
								admins={listAdmins()}
								item={publisher}
								uploadFn={(publisherId: string, file: File) => manager.uploadLogo(publisherId, file)}
								canEdit={userCan(EDIT)}
								deleteImageFn={deleteImage}
							/>
						</Styled.StyledCard>
					</Grid.Column>
					<Grid.Column xl={12}>
						<Hr />
					</Grid.Column>
					<Grid.Column xl={12}>
						<SubTitle text={`Who has access to ${publisher.attributes?.name}`}></SubTitle>
						<p style={{ marginBottom: '40px' }}>
							{userCan(CREATE_INVITATION)
								? `Add or manage who has access to all of ${publisher.attributes?.name}'s campaigns, including all of its associated brands.`
								: ''}
						</p>
						<UserListCard
							title='Members with access'
							text={
								<p>
									{userCan(CREATE_INVITATION) ? `Share access to ${publisher.attributes?.name} by inviting individuals.` : null} People with access can view and
									be assigned to all of <span>{publisher.attributes?.name}</span>'s campaigns.{' '}
									{userCan(CREATE_INVITATION) ? 'Permissions can be edited at any time.' : null}
								</p>
							}
						>
							<>
								{userCan(CREATE_INVITATION) && (
									<InviteUsers
										availableRoles={(publisher?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
										inviteTitle={`Add to ${publisher.attributes.name}`}
										buttonText='Invite member'
										drawerTitle={`Client management`}
										testId='invite-client-users'
										inviteFn={(emails, role) => inviteUsersToPublisher(emails, role)}
										companyName={publisher.attributes.name}
										roles={userPrivileges}
										invites={invites}
										deleteInvite={(inviteId: string) => deleteInvite(inviteId)}
										getUser={getUserFromPublisherPrivilege}
										changeUserRole={changeUserRole}
									/>
								)}
								<Styled.ListWrapper>
									<UsersWithRolesList
										availableRoles={(publisher?.attributes.availableRoles as OrganizationHierarchyRole[]) ?? []}
										roles={userPrivileges}
										changeUserRole={changeUserRole}
										getUser={getUserFromPublisherPrivilege}
										canEdit={userCan(CREATE_INVITATION)}
									/>
									<InvitationList invites={invites} deleteInvite={deleteInvite} canEdit={userCan(CREATE_INVITATION)} />
								</Styled.ListWrapper>
							</>
						</UserListCard>
					</Grid.Column>
				</Grid.Container>
			</>
		)
	);
};

export default PublisherDetails;
