import styled from 'styled-components';

const Wrapper = styled.div`
	overflow: hidden;
`;

const ListWrapper = styled.ul`
	padding: 0;
	list-style-type: none;
	padding-bottom: 3rem;
	width: calc(100% - 16px);
	height: 70vh;
	overflow-y: auto;
	overflow-x: hidden;
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Styled = {
	Wrapper,
	ListWrapper,
	SpinnerWrapper,
};

export default Styled;
