/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { CreateConversationPayload, CreateBlastMessagePayload, CreateMessagePayload, CreateConversationMessagePayload } from 'api-payloads';
import {
	CampaignListConversationsQuery,
	CampaignLatestMessagesQuery,
	ListConversationsQuery,
	CreateConversationQuery,
	ListLatestMessagesQuery,
	GetUnreadCountQuery,
	ViewConversationQuery,
	CreateMessageQuery,
	ListConversationMessagesQuery,
	CreateConversationMessageQuery,
} from 'api-queries';
import {
	ConversationCollectionResponse,
	ConversationMessageCollectionResponse,
	ConversationOverviewCollectionResponse,
	ConversationResponse,
	CountResponse,
	ConversationMessageResponse,
} from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import ConversationApiClientInterface from './ConversationApiClientInterface';

@injectable()
export default class ConversationApiClient implements ConversationApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List conversations
	 */
	async campaignListConversations(id: string, params: CampaignListConversationsQuery): Promise<ConversationCollectionResponse> {
		const response = await this.client.get<ConversationCollectionResponse>(`/campaigns/${id}/conversations`, { params });
		return response.data;
	}

	/**
	 * List latest messages for this user and campaign.
	 *
	 * List latest messages
	 */
	async campaignLatestMessages(id: string, params: CampaignLatestMessagesQuery): Promise<ConversationMessageCollectionResponse> {
		const response = await this.client.get<ConversationMessageCollectionResponse>(`/campaigns/${id}/messages`, { params });
		return response.data;
	}

	/**
	 * List conversation overviews
	 */
	async listOverviews(params: { include?: string }): Promise<ConversationOverviewCollectionResponse> {
		const response = await this.client.get<ConversationOverviewCollectionResponse>(`/conversation-overviews`, { params });
		return response.data;
	}

	/**
	 * List conversations
	 */
	async listConversations(params: ListConversationsQuery): Promise<ConversationCollectionResponse> {
		const response = await this.client.get<ConversationCollectionResponse>(`/conversations`, { params });
		return response.data;
	}

	/**
	 * Create conversation
	 */
	async create(payload: CreateConversationPayload, params: CreateConversationQuery): Promise<ConversationResponse> {
		const response = await this.client.post<ConversationResponse>(`/conversations`, payload, { params });
		return response.data;
	}

	/**
	 * Create blast message
	 */
	async createBlastMessage(payload: CreateBlastMessagePayload, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/conversations/blasts`, payload, { params });
		return response.data;
	}

	/**
	 * List the latest messages for this user.
	 *
	 * List latest messages
	 */
	async listLatestMessages(params: ListLatestMessagesQuery): Promise<ConversationMessageCollectionResponse> {
		const response = await this.client.get<ConversationMessageCollectionResponse>(`/conversations/messages`, { params });
		return response.data;
	}

	/**
	 * Get unread count
	 */
	async getUnreadCount(params: GetUnreadCountQuery): Promise<CountResponse> {
		const response = await this.client.get<CountResponse>(`/conversations/unread/total`, { params });
		return response.data;
	}

	/**
	 * Set message as read
	 */
	async setMessageAsRead(conversationId: string, conversationMessageId: string, params: { include?: string }): Promise<void> {
		const response = await this.client.post<void>(`/conversations/${conversationId}/messages/${conversationMessageId}/reads`, undefined, { params });
		return response.data;
	}

	/**
	 * View conversation
	 */
	async view(id: string, params: ViewConversationQuery): Promise<ConversationResponse> {
		const response = await this.client.get<ConversationResponse>(`/conversations/${id}`, { params });
		return response.data;
	}

	/**
	 * Create message
	 */
	async createMessage(id: string, payload: CreateMessagePayload, params: CreateMessageQuery): Promise<ConversationResponse> {
		const response = await this.client.post<ConversationResponse>(`/conversations/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * List conversation messages
	 */
	async listMessages(id: string, params: ListConversationMessagesQuery): Promise<ConversationMessageCollectionResponse> {
		const response = await this.client.get<ConversationMessageCollectionResponse>(`/conversations/${id}/messages`, { params });
		return response.data;
	}

	/**
	 * Create conversation message
	 */
	async createConversationMessage(
		id: string,
		payload: CreateConversationMessagePayload,
		params: CreateConversationMessageQuery,
	): Promise<ConversationMessageResponse> {
		const response = await this.client.post<ConversationMessageResponse>(`/influencers/${id}/conversation-messages`, payload, { params });
		return response.data;
	}
}
