import { Model } from 'json-api-models';

export const groupByCampaignInstagramOwnerId = (overview: Model[]) => {
	return (
		overview &&
		Object.values(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			overview.reduce((acc: { [key: string]: any }, item: Model) => {
				const ownerId = item.attributes?.campaignInstagramOwnerId;

				if (!acc[ownerId]) {
					acc[ownerId] = {
						campaignInstagramOwnerId: ownerId,
						brandName: item.attributes?.brandName,
						campaignId: item.attributes?.campaignId,
						campaignName: item.attributes?.campaignName,
						network: item.attributes?.network,
						profilePictureUrl: item.attributes?.profilePictureUrl,
						influencerId: item.attributes?.influencerId,
						unreadMessages: item.attributes?.unreadMessages,
						username: item.attributes?.username,
						archived: item.attributes?.archived,
						coverImage: {
							sm: item.links?.smallCoverPhoto ?? '',
							md: item.links?.mediumCoverPhoto ?? '',
							lg: item.links?.largeCoverPhoto ?? '',
						},
						items: [],
					};
				}

				acc[ownerId].items.push({
					assignmentName: item.attributes.assignmentName,
					startDate: item.attributes.startDate,
					newFancyStatus: item.attributes.newFancyStatus,
					assignmentId: item.id,
				});

				return acc;
			}, {}),
		)
	);
};
