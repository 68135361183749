import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const InfoBoxWrapper = styled.div`
	position: relative;
	.icon {
		cursor: pointer;
	}
`;

const InfoBox = styled.div`
	display: none;
	width: 88vw;
	max-width: 343px;
	&.display {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: -20px;
		top: 30px;
		z-index: 10;
		border-radius: ${borderRadius.s};
		background-color: ${colors.mainBlack};
		padding: 1rem 1rem 1rem 1.5rem;
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
		p {
			margin: 0;
			color: ${colors.mainWhite};
			font-size: 0.875rem;
		}
	}
`;

const Arrow = styled.div<{ right: string }>`
	width: 12px;
	height: 12px;
	background-color: ${colors.mainBlack};
	position: absolute;
	top: -5px;
	right: ${(props) => props.right};
	border-radius: 2px;
	transform: rotate(45deg);
`;

const Close = styled.div`
	cursor: pointer;
	position: relative;
	right: 0;
	color: ${colors.mainWhite};
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: end;
	.icon path {
		fill: ${colors.mainWhite};
	}
	.icon {
		margin-left: 8px;
	}
`;

const Styled = {
	InfoBoxWrapper,
	Close,
	Arrow,
	InfoBox,
};

export default Styled;
