import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import { translateRole } from 'components/Settings/Components/InviteTeamMembers/InviteTeamMembers';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { OrganizationHierarchyRole, RoleTranslation } from 'components/Settings/types';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './UsersWithRolesList.style';
import { UserListWithRolesProps } from './type';

const UsersWithRolesList = ({ roles, getUser, changeUserRole, canEdit, availableRoles }: UserListWithRolesProps) => {
	const me = useAppSelector((state) => state.user);

	const displayDescriptionOfRole = (role: string) => {
		switch (role) {
			case OrganizationHierarchyRole.ADMINISTRATOR:
				return RoleTranslation.ADMINISTRATOR;
			case OrganizationHierarchyRole.CAMPAIGN_MANAGER:
				return RoleTranslation.CAMPAIGN_MANAGER;
			case OrganizationHierarchyRole.BRAND_MANAGER:
				return RoleTranslation.BRAND_MANAGER;
		}
	};

	// Group roles by role type
	const groupedRoles = Array.isArray(roles)
		? roles.reduce(
				(acc, role) => {
					const roleType = role.attributes?.role;
					if (!acc[roleType]) {
						acc[roleType] = [];
					}
					acc[roleType].push(role);
					return acc;
				},
				{} as Record<string, typeof roles>,
			)
		: {};

	// Sort users within each group (you first)
	Object.keys(groupedRoles).forEach((roleType) => {
		groupedRoles[roleType].sort((a, b) => {
			const userA = getUser(a);
			const userB = getUser(b);
			if (!userA || !userB) return 0;
			if (userA.id === me.id) return -1;
			if (userB.id === me.id) return 1;
			return 0;
		});
	});

	return (
		<Styled.UserList>
			{Object.entries(groupedRoles).map(([roleType, users]) => (
				<div key={roleType}>
					<Heading as='h6' className='mt-24'>
						{displayDescriptionOfRole(roleType)}
					</Heading>
					{users.map((role) => {
						const user = getUser(role);
						return (
							<Styled.ListItem key={user.id}>
								<Styled.ItemAvatar title={user?.attributes?.name || ''}>
									<UserAvatar fullName={user?.attributes?.name} displayRole displayThisRole={user?.attributes?.email ?? ''} className='dark' medium />
								</Styled.ItemAvatar>
								{me?.id !== user?.id
									? canEdit && (
											<div className='select'>
												<Styled.Select value={role.attributes?.role ?? ''} onChange={(event) => changeUserRole(event.target, user)}>
													<option value=''>Select option</option>
													{Array.isArray(availableRoles) &&
														availableRoles.map((role) => (
															<option key={role} value={role}>
																{translateRole(role)}
															</option>
														))}

													<option value='delete'>Remove</option>
												</Styled.Select>
												<Icon name='arrow-down' size='14' />
											</div>
										)
									: ' (You)'}
							</Styled.ListItem>
						);
					})}
				</div>
			))}
		</Styled.UserList>
	);
};

export default UsersWithRolesList;
