import classNames from 'classnames';
import { useContext } from 'react';

import { PrimaryButton, SecondaryButton, TertiaryButton } from 'components/Button';
import FilterDrawer from 'components/Discovery/Components/FilterDrawer';
import Styled from 'components/Discovery/DiscoveryContainer.style';
import Pill from 'components/Pill';
import { SideDrawer } from 'components/SideDrawer';
import DiscoveryContext from 'contexts/Discovery';

type FilterSectionProps = {
	setShowFilter: (showFilter: boolean) => void;
	showFilter: boolean;
};

const FilterSection = ({ setShowFilter, showFilter }: FilterSectionProps) => {
	const { filter, resetFilter, setHasSearched } = useContext(DiscoveryContext);

	const filterCount = filter !== null ? Object.entries(filter).filter(([key, value]) => value !== null && value !== '' && key !== 'networks').length : 0;

	return (
		<>
			<Styled.FilterButtonAndTag>
				<Styled.ClearFilterButtonContainer>
					<TertiaryButton
						icon='filters'
						onClick={() => {
							setShowFilter(!showFilter);
						}}
						className={classNames('filters', { 'filter-opened': showFilter })}
					>
						Filter {filterCount > 0 ? <Pill className='discovery-filter' title={`${filterCount}`} /> : null}
					</TertiaryButton>
					{filterCount > 0 ? (
						<Styled.FilterSummaryWrapper>
							<Styled.ClearFilterButton onClick={resetFilter}>Clear filters</Styled.ClearFilterButton>
						</Styled.FilterSummaryWrapper>
					) : null}
				</Styled.ClearFilterButtonContainer>
			</Styled.FilterButtonAndTag>
			<SideDrawer
				sidebarIsOpen={showFilter}
				onClose={() => setShowFilter(false)}
				dataTestId='discovery-filters'
				title={`All Filters (${filterCount})`}
				isExpandable
				customButtons={
					<>
						<SecondaryButton onClick={resetFilter}>Clear all filters</SecondaryButton>
						<PrimaryButton
							onClick={() => {
								setShowFilter(false), setHasSearched(true);
							}}
						>
							View results
						</PrimaryButton>
					</>
				}
			>
				<FilterDrawer />
			</SideDrawer>
		</>
	);
};

export default FilterSection;
