import styled from 'styled-components';

import { pageHeaderHeight } from 'styles/variables/general';
import { breakpoints, devices } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Inner = styled.div`
	align-items: center;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin: 0 24px;
	text-align: center;

	@media only screen and (${devices.md}) {
		display: grid;
		gap: 1.5rem;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		margin: 0 16px;
	}
`;

const LeftContainer = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: auto auto;
	justify-content: start;

	@media only screen and (${devices.md}) {
		gap: 16px;
		grid-column: 1/4;
		grid-template-columns: auto 1fr;
	}
`;

const Heading = styled.h1`
	${typography.headings.h6};
	margin-bottom: 0;
`;

const RightContainer = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: auto auto;
	justify-content: flex-end;

	@media only screen and (${devices.md}) {
		grid-column: 10/-1;
	}
`;

const PageHeader = styled.div`
	padding: 0 0.5rem;
	&,
	${Inner} {
		height: ${pageHeaderHeight};
	}
`;

const PageHeading = styled.h1`
	margin-bottom: 16px;
	font-size: 1.0625rem;
	font-weight: 600;
	@media (min-width: ${breakpoints.md}) {
		font-weight: 700;
		font-size: 1.5rem;
		margin-bottom: 0;
	}
`;

const Styled = {
	PageHeading,
	PageHeader,
	Inner,
	LeftContainer,
	Heading,
	RightContainer,
};

export default Styled;
