import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Model } from 'json-api-models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CampaignModel } from 'api-models';
import { ConverImage } from 'components/Dashboard/Components/Campaigns/types';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import IconButton from 'components/IconButton';
import InfluencerAvatar from 'components/InfluencerAvatar';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Pill from 'components/Pill';
import { Network } from 'constants/socialMedia';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CampaignsService from 'services/Campaign';
import toast from 'services/Toast';
import colors from 'styles/theme/colors';
import IRepository from 'utils/Repository/IRepository';

import Styled from './CampaignCard.style';

/**
 * CampaignCard
 * @param {ICampaignCardProps} props
 * @returns  {JSX.Element}
 */
const CampaignCard = ({
	coverImage,
	first,
	brandName,
	className,
	canEdit,
	refresh,
	refreshAll,
	refreshArchived,
	children,
	userName,
	profilePictureUrl,
	network,
	influencerId,
	unreadMessages,
	campaignId,
	campaignName,
	inboxNavigation,
	index,
}: {
	coverImage: ConverImage;
	first?: boolean;
	canEdit?: boolean;
	refresh?: () => void;
	refreshAll?: () => void;
	refreshArchived?: () => void;
	repository?: IRepository;
	children?: JSX.Element;
	className?: string;
	campaign?: CampaignModel | Model;
	userName?: string;
	profilePictureUrl?: string;
	network?: string;
	influencerId?: string;
	brandName: string;
	unreadMessages: number;
	campaignId: string;
	campaignName: string;
	inboxNavigation: string | null;
	index: number;
}): JSX.Element => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const archiveCampaign = () => {
		setIsLoading(true);
		CampaignsService.archiveCampaign(campaignId)
			.then(() => {
				toast.success('The campaign has been added to the archive');
				refresh && refresh();
				refreshAll && refreshAll();
				refreshArchived && refreshArchived();
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error(getErrorMessageOnPost('archiving this campaign'));
			})
			.finally(() => {
				setIsLoading(false);
				setIsModalOpen(false);
			});
	};

	return (
		<Styled.CardWrapper
			key={index}
			data-testid='campaign-card'
			className={classNames({ first: first })}
			onClick={() => className === 'isPublisher' && navigate(`/campaigns/${campaignId}`)}
		>
			<Styled.NameTagWrapper>
				<Styled.NameTag>
					{isInfluencer && userName && (
						<InfluencerAvatar id={userName} userName={userName} network={network as Network} displayNetwork={true} profileImageUrl={profilePictureUrl ?? ''} />
					)}
					<span>{campaignName}</span>
					{userName && <Pill backgroundColor={colors.paleGreenTint} color={colors.forestGreen} title={userName} />}
				</Styled.NameTag>

				{inboxNavigation && (
					<IconButton
						displayNotification={unreadMessages > 0}
						iconName='mail'
						onClick={(e) => {
							e.stopPropagation();
							navigate(`${inboxNavigation}`);
						}}
					/>
				)}
				<Styled.Divider />
				<Dropdown icon='options' size='16'>
					<DropdownMenu>
						{canEdit && (
							<DropdownItem
								onClick={(e) => {
									e.stopPropagation();
									navigate(`/campaigns/${campaignId}/edit`);
								}}
							>
								Edit campaign
							</DropdownItem>
						)}
						<DropdownItem
							onClick={(e) => {
								e.stopPropagation();
								navigate(isInfluencer ? `/influencer/campaigns/${campaignId}/brief/${influencerId}` : `/campaigns/${campaignId}/preview`);
							}}
						>
							View brief
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</Styled.NameTagWrapper>
			<Styled.Card sm={coverImage.sm} md={coverImage.md} lg={coverImage.lg} className={className}>
				{children}
			</Styled.Card>
			<Styled.BrandTagWrapper>
				<Styled.BrandTag>
					<span>{brandName}</span>
				</Styled.BrandTag>
			</Styled.BrandTagWrapper>
			<ConfirmModal
				IconBackgroundColor={colors.skyBlue}
				icon='archive'
				isModalOpen={isModalOpen}
				toggleModal={() => setIsModalOpen(!isModalOpen)}
				title={`You are about to archive ${campaignName}`}
				isFetching={isLoading}
				action={archiveCampaign}
				buttonText='Archive'
			/>
		</Styled.CardWrapper>
	);
};

export default CampaignCard;
