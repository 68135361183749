import { RefObject } from 'react';

import InputText from 'components/Form/FormikElements/Text';
import Countries from 'utils/countries.json'; // eslint-disable-line import/extensions

//  Denmark, Finland, Norway and Sweden;
const PRIORITIZED_COUNTRIES = ['SWE', 'DNK', 'FIN', 'NOR', 'ESP', 'FRA', 'GRC', 'PRT', 'NLD', 'DEU'];

type Props = {
	name: string;
	label?: string;
	forwardRef?: RefObject<HTMLSelectElement>;
	required?: boolean;
};

/**
 */
const CountrySelector = ({ name, label, forwardRef, required }: Props) => {
	return (
		<InputText
			forwardRef={forwardRef}
			data-testid={`fc-${name}`}
			label={label || 'Country'}
			name={name}
			icon='chevron-down'
			iconPosition='right'
			required={required}
			smallIcon
			as='select'
		>
			<option>Select Country</option>
			<optgroup label=''>
				{Countries.filter(({ code }) => PRIORITIZED_COUNTRIES.includes(code)).map(({ name, code }) => (
					<option value={code} key={code}>
						{name}
					</option>
				))}
			</optgroup>
			<optgroup label='Others'>
				{Countries.filter(({ code }) => !PRIORITIZED_COUNTRIES.includes(code)).map(({ name, code }) => (
					<option value={code} key={code}>
						{name}
					</option>
				))}
			</optgroup>
		</InputText>
	);
};

export default CountrySelector;
