import styled from 'styled-components';

import Accordion from 'components/Accordion';
import { detailItem, detailText, detailTitle } from 'components/NewBriefPage/NewBreifPage.style';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-top: 40px;

	background-color: ${colors.mainWhite};
`;

const HeaderSubText = styled.div`
	margin-bottom: ${guttersWithRem.m};
`;

const HeaderText = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 4rem;
`;

const CampaignInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${guttersWithRem.m};
	max-width: 40rem;
	margin: 0 auto;
	margin-bottom: 2.5rem;
`;

const CustomAccordion = styled(Accordion)`
	& > div {
		text-align: left;
		padding: 0 2rem;
		max-height: unset;

		& > p {
			overflow-wrap: break-word;
		}

		& > div {
			margin-bottom: 1.5rem;
		}
	}
`;

const HashtagAndMentionWrapper = styled.div`
	display: flex;
	flex-flow: wrap;
	gap: ${guttersWithRem.xs};
	div {
		padding: 1rem 0;
	}
`;

const DetailItem = styled(detailItem)``;
const DetailTitle = styled(detailTitle)``;
const DetailText = styled(detailText)``;

const Styled = {
	Wrapper,
	HeaderSubText,
	HeaderText,
	CampaignInfoWrapper,
	CustomAccordion,
	HashtagAndMentionWrapper,
	DetailTitle,
	DetailText,
	DetailItem,
};

export default Styled;
