import classNames from 'classnames';

import { getAssignmentIcon, getAssignmentTypeText } from 'components/ContentManagement/Utils';
import { AssignmentType } from 'shared/helpers/Assigment/types';

import Styled from './AssignmentItem.style';

type AssignmentItemProps = {
	id: string;
	type: string;
	name: string;
	isActive: boolean;
};

/**
 * AssignmentItem
 * @param {AssignmentItemProps} props
 * @returns {JSX.Element}
 */
const AssignmentItem = ({ id, type, name, isActive }: AssignmentItemProps): JSX.Element => {
	return (
		<Styled.ListItem>
			<Styled.Wrapper className={classNames({ selected: isActive })}>
				<Styled.ImageWrapper>{getAssignmentIcon(type)}</Styled.ImageWrapper>
				<Styled.NameContainer>
					<Styled.Name>
						<span data-testid={`ai-${id}`}>{name}</span>
						<span className='type'>{getAssignmentTypeText(type as AssignmentType)}</span>
					</Styled.Name>
				</Styled.NameContainer>
			</Styled.Wrapper>
		</Styled.ListItem>
	);
};

export default AssignmentItem;
