import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { inject, injectable } from 'inversify';

import RequestDecoratorInterceptor from './Interceptor/RequestDecorator';
import { RetryConfig } from './types';

const DEBUG = process.env.NODE_ENV === 'development';

/**
 * This little service creates an HTTP client. It does not have all interceptors we want.
 * You should generally use `HttpClientFactory` instead.
 */

@injectable()
export default class InternalHttpClientFactory {
	public constructor(@inject(RequestDecoratorInterceptor) private requestDecoratorInterceptor: RequestDecoratorInterceptor) {}

	/**
	 * Decorated with Collabs API token
	 */
	public createCollabsApiClient(baseConfig: RetryConfig = {}): AxiosInstance {
		const apiClient = axios.create({
			baseURL: process.env.VITE_APP_COLLABS_API,
			...baseConfig,
			retry: baseConfig.retry ?? 2,
			retryDelay: baseConfig.retryDelay ?? 1000,
		} as CreateAxiosDefaults<RetryConfig>);

		apiClient.interceptors.request.use((config) => this.requestDecoratorInterceptor.requestHeadersConfig(config), this.logRejectedInDebugMode());

		return apiClient;
	}

	/**
	 * Create a generic HTTP client. Use this with a non-collabs-api
	 */
	public create(config: {}): AxiosInstance {
		const apiClient = axios.create(config);

		apiClient.interceptors.request.use(undefined, this.logRejectedInDebugMode());

		return apiClient;
	}

	private logRejectedInDebugMode() {
		return (error: Error) => {
			if (DEBUG) {
				console.error('✉️ ', error);
			}

			return Promise.reject(error);
		};
	}
}
