import { Model } from 'json-api-models';

import CampaignSection from './CampaignSection';
import Styled from './Campaigns.style';

export type CampaignsProps = {
	campaigns: Array<Model>;
	refresh: () => void;
	refreshAll: () => void;
	refreshArchived: () => void;
	dataTestId?: string;
};

const Campaigns = ({ campaigns, refresh, refreshAll, refreshArchived, dataTestId }: CampaignsProps) => {
	const firstImageCampaigns = campaigns.slice(0, 1);
	const secondSectionCampaigns = campaigns.slice(1, 5);
	const remainingCampaigns = campaigns.slice(5, 7);

	return (
		<Styled.WrapperContainer data-testid={dataTestId}>
			<Styled.Wrapper>
				<Styled.FirstImage>
					<CampaignSection
						campaigns={firstImageCampaigns}
						firstCard={true}
						refresh={refresh}
						refreshAll={refreshAll}
						refreshArchived={refreshArchived}
						startIndex={0}
					/>
				</Styled.FirstImage>
				<Styled.SecondSection>
					<CampaignSection campaigns={secondSectionCampaigns} refresh={refresh} refreshAll={refreshAll} refreshArchived={refreshArchived} startIndex={1} />
				</Styled.SecondSection>
			</Styled.Wrapper>
			<Styled.Wrapper>
				<CampaignSection campaigns={remainingCampaigns} refresh={refresh} refreshAll={refreshAll} refreshArchived={refreshArchived} startIndex={5} />
			</Styled.Wrapper>
		</Styled.WrapperContainer>
	);
};

export default Campaigns;
