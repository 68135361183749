import styled from 'styled-components';

import colors from 'styles/theme/colors';

const CloseDiv = styled.div`
	color: ${colors.slate};
	display: flex;
	justify-content: space-between;
	gap: 16px;
	cursor: pointer;
	.icon path {
		fill: ${colors.slate};
	}
`;

const Styled = {
	CloseDiv,
};

export default Styled;
