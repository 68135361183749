import { AxiosError } from 'axios';
import { Model, Store } from 'json-api-models';
import { useState, useEffect } from 'react';

import { UserModel } from 'api-models';
import Dropdown from 'components/Dropdown';
import ShareDrawer from 'components/ShareDrawer';
import { getErrorMessageOnPost, getErrorMessageOnFetch } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { UserType } from 'reducers/UserReducers/types';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import { ICollabsResponse } from 'services/Response.types';
import toast from 'services/Toast';
import UserService from 'services/User';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import ShareDropDown from './ShareDropdown';

/**
 * @returns {JSX.Element}
 */
const ShareContainer = ({ user }: { user: UserType }): JSX.Element => {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [users, setUsers] = useState<Array<Model>>([]);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);

	const queryBuilder = RequestQueryBuilder.create().withInclude('users');
	const { repository, mutate } = manager.get(dashboard.id, queryBuilder);

	const sharedWithUsers = repository.findAll<UserModel>('user') ?? [];

	const onSave = () => {
		return manager
			.update(dashboard.id, { users: selectedIds })
			.then(() => {
				toast.success(selectedIds?.length > 0 ? 'Dashboard shared' : 'Dashboard is no longer shared');
				mutate();
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('sharing the dashboard'));
				console.error(err.message);
			})
			.finally(() => {
				setIsSidebarOpen(false);
			});
	};

	useEffect(() => {
		setSelectedIds(sharedWithUsers.map((user: UserModel) => user.id));
	}, [sharedWithUsers.length, isSidebarOpen]);

	useEffect(() => {
		setSelectedIds(sharedWithUsers.map((user: UserModel) => user.id));
	}, []);

	useEffect(() => {
		// Fetch users and set already selected Members in state
		UserService.fetchAllUsers(
			`publisherPrivileges,publisherPrivileges.publisher,clientPrivileges,clientPrivileges.client,brandPrivileges,brandPrivileges.brand`,
			{ publisherIds: user.publisherIds },
		)
			.then((res: ICollabsResponse) => {
				const models = new Store();
				models.sync(res.data);
				const users = models.findAll('user');
				setUsers(users);
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnFetch('Users'));
				console.error(err.message);
			});
	}, []);

	useEffect(() => {
		return () => {
			setIsSidebarOpen(false);
			setSelectedIds([]);
		};
	}, []);

	return (
		<>
			<Dropdown icon='share' data-testid='share-dropdown-icon'>
				<ShareDropDown toggleShareModal={() => setIsSidebarOpen(!isSidebarOpen)} id={dashboard && dashboard.id} />
			</Dropdown>
			{isSidebarOpen && (
				<ShareDrawer
					title='Share dashboard'
					sidebarIsOpen={isSidebarOpen}
					setSidebarIsOpen={(sideBarIsOpen) => setIsSidebarOpen(sideBarIsOpen)}
					name={dashboard.name}
					nonShared='You are not sharing this dashboard yet'
					createdByMe={false}
					createdBy={undefined}
					updatedAt={dashboard.createdAt}
					allUsers={users}
					sharedWithUsers={sharedWithUsers}
					setSelectedUsers={setSelectedIds}
					selectedIds={selectedIds}
					onSave={onSave}
					user={user}
				/>
			)}
		</>
	);
};
export default ShareContainer;
