import styled from 'styled-components';

import { ButtonContainer, CancelButton } from 'components/Lists/ListsContainer.style';
import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import boxShadow from 'styles/variables/box-shadows';
import { devices } from 'styles/variables/media-queries';
import {
	DropdownItem,
	DropdownAvatar,
	TeamMembersContainer,
	SelectedTeamMember,
	IconContainer,
} from 'views/Campaign/CreateCampaign/Components/FormContainer/FormContainer.style';

const Wrapper = styled.section`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 24px;
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const NavSection = styled.div`
	transition: width 0.2s;
	width: 100%;
	height: auto;
	@media screen and (${devices.md}) {
		width: 20%;
		min-width: 318px;
		height: 90vh;
	}
`;

const ListSection = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	overflow-y: auto;
	h3 {
		font-size: 1.2rem;
		margin-bottom: 16px;
	}
	@media screen and (${devices.md}) {
		height: 90vh;
	}
`;

const TopSection = styled.div`
	width: 100%;
	justify-content: space-between;
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const ShareTitle = styled.div`
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 40px;
`;

const Info = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	font-size: 0.875rem;
	color: ${colors.slate};
	line-height: 1.5;
	span {
		color: ${colors.smoke};
		font-weight: 600;
	}
`;

const Hr = styled.hr`
	margin: 24px 0;
	border-top: 1px solid ${colors.dust};
`;

const DrawerContent = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
`;

const ListContainer = styled.div`
	position: absolute;
	background-color: ${colors.white};
	left: 0;
	right: 0;
	width: 100%;
	height: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	text-align: left;
	label {
		padding: 0 1rem;
		font-size: 0.875rem;
	}
	&.isOpen {
		height: auto;
		max-height: 45vh;
	}
`;

const List = styled.ul`
	list-style: none;
	padding-left: 0;
	z-index: 10;
	transition: height 0.2s ease-in;
	background-color: ${colors.white};
	height: 100%;
	${scrollbarY};
	border-radius: 10px;
	border: 1px solid ${colors.ash};
	box-shadow: ${boxShadow.small};
`;

const SelectDiv = styled.div`
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0;
	text-align: right;
	color: ${colors.oceanBlue};
	visibility: hidden;
	.keyDownSelect {
		visibility: unset;
	}
`;

const ListItem = styled.li`
	padding: 0.5rem 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 4px;
	width: calc(100% - 8px);
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.7;
	letter-spacing: 0em;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	span {
		max-width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&.keyDownSelect {
		background-color: ${colors.ash};
		${SelectDiv} {
			visibility: unset;
		}
	}
	&:hover,
	&.selected {
		background-color: ${colors.mist};
		${SelectDiv} {
			visibility: unset;
		}
	}
	&.sharedWith {
		pointer-events: none;
		opacity: 0.4;
	}
`;

const DropdownInnerContainer = styled.div`
	position: relative;
	width: 100%;
	height: auto;
`;

const DropdownFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5rem 0;
	span {
		padding: 1rem;
		cursor: pointer;
		color: ${colors.oceanBlue};
		&:hover {
			text-decoration: underline;
		}
	}
`;

const PropertiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 6rem;
`;

const ScrollContent = styled.div`
	padding-right: 8px;
	padding-top: 1rem;
	width: 100%;
	height: 80%;
	${scrollbarY};
`;

const NameAndCompany = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	p {
		font-size: 0.75rem;
		color: ${colors.slate};
		margin: 0;
		line-height: 1;
		font-weight: 400;
	}
	span {
		&.email {
			font-size: 0.875rem;
			color: ${colors.oceanBlue};
			margin: 0;
			line-height: 1;
			font-weight: 400;
		}
	}
`;

const InfoText = styled.div`
	font-size: 0.75rem;
	color: ${colors.slate};
	margin-top: 16px;
`;

const Styled = {
	InfoText,
	NameAndCompany,
	ScrollContent,
	PropertiesContainer,
	DropdownInnerContainer,
	ListContainer,
	List,
	DropdownFooter,
	ListItem,
	SelectDiv,
	Wrapper,
	Content,
	ButtonContainer,
	CancelButton,
	NavSection,
	ListSection,
	TopSection,
	ShareTitle,
	DropdownItem,
	DropdownAvatar,
	Info,
	TeamMembersContainer,
	SelectedTeamMember,
	IconContainer,
	Hr,
	DrawerContent,
};

export default Styled;
