import EmptyChatImage from 'assets/img/empty-state-message.svg';
import { Heading } from 'components/Heading';

import Styled from './EmptyChat.style';

/**
 */
const EmptyChatV2 = ({ isNew }: { isNew?: boolean }) => {
	return (
		<Styled.Wrapper data-testid='inbox-empty-chat-section'>
			<div className='image-wrapper'>
				<img src={EmptyChatImage} alt='empty-integrated-inbox' />
			</div>
			{isNew && <Heading as='h4'>Start a new conversation</Heading>}
			<div className='strong'>
				{isNew ? (
					<>
						<p>Write your message below to begin.</p>
						<p>We'll also send it as an email.</p>
					</>
				) : (
					<p>Select a conversation to get started</p>
				)}
			</div>
		</Styled.Wrapper>
	);
};

export default EmptyChatV2;
