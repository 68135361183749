import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, uniq } from 'lodash';

import { BrandItem } from 'views/Campaign/CreateCampaign/types';

type SliceState = {
	name: string;
	brand?: BrandItem;
	users: string[];
	influencerTargetCount: number;
	adtractionId?: string | null;
	links: { [key: string]: string } | null;
	hashtags: string[];
	mentions: string[];
};

export const initialState: SliceState = {
	name: '',
	users: [],
	adtractionId: null,
	links: null,
	influencerTargetCount: 0,
	hashtags: [],
	mentions: [],
};

const campaignBackgroundSlice = createSlice({
	name: 'background',
	initialState,
	reducers: {
		setName(state, action: PayloadAction<string>) {
			state.name = action.payload;
		},
		setBrand(state, action: PayloadAction<BrandItem | undefined>) {
			state.brand = action.payload;
		},
		setAdtractionId(state, action: PayloadAction<string>) {
			state.adtractionId = action.payload;
		},
		setUsers(state, action: PayloadAction<string[]>) {
			state.users = action.payload;
		},
		setInfluencerTargetCount(state, action: PayloadAction<number>) {
			state.influencerTargetCount = action.payload;
		},
		setHashtags(state, action: PayloadAction<string>) {
			const newHashtags = [...state.hashtags];
			state.hashtags = uniq(newHashtags.concat(action.payload).map((tag) => (tag.startsWith('#') ? tag : `#${tag}`)));
		},
		removeHashtag(state, action: PayloadAction<string>) {
			state.hashtags = filter(state.hashtags, (hashtag) => hashtag !== action.payload);
		},
		setMentions(state, action: PayloadAction<string>) {
			const newMentions = [...state.mentions];

			state.mentions = uniq(newMentions.concat(action.payload).map((tag) => (tag.startsWith('@') ? tag : `@${tag}`)));
		},
		removeMention(state, action: PayloadAction<string>) {
			state.mentions = filter(state.mentions, (mention) => mention !== action.payload);
		},
		clearBackground: () => initialState,
	},
});

export const {
	setName,
	setBrand,
	setAdtractionId,
	setUsers,
	setInfluencerTargetCount,
	removeMention,
	setHashtags,
	removeHashtag,
	setMentions,
	clearBackground,
} = campaignBackgroundSlice.actions;

export default campaignBackgroundSlice.reducer;
