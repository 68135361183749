import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { TertiaryButton } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Styled from 'components/DataLibrary/FilterSection/FilterSection.style';
import { FilterOptionProps } from 'components/DataLibrary/FilterSection/types';
import { INSTAGRAM_POST, INSTAGRAM_STORY, INSTAGRAM_REEL, TIKTOK_VIDEO } from 'constants/data-library';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboardFilterAssignmentTypes } from 'views/DataLibrary/reducers/DashboardFilterSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const AssignmentTypes = (props: FilterOptionProps): JSX.Element => {
	const [selectedAssignmentTypes, setSelectedAssignmentTypes] = useState<Array<string>>([]);
	const [clearThisFilter, setClearThisFilter] = useState<boolean>(false);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	const dispatch = useAppDispatch();

	const onSelect = (type: string) => {
		props.getClearFilter(false);
		setClearThisFilter(false);
		if (selectedAssignmentTypes?.includes(type)) {
			const update = selectedAssignmentTypes.filter((i) => i !== type);
			setSelectedAssignmentTypes(update);
		} else {
			setSelectedAssignmentTypes([...selectedAssignmentTypes, type]);
		}
	};

	useEffect(() => {
		dispatch(setDashboardFilterAssignmentTypes(selectedAssignmentTypes));
	}, [selectedAssignmentTypes]);

	const checkDashboardFilter = () => {
		if (dashboard.metaData.dashboardFilter) {
			const savedAssignmentTypes = dashboard.metaData.dashboardFilter.assignmentTypes ?? [];
			if (!isEqual(savedAssignmentTypes, selectedAssignmentTypes)) {
				setSelectedAssignmentTypes(savedAssignmentTypes);
			}
		} else {
			setSelectedAssignmentTypes([]);
		}
	};

	useEffect(() => {
		checkDashboardFilter();
	}, [props.dashboardId, dashboard]);

	useEffect(() => {
		checkDashboardFilter();
		return () => {
			setSelectedAssignmentTypes([]);
		};
	}, []);

	useEffect(() => {
		if (clearThisFilter || props.clearFilter) {
			setSelectedAssignmentTypes([]);
		}
	}, [props.clearFilter, clearThisFilter]);

	return (
		<Styled.CustomAccordion
			className={classNames({ bold: selectedAssignmentTypes?.length > 0 })}
			title={<> Channels {selectedAssignmentTypes?.length > 0 && <>({selectedAssignmentTypes?.length})</>}</>}
		>
			<Styled.FilterDataContainer>
				<div>
					<span>Total:</span> {selectedAssignmentTypes?.length}
				</div>
				<TertiaryButton disabled={selectedAssignmentTypes?.length < 1} onClick={() => setClearThisFilter(true)}>
					Clear filter
				</TertiaryButton>
			</Styled.FilterDataContainer>
			<Styled.ExtraMarginContainer>
				<Styled.FilterOptionListItem className={classNames({ selected: selectedAssignmentTypes?.includes(INSTAGRAM_POST) })}>
					<Checkbox checked={selectedAssignmentTypes?.includes(INSTAGRAM_POST)} label={'Instagram post'} onChange={() => onSelect(INSTAGRAM_POST)} />
				</Styled.FilterOptionListItem>
				<Styled.FilterOptionListItem className={classNames({ selected: selectedAssignmentTypes?.includes(INSTAGRAM_STORY) })}>
					<Checkbox checked={selectedAssignmentTypes?.includes(INSTAGRAM_STORY)} label={'Instagram story'} onChange={() => onSelect(INSTAGRAM_STORY)} />
				</Styled.FilterOptionListItem>
				<Styled.FilterOptionListItem className={classNames({ selected: selectedAssignmentTypes?.includes(INSTAGRAM_REEL) })}>
					<Checkbox checked={selectedAssignmentTypes?.includes(INSTAGRAM_REEL)} label={'Instagram reel'} onChange={() => onSelect(INSTAGRAM_REEL)} />
				</Styled.FilterOptionListItem>
				<Styled.FilterOptionListItem className={classNames({ selected: selectedAssignmentTypes?.includes(TIKTOK_VIDEO) })}>
					<Checkbox checked={selectedAssignmentTypes?.includes(TIKTOK_VIDEO)} label={'Tiktok'} onChange={() => onSelect(TIKTOK_VIDEO)} />
				</Styled.FilterOptionListItem>
			</Styled.ExtraMarginContainer>
		</Styled.CustomAccordion>
	);
};
export default AssignmentTypes;
