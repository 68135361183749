import EmptyContent from 'assets/img/app/empty-state-dead-invitation.svg';
import LinkAsSecondaryButton from 'components/LinkAsSecondaryButton';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './InviteUsed.style';

/**
 * Invitation link that has expired/been used
 * @returns {JSX.Element}
 */
const InviteUsed = ({ forPublisher }: { forPublisher?: boolean }): JSX.Element => {
	const user = useAppSelector((state) => state.user);

	return (
		<Styled.EmptyStateWrapper data-testid='invite-used' role='main' aria-labelledby='invitation-title'>
			<Styled.LinkAlreadyUsedWrapper>
				<Styled.AlignCenter>
					{forPublisher ? (
						<Styled.TextContainer role='article'>
							<Styled.Title id='invitation-title'>This invitation has died</Styled.Title>
							<Styled.Text>The invitation link is no longer active.</Styled.Text>
							{user?.id ? (
								<LinkAsSecondaryButton className='blue' to='/influencer/dashboard' aria-label='Navigate to dashboard'>
									Go to my dashboard
								</LinkAsSecondaryButton>
							) : (
								<LinkAsSecondaryButton className='blue' to='/login' aria-label='Navigate to login page'>
									Login
								</LinkAsSecondaryButton>
							)}
						</Styled.TextContainer>
					) : (
						<Styled.TextContainer role='article'>
							<Styled.Title id='invitation-title'>This invitation has died</Styled.Title>

							<Styled.Text>
								The invitation link is no longer active. This could be due to the campaign invitation expiring or the campaign reaching its end.{' '}
							</Styled.Text>
							<Styled.Text>Thank you for your interest, please explore other available opportunities.</Styled.Text>
							{user?.id ? (
								<LinkAsSecondaryButton className='blue' to='/influencer/dashboard' aria-label='Navigate to dashboard'>
									Go to my dashboard
								</LinkAsSecondaryButton>
							) : (
								<LinkAsSecondaryButton className='blue' to='/login' aria-label='Navigate to login page'>
									Login
								</LinkAsSecondaryButton>
							)}
							<Styled.Text>
								If you have any questions or need further assistance, please don&apos;t hesitate to contact our{' '}
								<a
									href={'https://help.collabs.se/hc/en-us/sections/12575769923345-Campaign-Management'}
									aria-label='Visit support pages'
									target='_blank'
									rel='noopener noreferrer'
								>
									support pages
								</a>
								.
							</Styled.Text>
						</Styled.TextContainer>
					)}
					<Styled.ImgContainer>
						<Styled.EmptyStateImage src={EmptyContent} alt='Illustration of a ghost representing an expired invitation' role='img' />
					</Styled.ImgContainer>
				</Styled.AlignCenter>
			</Styled.LinkAlreadyUsedWrapper>
		</Styled.EmptyStateWrapper>
	);
};

export default InviteUsed;
