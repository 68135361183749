import { useState } from 'react';

import useModels from 'hooks/useModels';
import InvitationItem from 'views/influencer/InfluencerDashboard/Components/InvitationItem';
import SeeAllToggle from 'views/influencer/InfluencerDashboard/Components/SeeAllToggle';
import { getCampaignInvitesUri } from 'views/influencer/InfluencerDashboard/utils';

import Styled from './InvitationSection.style';

/**
 * @returns {JSX.Element}
 */
const InvitationSection = (): JSX.Element => {
	const { models } = useModels(getCampaignInvitesUri());
	const invites = models.findAll('campaignInvite');
	const userHasInvites = invites?.filter((invite) => !invite.attributes?.isSegmentFull).length > 0;
	const [isShowAll, setIsShowAll] = useState(false);

	return userHasInvites ? (
		<Styled.Wrapper data-testid='invitation-section'>
			<Styled.HeadingWrapper>
				<span>Pending invitations</span>
			</Styled.HeadingWrapper>
			<Styled.InvitationsWrapper>
				{invites
					.filter((_, index) => {
						if (isShowAll) {
							return true;
						} else {
							return index < 3;
						}
					})
					.map((invitation) => (
						<InvitationItem key={invitation.id} invitation={invitation} displaySkeletonLoader={false} />
					))}
			</Styled.InvitationsWrapper>
			{invites.length > 3 ? <SeeAllToggle setIsShowAll={setIsShowAll} isShowAll={isShowAll} /> : null}
		</Styled.Wrapper>
	) : (
		<></>
	);
};

export default InvitationSection;
