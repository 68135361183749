import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HoverButton } from 'components/Button';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import { CreateBrandProps } from 'components/Settings/ClientManagement/types';
import InfoText from 'components/Settings/Components/InfoText/InfoText';
import SettingsStyle from 'components/Settings/Settings.style';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import BrandManager from 'services/ApiManager/Brand.manager';
import toast from 'services/Toast';
import colors from 'styles/theme/colors';

const CreateBrand = ({ client, mutateFn, publisher }: CreateBrandProps) => {
	const user = useAppSelector((state) => state.user);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [newBrandName, setNewBrandName] = useState<string>('');
	const [displayError, setDisplayError] = useState<boolean>(false);
	const navigate = useNavigate();

	const manager = useInjection<BrandManager>(BrandManager);
	const location = useLocation();

	const saveBrand = () => {
		if (newBrandName.length > 0) {
			setDisplayError(false);

			const payload = { name: newBrandName, client: client?.id, administrators: [user.id], projectManagers: [], brandManagers: [], dataAnalysts: [] };

			manager
				.create(payload, undefined, {
					mutateFn: mutateFn,
				})
				.then((brand) => {
					setIsModalOpen(false);
					toast.success(`New brand ${newBrandName} created`);
					setNewBrandName('');
					navigate(`/settings/brands/${brand.id}`, { state: { invite: true } });
				})
				.catch((e: AxiosError) => {
					console.error(e);
					toast.error(getErrorMessageOnPost('creating the brand'));
				});
		} else {
			setDisplayError(true);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewBrandName(e.target.value);
	};

	useEffect(() => {
		if (location.state?.create) {
			setIsModalOpen(true);
		}
	}, [location]);

	return (
		<>
			<HoverButton useAddIcon onClick={() => setIsModalOpen(true)}>
				Add brand to {client?.attributes.name}
			</HoverButton>
			<SideDrawer
				sidebarIsOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				dataTestId={''}
				title='Brand management'
				isExpandable
				saveButtonText='Save Brand'
				onSave={saveBrand}
				expandedTitle={
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						Add brand <Pill backgroundColor={colors.paleGreenTint} title={`${client?.attributes.name}`} />
					</div>
				}
			>
				<>
					<div style={{ marginTop: '16px' }}>
						<Field label='Brand name'>
							<Input value={newBrandName} name='newBrandName' placeholder='Name your brand' onChange={(e) => handleInputChange(e)} />
						</Field>
					</div>
					<div style={{ marginTop: '16px' }}>
						<InfoText
							color={colors.paleGreenTint}
							text={`This brand will be associated with ${client?.attributes.name} (for ${publisher?.attributes.name}).`}
						/>
					</div>
					{displayError && (
						<SettingsStyle.HelperText>
							<Icon name='alert' size='12' /> <span>You need to enter the name of your brand</span>
						</SettingsStyle.HelperText>
					)}
				</>
			</SideDrawer>
		</>
	);
};

export default CreateBrand;
