import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import Styled from './Label.style';
import { LabelProps } from './types';

const Label = ({ scale = 'medium', disabled = false, children, displayInfoTooltip, ...props }: LabelProps) => {
	return (
		<Styled.Label scale={scale} disabled={disabled} {...props}>
			{children}{' '}
			{displayInfoTooltip && (
				<Styled.TooltipContainer>
					<Tooltip content={displayInfoTooltip}>
						<Icon name='info-circle' size='14' />
					</Tooltip>
				</Styled.TooltipContainer>
			)}
		</Styled.Label>
	);
};

export default Label;
