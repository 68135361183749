import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { BlueButton } from 'components/Button/Button';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { pathInfluencerCampaignView } from 'routing/PathLookup';
import colors from 'styles/theme/colors';
import { formatAmount } from 'utils/formatters';
import InvitationStatusTag from 'views/influencer/InfluencerDashboard/Components/InvitationStatusTag';
import { InvitationItemProps } from 'views/influencer/InfluencerDashboard/types';

import Styled from './InvitationItem.style';

/**
 * @param {InvitationItemProps} props
 * @returns {JSX.Element}
 */
const InvitationItem = ({ invitation, displaySkeletonLoader }: InvitationItemProps): JSX.Element => {
	const navigate = useNavigate();

	return displaySkeletonLoader ? (
		<Skeleton width='350px' height='150px' />
	) : (
		<Styled.Wrapper lg={4} md={6} data-testid='invitation'>
			<Styled.InnerWrapper>
				<InvitationStatusTag invitedAt={invitation.createdAt} />
				<Styled.InvitationContentWrapper>
					{invitation.links.smallCoverPhoto ? (
						<Styled.CoverImageWrapper>
							<Styled.AvatarWrapper>
								<InfluencerAvatar
									displayNetwork={true}
									profileImageUrl={invitation.influencer?.links?.profilePictureUrl ?? ''}
									userName={invitation.influencer?.username}
									network={invitation.influencer?.network}
								/>
							</Styled.AvatarWrapper>
							<img src={invitation.links.smallCoverPhoto} alt='campaign-cover' />
						</Styled.CoverImageWrapper>
					) : (
						<Styled.CoverImageWrapper style={{ backgroundColor: colors.ash, borderRadius: '10px' }}>
							<Styled.AvatarWrapper>
								<InfluencerAvatar
									displayNetwork={true}
									profileImageUrl={invitation.influencer?.links?.profilePictureUrl ?? ''}
									userName={invitation.influencer?.username}
									network={invitation.influencer?.network}
								/>
							</Styled.AvatarWrapper>
						</Styled.CoverImageWrapper>
					)}
					<Styled.InvitationContent>
						<div>
							<Styled.Text className='title'>{invitation.campaignName}</Styled.Text>
							<Styled.Text className='brand'>{invitation.brandName}</Styled.Text>
						</div>
						{invitation.commission?.id && (
							<div>
								<Styled.Text className='commission-value'>Commission:</Styled.Text>
								<Styled.Text className='commission-label'>
									{invitation.commission?.id ? formatAmount(invitation.commission?.fixedAmount, invitation.commission?.fixedAmountCurrency) : null}
								</Styled.Text>
							</div>
						)}
					</Styled.InvitationContent>
				</Styled.InvitationContentWrapper>
				<BlueButton
					usePulse
					data-testid='view-brief'
					onClick={() => {
						navigate(pathInfluencerCampaignView(invitation.campaignShortId, invitation.influencer?.id));
					}}
				>
					Respond to invite
				</BlueButton>
			</Styled.InnerWrapper>
		</Styled.Wrapper>
	);
};

export default InvitationItem;
