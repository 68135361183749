import { Model } from 'json-api-models';
import { RefObject } from 'react';

import Assignment from 'components/NewBriefPage/Components/JoinModal/Assignment/Assignment';
import Styled from 'components/NewBriefPage/Components/JoinModal/Assignment/Assignment.style';
import Commission from 'components/NewBriefPage/Components/JoinModal/Commission/Commission';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';

const CampaignInfo = (props: {
	campaignInfoRef: RefObject<HTMLDivElement>;
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
	onPreview?: boolean;
}) => {
	return (
		<Styled.Container ref={props.campaignInfoRef}>
			{/* Need to make type to Model in order to group the in the Assignment component */}
			<Assignment
				campaignId={props.campaign.shortId}
				onPreview={props.onPreview}
				assignments={props.campaignInstagramOwner.campaign.assignments as unknown as Model}
			/>
			<Commission campaignInstagramOwner={props.campaignInstagramOwner} />
		</Styled.Container>
	);
};

export default CampaignInfo;
