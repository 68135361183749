import { useRef, useState, useEffect, useCallback } from 'react';

import { AssignmentReviewCommentType, AssignmentReviewInternalCommentType } from 'components/ContentManagement/types';
import Tabs from 'components/Tabs/Tabs';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import usePermissions from 'hooks/usePermissions';
import toast from 'services/Toast';

import Styled from './Comments.style';
import CommentInput from './components/CommentInput/CommentInput';
import Message from './components/Message/Message';
import { CommentsProps, selectedTabType } from './types';

const Comments = ({
	user,
	canUseTabs,
	comments = [],
	activeTab = selectedTabType.Influencer,
	onClickTab,
	onSend,
	isNotSharedWithClient,
	influencerName,
	brandName,
}: CommentsProps): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState<selectedTabType>(activeTab);
	const [localComments, setLocalComments] =
		useState<(AssignmentReviewCommentType | AssignmentReviewInternalCommentType | AssignmentReviewInternalCommentType)[]>(comments);
	const [isSending, setIsSending] = useState(false);

	const { isInfluencer } = usePermissions();
	const commentsRef = useRef<HTMLDivElement>(null);
	const lastMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setLocalComments(comments);
	}, [comments]);

	useEffect(() => {
		setSelectedTab(activeTab);
	}, [activeTab]);

	useEffect(() => {
		if (lastMessageRef.current) {
			lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [localComments.length]);

	const handleSend = useCallback(
		async (value: string) => {
			if (!value.trim()) return;

			const newComment = {
				id: `temp-${Date.now()}`,
				attributes: {
					text: value,
					createdAt: new Date().toISOString(),
					readAt: null,
				},
				user: {
					attributes: {
						initials: user.initials,
						firstName: user.name,
						uuid: user.uuid,
					},
				},
			};

			setLocalComments((prev) => [...prev, newComment]);
			setIsSending(true);

			try {
				await onSend(value);
			} catch (e) {
				console.error(e);
				toast.error(getErrorMessageOnPost('sending your comment'));
				setLocalComments((prev) => prev.filter((comment) => comment.id !== newComment.id));
			} finally {
				setIsSending(false);
			}
		},
		[user, onSend],
	);

	return (
		<Styled.CommentsContainer data-testid='cm-comment-box'>
			{canUseTabs && (
				<Tabs
					tabs={[
						{
							title: selectedTabType.Team,
							titleToDisplay: `To: ${selectedTabType.Team}`,
						},
						{
							title: selectedTabType.Influencer,
							titleToDisplay: `To: ${influencerName}`,
						},
						{
							title: selectedTabType.BrandManager,
							titleToDisplay: `To: ${brandName}`,
							testId: 'cm-comment-bm-tab',
							disabled: isNotSharedWithClient,
							tooltip: isNotSharedWithClient ? 'You need to share assignment.' : undefined,
						},
					]}
					selectedTab={selectedTab}
					setSelectedTab={(tab: string) => {
						setSelectedTab(tab as selectedTabType);
						onClickTab?.(tab as selectedTabType);
					}}
				/>
			)}
			<Styled.CommentsList ref={commentsRef} className='cm-comments'>
				{localComments.length === 0 && (
					<Styled.DefaultText>
						<span>
							{isInfluencer() ? 'This is the start of your conversation.' : 'Add feedback to request changes or provide updates about this assignment.'}
						</span>
					</Styled.DefaultText>
				)}
				{localComments.map((comment, index) => (
					<Message key={comment.id} comment={comment} user={user} isLast={index === localComments.length - 1} lastMessageRef={lastMessageRef} />
				))}
			</Styled.CommentsList>
			<CommentInput user={user} onSend={handleSend} isSending={isSending} />
		</Styled.CommentsContainer>
	);
};

export default Comments;
