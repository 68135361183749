import { Form, Formik, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { PublisherModel } from 'api-models';
import { InputText } from 'components/Form/FormikElements';
import Field from 'components/Forms/Field';
import Styled from 'components/Settings/ClientManagement/ClientManagement.style';
import CenteredSpinner from 'components/Settings/Components/CenteredSpinner/CenteredSpinner';
import LogoUpload from 'components/Settings/Components/LogoUpload/LogoUpload';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import { MutateFn } from 'services/ApiManager/types';
import toast from 'services/Toast';
import PublisherOptions from 'views/admin/Components/Publishers/Components/PublisherOptions/PublisherOptions';

export enum PublisherType {
	MEDIA_AGENCY = 'media_agency',
	AGENCY = 'agency',
	DIRECT_CLIENT = 'direct_client',
}

type PublisherFormProps = {
	savePublisher: (values: FormikValues) => void;
	initialValues: {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formRef: any;
	validateAfterSubmit: boolean;
	isLoading: boolean;
	publisher?: PublisherModel | null;
	mutate: MutateFn;
	disabled: boolean;
};

const PublisherForm = ({ savePublisher, initialValues, formRef, validateAfterSubmit, isLoading, publisher, mutate, disabled }: PublisherFormProps) => {
	const { publisherId } = useParams();
	const manager = useInjection<PublisherManager>(PublisherManager);

	const deleteImage = () => {
		publisherId &&
			manager.deleteLogo(publisherId).then(() => {
				mutate();
				toast.success('Logo deleted');
			});
	};

	const uploadLogo = (file: File) => {
		publisherId &&
			manager.uploadLogo(publisherId, file).then(() => {
				mutate();
				toast.success('Logo uploaded');
			});
	};

	return (
		<Formik
			innerRef={formRef}
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={Yup.object({
				name: Yup.string().required(),
			})}
			onSubmit={(values) => {
				savePublisher(values);
			}}
			validateOnChange={validateAfterSubmit}
			validateOnBlur={validateAfterSubmit}
		>
			{({ values, setFieldValue }) =>
				isLoading ? (
					<CenteredSpinner />
				) : (
					<Form>
						<Styled.FieldWrapper className='first'>
							<Field label='Company name of publisher' disabled={disabled}>
								<InputText name='name' placeholder='Name your publisher' />
							</Field>
						</Styled.FieldWrapper>
						{publisherId && publisher && !disabled ? (
							<>
								<Styled.FieldWrapper>
									<h5>Type of publisher</h5>
									<PublisherOptions setFieldValue={setFieldValue} values={values} />
								</Styled.FieldWrapper>
								<Styled.FieldWrapper>
									<LogoUpload canEdit item={publisher} deleteImageFn={deleteImage} uploadFn={(file: File) => uploadLogo(file)} />
								</Styled.FieldWrapper>
							</>
						) : (
							<Styled.FieldWrapper>
								<h5>Invite contact</h5>
								<Field label='Contact email' disabled={disabled}>
									<InputText name='email' placeholder='Email email' />
								</Field>
							</Styled.FieldWrapper>
						)}
					</Form>
				)
			}
		</Formik>
	);
};

export default PublisherForm;
