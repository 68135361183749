import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const CopyCircle = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: unset;
	font-size: 12px;
	margin-left: 8px;
	transition: background-color 0.2s ease-in-out;
	&[data-title]:after {
		content: attr(data-title);
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		padding: 0.5rem;
		border-radius: ${borderRadius.s};
	}
	.icon {
		margin-top: 4px;
		path {
			fill: ${colors.gray8};
		}
	}
	&:hover {
		background-color: ${colors.gray9};
	}

	&.copied {
		background-color: ${colors.gray10} !important;
	}

	&.am-copy-button {
		border-radius: 8px;
		width: 28px;
		height: 28px;

		& > span {
			display: none;
		}

		i {
			display: flex;
		}

		&:hover,
		&.copied {
			background-color: ${colors.gray3};
		}

		&:not(.copied) {
			.icon path {
				fill: ${colors.black};
			}
		}
	}
`;

const Message = styled.p`
	font-size: 0.875rem;
	margin: 0;
	margin-left: 5px;
`;

const CopyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Styled = {
	CopyCircle,
	Message,
	CopyWrapper,
};

export default Styled;
