import styled, { DefaultThemeV2 } from 'styled-components';

import Avatar from 'components/Avatar';
import colors from 'styles/theme/colors';

const CommentAvatar = styled(Avatar)`
	border: none;
	margin: 0;
`;

const Send = styled.button`
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

const DefaultText = styled.div`
	color: ${({ theme }: { theme: DefaultThemeV2 }) => theme.colors.placeholder};
	text-align: center;
	line-height: 1.6;
	font-weight: 500;
	padding: 2rem 0;
`;

const StartText = styled.div`
	color: ${({ theme }: { theme: DefaultThemeV2 }) => theme.colors.placeholder};
	text-align: center;
	line-height: 20px;
	margin-bottom: 34px;

	span {
		display: block;
	}
`;

const Headline = styled.h2`
	font-size: 1rem;
	margin-bottom: 8px;
`;

const Header = styled.div`
	margin-bottom: 24px;
`;

const CommentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.mist};
	padding: 1rem;
	max-height: 600px;
	border-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.default};
`;

const CommentsList = styled.div`
	min-height: 100px;
	overflow-y: auto;
	margin-bottom: 16px;
	flex-grow: 1;
`;

const ThreadTool = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: end;
	max-height: 250px;
	border-radius: 4px;
`;

const AvatarContainer = styled.div`
	background-color: ${colors.mist};
	align-self: stretch;
	display: flex;
	align-items: end;
	padding-right: 0.5rem;
	padding-bottom: 0.25rem;
`;

const MessageRow = styled.div`
	display: flex;
	max-width: 690px;

	&:not(.latest) {
		margin-bottom: 32px;
	}
`;

const UserAvatar = styled.div`
	padding-right: 1rem;
`;

const UserMessage = styled.div`
	white-space: pre-line;
	word-wrap: break-word;
	line-height: 1.5;
	max-width: 680px;
`;

const MessageFieldContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: end;
	flex-grow: 1;
	background-color: ${colors.snow};
	border-top-left-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	border-bottom-left-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	padding: 0 1rem;
	border-radius: 4px;
`;

const MessageField = styled.div`
	user-select: text;
	white-space: pre-wrap;
	word-break: break-word;
	overflow-y: auto;
	padding: 0.75rem 0;
	outline: none;
	appearance: none;
	width: 100%;
	max-width: 100%;
	min-height: 40px;
	max-height: 240px;
	overflow-y: auto;
	resize: none;
	line-height: 1.5;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const MessageFieldPlaceholder = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	color: ${colors.slate};
	padding: 1rem;
	user-select: none;
	cursor: text;
`;

const SendMessage = styled.span`
	display: inline-flex;
	background-color: ${colors.snow};
`;

const SendButton = styled.button`
	border: none;
	width: 32px;
	height: 32px;
	padding: 0.25rem;
	line-height: 0;
	border-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	margin-bottom: 7px;
	background-color: ${colors.paleGreenTint};
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;

	.icon {
		line-height: 2;

		path {
			transition: fill 0.2s ease-in-out;
		}
	}

	&:disabled {
		background-color: transparent;
		cursor: default;

		.icon path {
			fill: ${colors.slate};
		}
	}
`;

const MessageStatus = styled.div`
	font-size: ${({ theme }: { theme: DefaultThemeV2 }) => theme.fontSizes.small};
	color: ${colors.slate};
`;

const Styled = {
	CommentAvatar,
	Send,
	DefaultText,
	StartText,
	Headline,
	Header,
	CommentsContainer,
	CommentsList,
	ThreadTool,
	MessageRow,
	UserAvatar,
	UserMessage,
	MessageField,
	MessageFieldContainer,
	MessageFieldPlaceholder,
	SendMessage,
	SendButton,
	MessageStatus,
	AvatarContainer,
};

export default Styled;
