import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const UserList = styled.ul`
	padding-left: 0 !important;
	li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

const Select = styled.select`
	background-color: transparent;
	border: transparent;
	padding: 0.5rem;
`;

const DeleteContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: ${(props: T) => props.theme.radius.small};
	cursor: pointer;
	.icon {
		margin-top: 5px;
	}
	&:hover {
		background-color: ${colors.ash};
	}
`;

const DeleteWrapper = styled.div`
	display: flex;
	width: auto;
	align-items: center;
	p {
		white-space: nowrap;
		margin-right: 5px;
		font-size: 0.875rem;
		color: ${colors.black};
	}
`;

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 0 0.5rem;
	&:hover {
		background-color: ${colors.ash};
	}
`;

export default {
	UserList,
	Select,
	DeleteContainer,
	DeleteWrapper,
	ListItem,
};
