import classNames from 'classnames';
import { isNil } from 'lodash';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { HoverButton } from 'components/Button';
import Icon from 'components/Icon';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './PageHeader.style';
import { IPageHeaderProps } from './types';

export const greetingPhrase = (curHr: number): string => {
	if (curHr < 12) {
		return 'Good morning';
	} else if (curHr < 18) {
		return 'Good afternoon';
	} else {
		return 'Good evening';
	}
};

/**
 * Page header
 * @param {IPageHeaderProps} props
 * @returns {JSX.Element}
 */
const PageHeader = ({ headline, userGreeting, customHeadline, className, children }: IPageHeaderProps): JSX.Element => {
	const user = useAppSelector((state) => state.user);
	const curHr = new Date().getHours();

	const getUserHeadlineGreeting = () => {
		if (user.name !== 'null null') {
			return `${greetingPhrase(curHr)}, ${user.name}`;
		}
		return <>{greetingPhrase(curHr)}, anonymous</>;
	};
	const navigate = useNavigate();
	const Heading = () => {
		if (userGreeting) {
			return (
				<Styled.HeadingContainer>
					<Styled.GreetingContainer>
						<Styled.GreetingWrapper>
							<Icon name='wave' />
							{getUserHeadlineGreeting()}
						</Styled.GreetingWrapper>
						{user.name === 'null null' && (
							<HoverButton onClick={() => navigate('/settings/account')} iconRight icon='arrow-right'>
								Add your name
							</HoverButton>
						)}
					</Styled.GreetingContainer>
					<div>{customHeadline}</div>
				</Styled.HeadingContainer>
			);
		}

		if (customHeadline) {
			return customHeadline; // JSX Element
		}

		return headline || '';
	};

	return (
		<Styled.Wrapper className={classNames(className)}>
			<Helmet>
				<title>{isNil(headline) ? 'Collabs' : `${headline} | Collabs.se`}</title>
			</Helmet>
			<Styled.HeaderBottom>
				<Styled.PageHeadingWrapper>
					<Styled.PageHeading data-testid='page-title'>{Heading()}</Styled.PageHeading>
				</Styled.PageHeadingWrapper>
				<Styled.ComponentContainer>{children}</Styled.ComponentContainer>
			</Styled.HeaderBottom>
		</Styled.Wrapper>
	);
};

PageHeader.defaultProps = {
	showBreadcrumb: true,
	showCurrentDate: false,
};

export default PageHeader;
