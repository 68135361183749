import React from 'react';

import ConfirmModal from 'components/Modals/ConfirmModal';
import DeleteModal from 'components/Modals/DeleteModal';
import { AssignmentReviewType } from 'shared/Types/Assignment';
import colors from 'styles/theme/colors';
import { PALETTE } from 'styles/variables/original-colors';

type ModalHandlers = {
	onApprove: () => void;
	onRequestChanges: () => void;
	onSendForReview: () => void;
	onClickClientApprove: () => void;
	onDeleteMedia: (mediaId: string) => void;
	setIsApproveModalOpen: (isOpen: boolean) => void;
	setIsRequestChangeModalOpen: (isOpen: boolean) => void;
	setIsSendForReviewModalOpen: (isOpen: boolean) => void;
	setIsDeleteModalOpen: (isOpen: boolean) => void;
	setIsClientApproveModalOpen: (isOpen: boolean) => void;
};

type ModalState = {
	isSendForReviewModalOpen: boolean;
	isApproveModalOpen: boolean;
	isRequestChangeModalOpen: boolean;
	isDeleteModalOpen: boolean;
	selectedMediaId: string;
	isPosting: boolean;
	isUploadingContent: boolean;
	isClientApproveModalOpen: boolean;
	isClientApproveLoading: boolean;
	setIsClientApproveModalOpen: (isOpen: boolean) => void;
};

const Modals = ({
	state,
	handlers,
	review,
	influencerName,
	brandName,
}: {
	state: ModalState;
	handlers: ModalHandlers;
	review: AssignmentReviewType;
	influencerName: string;
	brandName: string;
}) => {
	const {
		isSendForReviewModalOpen,
		isApproveModalOpen,
		isRequestChangeModalOpen,
		isDeleteModalOpen,
		selectedMediaId,
		isPosting,
		isUploadingContent,
		isClientApproveModalOpen,
		isClientApproveLoading,
	} = state;
	const { onApprove, onRequestChanges, onSendForReview, onClickClientApprove, onDeleteMedia } = handlers;

	return (
		<>
			<ConfirmModal
				icon='upload'
				IconBackgroundColor={PALETTE.aquaHaze}
				isModalOpen={isSendForReviewModalOpen}
				toggleModal={() => handlers.setIsSendForReviewModalOpen(!isSendForReviewModalOpen)}
				title='Submit for review'
				action={onSendForReview}
				buttonText='Yes, submit'
				isFetching={isPosting || isUploadingContent}
			>
				<p>Once submitted, your content will be reviewed, and you'll be notified.</p>
			</ConfirmModal>

			<ConfirmModal
				IconBackgroundColor={review?.enabledForClientAt && !review?.approvedByClientAt ? colors.butter : PALETTE.aquaHaze}
				iconColor={review?.enabledForClientAt && !review?.approvedByClientAt ? colors.spice : colors.black}
				isModalOpen={isApproveModalOpen}
				toggleModal={() => handlers.setIsApproveModalOpen(!isApproveModalOpen)}
				action={onApprove}
				title='Approve content for posting'
				isFetching={isPosting}
				buttonText='Yes, approve'
				icon={review?.enabledForClientAt && !review.approvedByClientAt ? 'pending' : 'check-circle'}
			>
				<>
					{review?.enabledForClientAt && !review.approvedByClientAt ? (
						<p>
							<strong>{brandName}</strong> has not yet approved <strong>{influencerName}</strong>’s content. Approve for posting anyways?
						</p>
					) : (
						<p>
							This will approve <strong>{influencerName}</strong> to post their content on the assigned date.
						</p>
					)}
				</>
			</ConfirmModal>

			<ConfirmModal
				IconBackgroundColor={PALETTE.aquaHaze}
				iconColor={colors.black}
				isModalOpen={isClientApproveModalOpen}
				toggleModal={() => handlers.setIsClientApproveModalOpen(!isClientApproveModalOpen)}
				action={onClickClientApprove}
				title='Approve content for posting'
				isFetching={isClientApproveLoading}
				buttonText='Yes, approve'
				icon='check-circle'
			>
				<p>
					Approve <strong>{influencerName}'s</strong> content?
				</p>
			</ConfirmModal>

			<ConfirmModal
				IconBackgroundColor={PALETTE.aquaHaze}
				isModalOpen={isRequestChangeModalOpen}
				toggleModal={() => handlers.setIsRequestChangeModalOpen(!isRequestChangeModalOpen)}
				action={onRequestChanges}
				title='Request changes'
				isFetching={isPosting}
				buttonText='Request changes'
				icon='changes'
			>
				<p>Add your requested changes in the comments before notifying the influencer.</p>
			</ConfirmModal>

			<DeleteModal
				isModalOpen={isDeleteModalOpen}
				toggleModal={() => handlers.setIsDeleteModalOpen(!isDeleteModalOpen)}
				action={() => onDeleteMedia(selectedMediaId)}
				title='Are you sure?'
				isFetching={isPosting}
			>
				<p>Are you sure you want to delete this media?</p>
			</DeleteModal>
		</>
	);
};

export default Modals;
