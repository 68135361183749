import { Model } from 'json-api-models';

import CampaignCard from 'components/Dashboard/Components/CampaignCard';

/**
 * Holds the campaign cards for each section
 *
 * @returns {JSX.Element}
 */

const CampaignSection = ({
	campaigns,
	firstCard = false,
	refresh,
	refreshAll,
	refreshArchived,
}: {
	campaigns: Array<Model>;
	firstCard?: boolean;
	refresh: () => void;
	refreshAll: () => void;
	refreshArchived: () => void;
	startIndex: number;
}): JSX.Element => (
	<>
		{campaigns.map((item: Model, index: number) => {
			return (
				<CampaignCard
					index={index}
					className='isPublisher'
					coverImage={{
						sm: item.links?.smallCoverPhoto ?? '',
						md: item.links?.mediumCoverPhoto ?? '',
						lg: item.links?.largeCoverPhoto ?? '',
					}}
					brandName={item.brand?.name ?? ''}
					first={firstCard}
					key={item.id}
					campaign={item}
					canEdit={item.canEdit}
					refresh={refresh}
					refreshAll={refreshAll}
					refreshArchived={refreshArchived}
					unreadMessages={item?.conversationMetaData?.attributes?.unread}
					campaignId={item.id}
					campaignName={item.attributes?.name}
					inboxNavigation={`/inbox/${item.attributes?.shortId}`}
				/>
			);
		})}
	</>
);

export default CampaignSection;
