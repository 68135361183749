import { PrimaryButton } from 'components/Button';

import Styled from './UploadCSVFileBlock.style';

type Props = {
	onClick: () => void;
	disabled?: boolean;
};

/**
 * @param {Props} props
 * @returns {JSX.Element}
 */
const UploadCSVFileBlock = ({ onClick, disabled }: Props) => {
	return (
		<Styled.InnerWrapper data-testid='upload-csv-file-block'>
			<PrimaryButton icon='upload' onClick={onClick} disabled={disabled}>
				Import CSV file
			</PrimaryButton>
			<Styled.HelpText>Import CSV files from Instagram or TikTok.</Styled.HelpText>
		</Styled.InnerWrapper>
	);
};

export default UploadCSVFileBlock;
