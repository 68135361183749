import { Network } from 'constants/socialMedia';

import Styled from './InfluencerAvatar.style';

type InfluencerProps = {
	displayNetwork: boolean;
	pulse?: boolean;
	inHeader?: boolean;
	profileImageUrl: string;
	userName: string;
	network?: Network;
	size?: 'sm' | 'md' | 'lg';
	scale?: 'sm' | 'md' | 'lg';
	hasAnAccount?: boolean;
	missingEmail?: boolean;
	displayName?: boolean;
	id?: string;
};
/**
 * Influencer avatar with network icon
 * @returns {JSX.Element}
 */
const InfluencerAvatar = ({
	userName,
	network,
	displayNetwork,
	profileImageUrl,
	pulse,
	scale = 'md',
	hasAnAccount,
	missingEmail,
	displayName,
	id,
}: InfluencerProps): JSX.Element => {
	return (
		<Styled.Container id={id}>
			<Styled.Wrapper data-testid='avatar'>
				{profileImageUrl === '' ? (
					<Styled.LetterAvatar name={userName} scale={scale} />
				) : (
					<Styled.UserAvatar img={profileImageUrl} scale={scale} className={hasAnAccount ? 'account' : ''} />
				)}
				{pulse && <Styled.PulseRing />}
				{displayNetwork && network && (
					<Styled.NetworkIcon
						className={missingEmail ? 'missing-email' : ''}
						name={network === Network.INSTAGRAM ? 'instagram-circle-color' : 'tiktok-color'}
						size='20'
					/>
				)}
				{missingEmail && <Styled.EmailIcon name='email-missing' size='13' />}
			</Styled.Wrapper>
			{displayName && <strong>{userName}</strong>}
		</Styled.Container>
	);
};

export default InfluencerAvatar;
