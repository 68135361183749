import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { RegularLink } from 'styles/utils/Link';

const Wrapper = styled.div`
	ul {
		&.select-platform {
			display: flex;
			gap: 8px;
		}

		padding: 0;
		margin: 0;

		li {
			width: 100%;
			padding: 0;
			list-style: none;
		}
	}

	p {
		margin-top: 0;
	}
`;

const Headline = styled.span`
	display: block;
	font-size: 1rem;
	margin-bottom: 16px;
`;

const ConnectorWrapper = styled.div`
	margin-bottom: 16px;
`;

const ConnectedProfiles = styled.div`
	max-width: 500px;
`;

const SocialMediaPlatforms = styled.div`
	padding-bottom: 1.5rem;
	margin-bottom: 24px;
	border-bottom: 1px solid ${colors.dust};
`;

const ConnectSection = styled.div`
	display: flex;
	flex-direction: column;
	hr {
		border-top: 1px solid ${colors.ash};
	}
	p {
		margin-top: 24px;
	}
	button {
		width: fit-content;
	}
`;

const DisconnectButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	font-size: 1rem;
	cursor: pointer;
	${RegularLink};
	color: ${colors.oceanBlue};
`;

const ConnectLink = styled.button`
	display: block;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	font-size: 0.875rem;
	margin: 0 auto;
	${RegularLink};

	&:after {
		opacity: 1;
	}
`;

const SignUpButtonWrapper = styled.div`
	& > button {
		display: block;
		font-size: 1rem;
		width: fit-content;
		margin-top: 16px;
	}
`;

const Skip = styled.div`
	color: ${colors.oceanBlue};
	cursor: pointer;
	padding: 1rem 0;
`;

const Styled = {
	Skip,
	Wrapper,
	Headline,
	ConnectorWrapper,
	ConnectedProfiles,
	SocialMediaPlatforms,
	ConnectSection,
	DisconnectButton,
	ConnectLink,
	SignUpButtonWrapper,
};

export default Styled;
