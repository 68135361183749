import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HoverButton } from 'components/Button';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import Pill from 'components/Pill';
import Styled from 'components/Settings/ClientManagement/ClientManagement.style';
import { CreateClientProps } from 'components/Settings/ClientManagement/types';
import InfoText from 'components/Settings/Components/InfoText/InfoText';
import SettingsStyle from 'components/Settings/Settings.style';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import ClientManager from 'services/ApiManager/Client.manager';
import toast from 'services/Toast';
import colors from 'styles/theme/colors';

const CreateClient = ({ publisher, mutateFn }: CreateClientProps) => {
	const user = useAppSelector((state) => state.user);
	const [newClientName, setNewClientName] = useState<string>('');
	const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
	const [displayError, setDisplayError] = useState<boolean>(false);
	const navigate = useNavigate();

	const manager = useInjection<ClientManager>(ClientManager);

	const saveClient = () => {
		if (newClientName === '') {
			setDisplayError(true);
		} else {
			setDisplayError(false);
			const payload = {
				name: newClientName,
				administrators: [user.id],
				projectManagers: [],
				brandManagers: [],
				dataAnalysts: [],
			};

			return (
				newClientName &&
				manager
					.create(publisher.id, payload, undefined, {
						mutateFn: mutateFn,
					})
					.then((client) => {
						toast.success(`New client ${newClientName} created`);
						setIsCreateModalOpen(false);
						setNewClientName('');
						navigate(`/settings/clients/${client.id}`, { state: { invite: true } });
					})
					.catch((error: AxiosError) => {
						setDisplayError(true);
						toast.error(getErrorMessageOnPost('creating the client'));
						console.error('Error creating list', error);
						setNewClientName('');
					})
			);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewClientName(e.target.value);
	};

	return (
		<Styled.CreateContainer>
			<HoverButton useAddIcon onClick={() => setIsCreateModalOpen(true)} data-testid='add-client-button'>
				{`Add client to ${publisher?.attributes.name}`}
			</HoverButton>
			<SideDrawer
				sidebarIsOpen={isCreateModalOpen}
				expandedTitle={
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						Add client <Pill backgroundColor={colors.paleGreenTint} title={`${publisher?.attributes.name}`} />
					</div>
				}
				onClose={() => setIsCreateModalOpen(false)}
				dataTestId={'create-client-drawer'}
				title='Client management'
				isExpandable
				saveButtonText='Save Client'
				onSave={saveClient}
			>
				<>
					<div style={{ marginTop: '16px' }}>
						<Field label='Client name'>
							<Input value={newClientName} name='newClientName' placeholder='Name your client' onChange={(e) => handleInputChange(e)} />
						</Field>
					</div>
					<div style={{ marginTop: '16px' }}>
						<InfoText color={colors.paleGreenTint} text={`This client will be associated with ${publisher?.attributes.name}.`} />
					</div>
					{displayError && (
						<SettingsStyle.HelperText>
							<Icon name='alert' size='12' />
							<span>You need to enter the name of your client</span>
						</SettingsStyle.HelperText>
					)}
				</>
			</SideDrawer>
		</Styled.CreateContainer>
	);
};

export default CreateClient;
