import { Model } from 'json-api-models';
import { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import CMStartImage from 'assets/img/app/CM-start.svg';
import { SecondaryButton } from 'components/Button';
import { customValidator } from 'components/ContentManagement/Utils';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import Styled from './UploadMedia.style';

enum NUM_OF_FILES {
	TIKTOK_VIDEO = 1,
	INSTAGRAM_REEL = 1,
	INSTAGRAM_STORY = 50,
	INSTAGRAM_POST = 10,
}

const allowedFileTypes: Record<string, Record<string, string[]>> = {
	[ASSIGNMENT_TYPE.TIKTOK_VIDEO]: {
		'video/*': [],
	},
	[ASSIGNMENT_TYPE.INSTAGRAM_REEL]: {
		'video/*': [],
	},
	[ASSIGNMENT_TYPE.INSTAGRAM_STORY]: {
		'image/*,video/*': [],
	},
	[ASSIGNMENT_TYPE.INSTAGRAM_POST]: {
		'image/*,video/*': [],
	},
};

type UploadMedia = {
	onChange: (Files: Array<File>) => void;
	activeCampaignInstagramOwnerAssignment?: Model;
	disabled?: boolean;
};

/**
 * UploadMedia
 * Component to let users drag and drop files into a input
 * react-dropzone are used to make this possible.
 * @todo better typing
 * @param {UploadMedia}
 * @returns {JSX.Element}
 */
const UploadMedia = ({ activeCampaignInstagramOwnerAssignment, onChange, disabled }: UploadMedia): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	const assignment = activeCampaignInstagramOwnerAssignment?.assignment ?? {};

	const maxNumOfFiles = NUM_OF_FILES[assignment.kind as keyof typeof NUM_OF_FILES];

	const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
		maxFiles: maxNumOfFiles as unknown as number,
		accept: assignment?.kind && allowedFileTypes[assignment?.kind],
		validator: customValidator,
		disabled: disabled,
	});

	const tooManyFiles = fileRejections.some(({ errors }) => errors.some((e) => e.code === 'too-many-files'));

	const fileRejectionItems = tooManyFiles
		? [
				<Styled.ErrorMessagesListItem key={0}>
					<Styled.ErrorMessagesList>
						<Styled.ErrorMessagesListItem>Too many files, max {NUM_OF_FILES[assignment?.kind]} files.</Styled.ErrorMessagesListItem>
					</Styled.ErrorMessagesList>
				</Styled.ErrorMessagesListItem>,
			]
		: fileRejections.map(({ file, errors }) => (
				<Styled.ErrorMessagesListItem key={file.name}>
					{file.name}
					<Styled.ErrorMessagesList>
						{errors.map((e) => (
							<Styled.ErrorMessagesListItem key={e.code}>{e.message}</Styled.ErrorMessagesListItem>
						))}
					</Styled.ErrorMessagesList>
				</Styled.ErrorMessagesListItem>
			));

	useEffect(() => {
		if (Array.isArray(fileRejections) && !fileRejections.length) {
			onChange(acceptedFiles);
		}
	}, [acceptedFiles, fileRejections, onChange]);

	return (
		<Styled.UploadWrapper data-testid='upload-media'>
			<Styled.DndArea {...getRootProps({ className: 'dropzone' })}>
				<Styled.DndContent>
					<img src={CMStartImage} alt='Upload content' />
					<Styled.DndText>Drop your photos/videos here</Styled.DndText>
					<SecondaryButton disabled={disabled} onClick={() => open()}>
						Browse files
					</SecondaryButton>

					{fileRejectionItems.length > 0 ? (
						<Styled.ErrorMessagesList data-testid='error-messages'>{fileRejectionItems}</Styled.ErrorMessagesList>
					) : (
						<Styled.SupportedFormats>The Supported formats include JPEG, PNG, HEIC, MP4, MOV, AVI</Styled.SupportedFormats>
					)}
					<Styled.UploadInput data-testid='drop-input' accept='image/*, video/*' type='file' ref={inputRef} {...getInputProps()} />
				</Styled.DndContent>
			</Styled.DndArea>
		</Styled.UploadWrapper>
	);
};

export default UploadMedia;
