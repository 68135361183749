import { DropzoneState } from 'react-dropzone';
import styled from 'styled-components';

import { SecondaryButton } from 'components/Button';
import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	& > h5 {
		font-size: 1.4375rem;
		margin-bottom: 40px;
	}
`;

const getDndAreaBg = (props: { isDragAccept: boolean; isDragReject: boolean }) => {
	if (props.isDragAccept) {
		return colors.dataLibrary.csvFileUploadModal.dndArea.fileAcceptBg;
	}
	if (props.isDragReject) {
		return colors.dataLibrary.csvFileUploadModal.dndArea.background;
	}
	return colors.dataLibrary.csvFileUploadModal.dndArea.background;
};

const DndArea = styled.div<Pick<DropzoneState, 'isDragAccept' | 'isDragReject'>>`
	width: 100%;
	height: 176px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => getDndAreaBg(props)};
	border: 1px dashed ${colors.dataLibrary.csvFileUploadModal.dndArea.border};
	border-radius: 10px;

	margin-bottom: 24px;

	&.shorter {
		height: calc(176px / 1.5);

		& > div {
			&.icon-wrapper {
				margin-bottom: 0;
			}
		}
	}

	& > div {
		&.icon-wrapper {
			margin-bottom: 16px;
		}
	}

	& > button {
		display: ${(props) => (props.isDragAccept ? 'none' : 'unset')};
	}
`;

const HelpText = styled.span`
	&.mb-3 {
		margin-bottom: 24px;
	}

	&.dnd__help-text {
		color: ${colors.dataLibrary.csvFileUploadModal.dndArea.helpText};
	}

	&.modal__help-text {
		font-size: 0.75rem;
	}
`;

const Button = styled(SecondaryButton)`
	min-height: unset;
	height: 32px;
	border-color: ${colors.dataLibrary.csvFileUploadModal.dndArea.buttonColor};
	color: ${colors.dataLibrary.csvFileUploadModal.dndArea.buttonColor};
`;

const UploadInput = styled.input`
	display: none;
`;

const FileItems = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	margin-bottom: 24px;
`;

const FileItem = styled.div`
	width: 100%;
	height: 60px;
	padding: 1rem;

	display: flex;
	align-items: center;
	background-color: ${colors.dataLibrary.csvFileUploadModal.fileItem.background};
	border-radius: 10px;
	& > span {
		flex: 1;
	}
`;

const IconButton = styled.button`
	cursor: pointer;
	border: 0;
	padding: 0;
	background-color: transparent;

	.icon {
		display: flex;
	}

	&.remove-file {
		width: 32px;
		height: 32px;
		border-radius: 50%;

		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			background-color: ${colors.dataLibrary.csvFileUploadModal.iconButton.hoverBg};
		}
	}
`;

const ErrorMessage = styled.div`
	padding: 0.5rem;
	border-radius: 4px;
	background-color: ${colors.dataLibrary.csvFileUploadModal.errorMessage.background};
	color: ${colors.dataLibrary.csvFileUploadModal.errorMessage.color};

	display: flex;
	align-items: center;
	column-gap: 8px;
	margin-bottom: 8px;

	& > span {
		font-size: 0.75rem;
		line-height: 1;
	}

	.icon {
		display: flex;
	}
`;

const UploadingMessage = styled.div`
	height: 32px;

	padding: 0.5rem;
	border-radius: 4px;
	background-color: ${colors.dataLibrary.csvFileUploadModal.uploadingMessage.loadingBackground};
	font-size: 0.75rem;

	display: flex;
	align-items: center;
	justify-content: space-between;

	&.completed {
		background-color: ${colors.dataLibrary.csvFileUploadModal.uploadingMessage.completedBackgroud};
		color: ${colors.dataLibrary.csvFileUploadModal.uploadingMessage.completedColor};
		justify-content: flex-start;
		column-gap: 8px;
	}

	&.error {
		background-color: ${colors.dataLibrary.csvFileUploadModal.errorMessage.background};
		color: ${colors.dataLibrary.csvFileUploadModal.errorMessage.color};
		justify-content: flex-start;
		column-gap: 8px;
	}
`;

const UploadStatusWrapper = styled.div`
	width: 100%;
	max-width: 350px;
	margin: 0 auto;

	& > div {
		flex: 1;
		font-size: 0.75rem;
		text-align: start;

		&.file-upload-statuses {
			display: flex;
			flex-direction: column;
			row-gap: 8px;
			margin-bottom: 24px;
		}
	}
`;

const Styled = {
	Wrapper,
	DndArea,
	HelpText,
	Button,
	UploadInput,
	FileItems,
	FileItem,
	IconButton,
	ErrorMessage,
	UploadStatusWrapper,
	UploadingMessage,
};

export default Styled;
