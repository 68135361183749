import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import Icon from 'components/Icon';
import DeleteModal from 'components/Modals/DeleteModal';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import toast from 'services/Toast';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { clearDashboard, setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { clearDashboards, setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const DeleteDashboard = (): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);

	const dashboards: DashboardType[] = useAppSelector((state) => state.dashboards);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	// 1. Close delete modal after deleting a dashboard.
	// 2. Update searchable dashboards and dashboard to display.
	// 3. If last dashboard was deleted, create a new one and add to state.
	const deleteDashboardHandler = () => {
		setIsFetching(true);
		manager
			.delete(dashboard.id)
			.catch((err: AxiosError) => {
				console.error(err.message);
				toast.error(getErrorMessageOnPost('deleting the dashboard'));
			})
			.finally(() => {
				toast.success('Dashboard deleted');
				let lastDashboard = null;
				setIsFetching(false);
				setIsModalOpen(false);
				if (dashboards.length <= 0) {
					dispatch(clearDashboard());
					dispatch(clearDashboards());
				} else {
					for (const d of dashboards.slice().sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))) {
						if (d.id !== dashboard.id) {
							lastDashboard = d;
							break;
						}
					}

					const filteredList = dashboards.filter((d) => d.id !== dashboard.id);
					dispatch(setDashboards(filteredList));
				}

				if (lastDashboard === null) {
					navigate(`/data-library`);
				} else {
					dispatch(setDashboard(lastDashboard));
					navigate(`/data-library/${lastDashboard.id}`);
				}
			});
	};

	useEffect(() => {
		return () => {
			setIsModalOpen(false);
		};
	}, []);

	return (
		<>
			<Styled.CustomDropdownItem onClick={() => toggleModal()}>
				<Icon name='trash-bin' size='16' />
				Delete dashboard
			</Styled.CustomDropdownItem>
			<DeleteModal
				isFetching={isFetching}
				action={deleteDashboardHandler}
				title={`Delete the dashboard "${dashboard.name}"?`}
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
			/>
		</>
	);
};
export default DeleteDashboard;
