import styled from 'styled-components';

import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';

const FilterWrapper = styled.div`
	padding: 1rem;
	width: 0%;
	transition: width 200ms ease-in-out;
	visibility: hidden;
	position: relative;
	&.visible {
		height: calc(100vh - 255px);
		${scrollbarY};
		visibility: visible;
		float: right;
		width: 100%;
	}
`;

interface ICustomAccordionProps {
	bold?: boolean;
}
const CustomAccordion = styled(Accordion)<ICustomAccordionProps>`
	border-bottom: 1px solid ${colors.ash};
	padding: 0.75rem 0;

	.accordion__title {
		border-bottom: transparent !important;
	}
	&.inActive {
		.accordion__title {
			opacity: 0.5;
			border-bottom: transparent !important;
		}
	}
	.accordion__header {
		margin-bottom: 0;
	}
	label {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: 0px;
		text-align: left;
	}
	&.bold {
		.accordion__header {
			font-weight: ${({ theme }) => theme.fontWeights.bold};
		}
	}
`;

const OptionsList = styled.ul`
	max-height: 500px;
	height: auto;
	overflow: auto;
`;

const AccordionContent = styled.div`
	& > div {
		padding: 1rem;
	}
`;

const FilterOptionListItem = styled.li`
	line-height: 2;
	padding: 0.5rem 0;
	label {
		width: 85%;
		max-width: 300px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	&:hover {
		cursor: pointer;
	}
	img {
		height: 30px;
		width: 30px;
		border-radius: 100%;
		margin-left: 5px;
		margin-right: 5px;
	}
`;

const FilterInputContainer = styled.div`
	margin-top: 16px;
	margin-bottom: 5px;
`;

const ExtraMarginContainer = styled.ul`
	list-style: none;
	margin-top: 16px;
`;

const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 2rem;
`;

const CalendarContainer = styled.div`
	display: flex;
	button {
		background: transparent;
		border: none;
		cursor: pointer;
	}
`;

const DateFilterWrapper = styled.div`
	width: 100%;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
`;

const DateFilterLabel = styled.label`
	font-size: 1rem;
`;

const FilterDataContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	span {
		font-weight: 700;
	}
`;

const CustomGridContainer = styled(Grid.Container)`
	width: 100%;
	margin-top: 1rem;
`;

const Total = styled.div`
	font-weight: 400;
`;

const UnsavedText = styled.div`
	margin-bottom: 16px;
`;

const FiltersWrapper = styled.div`
	margin-top: 40px;
`;

const Styled = {
	FiltersWrapper,
	UnsavedText,
	FilterWrapper,
	Total,
	CustomAccordion,
	AccordionContent,
	FilterOptionListItem,
	OptionsList,
	FilterInputContainer,
	ExtraMarginContainer,
	SpinnerContainer,
	CalendarContainer,
	DateFilterWrapper,
	DateFilterLabel,
	Icon,
	FilterDataContainer,
	CustomGridContainer,
};

export default Styled;
