export interface CampaignStatistics {
	totalInfluencerAssignments: number;
	completedAssignments: number;
	influencerTargetCount: number;
	influencersJoined: number;
	nextDeadline: string;
	contentWaitingForReview: number;
}

export enum TabType {
	ALL = 'All',
	ACTIVE = 'Collaborations',
	DRAFTS = ' Drafts',
	ARCHIVED = 'Archived',
}

export enum Status {
	ACTIVE = 'active',
	DRAFT = 'draft',
	ARCHIVED = 'archived',
}
