import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const Publisher = styled.div`
	display: flex;
	align-items: center;
	img {
		margin-right: 10px;
		width: 150px;
		height: auto;
	}
`;

const Title = styled.h5`
	font-weight: 600;
	font-size: 0.938rem;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-bottom: 8rem;
	margin-top: 40px;
	hr {
		border-top: 1px solid black;
	}
	@media screen and (${devices.lg}) {
		width: 800px;
		margin: auto;
	}
`;

const Border = styled.div`
	width: 100%;
	height: 2px;
	border-top: 1px solid ${colors.gray11};
	margin: 24px 0;
`;

const Styled = {
	Publisher,
	Wrapper,
	Title,
	Border,
};

export default Styled;
