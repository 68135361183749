import styled from 'styled-components';

import colors from 'styles/theme/colors';

const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	padding-bottom: 1rem;
`;

const HelpText = styled.span`
	font-size: 0.75rem;
	margin-top: 16px;
	color: ${colors.slate};
`;

const Styled = {
	InnerWrapper,
	HelpText,
};

export default Styled;
