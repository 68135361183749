import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useState, useEffect, useCallback } from 'react';

import { HoverButton } from 'components/Button';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import { useAppDispatch } from 'views/DataLibrary/hooks';
import { setModalIsOpen } from 'views/DataLibrary/reducers/ModalSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './SearchDashboard.style';

/**
 * @returns {JSX.Element}
 */
const SearchDropdown = (props: { arrayToFilter: Array<DashboardType>; onSelect: (item: DashboardType) => void }): JSX.Element => {
	const { arrayToFilter, onSelect } = props;
	const [searchValue, setSearchValue] = useState<string>('');
	const [filteredSuggestions, setFilteredSuggestions] = useState<Array<DashboardType>>([]);
	const [cursor, setCursor] = useState(-1);
	const dispatch = useAppDispatch();

	// Fuzzy search function
	const fuzzySearch = (text: string, searchTerm: string): boolean => {
		const pattern = searchTerm
			.split('')
			.map((char) => `(?=.*${char})`)
			.join('');
		const regex = new RegExp(pattern, 'i');
		return regex.test(text);
	};

	// Enhanced search function
	const filterItems = useCallback(
		(value: string) => {
			if (!value || value.length < 1) {
				setFilteredSuggestions(arrayToFilter);
				return;
			}

			if (arrayToFilter?.length > 0) {
				const searchTerms = value.toLowerCase().split(' ');
				const newFilteredSuggestions = arrayToFilter.filter((item: DashboardType) => {
					const searchableText = [item.name].filter(Boolean).join(' ').toLowerCase();

					// Match all search terms
					return searchTerms.every((term) => fuzzySearch(searchableText, term) || searchableText.includes(term));
				});

				// Sort results by relevance
				newFilteredSuggestions.sort((a, b) => {
					const aStartsWith = a.name.toLowerCase().startsWith(value.toLowerCase());
					const bStartsWith = b.name.toLowerCase().startsWith(value.toLowerCase());

					if (aStartsWith && !bStartsWith) return -1;
					if (!aStartsWith && bStartsWith) return 1;
					return a.name.localeCompare(b.name);
				});

				setFilteredSuggestions(newFilteredSuggestions);
			}
		},
		[arrayToFilter],
	);

	// Debounced version of filterItems
	const debouncedFilter = useCallback(
		debounce((value: string) => filterItems(value), 300),
		[filterItems],
	);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
		setCursor(-1);
		debouncedFilter(value);
	};

	const reset = () => {
		setFilteredSuggestions(arrayToFilter);
		setSearchValue('');
	};

	const select = (item: DashboardType) => {
		setFilteredSuggestions([]);
		setSearchValue('');
		setCursor(-1);
		onSelect(item);
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (filteredSuggestions?.length > 0) {
				setCursor((prev) => {
					return prev < filteredSuggestions.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (filteredSuggestions?.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setFilteredSuggestions([]);
		}
		if (e.key === 'Enter') {
			if (cursor >= 0 && cursor < filteredSuggestions.length) {
				onSelect(filteredSuggestions[cursor]);
				setFilteredSuggestions([]);
				setCursor(-1);
			}
		}
	};

	useEffect(() => {
		setFilteredSuggestions(arrayToFilter);
	}, [arrayToFilter]);

	// Clean up debounce on unmount
	useEffect(() => {
		return () => {
			debouncedFilter.cancel();
		};
	}, [debouncedFilter]);

	return (
		<Styled.Dropdown data-testid='select-dashboards-dropdown'>
			<label id='search-dashboard-label' htmlFor='dashboard-search'>
				Search dashboards
			</label>
			<Styled.DropdownInnerContainer>
				<Input
					id='dashboard-search'
					value={searchValue}
					onChange={onChange}
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
					placeholder={'Find my dashboard'}
					aria-label='Search dashboards'
					aria-describedby='search-dashboard-label'
					role='combobox'
					aria-expanded={filteredSuggestions.length > 0}
					aria-controls='dashboard-search-listbox'
					aria-activedescendant={cursor >= 0 ? `dashboard-item-${cursor}` : undefined}
				/>
				{searchValue?.length > 0 && (
					<Styled.CancelContainer onClick={reset} aria-label='Clear search' role='button'>
						<Icon name='cancel-circle' size='16' />
					</Styled.CancelContainer>
				)}
				<Styled.ListContainer>
					<Styled.DashboardList role='listbox' id='dashboard-search-listbox' aria-label='Dashboard search results'>
						{filteredSuggestions.map((item: DashboardType, i: number) => {
							return (
								<Styled.DashboardListItem
									data-testid='dl-search-dashboard-filtered-item'
									key={i}
									onClick={() => {
										select(item);
									}}
									className={classNames({ keyDownSelect: i === cursor })}
									role='option'
									id={`dashboard-item-${i}`}
									aria-selected={i === cursor}
								>
									<span>{item.name}</span>
									<Styled.SelectDiv className={classNames({ keyDownSelect: i === cursor })}>select</Styled.SelectDiv>
								</Styled.DashboardListItem>
							);
						})}
					</Styled.DashboardList>
				</Styled.ListContainer>
				<Styled.DropdownFooter>
					<HoverButton useAddIcon onClick={() => dispatch(setModalIsOpen(true))} aria-label='Create new dashboard'>
						Create new
					</HoverButton>
				</Styled.DropdownFooter>
			</Styled.DropdownInnerContainer>
		</Styled.Dropdown>
	);
};

export default SearchDropdown;
