import Styled from './Pill.styles';

export type Props = {
	title: string;
	className?: string;
	backgroundColor?: string;
	color?: string;
	size?: 'sm' | 'md' | 'lg';
};

const Pill = ({ title, className, backgroundColor, color, size }: Props) => {
	return (
		<Styled.Pill data-testid='pill' className={className} backgroundColor={backgroundColor} color={color} size={size}>
			<div>{title}</div>
		</Styled.Pill>
	);
};

export default Pill;
