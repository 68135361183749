import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import Styled from './Dropdown.style';
import { IDropdown, IDropdownItem } from './types';

/**
 * Dropdown
 * @todo Test, move child components, add context
 * @param {IDropdown} props
 * @returns {JSX.Element}
 */
const Dropdown = ({
	getIsOpen,
	open,
	children,
	tooltip,
	tooltipPosition = 'bottom',
	position = 'right',
	title,
	icon,
	className,
	size,
	disabled,
	keepInDOM,
	setOpen,
	...props
}: IDropdown): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(open !== undefined ? open : false);
	const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	let tooltipTimeout: ReturnType<typeof setTimeout>;

	const setIsOpenStates = (value: boolean) => {
		// syncs controlled and internal states for isOpen
		setIsOpen(value);
		setOpen && setOpen(value);
	};

	const handleClickOutside = (e: MouseEvent): void => {
		if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
			setIsOpenStates(false);
		}

		if (e.target instanceof HTMLAnchorElement) {
			setIsOpenStates(false);
		}
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (wrapperRef.current && e.key === 'Escape') {
			setIsOpenStates(false);
		}
	};

	const clearTooltipTimeout = (): void => {
		if (tooltipTimeout) {
			clearTimeout(tooltipTimeout);
		}
	};

	const handleMouseEnter = (): void => {
		tooltipTimeout = setTimeout(() => {
			setDisplayTooltip(true);
		}, 0);
	};

	const handleMouseLeave = (): void => {
		clearTooltipTimeout();
		setDisplayTooltip(false);
	};

	const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();
		e.stopPropagation();
		if (disabled) {
			return;
		}
		clearTooltipTimeout();
		setIsOpenStates(!isOpen);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			setDisplayTooltip(false);
		}
		getIsOpen && getIsOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		if (open !== undefined) {
			setIsOpenStates(open);
		}
	}, [open]);

	return (
		<Styled.Wrapper className={className}>
			<Tooltip content={tooltip} isOpen={displayTooltip && !isOpen} delayShow={0} position={tooltipPosition} positionStrategy='fixed'>
				<Styled.DropdownWrapper
					ref={wrapperRef}
					className={classNames({
						show: isOpen,
						right: position === 'right',
						left: position === 'left',
					})}
				>
					<Styled.Button
						onClick={handleButtonClick}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						className={classNames({ show: isOpen }, 'dropdown-button')}
						aria-haspopup={true}
						aria-expanded={isOpen}
						aria-controls='menu'
						data-testid='toggle-dropdown-menu'
						disabled={disabled}
						type='button'
						{...props}
					>
						{title && <span>{title}</span>}{' '}
						{icon && (
							<Styled.IconWrapper className={className}>
								<Icon name={icon || ''} size={size || '18'} />
							</Styled.IconWrapper>
						)}
					</Styled.Button>
					{!keepInDOM ? isOpen && children : children}
				</Styled.DropdownWrapper>
			</Tooltip>
		</Styled.Wrapper>
	);
};

export const DropdownMenu = ({ children, ...props }: IDropdownItem) => {
	return <Styled.DropdownMenu {...props}>{children}</Styled.DropdownMenu>;
};

export const DropdownItem = ({ children, ...props }: IDropdownItem) => {
	return <Styled.DropdownItem {...props}>{children}</Styled.DropdownItem>;
};

export default Dropdown;
