import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Använder localStorage som standard

import { KEY_COLLABS_API_USER_OBJECT } from 'constants/localStorage-keys';
import ContentManagementSlice from 'reducers/ContentManagementReducers/ContentManagementSlice';
import mercureMiddleware from 'reducers/MercureReducer/mercureMiddleware';
import SegmentSlice from 'reducers/SegmentReducers/SegmentSlice';
import { userNotificationsReducer, userPermissionsReducer, userReducer } from 'reducers/UserReducers';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';
import brandUsers from 'views/Campaign/CreateCampaign/reducers/CampaignBrandUsersSlice';
import background from 'views/Campaign/CreateCampaign/reducers/campaignBackgroundSlice';
import details from 'views/Campaign/CreateCampaign/reducers/campaignDetailsSlice';
import invoice from 'views/Campaign/CreateCampaign/reducers/campaignInvoiceDetails';
import SidebarSlice from 'views/influencer/Payment/reducers/SidebarSlice';

import { loadState } from './Utils';

const rootReducers = combineReducers({
	user: userReducer,
	userPermissions: userPermissionsReducer,
	userNotifications: userNotificationsReducer,
	payment: SidebarSlice,
	contentManagement: ContentManagementSlice,
	segments: SegmentSlice,
	background,
	details,
	brandUsers,
	invoice,
});

const persistConfig = {
	key: 'root', // Nyckeln för att identifiera lagrat state
	storage, // localStorage (kan bytas till sessionStorage eller annat)
	whitelist: ['background', 'details', 'brandUsers', 'invoice'],
};

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;

const storeUserStateMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
	const result = next(action);
	const userState = store.getState().user;
	new BrowserStorage().setItem(KEY_COLLABS_API_USER_OBJECT, JSON.stringify(userState));
	return result;
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	preloadedState: {
		user: loadState(KEY_COLLABS_API_USER_OBJECT),
	},
	// @ts-ignore
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(mercureMiddleware, storeUserStateMiddleware),
});

export const persistor = persistStore(store);
