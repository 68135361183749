import classNames from 'classnames';
import { useEffect, useState } from 'react';

import ImpersonateBar from 'components/ImpersonateBar';
import MainNavigation from 'components/MainNavigation/MainNavigation';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { getHomePath } from 'reducers/UserReducers/helpers';
import { isImpersonating } from 'services/auth-service';

import UserMenu from './Components/UserMenu/UserMenu';
import Styled from './Header.style';
import { IHeaderProps } from './types';

/**
 * Header
 * @param {IHeaderProps} props
 * @returns {JSX.Element}
 */
const Header = ({ menuItems, location }: IHeaderProps): JSX.Element => {
	const [scroll, setScroll] = useState(false);

	const homePath = useAppSelector(getHomePath);

	useEffect(() => {
		const handleScroll = () => setScroll(window.pageYOffset > 0);
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<Styled.Wrapper className={classNames('header-bar', { 'fixed-header': scroll })}>
			<Styled.HeaderTop>
				<Styled.Logotype to={homePath}>
					<span>Collabs</span>
				</Styled.Logotype>
				<Styled.Navigation data-testid='main-nav-icon'>
					<MainNavigation menuItems={menuItems} location={location} />
					{isImpersonating() && <ImpersonateBar />}
				</Styled.Navigation>
				<Styled.UserList>
					<Styled.UserListItem className='margin-left' data-testid='header-profile-icon'>
						<UserMenu />
					</Styled.UserListItem>
				</Styled.UserList>
			</Styled.HeaderTop>
		</Styled.Wrapper>
	);
};

export default Header;
