import Pill from 'components/Pill';
import LogoUpload from 'components/Settings/Components/LogoUpload/LogoUpload';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';

import Styled from './LogoAndAdmins.style';
import { LogoAndAdminProps } from './types';

const LogoAndAdmins = ({ uploadFn, deleteImageFn, item, canEdit, admins }: LogoAndAdminProps) => {
	return (
		<Styled.Card>
			<LogoUpload canEdit={canEdit} item={item} deleteImageFn={deleteImageFn} uploadFn={(file: File) => uploadFn(file)} />
			<div>
				<Styled.NameContainer>
					<Styled.AdminsTitle>Managers</Styled.AdminsTitle>
					<Pill title={`${admins.length}`} className='publisher' />
				</Styled.NameContainer>
				<Styled.UserList>
					{admins.map((user, key) => {
						return (
							<li key={key}>
								<UserAvatar fullName={user?.attributes.name} className='dark' medium />
							</li>
						);
					})}
				</Styled.UserList>
			</div>
		</Styled.Card>
	);
};

export default LogoAndAdmins;
