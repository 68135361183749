import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Icon } from 'components';
import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import { Heading } from 'components/Heading';
import { createClient } from 'shared/ApiClient/ApiClient';
import errorHandler from 'utils/formik_error_handler';

export interface IResetPasswordForm {
	token: string;
}

const VALIDATION_SCHEMA = Yup.object({
	password: Yup.string().required('Password is required'),
});

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const StyledButton = styled(PrimaryButton)`
	float: right;
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

/**
 * ResetPasswordForm
 * @returns {JSX.Element}
 */
const ResetPasswordForm = ({ token }: IResetPasswordForm): JSX.Element => {
	const [displayPassword, setDisplayPassword] = useState(true);

	const passwordRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	const ResetForm = () => (
		<Formik
			initialValues={{ password: '', confirmPassword: '', token }}
			onSubmit={async ({ password, token }, { setErrors }) => {
				try {
					await createClient().post('/public/passwords', { password, token });
					navigate('/login');
					toast.success('Password reset successfully');
				} catch (e) {
					if (errorHandler(e, setErrors).token) {
						navigate('/login/reset/expired');
					}
				}
			}}
			validationSchema={VALIDATION_SCHEMA}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ isSubmitting, isValid, errors, touched, setErrors }) => (
				<Form>
					<InputText
						action={
							<IconWrapper
								onClick={() => setDisplayPassword(!displayPassword)}
								aria-label={displayPassword ? 'Hide password' : 'Show password'}
								role='button'
								tabIndex={0}
							>
								<Icon name={displayPassword ? 'hide' : 'unhide'} aria-hidden='true' size='18' />
							</IconWrapper>
						}
						label={'Password'}
						className={errors.password && touched.password ? 'error' : ''}
						innerRef={passwordRef}
						id='password'
						name='password'
						type={displayPassword ? 'password' : 'text'}
						placeholder='New password'
						required
						onInput={() => setErrors({})}
					/>
					<StyledButton isLoading={isSubmitting} type='submit' disabled={isSubmitting || !isValid} data-testid='submit-btn'>
						Reset password
					</StyledButton>
				</Form>
			)}
		</Formik>
	);

	useEffect(() => {
		passwordRef && passwordRef.current && passwordRef.current.focus();
	}, [passwordRef]);

	return (
		<div data-testid='reset-password-form'>
			<HeaderContainer>
				<Heading as='h4'>Reset password</Heading>
				<Link to='/login'>Login</Link>
			</HeaderContainer>
			<ResetForm />
		</div>
	);
};

export default ResetPasswordForm;
