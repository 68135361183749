import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

import { IButtonProps } from './types';

const Button = styled.button<IButtonProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 44px;
	min-width: ${(props) => (props.width ? props.width : '100px')};
	width: ${(props) => (props.width ? props.width : 'auto')};
	padding: 0.25rem 1rem;
	border: solid 2px ${colors.smoke};
	background-color: ${colors.smoke};
	color: ${colors.snow};
	cursor: pointer;
	font-size: 1rem;
	white-space: nowrap;
	transition:
		background-color 150ms ease-in-out,
		color 150ms ease-in-out,
		border-color 150ms ease-in-out;
	border-radius: 10px;
	&:hover:not(:disabled, .disabled) {
		background-color: ${colors.black};
		color: ${colors.snow};
		border-color: ${colors.black};
	}
	.icon path {
		fill: ${colors.snow};
	}
	.icon {
		&.green path {
			fill: ${colors.emerald}!important;
		}
	}
	.icon {
		&.green path {
			fill: ${colors.emerald};
		}
	}
	&:focus {
		border: solid 2px ${colors.skyBlue};
	}

	&.disabled,
	&:disabled {
		background-color: ${colors.fog};
		color: ${colors.slate};
		border-color: ${colors.fog};

		svg > * {
			fill: ${colors.slate};
		}
	}

	.icon {
		display: inherit;
		width: auto;
		height: auto;
		path {
			transition: fill 150ms ease-in-out;
		}
	}

	${(props) => props.error && `border: 2px solid ${colors.ruby};`}

	${(props) =>
		props.usePulse &&
		`
		box-shadow: 0 0 0 0 rgba(16, 76, 182, 1);
		animation: pulse 2s infinite;
	`}

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0 rgba(16, 76, 182, 0.7);
		}

		70% {
			box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		}

		100% {
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const PrimaryButton = styled(Button)`
	background-color: ${colors.smoke};
	color: ${colors.snow};

	&:hover:not(:disabled) {
		background-color: ${colors.black};
	}
	&.isDangerButton {
		background: ${colors.rose};
		border: none;
		color: ${colors.burgundy};
		&:hover {
			background: ${colors.burgundy};
			color: ${colors.rose};
		}
	}
`;

const SecondaryButton = styled(Button)`
	background-color: ${colors.snow};
	border-color: ${colors.smoke};
	color: ${colors.smoke};

	.icon {
		path {
			fill: ${colors.smoke};
		}
		rect {
			stroke: ${colors.smoke};
		}
	}

	&:hover:not(:disabled) {
		background-color: ${colors.black};
		color: ${colors.snow};

		.icon {
			path {
				fill: ${colors.snow};
			}
			rect {
				stroke: ${colors.snow};
			}
		}
	}
	.disabled,
	&:disabled {
		border-color: ${colors.slate};
		color: ${colors.slate};
	}
	${(props) => props.error && `border: 2px solid ${colors.ruby};`}
`;

const TertiaryButton = styled(Button)`
	background-color: ${colors.mist};
	border-color: ${colors.mist};
	color: ${colors.smoke};

	.icon path {
		fill: ${colors.smoke};
	}
	&:hover:not(:disabled) {
		background-color: ${colors.ash};
		border-color: ${colors.ash};
		color: ${colors.smoke};
		.icon path {
			fill: ${colors.smoke};
		}
	}
	.disabled,
	&:disabled {
		color: ${colors.slate};
	}
	${(props) => props.error && `border: 2px solid ${colors.ruby};`}
`;

const ButtonContent = styled.span`
	display: flex;
	align-items: center;
`;

const LinkButton = styled(Button)`
	background-color: transparent;
	border-color: transparent;
	color: ${colors.smoke};
	width: fit-content !important;
	padding: 0;
	border-radius: 0px;
	height: 30px;

	.icon {
		path {
			fill: ${colors.smoke};
		}
		rect {
			stroke: ${colors.smoke};
		}
	}

	&:hover:not(:disabled, .disabled) {
		background-color: transparent;
		color: ${colors.smoke};
		border-color: transparent;
		border-radius: 0px;
		text-decoration: underline;
		text-underline-offset: 8px;
		line-height: 2;
		.icon path {
			fill: ${colors.smoke};
		}
	}

	&:focus {
		border: solid 2px ${colors.skyBlue};
		padding: 1rem;
		border-radius: ${borderRadius.m};
		&:hover:not(:disabled, .disabled) {
			background-color: transparent;
			color: ${colors.smoke};
			.icon path {
				fill: ${colors.smoke};
			}
		}
	}

	&.disabled,
	&:disabled {
		border-color: transparent;
		background-color: transparent;
	}
	${(props) =>
		props.error &&
		`color: ${colors.ruby};
		.icon path {
		fill: ${colors.ruby};
	}
			&:hover:not(:disabled, .disabled) {
		color: ${colors.ruby};
		border-bottom: 2px solid ${colors.ruby};
		}

		`}

	${(props) =>
		props.isBlue &&
		`color: ${colors.oceanBlue};
		.icon path {
		fill: ${colors.oceanBlue};
	}
			&:hover:not(:disabled, .disabled) {
		color: ${colors.oceanBlue};
		border-bottom: 2px solid ${colors.oceanBlue};
		}

		`}
`;

const BlueButton = styled(Button)`
	background-color: ${colors.oceanBlue};
	border-color: transparent;
	color: ${colors.snow};
	.icon {
		path {
			fill: ${colors.snow};
		}
	}
	${(props) => props.error && `border: 2px solid ${colors.ruby};`}
`;

const HoverButton = styled(Button)`
	background-color: transparent;
	color: ${colors.smoke};
	border-color: transparent;
	font-weight: 600;
	&:hover {
		background-color: ${colors.ash} !important;
		color: ${colors.smoke} !important;
		border-color: transparent !important;
		.icon {
			path {
				fill: ${colors.smoke} !important;
			}
		}
	}
	.icon {
		path {
			fill: ${colors.smoke};
		}
		rect {
			stroke: ${colors.smoke};
		}
	}
	${(props) => props.error && `border: 2px solid ${colors.ruby};`}
`;

const Styled = {
	Button,
	SecondaryButton,
	TertiaryButton,
	PrimaryButton,
	ButtonContent,
	LinkButton,
	BlueButton,
	HoverButton,
};

export default Styled;
