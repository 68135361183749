import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CampaignModel, SegmentModel } from 'api-models';
import Avatar from 'components/Avatar';
import { TertiaryButton, PrimaryButton, SecondaryButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';
import { FEATURE_FLAG_SEGMENTS } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import IRepository from 'utils/Repository/IRepository';

import BackButton from './Components/BackButton';
import CloseButton from './Components/CloseButton';
import Styled from './DiscoveryDropdown.style';
import { scrollToItem } from './helpers';

type CampaignListMenuItemsProps = {
	items: Array<CampaignModel>;
	onCancel?: () => void;
	onClick: (url: string, campaignId: string, campaignName: string, segmentId?: string) => void;
	onClose: () => void;
	isCancellable?: boolean;
	isSaving: boolean;
	repository: IRepository;
	selectedItems: string[];
	isLoadingCampaigns?: boolean;
};

/**
 */
const CampaignListMenuItems = ({
	items,
	onCancel,
	onClick,
	onClose,
	isCancellable,
	isSaving,
	repository,
	selectedItems,
	isLoadingCampaigns,
}: CampaignListMenuItemsProps) => {
	const [selectedCampaign, setSelectedCampaign] = useState<CampaignModel | null>(null);
	const [isSelectionEnabled, setIsSelectionEnabled] = useState(true);
	const [searchValue, setSearchValue] = useState<string>('');
	const [campaignsToList, setCampaignsToList] = useState<CampaignModel[]>(items);
	const [selectedSegment, setSelectedSegment] = useState<SegmentModel | undefined>(undefined);

	const [cursor, setCursor] = useState(-1);
	const [isEnabled] = useFeatureToggle();
	const { userCan } = useFeaturePermissions(selectedCampaign?.links);

	const addSelectionToCampaignhandler = (addInfluencersUrl: string, id: string, campaignName: string, segmentId?: string) => {
		onClick(addInfluencersUrl, id, campaignName, segmentId);
	};

	const navigate = useNavigate();

	const onSearchCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
		if (value?.length === 0) {
			setCampaignsToList(items);
		} else {
			const filteredData = items.filter((item: CampaignModel) => {
				return item.attributes.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
			});
			setCampaignsToList(filteredData);
		}
	};

	const onResetSearch = () => {
		setSearchValue('');
		setCampaignsToList(items);
		setCursor(-1);
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev < campaignsToList.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (campaignsToList.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setCampaignsToList(items);
			setSearchValue('');
		}

		if (e.key === 'Enter') {
			const selectedCampaign = campaignsToList[cursor];
			if (selectedCampaign && selectedCampaign.links?.addInfluencers) {
				addSelectionToCampaignhandler(selectedCampaign.links?.addInfluencers, selectedCampaign.id, selectedCampaign.attributes.name);
			} else {
				console.warn('Campaign data is missing or incomplete for the selected item.');
			}
		}
	};

	useEffect(() => {
		scrollToItem(`item-${cursor}`);
	}, [cursor]);

	const renderItems = () => {
		if (items.length === 0) {
			return (
				<Styled.EmptyState>
					<p>No campaigns created yet.</p>
					<SecondaryButton onClick={() => navigate('/campaigns/create')}>Create new campaign</SecondaryButton>
				</Styled.EmptyState>
			);
		}

		const itemIsSelected = (item: CampaignModel, segments: SegmentModel[]) => {
			if (isEnabled(FEATURE_FLAG_SEGMENTS)) {
				return selectedCampaign?.id === item.id && segments.length < 1;
			} else {
				return selectedCampaign?.id === item.id;
			}
		};

		const displaySegments = (item: CampaignModel, segments: SegmentModel[]) => {
			return isEnabled(FEATURE_FLAG_SEGMENTS) && selectedCampaign?.id === item.id && segments.length > 1 && userCan('createSegment');
		};

		return (
			<Styled.MenuItemsWrapper className='campaign-list grow-1'>
				<SearchInput
					onKeyDown={(e) => {
						keyboardNavigation(e);
					}}
					value={searchValue}
					onChange={(e) => onSearchCampaignName(e)}
					onReset={() => onResetSearch()}
					className='small'
					placeholder='Search for campaigns'
				/>
				{campaignsToList.map((item, key) => {
					const segments = repository.findByRelation<SegmentModel, CampaignModel>(item, 'segments');

					return (
						<Styled.DropdownItemInnerWrapper
							id={`item-${key}`}
							key={key}
							onClick={() => {
								if (isSelectionEnabled) {
									setSelectedCampaign(item);
								}
							}}
							onDoubleClick={() => {
								setIsSelectionEnabled(false);
								addSelectionToCampaignhandler(item.links?.addInfluencers ?? '', item.id, item.attributes.name);
								setIsSelectionEnabled(true);
								setSelectedCampaign(null);
							}}
							className={classNames({
								'selected-item': itemIsSelected(item, segments),
								displaySegments: displaySegments(item, segments),
								cursor: cursor === key,
							})}
						>
							<Styled.NameWrapper>
								<Avatar imageUrl={item.links?.smallCoverPhoto} name={item.attributes.name} />
								<Styled.Name>{item.attributes.name}</Styled.Name>
							</Styled.NameWrapper>
							{displaySegments(item, segments) && (
								<Styled.SegmentsWrapper>
									<ul>
										{segments?.map((segment: SegmentModel, index: number) => {
											return (
												<Styled.SegmentItem
													className={selectedSegment?.id === segment.id ? 'slected' : ''}
													key={index}
													onClick={() => setSelectedSegment(segment)}
												>
													<Styled.SegmentTitle>
														<Icon name='change-segment' size='16' /> <span>{segment.attributes.name}</span>
													</Styled.SegmentTitle>
													<Styled.SelectText>Add to segment</Styled.SelectText>
												</Styled.SegmentItem>
											);
										})}
									</ul>
								</Styled.SegmentsWrapper>
							)}
						</Styled.DropdownItemInnerWrapper>
					);
				})}
				{isLoadingCampaigns ? <LoadingSpinner size='sm' /> : null}
			</Styled.MenuItemsWrapper>
		);
	};

	return (
		<Styled.CustomMenu>
			<Styled.MenuHeader>
				{isCancellable && onCancel && <BackButton onCancel={onCancel!} />}
				<CloseButton onClose={onClose} />
			</Styled.MenuHeader>

			{renderItems()}

			<Styled.MenuItemsWrapper>
				<Styled.MenuFooter>
					<TertiaryButton type='button' disabled={selectedCampaign === null} onClick={() => setSelectedCampaign(null)}>
						Cancel
					</TertiaryButton>
					<PrimaryButton
						type='button'
						isLoading={isSaving}
						disabled={selectedCampaign === null || !isSelectionEnabled}
						onClick={() => {
							selectedCampaign!.links?.addInfluencers
								? addSelectionToCampaignhandler(
										selectedCampaign!.links?.addInfluencers,
										selectedCampaign!.id,
										selectedSegment ? selectedSegment.attributes.name : selectedCampaign!.attributes.name,
										selectedSegment?.id,
									)
								: toast.error(getSomethingWentWrongMessage());
							setSelectedCampaign(null);
						}}
					>
						<span>Add {selectedItems?.length} profiles</span>
					</PrimaryButton>
				</Styled.MenuFooter>
			</Styled.MenuItemsWrapper>
		</Styled.CustomMenu>
	);
};

export default CampaignListMenuItems;
