/**
 * Collabs Global styles
 */
import { DefaultThemeV2, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import ToastStyles from './Toasts';
import { Input as rSuiteInput } from './Vendor/RSuite/Input';
import colors from './theme/colors';
import { Borders, Cursor, Flex, Margins, TextAlign, Typo } from './utils/UtilityClasses';
import { scrollbarY } from './utils/scrollbar';
import { mediaQueries } from './variables/media-queries';
import typography from './variables/typography';

const GlobalStyle = createGlobalStyle<{ theme: DefaultThemeV2 }>`
	${reset}; // reset css

	* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	}

	*, :after, :before {
		box-sizing: border-box;
	}

	html {
		font-size: 100%;
	}





	body {
		font-family: ${typography.BaseFontFamiliy};
		background-color: ${({ theme }) => theme.colors.background};
		color: ${({ theme }) => theme.colors.text};
		font-optical-sizing: auto;
		font-size: 16px; // base
	}

	// Headings
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: ${({ theme }) => theme.fontWeights.bold};
		color: ${({ theme }) => theme.colors.text};
		line-height: 1.370;
		margin-top: 0;
	}

	h1 {
		font-size: ${({ theme }) => theme.fontSizes.legacy.headings.h1};
		margin-bottom: 24px;
	}

	h2 {
		font-size: ${({ theme }) => theme.fontSizes.legacy.headings.h2};
		line-height: 1.370;
		margin-bottom: 16.8px;
	}

	h3 {
		font-size: ${({ theme }) => theme.fontSizes.legacy.headings.h3};
		line-height: 1.370;
		margin-bottom: 16px;
	}

	h4 {
		font-size: ${({ theme }) => theme.fontSizes.legacy.headings.h4};
		margin-bottom: 14.6px;
	}

	h5 {
		font-size: ${({ theme }) => theme.fontSizes.legacy.headings.h5};
		margin-bottom: 14px;
	}

	// Paragraphs
	p {
		font-size: 1rem;
		line-height: 1.5;
		color: ${({ theme }) => theme.colors.text};
		margin-bottom: 8px;
	}

	table {
		border-spacing: inherit;
	}

	// Hrefs
	a {
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;

		&:hover,
		&:focus {
			text-decoration: none;
			color: ${({ theme }) => theme.colors.text};
		}
	}

	a[href^="http"],
	a[href^="https"],
	a[href^="mailto:"],
	a[href^="tel:"] {
		color: ${({ theme }) => theme.colors.link.color};
		border-bottom: 1px solid ${({ theme }) => theme.colors.link.border};
		transition: border 0.2s ease-in;

		&:hover,
		&:focus {
			color: ${({ theme }) => theme.colors.link.hover};
			border-bottom: ${({ theme }) => theme.colors.link.hoverBorder};
		}
	}

	strong {
		font-weight: 600;
	}

	em {
		font-style: italic;
	}

	button, input, optgroup, select, textarea {
		margin: 0;
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
	}

	input {
		line-height: 1.5;
	}

  select,
	input {
	  -webkit-appearance: none;
	}

	input[type=date] {
	  line-height: 1;
	}

	// Lists
	ul,
	ol {
		padding-left: 0;

		&.unstyled {
			list-style: none;
			padding: 0;
			margin: 0;
		}
	}

	.disable-scroll {
		overflow-y: hidden;
	}

	@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
	}

	@keyframes scaleIn {
  0% {
    transform: scale(0);
		opacity: 0;
  }

  100% {
    transform: scale(1);
		opacity: 1;
  }
	}

	@keyframes scaleOut {
		0% {
			transform: scale(1);
			opacity: 1;
		}

		100% {
			transform: scale(0);
			opacity: 0;
		}
	}


	.d-block {
		display: block;
	}

	.relative {
		position: relative;
	}

	.main-content {
		padding-top: 3.7rem;
	}

	img {
    	max-width: 100%;
	}

	td.fit-content {
    width: 1% !important;
	}

	.scrollbar {
		${scrollbarY}
	}

	.fade-out {
		-webkit-animation: fadeOut 1s;
		animation: fadeOut 1s;
		animation-fill-mode: forwards;
	}

	@-webkit-keyframes fadeOut {
			0% { opacity: 1;}
			100% { opacity: 0;}
	}
	@keyframes fadeOut {
			0% { opacity: 1;}
			100% { opacity: 0;}
	}

	@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
	}

	.clearfix::after {
		content: "";
		clear: both;
		display: table;
	}

	@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
	}

	@keyframes changeColor {
  0% {
    border-color: #C5DCE0;
		border-bottom-color: transparent;
  }

  50% {
    border-color: #FFD7CC;
		border-bottom-color: transparent;
  }

  100% {
    border-color: #C5DCE0;
		border-bottom-color: transparent;
  }
}

@keyframes pulsing {
  0% {
    background-color: #333;
  }
  50% {
    background-color: #5c5c5c;
  }
  100% {
    background-color: #333;
  }
}

  .w-100 {
    width: 100%
  }

	.m-32-32 {
		margin: 32px 0;
	}

	.mr-16 {
		margin-right: 16px;
	}

	.ml-16 {
		margin-left: 16px;
	}

	.mb-0 {
		margin-bottom: 0;
	}

	.mb-24 {
		margin-bottom: 24px;
	}

	.mt-24 {
		margin-top: 24px;
	}

  table.table th {
    text-align: left;
  }

	.text-normal {
		font-weight: normal;
		line-height: 1;
	}

	.text-small {
		font-size: 0.875rem;
	}

	.text-strong {
		font-weight: 900;
	}

	.inbox-conversation {
		overflow: hidden;
		${mediaQueries.large`
			overflow: auto;
		`};
	}

	.no-style-btn {
		background: none;
		border: 0 none;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.loading-center {
	 	width: 100%;
	 	height: 100vh;
	 	display: flex;
	 	justify-content: center;
	 	align-items: center;
	 }

	.layout {
		height: 100vh !important;
		display: flex;
		flex: auto;
		flex-direction: column;
	}

	// Body backgrounds
	.bg--gray {
		&, .header-bar {
			background-color: ${colors.mist};
		}
	}

	.opacity-0 {
		opacity: 0;
	}

	.opacity-100 {
		opacity: 1;
	}

	.transition-opacity {
		transition: opacity ease-in-out;
	}

	.duration-200 {
		transition-duration: .2s;
	}

	${Flex};
	${Borders};
	${Typo};
	${ToastStyles}
	${TextAlign};
	${Cursor};
	${Margins};
	${rSuiteInput};
`;

export default GlobalStyle;
