import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormRefs {
	firstName: React.RefObject<HTMLInputElement>;
	lastName: React.RefObject<HTMLInputElement>;
	email: React.RefObject<HTMLInputElement>;
	password: React.RefObject<HTMLInputElement>;
	reCaptcha: React.RefObject<ReCAPTCHA>;
}

export const useAccountValidation = () => {
	const [displayPassword, setDisplayPassword] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);

	const refs: FormRefs = {
		firstName: useRef<HTMLInputElement>(null),
		lastName: useRef<HTMLInputElement>(null),
		email: useRef<HTMLInputElement>(null),
		password: useRef<HTMLInputElement>(null),
		reCaptcha: useRef<ReCAPTCHA>(null),
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		const fieldMap = {
			firstName: refs.lastName,
			lastName: refs.email,
			email: refs.password,
			password: refs.password,
		};

		const nextField = fieldMap[event.target.name as keyof typeof fieldMap];
		if (event.key === 'Enter' && nextField?.current) {
			nextField.current.focus();
		}
	};

	return {
		displayPassword,
		setDisplayPassword,
		shouldValidate,
		setShouldValidate,
		refs,
		handleKeyDown,
	};
};
