import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

const dl = colors.dataLibrary;

const SearchInputWrapper = styled.div`
	width: 100%;
	background-color: ${newColors.white};
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background: ${dl.button.cancelBackground};
	}
`;

const Styled = {
	SearchInputWrapper,
	IconContainer,
};

export default Styled;
