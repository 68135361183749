import { useEffect, useState } from 'react';

import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';
import { formatNumber, formatPercent } from 'shared/helpers/Chart/chart-util';
import { formatAmount } from 'utils/formatters';

import Styled from './InfluencerItem.style';
import { IInfluencerItem } from './types';

const InfluencerItem = (props: IInfluencerItem) => {
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			setDisplayRealValue(false);
		};
	}, []);

	const influencerValue = () => {
		if (props.isAmount) {
			return formatAmount(+props.value, 'SEK');
		}

		if (props.sign) {
			return formatPercent(props.value);
		}

		if (displayRealValue) {
			return props.value;
		}

		return formatNumber(props.value, true);
	};
	return (
		<Styled.Wrapper width={props.width} height={props.height}>
			<InfluencerAvatar
				profileImageUrl={props.profileImage ?? ''}
				userName={props.instagramUsername}
				displayNetwork={true}
				network={props.network as Network}
				scale='lg'
			/>
			<Styled.Section display='flex' alignItems='flex-start' justifyContent='center' flexDirection='column'>
				<Styled.InfluenceName>{props.instagramUsername}</Styled.InfluenceName>
				<Styled.FollowerValue>{`${formatNumber(props.follower, true)} followers`}</Styled.FollowerValue>
			</Styled.Section>
			<Styled.Section
				display='flex'
				justifyContent='flex-end'
				alignItems='center'
				maxWidth='30%'
				onMouseEnter={() => {
					setDisplayRealValue(true);
				}}
				onMouseLeave={() => {
					setDisplayRealValue(false);
				}}
			>
				<Styled.InfluencerValue>{influencerValue()}</Styled.InfluencerValue>
			</Styled.Section>
		</Styled.Wrapper>
	);
};

export default InfluencerItem;
