import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InvoiceDetails = {
	invoiceReference: string | null;
	yourReference: string | null;
	externalInvoiceReference1: string | null;
	externalInvoiceReference2: string | null;
	mediaAgencyFeePercentage: number;
};

export const initialState: InvoiceDetails = {
	invoiceReference: '',
	yourReference: '',
	externalInvoiceReference1: '',
	externalInvoiceReference2: '',
	mediaAgencyFeePercentage: 0,
};

const campaignInvoiceDetailsSlice = createSlice({
	name: 'invoice',
	initialState,
	reducers: {
		setInvoiceReference(state, action: PayloadAction<string>) {
			state.invoiceReference = action.payload;
		},
		setYourReference(state, action: PayloadAction<string>) {
			state.yourReference = action.payload;
		},
		setExternalInvoiceReference1(state, action: PayloadAction<string>) {
			state.externalInvoiceReference1 = action.payload;
		},
		setExternalInvoiceReference2(state, action: PayloadAction<string>) {
			state.externalInvoiceReference2 = action.payload;
		},
		setMediaAgencyFeePercentage: (state, action: PayloadAction<number>) => {
			state.mediaAgencyFeePercentage = action.payload ?? 0;
		},
		clearInvoiceDetails: () => initialState,
	},
});

export const {
	setInvoiceReference,
	setYourReference,
	setExternalInvoiceReference1,
	setExternalInvoiceReference2,
	clearInvoiceDetails,
	setMediaAgencyFeePercentage,
} = campaignInvoiceDetailsSlice.actions;

export default campaignInvoiceDetailsSlice.reducer;
