import { Model } from 'json-api-models';
import { useState, useRef, useEffect, RefObject } from 'react';
import { useParams } from 'react-router-dom';

import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import InfluencerAvatar from 'components/InfluencerAvatar';
import Tooltip from 'components/Tooltip';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useAutosizeTextArea from 'hooks/useAutosizeTextArea';
import toast from 'services/Toast';

import Styled from './ChatInput.style';

type Props = {
	onSend: (message: string, attachments?: Array<File>) => Promise<boolean>;
	onScrollToBottom: () => void;
	isDisabled: boolean;
	messageEndRef: RefObject<HTMLDivElement>;
	owners: Model[];
	setSelectedInfluencerId: (id: string) => void;
	selectedInfluencerId: string | undefined;
	isInfluencer?: boolean;
};

const ChatInput = ({ onSend, onScrollToBottom, isDisabled, messageEndRef, owners, setSelectedInfluencerId, selectedInfluencerId, isInfluencer }: Props) => {
	const [value, setValue] = useState<string>('');
	const [attachments, setAttachments] = useState<Array<File>>([]);
	const [isSendDisabled, setIsSendDisabled] = useState<boolean>(true);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [isPulsing, setIsPulsing] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const { conversationId } = useParams();

	useAutosizeTextArea(textAreaRef.current ? textAreaRef.current : null, value);

	const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (evt.target) {
			const val = evt.target.value;
			setValue(val);
		}
	};

	const handleTextAreaClick = () => {
		if (!selectedInfluencerId) {
			setIsPulsing(true);
			setTimeout(() => {
				setIsPulsing(false);
			}, 2000);
		}
	};

	const onClickSend = () => {
		setIsSendDisabled(true);
		setValue('');
		setAttachments([]);

		if (inputRef.current) {
			inputRef.current.value = '';
		}

		onSend(value, attachments)
			.then()
			.catch((e) => {
				toast.error(getErrorMessageOnPost('sending the message'));
				console.error(e);
			});
	};

	const onChangeAttachFile = (e: React.FormEvent<HTMLInputElement>) => {
		const files: FileList | null = e.currentTarget.files;
		if (files) {
			const fileArray = Array.from(files);
			setAttachments(attachments.concat(fileArray));
			setIsDropdownOpen(false);
		}
	};

	const onClickDeleteAttachment = (file: File) => {
		const filterFiles = attachments.filter((a) => a.name !== file.name);
		setAttachments(filterFiles);
		return filterFiles;
	};

	const getAttachmentIconName = (type: string) => {
		switch (true) {
			case type.includes('image'):
				return 'image';
			case type.includes('pdf'):
				return 'pdf-file';
			default:
				return 'other-file';
		}
	};

	const renderAttachments = (fileList: Array<File>) => {
		const files = fileList ? Array.from(fileList) : [];

		return (
			<Styled.AttachmentList>
				{files.map((file: File, index: number) => {
					return (
						<Styled.Attachment key={index} data-testid='inbox-attached-file-item'>
							<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
								<Icon className='attachment__type' name={getAttachmentIconName(file.type)} />
								<span className='attachment__name'>{file.name}</span>
							</div>
							<IconButton iconName='cross' iconSize='14' onClick={() => onClickDeleteAttachment(file)} />
						</Styled.Attachment>
					);
				})}
			</Styled.AttachmentList>
		);
	};

	useEffect(() => {
		setAttachments([]);
		setValue('');
		setIsDropdownOpen(false);
	}, [conversationId]);

	useEffect(() => {
		setIsSendDisabled(!/\S/.test(value));
	}, [value]);

	useEffect(() => {
		setIsSendDisabled(attachments.length === 0);
	}, [attachments]);

	useEffect(() => {
		if (!isSendDisabled) {
			onScrollToBottom();
		}
	}, [isSendDisabled]);

	return (
		<>
			{isInfluencer && (
				<div style={{ display: 'flex', gap: '8px', alignItems: 'center', margin: '8px 0' }}>
					{conversationId && owners && selectedInfluencerId ? (
						<Tooltip content={'Select profile'}>
							<InfluencerAvatar
								hasAnAccount={owners.filter((owner) => owner.influencer.id === selectedInfluencerId).length > 0}
								displayNetwork
								pulse={isPulsing}
								network={owners.filter((owner) => owner.influencer.id === selectedInfluencerId)[0]?.influencer.network}
								profileImageUrl={owners.filter((owner) => owner.influencer.id === selectedInfluencerId)[0]?.influencer.links?.profilePictureUrl ?? ''}
								userName={owners.filter((owner) => owner.influencer.id === selectedInfluencerId)[0]?.influencer.username}
							/>
						</Tooltip>
					) : (
						owners?.map(({ influencer }) => {
							return (
								<div onClick={() => setSelectedInfluencerId(influencer.id)} key={influencer.id} style={{ cursor: 'pointer' }}>
									<Tooltip content={'Select profile'}>
										<InfluencerAvatar
											hasAnAccount={influencer?.id === selectedInfluencerId}
											displayNetwork
											pulse={isPulsing}
											network={influencer?.network}
											profileImageUrl={influencer?.links?.profilePictureUrl ?? ''}
											userName={influencer?.username}
										/>
									</Tooltip>
								</div>
							);
						})
					)}
				</div>
			)}
			<Styled.MessageWrapper ref={messageEndRef} style={{ opacity: !selectedInfluencerId ? 0.7 : 1 }} onClick={handleTextAreaClick}>
				<Styled.MessageBar className='msg-bar'>
					{attachments.length > 0 && <Styled.AttachedFilesWrapper>{renderAttachments(attachments)}</Styled.AttachedFilesWrapper>}
					<Styled.TextAreaWrapper>
						{selectedInfluencerId && (
							<Styled.TextArea
								rows={2}
								placeholder='Message'
								onChange={handleChange}
								onClick={handleTextAreaClick}
								ref={textAreaRef}
								value={value}
								disabled={!selectedInfluencerId}
							/>
						)}
					</Styled.TextAreaWrapper>
					<Styled.MessageBarControlsWrapper onClick={handleTextAreaClick}>
						<Styled.MessageBarControls>
							<Styled.CustomDropdown
								disabled={!selectedInfluencerId}
								icon='plus'
								size='14'
								open={isDropdownOpen}
								position='left'
								data-testid='inbox-input-actions-dropdown'
							>
								<DropdownMenu>
									<DropdownItem
										data-testid='inbox-input-actions-dropdown-item'
										onClick={() => {
											inputRef?.current?.click();
											setIsDropdownOpen(true);
										}}
									>
										<span>
											<Icon name='add-document' /> Attach file...
										</span>
										<Styled.InvisibleInput ref={inputRef} multiple type='file' onChange={(e) => onChangeAttachFile(e)} data-testid='inbox-file-input' />
									</DropdownItem>
								</DropdownMenu>
							</Styled.CustomDropdown>
							<div>
								<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
									<Styled.Info>Message will also be sent as an email.</Styled.Info>
									<Styled.Send
										data-testid='send-msg-btn'
										onClick={onClickSend}
										disabled={!selectedInfluencerId || isSendDisabled || (isSendDisabled && isDisabled) || (isSendDisabled && attachments.length === 0)}
									>
										<Icon name='send' size='18' />
									</Styled.Send>
								</div>
							</div>
						</Styled.MessageBarControls>
					</Styled.MessageBarControlsWrapper>
				</Styled.MessageBar>
			</Styled.MessageWrapper>
		</>
	);
};

export default ChatInput;
