import Icon from 'components/Icon';
import colors from 'styles/theme/colors';

import Styled from './CloseButton.style';

const CloseButton = (props: { onClose: () => void }) => (
	<Styled.CloseDiv onClick={props.onClose}>
		<span>Close</span>
		<Icon name='cross' size='12' svgProps={{ fill: colors.slate }} />
	</Styled.CloseDiv>
);

export default CloseButton;
