import styled from 'styled-components';

import { IInfluencerStyle } from './types';

const Wrapper = styled.div<IInfluencerStyle>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};

	display: grid;
	row-gap: 16px;
`;

const CenteredWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const Styled = {
	Wrapper,
	CenteredWrapper,
};

export default Styled;
