import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	margin-bottom: ${guttersWithRem.xxl};
	margin-top: 40px;
`;

const HeadingWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.xs};

	font-size: 1.5rem;
	font-weight: ${typography.headings.h5.fontWeight};
	line-height: 1;

	margin-bottom: 40px;

	& > i {
		line-height: 1;
	}
`;

const CampaignsWrapper = styled.div`
	margin-bottom: ${guttersWithRem.xxl};
`;

const BottomWrapper = styled.div`
	width: 100%;
	line-height: 1;
`;

const SeeAll = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:before,
	&:after {
		content: ' ';
		flex-grow: 1;
		height: 1px;
		background-color: ${colors.black};
	}

	&:before {
		margin-right: ${guttersWithRem.m};
	}

	&:after {
		margin-left: ${guttersWithRem.m};
	}

	& > div {
		cursor: pointer;
	}
`;

const FilterSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const InputWrapper = styled.div`
	width: 100%;
	margin-bottom: 32px;
	@media screen and (${devices.md}) {
		width: 400px;
	}
`;

const Styled = {
	InputWrapper,
	FilterSection,
	Wrapper,
	HeadingWrapper,
	CampaignsWrapper,
	BottomWrapper,
	SeeAll,
};

export default Styled;
