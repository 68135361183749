import classNames from 'classnames';
import { FieldAttributes, useField } from 'formik';
import { useEffect, useMemo, useCallback, memo, RefObject } from 'react';

import Styled from 'components/Form/FormikElements/FormikElements.style';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

export enum DisableMode {
	DisableSelectOnly = 'disable-select',
	DisableInputOnly = 'disable-input',
	Disable = 'disable',
	None = 'none',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IFormSelectAndInputTextProps extends FieldAttributes<any> {
	size?: string;
	name: string;
	id: string;
	selectName: string;
	selectId: string;
	selectValue: string;
	options: Array<{ label: string; value: string }>;
	inputType: string;
	label?: string;
	helpText?: string;
	forwardRef?: RefObject<HTMLInputElement>;
	autoFocus?: boolean;
	/** @deprecated Use disableMode instead */
	isDisabled?: boolean;
	tooltip?: string;
	disableMode?: DisableMode;
}

const SelectMenu = memo(
	(props: { value: string; name: string; id: string; autoFocus?: boolean; options: Array<{ label: string; value: string }>; isDisabled?: boolean }) => {
		const [field, , helpers] = useField(props);
		const { setValue } = helpers;

		const handleChange = useCallback(
			(newValue: unknown) => {
				if (newValue && typeof newValue === 'object' && 'value' in newValue) {
					setValue((newValue as { value: string }).value);
				}
			},
			[setValue],
		);

		return (
			<Styled.CustomSelectMenu isDisabled={props.isDisabled} {...field} {...props} value={{ value: props.value, label: props.value }} onChange={handleChange} />
		);
	},
);

/**
 * Dropdown and Input component
 * @param {IFormSelectAndInputTextProps} props
 * @returns {JSX.Element}
 */
const DropdownAndInput = (props: IFormSelectAndInputTextProps): JSX.Element => {
	const {
		helpText,
		label,
		size,
		name,
		id,
		selectName,
		selectId,
		selectValue,
		options,
		inputType,
		forwardRef,
		className,
		tooltip,
		isDisabled,
		disableMode,
		autoFocus,
		...rest
	} = props;

	const [field, meta] = useField({ name, id });
	const [, selectMeta] = useField({ name: selectName, id: selectId });

	const isDisabledSelect = disableMode === DisableMode.DisableSelectOnly || disableMode === DisableMode.Disable || isDisabled;
	const isDisabledInput = disableMode === DisableMode.DisableInputOnly || disableMode === DisableMode.Disable || isDisabled;

	const isFocused = useMemo(() => document?.activeElement === forwardRef?.current, [forwardRef]);

	useEffect(() => {
		if (autoFocus && forwardRef?.current) {
			forwardRef.current.focus();
		}
	}, [autoFocus, forwardRef]);

	return (
		<Styled.InputWrapper className={className}>
			{label && (
				<Styled.Row>
					<Styled.Label htmlFor={name}>{label}</Styled.Label>
					{tooltip && (
						<Tooltip content={tooltip}>
							<Icon name='info-circle' size='12' />
						</Tooltip>
					)}
				</Styled.Row>
			)}
			<Styled.InputContainerWithSelect className={classNames({ focused: isFocused })}>
				<Styled.HoverSection>
					<SelectMenu autoFocus={autoFocus} name={selectName} id={selectId} value={selectValue} options={options} isDisabled={isDisabledSelect} />
				</Styled.HoverSection>
				<Styled.InputNextToDropdown
					data-testid='enter-emails-input'
					ref={forwardRef}
					type={inputType}
					{...field}
					size={size ? +size : undefined}
					disabled={isDisabledInput}
					{...rest}
				/>
			</Styled.InputContainerWithSelect>
			{helpText && <Styled.HelpText>{helpText}</Styled.HelpText>}
			{meta.touched && meta.error && <Styled.ErrorMessage>{meta.error}</Styled.ErrorMessage>}
			{selectMeta.touched && selectMeta.error && <Styled.ErrorMessage>{selectMeta.error}</Styled.ErrorMessage>}
		</Styled.InputWrapper>
	);
};

export default DropdownAndInput;
