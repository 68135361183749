import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';

import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import convertDashboardModelToDashboardType from 'components/DataLibrary/ConvertDashboardModelToDashboardType';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import Icon from 'components/Icon';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import toast from 'services/Toast';
import { useAppDispatch, useAppSelector } from 'views/DataLibrary/hooks';
import { setDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const EditDashboard = (): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [newDashboardName, setNewDashboardName] = useState<string>('');
	const [isFetching, setIsFetching] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const dashboardHelper = useInjection<DashboardHelper>(DashboardHelper);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		setNewDashboardName(dashboard.name);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewDashboardName(e.target.value);
	};

	const editDashboardHandler = () => {
		setIsFetching(true);
		manager
			.update(dashboard.id, { name: newDashboardName })
			.then((result) => {
				const { dashboard: updatedDashboard } = convertDashboardModelToDashboardType(manager.getRepository(), result);
				dispatch(setDashboard(updatedDashboard));
				dashboardHelper.getDashboards().then((res) => {
					// To update searchable options
					dispatch(setDashboards(res));
				});
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('updating the dashboard'));
				console.error(err.message);
			})
			.finally(() => {
				setIsFetching(false);
				setIsModalOpen(false);
				toast.success(`Name on dashboard changed to: ${newDashboardName}`);
				setNewDashboardName('');
			});
	};

	useEffect(() => {
		return () => {
			setIsModalOpen(false);
			setNewDashboardName('');
		};
	}, []);

	return (
		<>
			<Styled.CustomDropdownItem onClick={() => toggleModal()}>
				<Icon name='pen' size='16' />
				Rename dashboard
			</Styled.CustomDropdownItem>
			<ConfirmModal
				title='Edit dashboard'
				isFetching={isFetching}
				isModalOpen={isModalOpen}
				toggleModal={toggleModal}
				action={editDashboardHandler}
				buttonText='Save'
			>
				<Field label='Rename your dashboard'>
					<Input name='dasboard-name' type='text' value={newDashboardName} onChange={(e) => handleChange(e)} />
				</Field>
			</ConfirmModal>
		</>
	);
};
export default EditDashboard;
