import { AxiosResponse, AxiosError } from 'axios';
import { JsonApiDocument, JsonApiIdentifier, JsonApiResource, Store } from 'json-api-models';
import { filter } from 'lodash';
import { useState } from 'react';
import useSWR, { mutate } from 'swr';

import { AssignmentReviewResponse } from 'api-responses';
import { ICollabsResponse } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';
import { AssignmentType } from 'shared/helpers/Assigment/types';

type StatisticsPayloadType = {
	postedAt: string;
	url?: string;
	reach?: number;
	reachLastFrame?: number;
	impressions?: number;
	comments?: number;
	likes?: number;
	saves?: number;
	shares?: number;
	stickerLinkClicks?: number;
	stickerTaps?: number;
	otherInteractions?: number;
};

export type StatisticsPayloadForSelectorType = {
	stickerLinkClicks?: number;
	stickerTaps?: number;
	otherInteractions?: number;
	frames: string[];
};

function mapPayloadForStats(type: AssignmentType, payload: StatisticsPayloadType) {
	const data = {
		postedAt: new Date(payload.postedAt),
		reach: +payload.reach!,
		impressions: +payload.impressions!, // plays
	};

	switch (type) {
		case AssignmentType.TIKTOK_VIDEO:
			return {
				...data,
				url: payload.url,
				commentsCount: +payload.comments!,
				likeCount: +payload.likes!,
				saves: +payload.saves!,
				shares: +payload.shares!,
			};
		case AssignmentType.INSTAGRAM_STORY:
			return {
				...data,
				reachLastFrame: +payload.reachLastFrame!,
				stickerLinkClicks: +payload.stickerLinkClicks!,
				stickerTaps: +payload.stickerTaps!,
				otherInteractions: +payload.otherInteractions!,
			};

		default:
			return {
				...data,
				url: payload.url,
				commentsCount: +payload.comments!,
				likeCount: +payload.likes!,
				saves: +payload.saves!,
			};
	}
}

export function getCampaignUri(campaignId: string): string {
	return `/campaigns/${campaignId}?includes=commissions.campaignInstagramOwnerCommissions.commission,
	invites.campaignInstagramOwner.influencer.user,
	invites.campaignInstagramOwner.campaignInstagramOwnerCommissions.commission,
	invites.campaignInstagramOwner.campaignInstagramOwnerAssignments.instagramStories.files,
	invites.campaignInstagramOwner.campaignInstagramOwnerAssignments.instagramStories.screenshots,
	invites.campaignInstagramOwner.campaignInstagramOwnerAssignments.assignment.reviews,
	assignments.groups,
	assignments.groups.campaignInstagramOwnerAssignments.assignment,
	campaignInstagramOwners:context(contentReview)
	`;
}
export default function useContentManagementData(showOnlyJoinedCampaigns = false, influencerId?: string) {
	const client = createClient();

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const getCampaigns = () => {
		const models = new Store();

		const params = new URLSearchParams({
			includes: 'invites',
		});
		if (showOnlyJoinedCampaigns) {
			params.append('joined', 'true');
		}

		if (influencerId) {
			params.append('influencer', influencerId);
		}

		const url = `/campaigns?${params}`;
		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			mutation: {
				async add(resource: JsonApiResource) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: !data && !error,
		};
	};

	const getCampaign = (campaignId?: string) => {
		const models = new Store();

		const url = campaignId ? getCampaignUri(campaignId) : null;
		const { data, error } = useSWR(url, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			mutation: {
				async add(resource: JsonApiResource) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					await mutate(url);
				},
			},
			error,
			loading: campaignId ? !data && !error : false,
		};
	};

	// const getCIOAS = (hateoasUrl?: string) => {
	// 	debugger;
	// 	const models = new Store();
	// 	const INCLUDES = `
	// 		influencer.user,
	// 		reviews.medias,
	// 		assignment,
	// 		assignmentGroup
	// 	`;
	// 	const url = hateoasUrl ? `${hateoasUrl}?includes=${INCLUDES}` : '';

	// 	const { data, error } = useSWR(url, fetcher);

	// 	if (data && !error) {
	// 		models.sync(data);
	// 	}

	// 	return {
	// 		models,
	// 		mutation: {
	// 			async add(resource: JsonApiResource) {
	// 				await mutate({
	// 					...data,
	// 					data: [...(data!.data as JsonApiResource[]), resource],
	// 				});
	// 			},
	// 			async addIncluded(resource: JsonApiResource) {
	// 				await mutate({
	// 					...data,
	// 					included: [...(data!.included as JsonApiResource[]), resource],
	// 				});
	// 			},
	// 			async remove({ id }: JsonApiIdentifier) {
	// 				await mutate({
	// 					...data,
	// 					data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
	// 				});
	// 			},
	// 			async refresh() {
	// 				await mutate(url);
	// 			},
	// 		},
	// 		error,
	// 		loading: url ? !data && !error : false,
	// 	};
	// };

	// const getAssignmentReviews = async () => {
	// 	const url = `/assignment-reviews?includes=assignment,assignment.campaign,influencer,comments,comments.user,medias`;
	// 	const response = await client.get<JsonApiDocument>(url);
	// 	return response;
	// };

	const createComment = async (url: string, message: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			text: message,
		});
		return response;
	};

	const postReview = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const patchEditStatistics = async (url: string, data: StatisticsPayloadType, type: AssignmentType) => {
		const payload = mapPayloadForStats(type, data);
		const response = await client.patch<JsonApiDocument>(url, payload);
		return response;
	};

	const patchStorySelectorStatistics = async (url: string, data: StatisticsPayloadForSelectorType) => {
		const response = await client.patch<JsonApiDocument>(url, data);
		return response;
	};

	const postStatistics = async (url: string, data: StatisticsPayloadType, type: AssignmentType) => {
		const payload = mapPayloadForStats(type, data);
		const response = await client.post<JsonApiDocument>(url, payload);
		return response;
	};

	const patchMediaItem = async (
		url: string,
		props: {
			sort: number;
			text?: string;
			url?: string;
		},
	) => {
		const response = await client.patch<JsonApiDocument>(url, props);
		return response;
	};

	const patchReview = async (
		url: string,
		props: {
			text?: string;
			url?: string;
		},
	) => {
		const response = await client.patch<JsonApiDocument>(url, props);
		return response;
	};

	const disapproveReview = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const uploadStatisticsScreenshot = async (url: string, file: File) => {
		const formData = new FormData();
		formData.set('file', file);

		const response = await client.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
		return response;
	};

	const deleteMedia = async (url: string) => {
		const response = await client.delete<JsonApiDocument>(url);
		return response;
	};

	const postEnableToClient = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const postApproveAsClient = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const getAffiliateLink = async (url: string) => {
		const response = await client.get<JsonApiDocument>(url);
		return response.data;
	};

	const postAffiliateLink = async (url: string, value: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			url: value,
		});
		return response.data;
	};

	const getStories = async (url: string, from?: string, to?: string) => {
		const response = await client.get<JsonApiDocument>(url, {
			params: {
				includes: 'files,campaignInstagramOwnerAssignment,frames',
				from: from,
				to: to,
			},
		});
		return response;
	};

	const patchStory = async (url: string, data: { campaignInstagramOwnerAssignment: string | null }) => {
		const response = await client.patch<JsonApiDocument>(url, data);
		return response;
	};

	const checkIfInstagramUrlIsValid = async (postUrl: string, url: string) => {
		const response = await client.post<JsonApiDocument>(postUrl, { url: url });
		return response.data;
	};

	return {
		getCampaigns,
		getCampaign,
		createComment,
		// getAssignmentReviews,
		postReview,
		patchEditStatistics,
		patchStorySelectorStatistics,
		patchReview,
		patchMediaItem,
		postStatistics,
		disapproveReview,
		uploadStatisticsScreenshot,
		deleteMedia,
		postEnableToClient,
		postApproveAsClient,
		getAffiliateLink,
		postAffiliateLink,
		getStories,
		patchStory,
		// getCIOAS,
		checkIfInstagramUrlIsValid,
	};
}

export function useCampaignInstagramOwnerAssignments() {
	const client = createClient();
	const [progress, setProgress] = useState<{ [key: string]: number }>({});

	const fetcher = async (url: string) => {
		const { data } = await client.get<JsonApiDocument>(url, {});
		return data;
	};

	const useGetIOAS = (url?: string) => {
		const models = new Store();
		const INCLUDES = `
			influencer.user,
			reviews:hateoas(approve|edit|reject|requestChange|requestReview|createComment|comments|internalComments|createInternalComment|createManagerInternalComment|listManagerInternalComments|clientApprove|enableForClient|createPresignUrl),
			reviews.medias:hateoas(editUrl|createDownloadUrl|delete|createPresignUrl),
			reviews.pendingUploads,
			instagramPosts.screenshots,
			instagramStories.screenshots,
			instagramStories.frames,
			assignment,
			assignmentGroup
		`;
		const requestUrl = `${url}?includes=${INCLUDES}`;

		const { data, error } = useSWR(requestUrl, fetcher);

		if (data && !error) {
			models.sync(data);
		}

		return {
			models,
			mutation: {
				async add(resource: JsonApiResource) {
					await mutate({
						...data,
						data: [...(data!.data as JsonApiResource[]), resource],
					});
				},
				async addIncluded(resource: JsonApiResource) {
					await mutate({
						...data,
						included: [...(data!.included as JsonApiResource[]), resource],
					});
				},
				async remove({ id }: JsonApiIdentifier) {
					await mutate({
						...data,
						data: filter(data!.data as JsonApiResource[], (resource) => resource.id !== id),
					});
				},
				async refresh() {
					return await mutate(requestUrl);
				},
			},
			error,
			loading: url ? !data && !error : false,
		};
	};

	const postReview = async (url: string, values?: { text: string; url?: string | null }) => {
		const response = await client.post<JsonApiDocument>(url, values);
		return response;
	};

	const patchReview = async (url: string, values: { text: string; url?: string | null }) => {
		const response = await client.patch<JsonApiDocument>(url, values);
		return response;
	};

	const postPresignUrl = async (
		url: string,
		data: {
			filename: string;
			sort: number;
			text?: string;
			productUrl: string | null;
		},
	) => {
		const response = await client.post(url, data);
		return response.data;
	};

	const putMediaInBucket = async (url: string, file: File) => {
		setProgress({});

		const response = await client
			.put(url, file, {
				headers: { 'Content-Type': file.type, 'Cache-Control': 'max-age=31449600,immutable' },
				onUploadProgress: (event) => {
					const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 100;

					setProgress((prevProgress) => ({
						...prevProgress,
						[file.name]: percentUploaded,
					}));
				},
			})
			.then((response: AxiosResponse<ICollabsResponse>) => {
				return response;
			})
			.catch((err: Error | AxiosError) => {
				console.error(err);
				setProgress({});
			});

		return response;
	};

	const patchMedia = async (data: { sort: number; url?: string | null; text?: string }, url?: string | null) => {
		if (!url) {
			return null;
		}
		const response = await client.patch<JsonApiDocument>(url, data);
		return response;
	};

	const setAsUploadedMediaItem = (url: string) => {
		const response = client.post(url);
		return response;
	};

	const deleteMedia = async (url: string) => {
		const response = await client.delete<JsonApiDocument>(url);
		return response;
	};

	const postApproveAsClient = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const postStatus = async (url: string) => {
		const response = await client.post<JsonApiDocument>(url);
		return response;
	};

	const postComment = async (url: string, message: string) => {
		const response = await client.post<JsonApiDocument>(url, {
			text: message,
		});
		return response;
	};

	const getStories = async () => {
		const response = await client.get<JsonApiDocument>('instagram-stories?includes=files');
		return response;
	};

	const patchStory = async (url: string) => {
		const response = await client.patch<JsonApiDocument>(url);
		return response;
	};

	const getComments = async (url: string) => {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(read),user:hateoas(false)');

		return await client.get<JsonApiDocument>(url ? `${url}?${params}` : '');
	};

	const postReviewRequest = async (url: string) => {
		return await client.post(url);
	};

	const editTrackinglink = async (url: string, values: { url?: string | null }): Promise<AssignmentReviewResponse> => {
		const { data } = await client.patch(url, values);
		return data;
	};

	return {
		useGetIOAS,
		postReview,
		patchReview,
		postPresignUrl,
		putMediaInBucket,
		setAsUploadedMediaItem,
		patchMedia,
		deleteMedia,
		postStatus,
		postApproveAsClient,
		postComment,
		progress,
		getStories,
		patchStory,
		getComments,
		postReviewRequest,
		editTrackinglink,
	};
}
