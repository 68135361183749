import axios from 'axios';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import Icon from 'components/Icon';
import Styled from 'components/Settings/Settings.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import { PasswordFormValues } from './types';

/**
 * PasswordForm
 * @returns {JSX.Element}
 */
const PasswordForm = (): JSX.Element => {
	const client = createClient();
	const [displayPassword, setDisplayPassword] = useState(false);

	const defaultValues: PasswordFormValues = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
	};
	const userId = useAppSelector((state) => state.user?.id);

	const validateSchema = Yup.object().shape({
		oldPassword: Yup.string().required('This field is required'),
		newPassword: Yup.string().min(8, 'Your password is too short').required('This field is required'),
		confirmPassword: Yup.string()
			.required('This field is required')
			.when('newPassword', {
				is: (val: string) => val && val.length > 0,
				then: () => Yup.string().oneOf([Yup.ref('newPassword')], 'Both password need to be the same'),
			}),
	});

	return (
		<Styled.Content>
			<h3>Change password</h3>
			<p>You can change your current password here.</p>
			<Formik
				initialValues={defaultValues}
				onSubmit={async ({ oldPassword, newPassword }, { resetForm, setFieldError }) => {
					try {
						await client.patch(`/users/${userId}`, {
							newPassword,
							currentPassword: oldPassword,
						});
						toast.success('Your password has been updated successfully');
						resetForm();
					} catch (e) {
						console.error(e);
						if (axios.isAxiosError(e)) {
							e?.response?.data?.errors?.forEach((error: { source: { parameter: string }; detail: string }) => {
								if (error.source.parameter === 'currentPassword') {
									setFieldError('oldPassword', error.detail);
								}
							});
						} else {
							toast.error(getErrorMessageOnPost('updating your password'));
						}
					}
				}}
				validationSchema={validateSchema}
				validateOnBlur={false}
				validateOnChange={false}
			>
				{({ isSubmitting, dirty }) => (
					<Form>
						<InputText
							action={
								<Styled.IconWrapper onClick={() => setDisplayPassword(!displayPassword)}>
									<Icon name={!displayPassword ? 'hide' : 'unhide'} />
								</Styled.IconWrapper>
							}
							label='Old password'
							autoComplete='current-password'
							id='old-password'
							name='oldPassword'
							placeholder='Type your old password'
							type={displayPassword ? 'text' : 'password'}
							required
						/>
						<InputText
							action={
								<Styled.IconWrapper onClick={() => setDisplayPassword(!displayPassword)}>
									<Icon name={!displayPassword ? 'hide' : 'unhide'} />
								</Styled.IconWrapper>
							}
							label='New password'
							autoComplete='new-password'
							type={displayPassword ? 'text' : 'password'}
							id='new-password'
							name='newPassword'
							placeholder='Type your new password'
							required
						/>
						<InputText
							action={
								<Styled.IconWrapper onClick={() => setDisplayPassword(!displayPassword)}>
									<Icon name={!displayPassword ? 'hide' : 'unhide'} />
								</Styled.IconWrapper>
							}
							label='Confirm password'
							autoComplete='new-password'
							type={displayPassword ? 'text' : 'password'}
							id='confirm-password'
							name='confirmPassword'
							placeholder='Confirm your new password'
							required
						/>
						<Styled.ButtonGroup>
							<PrimaryButton isLoading={isSubmitting} type='submit' disabled={isSubmitting || !dirty}>
								Update
							</PrimaryButton>
						</Styled.ButtonGroup>
					</Form>
				)}
			</Formik>
		</Styled.Content>
	);
};

export default PasswordForm;
