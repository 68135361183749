import styled from 'styled-components';

const BackDiv = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 12px;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
`;

const Title = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Styled = {
	BackDiv,
	Title,
};

export default Styled;
