import { Store, Model } from 'json-api-models';
import moment from 'moment';
import { useState, useEffect } from 'react';

import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import { InstagramUserStory } from 'components/ContentManagement/types';

const useInstagramStories = ({
	FETCH_STORIES_URL,
	selectedDate,
	INFLUENCER_PROFILE_CONNECTED,
	CIOAssignment,
	statsEntity,
	formValues,
	setFormValues,
	getStories,
}: {
	FETCH_STORIES_URL: string;
	selectedDate?: [Date, Date];
	INFLUENCER_PROFILE_CONNECTED: boolean;
	CIOAssignment: { id: string; name: string };
	statsEntity?: Model;
	formValues: StatisticsFormValues;
	setFormValues: (formValues: StatisticsFormValues) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getStories: (url: string, from: string, to: string) => Promise<any>;
}) => {
	const [isUserStoriesLoading, setIsUserStoriesLoading] = useState(false);
	const [noResult, setNoResult] = useState(false);
	const [userStoryFrames, setUserStoryFrames] = useState<Array<InstagramUserStory>>([]);
	const [framesConnectedToOtherAssignments, setFramesConnectedToOtherAssignments] = useState<Array<InstagramUserStory>>([]);
	const [selectedStoryItems, setSelectedStoryItems] = useState<Array<InstagramUserStory>>([]);
	const [fetchedScreenShots, setFetchedScreenShots] = useState<Array<InstagramUserStory>>([]);

	const getInstagramStories = async () => {
		if (FETCH_STORIES_URL) {
			setIsUserStoriesLoading(true);
			setNoResult(false);

			const from = selectedDate && moment(selectedDate[0]).format('YYYY-MM-DD');
			const to = selectedDate && moment(selectedDate[1]).format('YYYY-MM-DD');

			if (from && to) {
				try {
					const response = await getStories(FETCH_STORIES_URL, from, to);
					const store = new Store();
					const fetchedStories = store.sync(response.data);

					const storiesManagedByApi = fetchedStories.filter((story: Model) => story.managedByApi);

					if (storiesManagedByApi.length === 0 && INFLUENCER_PROFILE_CONNECTED) {
						setNoResult(true);
					}

					const storiesConnectedToOtherAssignments = storiesManagedByApi.filter(
						(story: Model) => story.campaignInstagramOwnerAssignment && story.campaignInstagramOwnerAssignment?.id !== CIOAssignment?.id,
					);

					const disableFrames = storiesConnectedToOtherAssignments.flatMap((story: Model) => story.frames || []);
					setFramesConnectedToOtherAssignments(disableFrames);

					const frames = store.findAll('instagramStoryFrame') as unknown as Array<InstagramUserStory>;
					setUserStoryFrames(frames);

					const ThisStory = fetchedStories.filter((story: Model) => story.campaignInstagramOwnerAssignment?.id === CIOAssignment?.id);
					setFetchedScreenShots(ThisStory[0]?.files);

					if (statsEntity) {
						setSelectedStoryItems(statsEntity.frames);
						setFormValues({ ...formValues, frames: statsEntity.frames });
					}
				} catch (error) {
					console.error('Error fetching Instagram stories:', error);
				} finally {
					setIsUserStoriesLoading(false);
				}
			}
		} else {
			setNoResult(true);
		}
	};

	useEffect(() => {
		setSelectedStoryItems([]);
		if (selectedDate && INFLUENCER_PROFILE_CONNECTED) {
			getInstagramStories();
		}
	}, [selectedDate]);

	return {
		userStoryFrames,
		isUserStoriesLoading,
		noResult,
		framesConnectedToOtherAssignments,
		selectedStoryItems,
		setSelectedStoryItems,
		fetchedScreenShots,
		setFetchedScreenShots,
	};
};

export default useInstagramStories;
