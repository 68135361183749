import axios from 'axios';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useEffect, useState } from 'react';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { MessageItem } from 'components/IntegratedInbox/Components/ChatMessages/MessageItem';
import { customFormatDate } from 'components/IntegratedInbox/Components/ChatMessages/types';
import { FileItem } from 'components/IntegratedInbox/types';
import LoadingSpinner from 'components/LoadingSpinner';
import Pill from 'components/Pill';
import ProgressBar from 'components/Progressbar';
import Tooltip from 'components/Tooltip';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';

import Styled from './ChatBubble.style';

/**
 */
const ChatBubble = (props: {
	lastMessageSeen?: MessageItem;
	messageFromCurrentUser?: boolean;
	className: string;
	message: MessageItem;
	isLastMessage?: boolean;
	fileUploadProgress: { [key: string]: number };
	markAsRead?: (message: MessageItem) => void;
}) => {
	const [isDownloading, setIsDownloading] = useState<string>('');
	const client = createClient();

	const onClickDownload = async (file: FileItem) => {
		setIsDownloading(file.uuid || '');

		try {
			const { data } = await client.post(file.links.createDownload);
			const response = await axios.create().get(data.data.attributes.url, { responseType: 'blob' });
			const href = URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', file.originalName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (e) {
			console.error(e);
			toast.error(`Unable to download "${file.originalName}"`);
		}

		setIsDownloading('');
	};

	const isImage = (extension: string) => {
		return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'heic'].includes(extension);
	};

	const isDocument = (extension: string) => {
		return ['pdf', 'xls', 'docx'].includes(extension);
	};

	const getFileIconName = (extension: string) => {
		switch (true) {
			case isImage(extension):
				return 'image';
			case isDocument(extension):
				return 'pdf-file';
			default:
				return 'other-file';
		}
	};

	const getFileLabel = (extension: string) => {
		switch (true) {
			case isImage(extension):
				return 'Image';
			case isDocument(extension):
				return 'Doc';
			default:
				return 'File';
		}
	};

	useEffect(() => {
		if (props.message.isUnread && props.markAsRead) {
			props.markAsRead(props.message);
		}
	}, []);

	return (
		<Styled.Wrapper className={props.className} data-testid='inbox-chat-bubble'>
			<Styled.AvatarWrapper className={classNames({ 'no-image': !props.message.profileImage })}>
				<Avatar size='sm' imageUrl={props.message.profileImage} name={props.message.name ? props.message.name.split(' ')[0] : ''} />
			</Styled.AvatarWrapper>
			<Styled.ChatBoxOuter>
				{props.message?.messageHtml && (
					<Styled.ChatBoxWrapper>
						{props.message.subject && <Pill title={props.message.subject} />}
						<Styled.ChatBox className={props.className}>
							<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.message.messageHtml) }}></p>
							<Styled.SendTime>{customFormatDate(props.message.createdAt, 'HH:mm')}</Styled.SendTime>
						</Styled.ChatBox>
						{props.message.id === props.lastMessageSeen?.id && props.messageFromCurrentUser && (
							<Tooltip
								position={props.messageFromCurrentUser ? 'left' : 'right'}
								content={`Seen at ${moment(props.message.messageModel.anyoneReadAt).format('YYYY-MM-DD, HH:mm')}`}
							>
								<Styled.ReceiverHasSeenMessage>From you seen</Styled.ReceiverHasSeenMessage>
							</Tooltip>
						)}
						{props.messageFromCurrentUser || props.message.files?.length > 0 ? null : (
							<Styled.MessageFrom>
								{props.message.senderInfo?.type === 'user' ? (
									<>
										{props.message.senderInfo?.firstName}, {props.message.publisherName}{' '}
									</>
								) : (
									props.message.name
								)}{' '}
							</Styled.MessageFrom>
						)}
					</Styled.ChatBoxWrapper>
				)}
				{props.message.files?.length > 0 &&
					props.message.files.map((file) => {
						return (
							<Styled.ChatBoxWrapper
								key={file.id}
								className={classNames(props.className, 'chat-box--attachment')}
								onClick={() => onClickDownload(file)}
								title={file.originalName}
							>
								<Styled.ChatBox className={props.className}>
									{file.uuid && props.fileUploadProgress[file.uuid] && props.fileUploadProgress[file.uuid] !== 100 ? (
										<ProgressBar percentage={props.fileUploadProgress[file.uuid] ?? 0} text={`Uploading ${file.originalName}...`} />
									) : (
										<>
											{file.isLoading ? (
												<LoadingSpinner size='sm' position='center' />
											) : (
												<>
													<Icon name={getFileIconName(file.extension)} />
													<Styled.FileTypeLabel key={file.id}>{getFileLabel(file.extension)} &#x2022;</Styled.FileTypeLabel>
													<Styled.FileName>
														<span>{file.originalName}</span>
													</Styled.FileName>
													<Styled.DownloadContainer>
														{isDownloading === file.uuid ? (
															<LoadingSpinner size='sm' />
														) : (
															<Styled.DownloadButton aria-label='Download file'>
																<Icon name='download' />
															</Styled.DownloadButton>
														)}
														<Styled.DownloadTimestamp>{customFormatDate(file.createdAt, 'HH:mm')}</Styled.DownloadTimestamp>
													</Styled.DownloadContainer>
												</>
											)}
										</>
									)}
								</Styled.ChatBox>
								{props.message.id === props.lastMessageSeen?.id && props.messageFromCurrentUser && (
									<Tooltip
										position={props.messageFromCurrentUser ? 'left' : 'right'}
										content={`Seen at ${moment(props.message.messageModel.anyoneReadAt).format('YYYY-MM-DD, HH:mm')}`}
									>
										<Styled.ReceiverHasSeenMessage>From you seen</Styled.ReceiverHasSeenMessage>
									</Tooltip>
								)}
							</Styled.ChatBoxWrapper>
						);
					})}
				{props.message.files?.length > 0 && (
					<>
						{props.messageFromCurrentUser ? null : (
							<Styled.MessageFrom>
								{props.message.senderInfo?.type === 'user' ? (
									<>
										{props.message.senderInfo?.firstName}, {props.message.publisherName}{' '}
									</>
								) : (
									props.message.name
								)}{' '}
							</Styled.MessageFrom>
						)}
					</>
				)}
			</Styled.ChatBoxOuter>
		</Styled.Wrapper>
	);
};

export default ChatBubble;
