import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Styled from 'components/DataLibrary/ContentHeader/ContentHeader.style';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import toast from 'services/Toast';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

/**
 * @returns {JSX.Element}
 */
const ShareDropDown = (props: { toggleShareModal: () => void; id: string }): JSX.Element => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [displayTooltip, setDisplayTooltip] = useState(false);
	const [isOkToShare, setIsOkToShare] = useState(false);

	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const canShareDashboard = dashboard?.links?.createDashboardUser;

	const share = () => {
		if (isOkToShare) {
			setIsLoading(true);
			dashboardFilter.campaigns &&
				manager
					.createUser(dashboard.id, { campaigns: dashboardFilter.campaigns })
					.then(() => {
						navigate(`/data-library/${props.id}/preview`);
					})
					.catch((error: AxiosError) => {
						console.error(error);
						toast.error(getErrorMessageOnPost('creating a share link'));
					});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (dashboardFilter.campaigns && dashboardFilter.campaigns?.length > 0) {
			setIsOkToShare(true);
		} else {
			setIsOkToShare(false);
		}
	}, [dashboardFilter.campaigns]);

	const renderTooltip = () => {
		return (
			<Styled.Tooltip className={displayTooltip ? 'visible' : ''}>
				<Styled.TooltipText>Select a campaign to share with client</Styled.TooltipText>
			</Styled.Tooltip>
		);
	};

	return (
		<Styled.CustomDropdownMenu>
			<Styled.CustomDropdownItem onClick={() => props.toggleShareModal()} data-testid='share-dashboard'>
				<Icon name='share' size='16' /> Share dashboard
			</Styled.CustomDropdownItem>
			{dashboard.dashboardUsers && dashboard.dashboardUsers?.length > 0 && (
				<Styled.CustomDropdownItem onClick={() => navigate(`/data-library/${props.id}/preview`)}>
					<Icon name='template' size='16' /> View shared dashboard
				</Styled.CustomDropdownItem>
			)}
			{canShareDashboard && (
				<Styled.CustomDropdownItem
					onClick={share}
					className={classNames({ disabled: !isOkToShare })}
					onMouseEnter={() => setDisplayTooltip(true)}
					onMouseLeave={() => setDisplayTooltip(false)}
				>
					<Icon name='template' size='16' />
					<div>Share with Client</div> {isLoading && <LoadingSpinner size='sm' />}
					{displayTooltip && !isOkToShare && renderTooltip()}
				</Styled.CustomDropdownItem>
			)}
		</Styled.CustomDropdownMenu>
	);
};
export default ShareDropDown;
