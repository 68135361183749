import { useParams } from 'react-router-dom';

import ConversationItem from 'components/IntegratedInbox/influencer/ConversationList/ConversationItem';
import { ConversationOverview } from 'components/IntegratedInbox/types';
import SkeletonLoader from 'views/Campaign/Dashboard/ContentManagement/components/SkeletonLoaders/SkeletonLoaders';

import Styled from './ConversationList.style';
import { Props } from './types';

const ConversationList = ({ isFetching, conversationOverview, selectConversation }: Props) => {
	const params = useParams();

	return (
		<Styled.Wrapper data-testid='inbox-influencer-conversation-list'>
			{isFetching ? (
				<div style={{ padding: '0.5rem' }}>
					<SkeletonLoader variant='campaign-list' dataId='loading-conversations' />
				</div>
			) : (
				<>
					<Styled.ListWrapper>
						{conversationOverview
							?.sort((a, b) => new Date(b.attributes.latestMessageCreatedAt).getTime() - new Date(a.attributes.latestMessageCreatedAt).getTime())
							.map((conversation: ConversationOverview) => {
								const isSelected = params.conversationId === conversation.id;
								const hasUnreadMessages = conversation.attributes.unreadMessages > 0;

								return (
									<ConversationItem
										key={conversation.id}
										title={conversation.attributes?.campaignName || conversation.attributes.latestMessageSubject || conversation.attributes.publisherName}
										coverImage={conversation.links.smallCoverPhoto || conversation.attributes?.profilePictureUrl || ''}
										onClick={(e: React.MouseEvent<HTMLElement>) => {
											e.stopPropagation();
											selectConversation(conversation.id, conversation.attributes.campaignId);
										}}
										isSelected={isSelected}
										hasUnreadMessages={hasUnreadMessages}
										latestMessageObj={{
											title: conversation.attributes.latestMessageContent,
											message: conversation.attributes.latestMessageContent,
											createdAt: conversation.attributes.latestMessageCreatedAt,
										}}
									/>
								);
							})}
					</Styled.ListWrapper>
				</>
			)}
		</Styled.Wrapper>
	);
};

export default ConversationList;
