import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line import/no-unassigned-import
import 'react-loading-skeleton/dist/skeleton.css';

import Styled from 'views/Campaign/Dashboard/ContentManagement/ContentManagementSidebar.style';

const SkeletonLoader = ({ variant, dataId }: { variant: 'content' | 'influencer' | 'manager' | 'campaign-list' | 'inbox'; dataId?: string }) => (
	<Styled.SkeletonLoaderList data-testid={dataId}>
		{variant === 'inbox' && (
			<>
				<Skeleton width='100%' borderRadius={'10px'} height='300px' />
				<Skeleton width='100%' borderRadius={'10px'} height='60px' />
				<Skeleton width='100%' borderRadius={'10px'} height='300px' />
				<Skeleton width='100%' borderRadius={'10px'} height='60px' />
			</>
		)}
		{variant === 'campaign-list' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='60px' />
			</>
		)}
		{variant === 'content' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='100%' height='300px' borderRadius='10px' />
			</>
		)}
		{variant === 'influencer' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='50%' height='40px' />
				<Skeleton width='60%' height='20px' />
				<Skeleton width='100%' height='50px' />
				<Skeleton width='100%' height='50px' />
			</>
		)}
		{variant === 'manager' && (
			<>
				<Skeleton width='100%' height='60px' />
				<Skeleton width='60%' height='20px' />
				{Array.from({ length: 5 }).map((_, index) => (
					<Skeleton key={index} width='100%' height='50px' />
				))}
			</>
		)}
	</Styled.SkeletonLoaderList>
);

export default SkeletonLoader;
