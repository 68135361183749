import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAppDispatch } from 'hooks/useUserAppSelector';
import { updateUser } from 'shared/User/User.helpers';

import Styled from './InfluencerLayout.style';
import { ILoggedInLayoutProps } from './types';
/**
 * LoggedInLayout (Influencer view)
 * @param {ILoggedInLayoutProps} props
 * @returns {JSX.Element}
 */
const LoggedInLayout = (props: ILoggedInLayoutProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch({ type: 'CONNECT_TO_MERCURE' });
	}, []);

	useEffect(() => {
		setIsLoading(true);
		// Poll /me to ensure that the user always has the latest user object
		updateUser().then(() => {
			setIsLoading(false);
		});

		const meInterval = setInterval(async () => {
			await updateUser();
		}, 30 * 60000);

		return () => clearInterval(meInterval);
	}, []);

	return (
		<div className='layout main-content'>
			{!isLoading && (
				<>
					<Header
						menuItems={[
							{ url: '/influencer/dashboard', title: 'Home', icon: 'home' },
							{ url: '/influencer/payment', title: 'Payment', icon: 'money' },
							{ url: '/influencer/inbox', title: 'Messages', icon: 'message' },
						]}
						fixed={true}
						isInfluencer={true}
					/>
					<Styled.Wrapper className={classNames({ 'no-padding-bottom': props.isNoPaddingBottom })}>
						<Outlet />
					</Styled.Wrapper>
				</>
			)}
			{isLoading && <LoadingSpinner position='center' />}
		</div>
	);
};

export default LoggedInLayout;
