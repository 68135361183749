import classNames from 'classnames';
import { Model } from 'json-api-models';
import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';

import Avatar from 'components/Avatar';
import Pill from 'components/Pill';

import { DirectConversationData } from './DirectConversationData';
import Styled from './DirectConversationItem.style';

type Props = {
	coverImage?: string;
	title: string;
	onClick: (e: React.MouseEvent) => void;
	isSelected: boolean;
	conversation: Model | DirectConversationData | undefined;
	hasUnreadMessages: boolean;
};

const DirectConversationItem = ({ coverImage, title, onClick, isSelected, conversation, hasUnreadMessages }: Props) => {
	const latestMessage = conversation ? new LatestMessage(conversation.latestMessage) : null;

	return (
		<Styled.ListItem onClick={onClick} data-testid='inbox-direct-conversation-item'>
			<Styled.Wrapper className={classNames({ selected: isSelected })}>
				<Styled.ImageWrapper>
					<Avatar imageUrl={coverImage} name={title} />
					{hasUnreadMessages ? <Styled.UnreadMark data-testid='inbox-dm-unread-mark' /> : null}
				</Styled.ImageWrapper>
				{latestMessage ? (
					<Styled.LatestMessageWrapper>
						<Styled.LatestMessageHeader>
							<Styled.CampaignName className='has-messages'>
								<span>{title}</span>
								<Pill title='private' className='private' />
							</Styled.CampaignName>
							<div className='date'>{latestMessage.createdAt}</div>
						</Styled.LatestMessageHeader>
						<Styled.LatestMessageContent>
							<span>{latestMessage.message}</span>
						</Styled.LatestMessageContent>
					</Styled.LatestMessageWrapper>
				) : (
					<Styled.CampaignName>
						<span>{title}</span>
					</Styled.CampaignName>
				)}
			</Styled.Wrapper>
		</Styled.ListItem>
	);
};

export class LatestMessage {
	sender: string;
	message: string;
	createdAt: string;
	isRead: boolean;

	constructor(latestMessage: Model) {
		this.sender = this.getSenderName(latestMessage);
		this.message = latestMessage.message;
		this.createdAt = moment(latestMessage.createdAt).format('YY-MM-DD');
		this.isRead = !!latestMessage.readAt;
	}

	getSenderName(latestMessage: Model) {
		return !isNil(latestMessage.user) ? latestMessage.user.initials.split('')[0] : latestMessage.influencer.username;
	}
}

export default DirectConversationItem;
