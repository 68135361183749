import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { breakpoints, devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	padding: 0 2rem 1rem;
	hr {
		margin: 40px 0;
		border-top: ${colors.lightGray};
	}
	@media (max-width: ${breakpoints.sm}) {
		padding-right: 1rem;
		padding-left: 1rem;
	}
`;

const BannerContainer = styled.div`
	margin-bottom: 40px;

	@media screen and (${devices.sm}) {
		margin-bottom: 80px;
	}
`;

const Styled = {
	Wrapper,
	BannerContainer,
};

export default Styled;
