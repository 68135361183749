import classNames from 'classnames';
import { isNil } from 'lodash';
import { useState } from 'react';

import Icon from 'components/Icon';
import { IconSize } from 'components/Icon/types';
import Tooltip from 'components/Tooltip';
import toast from 'services/Toast';

import Styled from './Copy.style';

export type CopyProps = {
	id?: string;
	description: string;
	value: string;
	iconSize?: IconSize;
	className?: string;
	action?: () => void;
};

/**
 * @returns {JSX.Element}
 */
const Copy = ({ id, description, value, iconSize, className, action }: CopyProps): JSX.Element => {
	const [copied, setCopied] = useState(false);
	const altText = `Copy ${description} to clipboard`;

	return (
		<Styled.CopyWrapper>
			<Tooltip content={altText} id={id} delayShow={500}>
				<Styled.CopyCircle
					className={classNames(className, { copied: copied })}
					onClick={() => {
						try {
							navigator.clipboard.writeText(value);
							setCopied(true);
							action && action();
							toast.success('Successfully copied to clipboard');
							setTimeout(() => setCopied(false), 500);
						} catch (e) {
							console.error('Unable to access clipboard: %O', e);
						}
					}}
				>
					<Icon name='copy' size={isNil(iconSize) ? '20' : iconSize} />
				</Styled.CopyCircle>
			</Tooltip>
		</Styled.CopyWrapper>
	);
};

export default Copy;
