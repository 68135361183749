import { useState, useRef, useEffect, KeyboardEvent } from 'react';

import Icon from 'components/Icon';
import { UserType } from 'reducers/UserReducers/types';
import { isMobile } from 'shared/utils/navigator';
import colors from 'styles/theme/colors';

import Styled from './CommentInput.style';

interface CommentInputProps {
	user: UserType;
	onSend: (message: string) => void;
	isSending: boolean;
}

const CommentInput = ({ user, onSend, isSending }: CommentInputProps) => {
	const [value, setValue] = useState<string>('');
	const [hidePlaceholder, setHidePlaceholder] = useState(false);
	const [displaySendButton, setDisplaySendButton] = useState<boolean>(false);
	const messageFieldRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setDisplaySendButton(/\S/.test(value));
	}, [value]);

	const resetInput = () => {
		setValue('');
		if (messageFieldRef.current) {
			messageFieldRef.current.textContent = '';
		}
		setHidePlaceholder(false);
		setDisplaySendButton(false);
	};

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!/\S/.test(value) && e.key === 'Enter') {
			e.preventDefault();
			return false;
		}

		if (!isMobile() && e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			onSend(value);
			resetInput();
			return false;
		}

		if (e.key.length === 1) {
			setHidePlaceholder(true);
		}

		return true;
	};

	const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
		if (!/\S/.test(e.currentTarget.textContent || '')) {
			setHidePlaceholder(false);
		}
	};

	return (
		<Styled.ThreadTool>
			<Styled.AvatarContainer>
				<Styled.CommentAvatar name={user.name!} backgroundColor={colors.paleGreenTint} />
			</Styled.AvatarContainer>
			<Styled.MessageFieldContainer id='comment-input'>
				<Styled.MessageField
					aria-label='message'
					contentEditable='true'
					role='textbox'
					data-testid='input-comment'
					spellCheck='true'
					onInput={(e) => setValue(e.currentTarget.textContent || '')}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					ref={messageFieldRef}
					onBlur={(e) => !e.currentTarget.textContent && setHidePlaceholder(false)}
				/>
				{!hidePlaceholder && <Styled.MessageFieldPlaceholder onClick={() => messageFieldRef.current?.focus()}>Leave a comment</Styled.MessageFieldPlaceholder>}
				<Styled.SendMessage>
					<Styled.SendButton
						onClick={() => {
							onSend(value);
							resetInput();
						}}
						disabled={isSending || !displaySendButton}
						data-testid='submit-comment'
					>
						<Icon name='send' size='20' />
					</Styled.SendButton>
				</Styled.SendMessage>
			</Styled.MessageFieldContainer>
		</Styled.ThreadTool>
	);
};

export default CommentInput;
