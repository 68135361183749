import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PrimaryButton } from 'components/Button/Button';
import { CTALink as ctaLink } from 'styles/utils/CTALink';

const EmptyState = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 604px;
`;

const ImageContainer = styled.div`
	margin-bottom: 24px;
`;

const TextContainer = styled.div`
	text-align: center;
	p {
		margin: 0;
	}

	p + button,
	p + a {
		margin-top: 16px;
	}
`;

const Headline = styled.h3`
	font-size: 1rem;
`;

const CTAButton = styled(PrimaryButton)`
	margin: 0 auto;
	width: 100%;
	height: 36px;
	max-width: 330px;
	font-size: 0.9375rem;
`;

const CTALink = styled(Link)`
	text-align: center;
	margin: 0;
	${ctaLink};

	&:after {
		opacity: 1;
	}
`;

const Styled = {
	EmptyState,
	ImageContainer,
	TextContainer,
	Headline,
	CTAButton,
	CTALink,
};

export default Styled;
