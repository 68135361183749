import { spacing as space } from 'styles/variables/spacings';

import colors from './colors';
import { Borders, Breakpoints, FontSizes, FontWeights, MediaQueries, Radiuses, Shadows, ZIndexes } from './types';

export const thankYou = `
It's been fun, take care of the internet cats out there.
 /\_/\
( o.o )
 > ^ <
 // KB :)
 `;

export const breakpointMap: { [key: string]: number } = {
	xs: 320,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`);

const mediaQueries: MediaQueries = {
	xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
	sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
	md: `@media screen and (min-width: ${breakpointMap.md}px)`,
	lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
	xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
	xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
};

export const shadows: Shadows = {
	default: '0 10px 20px rgba(92, 104, 128, 0.16)',
	small: '0 2px 4px rgba(51, 51, 51, 0.1)',
};

const radius: Radiuses = {
	small: '4px',
	default: '10px',
};

const zIndexes: ZIndexes = {
	dropdown: 10,
	modal: 100,
	header: 2090,
	sidedrawer: 2500,
	overlay: 3000,
};

const fontWeights: FontWeights = {
	thin: 100,
	normal: 400,
	medium: 500,
	semi: 600,
	bold: 700,
};

const fontSizes: FontSizes = {
	base: '16px',
	small: '0.75rem',
	text: '1rem',
	input: '1rem',
	smallInput: '0.875rem',
	largeInput: '1.125rem',
	headings: {
		h1: '2.4875rem',
		h2: '2.075rem',
		h3: '1.4375rem',
		h4: '1.4375rem',
		h5: '1.4375rem',
		h6: '1rem',
	},
	legacy: {
		headings: {
			h1: '2.0737rem',
			h2: '1.4375rem',
			h3: '1.375rem',
			h4: '1.25rem',
			h5: '1.2rem',
			h6: '1rem',
		},
	},
};

export const borders: Borders = {
	primary: colors.dust,
	secondary: colors.silver,
	success: colors.emerald,
	warning: colors.spice,
	error: colors.ruby,
	hover: colors.black,
	focus: colors.skyBlue,
};

const spacing = space;

export default {
	siteWidth: 1920,
	breakpoints,
	mediaQueries,
	shadows,
	radius,
	zIndexes,
	fontWeights,
	fontSizes,
	borders,
	spacing,
};
