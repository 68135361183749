import Icon from 'components/Icon';
import Styled from 'components/NewBriefPage/Components/JoinModal/Assignment/Assignment.style';
import { CampaignInstagramOwner } from 'components/NewBriefPage/types';

type CommissionProps = {
	campaignInstagramOwner: CampaignInstagramOwner;
};
/**
 * @returns JSX.Element
 */
const Commission = ({ campaignInstagramOwner }: CommissionProps): JSX.Element => {
	const compensation = campaignInstagramOwner.campaign.compensationsTotalValue;
	const products = campaignInstagramOwner.campaign.products;

	return (
		<Styled.Container>
			{compensation > 0 ||
				(products?.length > 0 && (
					<>
						<Styled.Title>Commission</Styled.Title>
						<Styled.List>
							{compensation > 0 ||
								(products?.length > 0 &&
									products.map((product, i) => {
										return (
											<li key={i}>
												<Styled.Task>
													<Icon name='product' />
													<span>
														{product.quantity} {product.product?.name}
													</span>
												</Styled.Task>
											</li>
										);
									}))}
						</Styled.List>
					</>
				))}
		</Styled.Container>
	);
};

export default Commission;
