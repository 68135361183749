/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { AdminUserPayoutCollectionResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import AdminApiClientInterface from './AdminApiClientInterface';

@injectable()
export default class AdminApiClient implements AdminApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * List UserPayouts.
	 *
	 * Get a nice list of a specific user's payouts
	 */
	async listUserPayouts(id: string, params: { include?: string }): Promise<AdminUserPayoutCollectionResponse> {
		const response = await this.client.get<AdminUserPayoutCollectionResponse>(`/admin/users/${id}/payments`, { params });
		return response.data;
	}
}
