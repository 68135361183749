import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';

const ListItem = styled.div`
	margin: 8px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${guttersWithRem.m};

	padding-left: 0.5rem;

	flex: 1;
	min-height: 80px;
	border-radius: ${borderRadius.s};
	cursor: pointer;
	&:hover {
		background-color: ${newColors.ash};
	}

	&.selected {
		background-color: ${newColors.ash};
	}
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 40px;
	height: 40px;

	& > figure {
		margin: 0;
	}

	figcaption {
		margin-top: 0;
	}
`;

const CampaignName = styled.div`
	line-height: 1.3;
	display: flex;
	align-items: center;
	gap: 8px;
	span {
		font-size: 0.875rem;
		font-weight: 600;
	}

	&.has-messages {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.no-message {
		flex: 1;
		height: 80px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid ${colors.lightGray};
	}
`;

const LatestMessageWrapper = styled.div`
	flex: 1;
	height: 85px;
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	padding-bottom: ${guttersWithRem.xxs};
`;

const LatestMessageHeader = styled.div`
	max-width: 300px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	& > div {
		&.date {
			font-size: 0.675rem;
			color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
			white-space: nowrap;
		}
	}
`;

const LatestMessageContent = styled.div`
	font-size: 0.875rem;
	line-height: 1.3;
	width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	& > span {
		color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
		&.name {
			margin-right: ${guttersWithRem.xxs};
		}
	}

	&:hover {
		color: ${colors.integratedInbox.campaignSection.listItem.influencer.messageColor};
	}
`;

const UnreadMark = styled.div`
	position: absolute;
	right: 0;
	top: 0;

	width: 12px;
	height: 12px;
	background-color: ${colors.integratedInbox.campaignSection.listItem.unreadMark.background};
	border: 2px solid ${colors.integratedInbox.campaignSection.listItem.unreadMark.border};
	border-radius: 50%;
`;

const Styled = {
	ListItem,
	Wrapper,
	ImageWrapper,
	CampaignName,
	LatestMessageWrapper,
	LatestMessageHeader,
	LatestMessageContent,
	UnreadMark,
};

export default Styled;
