import { Model } from 'json-api-models';
import moment from 'moment';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { getAssignmentIcon, getAssignmentTypeText } from 'components/ContentManagement/Utils';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import { APPROVE, DISAPPROVE } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { isAssignmentApproved, isAssignmentDisapproved, AssignmentStatus } from 'shared/Types/Assignment';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import { AssignmentReview } from 'views/Campaign/Dashboard/types';

import Styled from './AssingmentList.style';

type AssignmentListProps = {
	assignments: Model[];
	onClickApprove?: (url: string) => void;
	onClickDisapprove?: (url: string) => void;
	hasJoined: boolean;
};

interface CIOAssignment {
	reviews: Array<AssignmentReview>;
	newFancyStatus: AssignmentStatus;
	/**
	 * @deprecated
	 */
	status: string;
	links: {
		approve: string;
		disapprove: string;
	};
}

/**
 * Group assignments by their status
 * @param {Model[]} assignments
 * @returns {Record<string, Model[]>}
 */
const groupAssignmentsByStatus = (assignments: Model[]) => {
	return assignments.reduce(
		(acc, assignment) => {
			const status = assignment.attributes.newFancyStatus || 'Unknown'; // Default to 'Unknown' if no status
			if (!acc[status]) {
				acc[status] = [];
			}
			acc[status].push(assignment);
			return acc;
		},
		{} as Record<string, Model[]>,
	);
};

/**
 * AssignmentList
 * @param {AssignmentListProps} props
 * @returns {JSX.Element}
 */
const AssignmentList = ({ assignments, onClickApprove, onClickDisapprove, hasJoined }: AssignmentListProps): JSX.Element => {
	const actions = (CIOAssignment: CIOAssignment) => {
		const CIOALinks = CIOAssignment.links;
		const CIOAPermissions = useFeaturePermissions(CIOALinks);
		const isApproved = isAssignmentApproved(CIOAssignment.newFancyStatus);
		const isDisapproved = isAssignmentDisapproved(CIOAssignment.newFancyStatus);

		return (
			<Styled.ActionButtons>
				{CIOAPermissions.userCan(DISAPPROVE) && !(isApproved || isDisapproved) && hasJoined && (
					<a
						onClick={(e) => {
							e.preventDefault(), onClickDisapprove && onClickDisapprove(CIOALinks.disapprove);
						}}
					>
						Decline
					</a>
				)}
				{CIOAPermissions.userCan(APPROVE) && !isApproved && (
					<SecondaryButton
						disabled={!hasJoined}
						onClick={() => {
							onClickApprove && onClickApprove(CIOALinks.approve);
						}}
						data-testid='cm-approve-btn'
					>
						Approve for payment
					</SecondaryButton>
				)}

				{isDisapproved && <PrimaryButton disabled>Declined</PrimaryButton>}
			</Styled.ActionButtons>
		);
	};

	const groupedAssignments = groupAssignmentsByStatus(assignments);

	return (
		<>
			<Styled.Wrapper>
				{Object.keys(groupedAssignments).map((status) => (
					<div key={status}>
						{groupedAssignments[status].map((item) => {
							const IS_STORY = item.assignment.kind === AssignmentType.INSTAGRAM_STORY;
							const stats = IS_STORY ? item.instagramStories : item.instagramPosts;
							const postedAt = stats[0]?.attributes?.postedAt;
							const postUrl = stats[0]?.attributes.url;
							return (
								<Styled.Item key={item.id}>
									<div>
										<Styled.Content>
											<Styled.Icon>{getAssignmentIcon(item.assignment.kind)}</Styled.Icon>
											<Styled.AssignmentName data-testid='cm-summary-assignment-name'>
												<Styled.Name>{item.assignment.name}</Styled.Name>
												<Styled.Status>
													<span>{getAssignmentTypeText(item.assignment.kind)}</span>
												</Styled.Status>
											</Styled.AssignmentName>
											{actions(item as unknown as CIOAssignment)}
										</Styled.Content>
									</div>
									{(postUrl?.length > 0 || postedAt) && (
										<Styled.Info>
											{!IS_STORY && postUrl?.length > 0 && <Icon name='url-link' />}
											<Styled.InfoText>
												{postedAt && (
													<Styled.Date>
														<Heading as='h5'>Posted at: </Heading> {moment(postedAt).format('YYYY-MM-DD')}
													</Styled.Date>
												)}
												{!IS_STORY && postUrl?.length > 0 && (
													<span>
														<a href={postUrl} target='_blank' rel='noreferrer'>
															{postUrl}
														</a>
													</span>
												)}
											</Styled.InfoText>
										</Styled.Info>
									)}
								</Styled.Item>
							);
						})}
					</div>
				))}
			</Styled.Wrapper>
		</>
	);
};
export default AssignmentList;
