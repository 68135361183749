import SignupImage from 'assets/img/Signup.jpg';
import fallbackLogotype from 'assets/img/logo/collabs-logo-dark.svg';

import Styled from './AuthContainer.style';
import Footer from './components/Footer';

/**
 * Auth container wraps the login and signup pages
 */
type Props = {
	children: React.ReactNode;
};
const AuthContainer = ({ children }: Props): JSX.Element => {
	return (
		<Styled.Wrapper data-testid='signup' role='main' aria-label='Authentication page'>
			<Styled.ImageWrapper aria-hidden='true'>
				<img src={SignupImage} alt='' loading='eager' width='100%' height='100%' />
				<Styled.Name aria-hidden='true'>&copy; {new Date().getFullYear()} Collabs AB</Styled.Name>
			</Styled.ImageWrapper>
			<Styled.ContentWrapper>
				<header>
					<img src={fallbackLogotype} alt='Collabs' width='90' height='28' />
					{children}
				</header>
				<Footer />
			</Styled.ContentWrapper>
		</Styled.Wrapper>
	);
};

export default AuthContainer;
