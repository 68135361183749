import { FormikErrors } from 'formik';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import useContentManagementData from 'components/ContentManagement/hooks';
import Icon from 'components/Icon';
import toast from 'services/Toast';

type AddScreenShotsProps = {
	files: File[];
	setFiles: (files: File[]) => void;
	url?: string;
	refresh: () => Promise<void>;
	inEditMode?: boolean;
	setFieldValue: (field: string, value: File, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<StatisticsFormValues>>;
};
/**
 * Add screenshots component
 * @returns {JSX.Element}
 */
const AddScreenShots = ({ url, setFiles, refresh, setFieldValue }: AddScreenShotsProps) => {
	const { uploadStatisticsScreenshot } = useContentManagementData();

	const postMedia = async (url: string, medias: Array<File>) => {
		try {
			await Promise.all(medias.map((media) => uploadStatisticsScreenshot(url, media)));
			toast.success(`${medias?.length} file${medias?.length > 1 ? 's' : ''} uploaded`);
			await setFieldValue('screenshot', medias[0]);
			await refresh();
			setFiles([]);
		} catch (e) {
			console.error(e);
			toast.error('Failed to upload files');
		}
	};

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			if (url) {
				await postMedia(url, acceptedFiles);
			} else {
				setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
			}
		},
		[url, setFiles, postMedia],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'image/jpeg': [],
			'image/jpg': [],
			'image/png': [],
		},
	});

	return (
		<Styled.AddScreenShotsSection>
			<Styled.DropZone {...getRootProps({ className: 'dropzone' })} data-testid='dropzone'>
				<input {...getInputProps()} type='file' data-testid='dropzone-input' />
				{!isDragActive && (
					<Styled.DropZoneText>
						<Icon name='add-circle' />
						<Styled.Title>Add screenshots</Styled.Title>
						<Styled.HelpText data-testid='helptext'>
							Supported formats <br />
							jpeg, jpg and png
						</Styled.HelpText>
					</Styled.DropZoneText>
				)}
			</Styled.DropZone>
		</Styled.AddScreenShotsSection>
	);
};
export default AddScreenShots;
