import styled from 'styled-components';

import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@media screen and (${devices.lg}) {
		max-width: 800px;
		width: auto;
		margin: auto;
	}
`;

const ImageWrapper = styled.div`
	flex: 1;
	& > img {
		width: 100%;
		height: auto;
		object-fit: cover;
		flex: 1;
	}
`;

const MoodBoardTitle = styled.h4`
	width: 100%;
	font-weight: 600;
	font-size: 2rem;
	margin-bottom: ${guttersWithRem.m};
`;

const SelectedFiles = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 24px;
	gap: 16px;
	@media screen and (${devices.lg}) {
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
	}
`;

const UploadBox = styled.div`
	flex: 1;
	max-width: 316px;
`;

const ButtonWrapper = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	margin: 40px 0 8px;
`;

const Cancel = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	p {
		cursor: pointer;
		margin: 0;
		&:hover {
			font-weight: 600;
		}
	}
`;

const MoodboardImagesContainer = styled.div`
	width: 100%;
	padding: 1rem;
	@media screen and (${devices.lg}) {
		width: 80%;
	}
	margin: auto;
`;

const Styled = {
	MoodboardImagesContainer,
	Wrapper,
	ImageWrapper,
	MoodBoardTitle,
	SelectedFiles,
	UploadBox,
	ButtonWrapper,
	Cancel,
};

export default Styled;
