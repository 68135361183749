import styled from 'styled-components';

import colors from 'styles/theme/colors';

const CheckBoxContainer = styled.div``;

const CheckMarkContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 10px;
	}
`;

const AgreeSectionText = styled.div`
	margin-left: 4px;

	a {
		&:hover {
			color: ${colors.oceanBlue};
			border-bottom: 1px solid ${colors.oceanBlue};
		}
	}
`;

const AgreeSection = styled.div`
	display: flex;
	align-items: flex-start;
	width: calc(100% - 8px);
	padding: 1rem;
	margin: auto;
	cursor: pointer;
	border-radius: 4px;
	margin-bottom: 16px;
	&.isActive {
		border-color: ${colors.oceanBlue};
		box-shadow: 0 0 4px 0 ${colors.oceanBlue};
	}
`;

const Styled = {
	CheckBoxContainer,
	CheckMarkContainer,
	AgreeSectionText,
	AgreeSection,
};

export default Styled;
