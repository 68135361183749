import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton, SecondaryButton } from 'components/Button';
import { BlueButton } from 'components/Button/Button';
import { SvgInfoCircle } from 'components/Icon/SvgComponents/svg-info-circle';
import DeclineButton from 'components/NewBriefPage/Components/DeclineButton';
import JoinButton from 'components/NewBriefPage/Components/JoinButton';
import JoinModal from 'components/NewBriefPage/Components/JoinModal/JoinModal';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { pathCampaignDashboard, pathCampaignEdit } from 'routing/PathLookup';
import { breakpoints } from 'styles/variables/media-queries';
import { formatAmount } from 'utils/formatters';

import Styled from './NavBar.style';

type NavBarProps = {
	onClick: (value: string) => void;
	campaignInstagramOwner: CampaignInstagramOwner;
	campaign: Campaign;
	onPreview?: boolean;
	hasJoined: boolean;
	spotsLeft: number;
	onInvite?: boolean;
	canEdit: boolean;
};
/**
 * @returns JSX.Element
 */
const NavBar = ({ campaignInstagramOwner, campaign, onPreview, hasJoined, spotsLeft, onInvite, canEdit }: NavBarProps): JSX.Element => {
	const [previewIsOpen, setPreviewIsOpen] = useState(false);

	const navigate = useNavigate();
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];

	const user = useAppSelector((state) => state.user);

	const totalInvoicedValue = campaignInstagramOwner.campaign.invoices.reduce((prev, current) => prev + current.invoice.value, 0);
	const totalInvoicesAmount = formatAmount(totalInvoicedValue, campaignInstagramOwner.campaign.currency ? campaignInstagramOwner.campaign.currency : undefined)
		.replace(',', '\xa0')
		.replace('.00', '');

	const products = campaignInstagramOwner.campaign.products.reduce((prev, current) => prev + current.quantity, 0);

	const scrollSmoothTo = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	const [isVisible, setIsVisible] = useState(true);
	const [headerHeight, setHeaderHeight] = useState(0);
	const headerRef = useRef<HTMLDivElement>(null);
	const contentStartRef = useRef<HTMLDivElement>(null);
	const lastScrollY = useRef(0);

	useEffect(() => {
		const header = headerRef.current;
		const contentStart = contentStartRef.current;

		const updateHeaderHeight = () => {
			if (header) {
				const height = header.offsetHeight;
				setHeaderHeight(height);
			}
		};

		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			const contentStartBottom = contentStart?.getBoundingClientRect().bottom ?? 0;

			if (currentScrollY < lastScrollY.current) {
				// Scrolling up
				setIsVisible(true);
			} else if (contentStartBottom < 0) {
				// Scrolling down and content-start is not visible
				setIsVisible(false);
			}

			lastScrollY.current = currentScrollY;
		};

		if (isMobileView) {
			updateHeaderHeight();
			window.addEventListener('resize', updateHeaderHeight);
			window.addEventListener('scroll', handleScroll);
		}

		return () => {
			window.removeEventListener('resize', updateHeaderHeight);
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<Styled.Wrapper className={classNames({ 'translate-y-0': isVisible, 'translate-y-full': !isVisible, onInvite: onInvite })} ref={headerRef}>
				<Styled.JoinMessage>{campaignInstagramOwner.joined ? 'You have joined this campaign' : 'Join our campaign'}</Styled.JoinMessage>
				{onPreview && (
					<Styled.TopBanner>
						<Styled.TopBannerContainer>
							<SvgInfoCircle height={20} />
							<Styled.TopBannerText>
								You are in preview mode
								{campaignInstagramOwner.influencer?.username && (
									<>
										&nbsp;for <strong>{campaignInstagramOwner.influencer?.username}</strong>
									</>
								)}
							</Styled.TopBannerText>
						</Styled.TopBannerContainer>
						<Styled.TopBannerButtonWrapper>
							{canEdit && (
								<SecondaryButton tabIndex={0} className='button-size-small' onClick={() => navigate(pathCampaignEdit(campaign.shortId))}>
									Edit
								</SecondaryButton>
							)}
							<SecondaryButton className='button-size-small' onClick={() => setPreviewIsOpen(true)}>
								<span>Preview join popup</span>
							</SecondaryButton>
							<PrimaryButton className='button-size-small' onClick={() => navigate(pathCampaignDashboard(campaign.shortId))} data-testid='brief-to-campaign'>
								Go to campaign
							</PrimaryButton>
							<JoinModal
								onPreview
								isModalOpen={previewIsOpen}
								toggleModal={() => setPreviewIsOpen(!previewIsOpen)}
								campaign={campaign}
								campaignInstagramOwner={campaignInstagramOwner}
								join={() => {}}
							/>
						</Styled.TopBannerButtonWrapper>
					</Styled.TopBanner>
				)}
				<Styled.Menu>
					<Styled.InfoBoxWrapper>
						<Styled.CampaignName data-testid='brief-campaign-name'>{campaign.name}</Styled.CampaignName>
						{totalInvoicedValue > 0 ? (
							<Styled.InfoBox onClick={() => scrollSmoothTo('compensation')}>
								Commission: <span>{totalInvoicesAmount}</span>
							</Styled.InfoBox>
						) : null}
						<Styled.InfoBox onClick={() => scrollSmoothTo('assignments')}>
							Assignments:<span>{campaignInstagramOwner?.campaign?.assignments?.length} </span>
						</Styled.InfoBox>
						{products ? (
							<Styled.InfoBox onClick={() => scrollSmoothTo('products')}>
								Products:<span>{products} </span>
							</Styled.InfoBox>
						) : null}
						{!hasJoined && !onPreview && (
							<Styled.InfoBox className={classNames({ oneLeft: spotsLeft < 2 }, 'noClick')}>
								{spotsLeft > 0 ? (
									<>
										<strong>Spot{spotsLeft > 1 && 's'} left:</strong>
										<span>{spotsLeft}</span>
									</>
								) : (
									<strong>Campaign is full</strong>
								)}
							</Styled.InfoBox>
						)}
					</Styled.InfoBoxWrapper>
					<Styled.Buttons>
						{campaignInstagramOwner?.joined && !user.id && <BlueButton onClick={() => navigate('/login')}>Login</BlueButton>}
						{campaignInstagramOwner?.links?.decline && <DeclineButton link={campaignInstagramOwner?.links?.decline} />}
						{campaignInstagramOwner && <JoinButton campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} />}
					</Styled.Buttons>
				</Styled.Menu>
			</Styled.Wrapper>
			<div ref={contentStartRef} id='content-start' style={{ height: `${headerHeight}px` }}></div>
		</>
	);
};

export default NavBar;
