import { Model } from 'json-api-models';
import moment from 'moment';

import ContentCard from 'components/ContentCard';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import useContentManagementData from 'components/ContentManagement/hooks';
import toast from 'services/Toast';
import { InstagramStory } from 'shared/Types/InstagramStories';

/**
 * List screenshots
 * Component to list single and multiple screenshots for the stats.
 *
 * @param {ScreenshotsProps} props
 * @returns {JSX.Element}
 */
const ListScreenshots = ({
	refresh,
	fetchedScreenShots,
	assignmentApproved,
	files,
	setFiles,
}: {
	refresh?: () => Promise<void>;
	fetchedScreenShots: InstagramStory[] | Model[];
	assignmentApproved?: boolean;
	files: File[];
	setFiles: (files: File[]) => void;
}): JSX.Element => {
	const { deleteMedia } = useContentManagementData();

	const deleteFile = async (selectedFile: Model) => {
		try {
			await deleteMedia(selectedFile.links.delete);
			if (refresh) {
				await refresh();
			}
			toast.success('File deleted');
		} catch (e) {
			console.error(e);
			toast.error('Failed to delete file');
		}
	};

	const updateSelectedScreenshots = (file: File) => {
		if (files?.some((f) => f.name === file.name)) {
			// If file exists, remove it
			setFiles(files.filter((f) => f.name !== file.name));
		}
	};

	return (
		<Styled.ListScreenShots>
			{// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fetchedScreenShots?.map((screenshot: any, i) => {
				return (
					<Styled.StoryWrapper key={i}>
						<ContentCard imageUrl={screenshot.links.file} altText='Screenshot' active={assignmentApproved ? false : true} type='tall' />
						<Styled.RemoveFile>
							<p className='name'>Uploaded: {moment(screenshot.attributes.createdAt).format('YYYY-MM-DD')}</p>
							{screenshot.links.delete && (
								<p
									className='remove'
									onClick={() => {
										deleteFile(screenshot);
									}}
								>
									Delete
								</p>
							)}
						</Styled.RemoveFile>
					</Styled.StoryWrapper>
				);
			})}
			{files?.map((file, i) => {
				return (
					<Styled.StoryWrapper key={i} data-id={i}>
						<ContentCard imageUrl={typeof file === 'string' ? file : URL.createObjectURL(file)} altText='Screenshot' active={true} type='tall' />
						<Styled.RemoveFile>
							<p className='name'>{file.name}</p>
							<p
								className='remove'
								onClick={() => {
									updateSelectedScreenshots(file);
								}}
							>
								Delete
							</p>
						</Styled.RemoveFile>
					</Styled.StoryWrapper>
				);
			})}
		</Styled.ListScreenShots>
	);
};

export default ListScreenshots;
