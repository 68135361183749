import { Navigate, RouteObject, useParams } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import OrganizationLayout from 'layouts/organization';
import NewCampaignPreview from 'views/Campaign/CampaignPreview/NewCampaignPreview';
import DiscoveryPage from 'views/Discovery/DiscoveryPage';
import InfluencerManagementContainer from 'views/InfluencerManagement/InfluencerManagementContainer';
import IntegratedInbox from 'views/IntegratedInbox/IntegratedInbox';
import LandingPageByInvite from 'views/landingpage/by-invite';
import DashboardView from 'views/organization/dashboard/';
import SettingsView from 'views/organization/settings/layout';

import CampaignRoutes from './CampaignRoutes';
import { ProtectedRoute } from './layout-routes/ProtectedRoute';

/**
 * User Routes
 * Routes for managers, agents and admins.
 * @returns {Array<RouteObject>}
 */

const buildRedirectPath = (campaignId: string, ownerId: string, assignmentId: string) => {
	let path = '/campaigns';
	if (campaignId) path += `/${campaignId}`;
	if (ownerId) path += `/${ownerId}`;
	if (assignmentId) path += `/assignments/${assignmentId}`;
	return path;
};

const ContentManagementRedirect = () => {
	const { campaignId, ownerId, assignmentId } = useParams();
	const targetPath = buildRedirectPath(campaignId!, ownerId!, assignmentId!);

	return <Navigate to={targetPath} replace />;
};

const routes: Array<RouteObject> = [
	{
		path: 'campaigns/:campaignId/preview',
		element: (
			<ProtectedRoute>
				<OrganizationLayout />
			</ProtectedRoute>
		),
		children: [
			{ index: true, element: <NewCampaignPreview /> },
			{ path: ':influencerId', element: <LandingPageByInvite /> },
		],
	},
	{
		path: '',
		element: (
			<ProtectedRoute>
				<OrganizationLayout />
			</ProtectedRoute>
		),
		children: [
			{ index: true, element: <Navigate to='dashboard' /> },
			{ path: 'dashboard', element: <DashboardView /> },
			{ path: 'campaigns/*', children: CampaignRoutes }, // we need to defined the routes
			{
				path: 'content-management/:campaignId?/:ownerId?/:assignmentId?',
				element: <ContentManagementRedirect />,
			},
			{
				path: 'inbox',
				children: [
					{ path: ':campaignId?/:conversationId?', element: <IntegratedInbox /> },
					{ path: 'direct/:conversationId', element: <IntegratedInbox /> },
					{ path: '*', element: <PageNotFound /> },
				],
			},
			{
				path: 'discovery',
				element: <DiscoveryPage />,
			},
			{
				path: 'influencer-management',
				children: [
					{ index: true, element: <Navigate to='folders' /> },
					{ path: 'lists/:listId', element: <InfluencerManagementContainer /> },
					{ path: 'folders/:folderId?', element: <InfluencerManagementContainer /> },
					{ path: '*', element: <PageNotFound /> },
				],
			},
			{
				path: 'settings',
				children: [
					{ index: true, element: <SettingsView /> },
					{ path: 'account', element: <SettingsView /> },
					{ path: 'campaign', element: <SettingsView /> },
					{ path: 'delivery-info', element: <SettingsView /> },
					{ path: 'change-password', element: <SettingsView /> },
					{ path: 'company', element: <SettingsView /> },
					{ path: 'billing', element: <SettingsView /> },
					{ path: 'brands', element: <SettingsView /> },
					{ path: 'brands/:brandId', element: <SettingsView /> },
					{ path: 'clients', element: <SettingsView /> },
					{ path: 'clients/:clientId', element: <SettingsView /> },
					{ path: '*', element: <PageNotFound /> },
				],
			},
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
