import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';

import { TertiaryButton } from 'components/Button';
import Field from 'components/Forms/Field';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput';

import Styled from './FilterSection.style';
import FilterSectionOptionListItem from './FilterSectionOptionListItem';

type FilterOptionItem = {
	id: string;
	name: string;
	imageUrl?: string | undefined;
};

type FilterSectionAccordionItemProps = {
	title: JSX.Element;
	label: string;
	placeholder: string;
	data: Array<FilterOptionItem>; // All filter options fetched with the dashboard filter. Changes everytime the dashboard filter changes
	selectedData: Array<string>;
	onSelect: (id: string) => void;
	isSearching: boolean; // Display spinner in Accordion body first time the options are fetched. In total every time new options are being fetched
	setClearThisFilter: (clearThisFilter: boolean) => void;
};

/**
 * @returns {JSX.Element}
 */
const FilterSectionAccordionItem = ({
	title,
	label,
	placeholder,
	data,
	selectedData,
	onSelect,
	isSearching,
	setClearThisFilter,
}: FilterSectionAccordionItemProps): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [dataToDisplay, setDataToDisplay] = useState<Array<FilterOptionItem>>([]);
	const ref = useRef<HTMLDivElement | null>(null);

	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchValue(value);
	};

	useEffect(() => {
		if (searchValue?.length === 0) {
			setDataToDisplay(data);
		} else {
			const filteredData = data.filter((item: FilterOptionItem) => {
				return item.name.toLowerCase()?.indexOf(searchValue.toLowerCase()) === 0;
			});
			setDataToDisplay(filteredData);
		}
	}, [searchValue]);

	const cancel = () => {
		setSearchValue('');
	};

	useEffect(() => {
		setDataToDisplay(data);
	}, [data]);

	const handleClickOutside = (e: MouseEvent): void => {
		if (ref.current && !ref.current.contains(e.target as Node)) {
			setSearchValue('');
		}
		if (e.target instanceof HTMLAnchorElement) {
			setSearchValue('');
		}
	};

	useEffect(() => {
		setDataToDisplay(data);
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			setSearchValue('');
		};
	}, []);

	const createLabel = (option: FilterOptionItem): string | JSX.Element => {
		if (typeof option.imageUrl === 'undefined') {
			return option.name;
		}
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={option.imageUrl} /> {option.name}
			</div>
		);
	};

	return (
		<Styled.CustomAccordion title={title} className={classNames({ bold: selectedData?.length > 0 })}>
			<Styled.FilterDataContainer>
				<Styled.Total>
					<span>Total:</span> {data?.length}
				</Styled.Total>
				<TertiaryButton size='sm' disabled={selectedData?.length < 1} onClick={() => setClearThisFilter(true)}>
					Clear filter
				</TertiaryButton>
			</Styled.FilterDataContainer>
			<div ref={ref}>
				<Styled.FilterInputContainer>
					<Field label={label}>
						<SearchInput
							value={searchValue}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearch(e)}
							onReset={() => cancel()}
							placeholder={placeholder}
						/>
					</Field>
				</Styled.FilterInputContainer>
				{isSearching ? (
					<Styled.SpinnerContainer>
						<LoadingSpinner size='sm' />
					</Styled.SpinnerContainer>
				) : (
					<Styled.OptionsList>
						{dataToDisplay.map((option: FilterOptionItem, i: number) => {
							if (selectedData?.includes(option.id)) {
								return (
									<FilterSectionOptionListItem
										key={i}
										onChange={() => onSelect(option.id)}
										label={createLabel(option)}
										checked={selectedData?.indexOf(option.id) > -1 ? true : false}
									/>
								);
							}
						})}
						{dataToDisplay.map((option: FilterOptionItem, i: number) => {
							if (!selectedData?.includes(option.id)) {
								return (
									<FilterSectionOptionListItem
										key={i}
										onChange={() => onSelect(option.id)}
										label={createLabel(option)}
										checked={selectedData?.indexOf(option.id) > -1 ? true : false}
									/>
								);
							}
						})}
					</Styled.OptionsList>
				)}
			</div>
		</Styled.CustomAccordion>
	);
};
export default FilterSectionAccordionItem;
