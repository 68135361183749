import { PageHeader } from 'components';
import { useAppSelector } from 'hooks/useUserAppSelector';

import CampaignSection from './Components/CampaignSection';
import InvitationSection from './Components/InvitationSection';
import Styled from './InfluencerDashboardContainer.style';

/**
 * Influencer dashboard
 * @returns {JSX.Element}
 */
const InfluencerDashboard = (): JSX.Element => {
	const user = useAppSelector((state) => state.user);
	const userHasInfluecers = user.influencers?.length > 0;
	return (
		<>
			<div style={{ paddingLeft: '1rem' }}>
				<PageHeader userGreeting showBreadcrumb={false} />
			</div>
			<Styled.Wrapper>
				{userHasInfluecers && <InvitationSection />}
				<CampaignSection />
			</Styled.Wrapper>
		</>
	);
};

export default InfluencerDashboard;
