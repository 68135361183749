import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import InviteUsed from 'components/InviteUsed/InviteUsed';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { ICollabsResponse } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';
import SignUpContainer from 'views/Auth/SignupContainer/SignupContainer';

import AccountCreation from './components/AccountCreation/AccountCreation';
import BillingInformation from './components/BillingInformation/BillingInformation';
import PersonalInformation from './components/PersonalInformation/PersonalInformation';

type RouteParams = {
	inviteToken: string;
};

/**
 * PublisherSignUpContainer. Displays 3 steps for the first publisher user, 2 steps for the rest
 * @returns {JSX.Element}
 */
const PublisherSignUpContainer = (): JSX.Element => {
	const [step, setStep] = useState(0);
	const [totalSteps, setTotalSteps] = useState(0);
	const [userId, setUserId] = useState<number | string | undefined>();
	const [publisherEdit, setPublisherEdit] = useState<string | undefined>('');
	const [isNewOrganization, setIsNewOrganization] = useState<boolean>(false);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [inviteIsUsed, setInviteIsUsed] = useState(false);
	const location = useLocation();

	const user = useAppSelector((state) => state.user);
	const { inviteToken: token } = useParams<RouteParams>();
	const navigate = useNavigate();
	const userDispatch = useAppDispatch();
	const logoutService = useInjection<LogoutService>(LogoutService);
	const incrementStep = () => setStep(step + 1);

	const steps = [
		<AccountCreation
			key={0}
			inviteToken={token}
			setTermsAccepted={setTermsAccepted}
			termsAccepted={termsAccepted}
			onCreate={async (id) => {
				setUserId(id);
				const { data } = await createClient().get<JsonApiDocument>('/me', { params: { includes: 'publisher' } });
				const user = new Store().sync(data) as Model;
				setTotalSteps(user.publisher.links.edit === undefined ? 2 : 3);
				setPublisherEdit(user.publisher.links.edit);
				incrementStep();
			}}
		/>,
		<PersonalInformation
			key={1}
			userId={userId!}
			publisherEdit={publisherEdit}
			isNewOrganization={isNewOrganization}
			onSubmit={!isNewOrganization ? () => navigate('/dashboard') : () => incrementStep()}
		/>,
		<BillingInformation key={2} publisherEdit={publisherEdit} onSubmit={() => navigate('/dashboard')} />,
	];

	const checkToken = () => {
		const baseUrl = '/public/invites/';
		createClient()
			.get(baseUrl + token)
			.then((response: ICollabsResponse) => {
				const model = new Store();
				model.sync(response.data);
				const invite = model.findAll('invite')[0];
				setIsNewOrganization(invite.attributes.isNewOrganization);
			})
			.catch((error) => {
				if (error.status === 404 || error.status === 410) {
					setInviteIsUsed(true);
				}
			});
	};

	const checkUser = async () => {
		if (user.id) {
			await logoutService.logout(userDispatch);
		}
	};

	useEffect(() => {
		checkUser();
		token && checkToken();
	}, []);

	useEffect(() => {
		const cleanupSignup = () => {
			if (!termsAccepted && user.id) {
				logoutService.panicLogout();
			}
		};

		// Check if we're navigating away from the signup flow
		if (!location.pathname.includes('/signup')) {
			cleanupSignup();
		}

		return () => {
			cleanupSignup();
		};
	}, [location.pathname, termsAccepted]);

	return inviteIsUsed ? (
		<InviteUsed forPublisher={true} />
	) : (
		<SignUpContainer inviteToken={token} steps={totalSteps} currentStep={step} termsAccepted={termsAccepted}>
			{steps[step]}
		</SignUpContainer>
	);
};

export default PublisherSignUpContainer;
