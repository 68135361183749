import { AxiosInstance } from 'axios';
import { JsonApiDocument, JsonApiResource } from 'json-api-models';

import { BillingInfoType } from 'components/Settings/ClientManagement/types';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import { createClient } from 'shared/ApiClient/ApiClient';

/**
 * @deprecated use PublisherManager instead. The PublisherManager is part of our new ApiManager pattern.
 */
class PublisherService {
	ENDPOINT_URL: string;
	private client: AxiosInstance;

	constructor() {
		this.ENDPOINT_URL = '/publishers';
		this.client = createClient();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async updatePublisher(publisherId: string, values: any) {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		return await this.client.patch<JsonApiDocument>(`${this.ENDPOINT_URL}/${publisherId}?${params}`, values);
	}

	async deleteInvite(publisherId: string, userId: string) {
		return await this.client.delete<JsonApiDocument>(`${this.ENDPOINT_URL}/${publisherId}/invites/${userId}`);
	}

	async deleteUser(publisherId: string, inviteId: string) {
		return await this.client.delete<JsonApiDocument>(`${this.ENDPOINT_URL}/${publisherId}/users/${inviteId}`);
	}

	async deleteClient(publisherId: string, clientId: string) {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		return await this.client.delete<JsonApiDocument>(`${this.ENDPOINT_URL}/${publisherId}/clients/${clientId}?${params}`);
	}

	async deleteBrand(publisherId: string, clientId: string, brandId: string) {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		return await this.client.delete<JsonApiDocument>(`${this.ENDPOINT_URL}/${publisherId}/clients/${clientId}/brands/${brandId}?${params}`);
	}

	async createClient(publisherId: string, name: string) {
		return await this.client.post<JsonApiResource>(`${this.ENDPOINT_URL}/${publisherId}/clients`, { name });
	}

	async updateClientName(hateoasLink: string, data: { name: string }) {
		return await this.client.patch(hateoasLink, data);
	}

	/**
	 * @deprecated This is the legacy route. New route is /brands
	 */
	async createBrand(publisherId: string, clientId: string, name: string) {
		return await this.client.post<JsonApiResource>(`${this.ENDPOINT_URL}/${publisherId}/clients/${clientId}/brands`, { name });
	}

	async getPublishersByRole() {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		return await this.client.get<JsonApiDocument>(
			`${this.ENDPOINT_URL}?${params}&roles[]=${OrganizationHierarchyRole.ADMINISTRATOR}&roles[]=${OrganizationHierarchyRole.CAMPAIGN_MANAGER}`,
		);
	}

	async editClientBillingInfo(url: string, values: BillingInfoType) {
		const params = new URLSearchParams();
		params.append('exclude', 'defaultIncludes');
		params.append('include', ':hateoas(false)');

		return await this.client.patch<JsonApiDocument>(`${url}?${params}`, values);
	}
}

export default new PublisherService();
