import { ReactNode, useState } from 'react';

import Accordion from 'components/Accordion';
import { HoverButton } from 'components/Button';

import Styled from './ExplanationAccordion.style';

type Props = {
	className?: string;
	title?: string;
	chartTitle?: string;
	chartExplanation?: {
		explanation?: string;
		formula?: string | ReactNode;
	};
};

const ExplanationAccordition = ({ className, title, chartTitle, chartExplanation }: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<Styled.Wrapper className={className}>
			<HoverButton iconRight icon='arrow-down' onClick={() => setOpen(!open)}>
				{title || 'Learn more about the graph'}
			</HoverButton>
			<Accordion open={open} hideChevron title=''>
				<Styled.InnerWrapper>
					{chartTitle && (
						<span className='title' data-testid='chart-title'>
							{chartTitle}
						</span>
					)}
					{chartExplanation?.explanation && (
						<span className='explanation' data-testid='chart-explanation'>
							{chartExplanation?.explanation}
						</span>
					)}
					{chartExplanation?.formula && (
						<span className='formula' data-testid='chart-formula'>
							{chartExplanation?.formula}
						</span>
					)}
				</Styled.InnerWrapper>
			</Accordion>
		</Styled.Wrapper>
	);
};

export default ExplanationAccordition;
