import { AxiosError } from 'axios';
import { FormikProps, FormikValues } from 'formik';
import { useRef, useState } from 'react';

import Icon from 'components/Icon';
import Styled from 'components/Settings/ClientManagement/ClientManagement.style';
import { SideDrawer } from 'components/SideDrawer';
import useInjection from 'hooks/useInjection';
import PublisherManager from 'services/ApiManager/Publisher.manager';
import { MutateFn } from 'services/ApiManager/types';
import toast from 'services/Toast';
import PublisherForm from 'views/admin/Components/Publishers/Components/PublisherForm/PublisherForm';
import { PublisherType } from 'views/admin/Components/Publishers/Components/PublisherOptions/PublisherOptions';

const InvitePublisher = (props: { mutateFn: MutateFn }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

	const manager = useInjection<PublisherManager>(PublisherManager);
	const formRef = useRef<FormikProps<{ name: string; email: string; kind: PublisherType }> | null>(null);

	const createPublisher = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const savePublisher = (values: FormikValues) => {
		setValidateAfterSubmit(true);

		const payload = { name: values.name, email: values.email };
		return manager
			.createInvite(payload, undefined, {
				mutateFn: props.mutateFn,
			})
			.then(() => {
				toast.success(`Member added. `);
				setValidateAfterSubmit(false);
				setIsModalOpen(false);
			})
			.catch((error: AxiosError) => {
				console.error('Error creating publisher', error);
				setValidateAfterSubmit(false);
			});
	};

	return (
		<Styled.CreateContainer>
			<Styled.AddSection onClick={() => setIsModalOpen(true)}>
				<Styled.AddIcon role='button'>
					<Icon name='plus' size='12' />
				</Styled.AddIcon>
				<p>Invite publisher</p>
			</Styled.AddSection>
			<SideDrawer
				sidebarIsOpen={isModalOpen}
				expandedTitle='Invite a new publisher'
				onClose={() => setIsModalOpen(false)}
				dataTestId={''}
				title='Publisher management'
				isExpandable
				saveButtonText='Invite publisher'
				onSave={createPublisher}
			>
				<PublisherForm
					disabled={false}
					savePublisher={savePublisher}
					initialValues={{ name: '', email: '' }}
					formRef={formRef}
					validateAfterSubmit={validateAfterSubmit}
					isLoading={false}
					mutate={props.mutateFn}
				/>
			</SideDrawer>
		</Styled.CreateContainer>
	);
};

export default InvitePublisher;
