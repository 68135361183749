import { first } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import InviteUsed from 'components/InviteUsed/InviteUsed';
import LoadingSpinner from 'components/LoadingSpinner';
import useModels from 'hooks/useModels';
import ToolsService from 'services/Tools';
import { createClient } from 'shared/ApiClient/ApiClient';
import { gtagEvent } from 'utils/ga';
import SignUpContainer from 'views/Auth/SignupContainer/SignupContainer';

import AccountCreation from './Components/AccountCreation/AccountCreation';

type RouteParams = {
	inviteToken: string;
};

/**
 * InfluencerSignUpContainer Used by agents on a "hidden" route
 * @returns {JSX.Element}
 */
const InfluencerSignUpContainer = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [displayInviteUsed, setDisplayInviteUsed] = useState(false);

	const location = useLocation();
	const { inviteToken: token } = useParams<RouteParams>();
	const isAgentSignup = location.pathname === '/signup/agents';
	const { models, loading } = useModels(!isAgentSignup ? `/public/campaigns/${token}` : null);
	const invites = models.findAll('invite');
	const thisInvite = invites.length > 0 ? first(invites) : null;
	const [searchParams] = useSearchParams();
	const totalSteps = 2;

	const checkToken = () => {
		const baseUrl = '/public/campaigns/';

		setIsLoading(true);
		createClient()
			.get(baseUrl + token)
			.catch((error) => {
				if (error.status === 404) {
					setDisplayInviteUsed(true);
				}
				ToolsService.log('Account Creation error', {
					error: error,
					errorInfo: error,
				});
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (!isAgentSignup && !thisInvite && !loading) {
			checkToken();
		}
	}, [thisInvite]);

	useEffect(() => {
		const origin = token ? 'invitation' : searchParams.get('origin') ?? 'unknown';
		gtagEvent('sign_up_page_shown', { origin });

		return () => {
			setIsLoading(false);
		};
	}, []);

	return isLoading || loading ? (
		<LoadingSpinner position='center' aria-label='Loading signup form' />
	) : location.pathname === '/signup' ? (
		<PageNotFound />
	) : (!isAgentSignup && !thisInvite) || displayInviteUsed ? (
		<InviteUsed />
	) : (
		<div role='main' aria-label='Influencer signup process'>
			<SignUpContainer inviteToken={token!} steps={totalSteps} currentStep={currentStep} termsAccepted={termsAccepted} aria-live='polite'>
				<AccountCreation
					currentStep={currentStep}
					setCurrentStep={(step) => setCurrentStep(step)}
					termsAccepted={termsAccepted}
					setTermsAccepted={(accepted) => setTermsAccepted(accepted)}
					email={thisInvite?.email}
					aria-current={`step ${currentStep + 1} of ${totalSteps}`}
				/>
			</SignUpContainer>
		</div>
	);
};

export default InfluencerSignUpContainer;
