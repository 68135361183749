/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { CampaignInstagramOwnerStatusText } from 'components/ContentManagement/Utils';
import { Heading } from 'components/Heading';
import Pill from 'components/Pill';

import Styled from './CampaignItem.style';

/**
 * @returns {JSX.Element}
 */
const CampaignItem = ({
	campaignId,
	assignments,
	setModalIsOpen,
	CIOId,
}: {
	CIOId: string;
	setModalIsOpen: () => void;
	campaignId: string;
	assignments: { assignmentId: string; startDate: string; newFancyStatus: string; assignmentName: string }[];
}): JSX.Element => {
	const navigate = useNavigate();

	const handleOpenModal = (id: string) => {
		setModalIsOpen();
		navigate(`/influencer/campaigns/${campaignId}/${CIOId}/assignments/${id}`);
	};

	return (
		<Styled.InnerWrapper data-testid={`${campaignId}-campaign-item`}>
			<Styled.CustomAccordion
				title={
					<div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
						<Heading as='h5'>Assignments</Heading> <Pill title={`${assignments.length}`} className='number' />
					</div>
				}
			>
				<Styled.CampaignInfo>
					{assignments.map((assignment: { assignmentId: string; startDate: string; newFancyStatus: string; assignmentName: string }, index: number) => {
						const startDate = moment(assignment.startDate);
						const today = moment();
						const daysUntil = startDate.diff(today, 'days');

						const postingDateText = daysUntil > 0 ? `Post in ${daysUntil} days` : `Posting date: ${startDate.format('YYYY-MM-DD')}`;
						return (
							<Styled.AssignmentLink
								data-testid={`assignment-link-${index}`}
								key={assignment.assignmentId}
								onClick={() => handleOpenModal(assignment.assignmentId)}
							>
								<Styled.AssignmentTitle>
									<span>
										{assignment.assignmentName} - {postingDateText}
									</span>
									<Pill title={CampaignInstagramOwnerStatusText(assignment.newFancyStatus)} className={assignment.newFancyStatus} />
								</Styled.AssignmentTitle>
							</Styled.AssignmentLink>
						);
					})}
				</Styled.CampaignInfo>
			</Styled.CustomAccordion>
		</Styled.InnerWrapper>
	);
};

export default CampaignItem;
