import styled from 'styled-components';

import theme from 'styles/theme/base';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { devices, devicesMax } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	background-color: ${colors.snow};
	width: 100%;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 60px;
	left: 0;
	right: 0;
	z-index: 100;
	height: auto;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 300ms;

	&.onInvite {
		top: 0;
	}
	&.collapse {
		height: 0;
		overflow: hidden;
	}

	&.translate-y-0 {
		transform: translateY(0px);
	}

	&.translate-y-full {
		transform: translateY(-100%);
	}
`;

const TopBanner = styled.div`
	background-color: ${colors.paleGreenTint};
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: ${theme.fontSizes.base};
	line-height: 1rem;
	text-align: center;
	flex-wrap: wrap;
	padding: 1rem;

	@media screen and (${devicesMax.lg}) {
		flex-direction: column;

		.button-size-small {
			height: 24px;
			font-size: ${theme.fontSizes.text};
			min-height: unset;
			padding: 1rem;
			flex-shrink: 0;
		}
	}
`;

const TopBannerContainer = styled.div`
	height: 64px;
	text-align: center;
	display: flex;
	align-items: center;
	align-content: center;

	@media screen and (${devicesMax.sm}) {
		height: auto;
		padding-bottom: 4px;
	}
`;
const TopBannerText = styled.span`
	padding-left: 1rem;
`;

const TopBannerButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: auto;
	gap: 8px;
	@media screen and (${devicesMax.sm}) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 16px;
	}
`;

const CampaignName = styled.div`
	font-weight: 600;
	padding: 12px 12px 12px 0;
	display: block;
	@media screen and (${devices.lg}) {
		width: fit-content;
		white-space: nowrap;
	}
`;

const InfoBoxWrapper = styled.div`
	display: flex;
	width: 100%;
	top: 0;
	gap: 8px;
	flex-direction: column;
	@media screen and (${devices.lg}) {
		flex-direction: row;
	}
`;

const InfoBox = styled.div`
	display: flex;
	height: 45px;
	padding: 16px;
	background-color: ${colors.mist};
	border-radius: ${borderRadius.m};
	flex-shrink: 0;
	cursor: pointer;
	&.noClick {
		cursor: default;
		background-color: transparent;
	}
	span {
		margin-left: 8px;
		font-weight: 600;
	}
	@media screen and (${devices.lg}) {
		justify-content: center;
		align-items: center;
	}
	&.oneLeft {
		background-color: ${colors.rose};
	}
`;

const JoinMessage = styled.div`
	padding-top: 1.5rem;
	padding-left: 0;
	display: none;
	@media screen and (${devices.lg}) {
		font-size: 1.0625rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5;
		padding: 1.5rem;
		padding-top: 0.5rem;
		display: flex;
	}
`;

const Menu = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1.5rem 1rem;
	box-shadow: 0 4px 2px -2px rgba(51, 51, 51, 0.1);
	flex-direction: column;
	gap: 0.5rem;
	transition: height 0.2s;
	height: 300px;
	padding-top: 0.5rem;

	&.collapse {
		height: 0;
		overflow: hidden;
	}

	${({ theme }) => theme.mediaQueries.lg} {
		padding-top: 2rem;
	}

	@media screen and (${devices.lg}) {
		justify-content: space-between;
		height: auto;
	}

	@media screen and (${devices.xxl}) {
		flex-direction: row;
	}
`;

const Buttons = styled.div`
	display: none;
	width: 100%;

	@media screen and (${devices.lg}) {
		display: flex;
		gap: 8px;
	}

	@media screen and (${devices.xxl}) {
		justify-content: flex-end;
		padding-top: 0;
	}
`;

const PreviewBox = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1.5rem;
	border-radius: ${borderRadius.m};
	background-color: ${colors.smoke};
	backdrop-filter: blur(2px);
	color: ${colors.snow};
	align-items: center;
	margin-bottom: 24px;
	width: calc(100% - 16px);
	margin: 8px;
	h5 {
		color: ${colors.snow};
		white-space: nowrap;
		font-weight: 700;
		font-size: 1rem;
		@media screen and (${devices.lg}) {
			margin-bottom: 0;
		}
	}
	@media screen and (${devices.lg}) {
		flex-direction: row;
		width: fit-content;
		margin: auto;
		margin-top: -40px;
		margin-bottom: 8px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;

	@media screen and (${devices.lg}) {
		margin-left: 24px;
		flex-direction: row;
	}
	button {
		width: 100%;
		@media screen and (${devices.lg}) {
			width: auto;
		}
		&.white {
			color: ${colors.snow};
			border: 2px solid ${colors.snow};
		}
	}
`;

const Styled = {
	JoinMessage,
	InfoBoxWrapper,
	InfoBox,
	Wrapper,
	CampaignName,
	Menu,
	Buttons,
	PreviewBox,
	ButtonWrapper,
	TopBanner,
	TopBannerButtonWrapper,
	TopBannerContainer,
	TopBannerText,
};

export default Styled;
