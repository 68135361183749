import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import DeleteModal from 'components/Modals/DeleteModal';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { FacebookPage } from 'components/Settings/Profiles/types';
import SPCStyled from 'components/SocialProfileConnector/SocialProfileConnector.style';
import { getErrorMessageOnPost, getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import FacebookAuthService from 'services/FacebookApi/Facebook-auth.service';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import { updateUser } from 'shared/User/User.helpers';

import Styled from './FacebookConnector.style';

type ConnectedFacebookProfiles = {
	connectedPages: Array<FacebookPage>;
	FbPagesWithInstagramAccount: Array<FacebookPage>;
	FbPagesWithoutInstagramAccount: Array<FacebookPage>;
	displaySuccessMessage?: boolean;
	connectedToFacebook: boolean;
	FbPagesIsLoading: boolean;
	disconnectSucceeded: () => void;
	isSignUp: boolean;
};

/**
 * ConnectedFacebookProfiles
 * @param {ConnectedFacebookProfiles} props
 * @returns {JSX.Element}
 */
const ConnectedFacebookProfiles = (props: ConnectedFacebookProfiles): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const disconnectFromInstagram = async (renderSuccessMessage = true) => {
		setIsLoading(true);

		try {
			const tokenResult = await FacebookAuthService.deleteFacebookToken();
			if (tokenResult !== StatusCode.OK) {
				toast.error(getErrorMessageOnPost('disconnecting'));
			}

			const dataResult = await FacebookAuthService.deleteFacebookData();
			if (dataResult !== StatusCode.OK) {
				toast.error(getErrorMessageOnPost('removing data'));
			}

			await updateUser();

			if (renderSuccessMessage) {
				toast.success('You have disconnected from Instagram');
				setIsModalOpen(false);
			}

			props.disconnectSucceeded();
		} catch (error) {
			if (error) {
				console.error(error);
			}

			toast.error(getSomethingWentWrongMessage());
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{props.FbPagesIsLoading && <Skeleton width={'100%'} height={'70px'} borderRadius={'10px'} style={{ marginBottom: '16px' }} />}
			{props.connectedPages.length > 0 ? (
				<>
					<Styled.ToggleTitleWrapper>
						<h5>You're connected</h5>
						<SPCStyled.DisconnectButton data-testid='disconnect-button' onClick={() => setIsModalOpen(true)}>
							Disconnect
						</SPCStyled.DisconnectButton>
					</Styled.ToggleTitleWrapper>
					<Styled.Info>Your account is successfully linked. To edit or add another account, disconnect and reconnect to Facebook.</Styled.Info>

					<Styled.PagesWrapper>
						{props.FbPagesWithInstagramAccount.map((page: FacebookPage) =>
							page.attributes.instagramAccounts?.map((account: { username: string; profilePictureUrl: string }, i: number) => (
								<Styled.ConnectedUser key={i}>
									<UserAvatar fullName={account.username} img={account.profilePictureUrl ?? ''} displayFacebookName={`Facebook/${page.attributes.name}`} />
								</Styled.ConnectedUser>
							)),
						)}

						{props.FbPagesWithoutInstagramAccount.map((page: FacebookPage) => {
							return page.attributes.instagramAccounts?.map((account: { username: string; profilePictureUrl: string }, i: number) => (
								<Styled.ConnectedUser key={i}>
									<UserAvatar fullName={account.username} img={account.profilePictureUrl ?? ''} displayFacebookName={`Facebook/${page.attributes.name}`} />
								</Styled.ConnectedUser>
							));
						})}
					</Styled.PagesWrapper>

					<DeleteModal
						iconReplacement={<></>}
						title='Disconnect Instagram account?'
						isFetching={isLoading}
						action={() => disconnectFromInstagram()}
						isModalOpen={isModalOpen}
						toggleModal={() => setIsModalOpen(!isModalOpen)}
						buttonText='Disconnect'
					>
						<>
							<p>Disconnecting your account might limit your opportunities for future projects and reduce the effectiveness of your campaigns.</p>
							<br />
							<p>Are you sure?</p>
						</>
					</DeleteModal>
				</>
			) : null}
		</>
	);
};

export default ConnectedFacebookProfiles;
