import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

export const Navigation = styled.ul`
	list-style: none;
	padding-left: 0;
	margin: 0;

	li {
		padding: 0.5rem;
		border-radius: ${borderRadius.s};
		display: flex;
		align-items: center;
		color: ${colors.mainBlack};
		cursor: pointer;
		p {
			margin: 0;
			font-size: 1.188rem;
			@media screen and (${devices.lg}) {
				font-size: 1rem;
			}
		}
		.icon {
			line-height: 0;
			margin-right: 16px;
		}
		&.selected {
			background-color: ${colors.borderAlmostLightGray};
			font-weight: 500;
		}
		&.disabled {
			opacity: 0.5;
			cursor: auto;
		}
	}
`;

export const NavigationWrapper = styled.div`
	width: 100%;
	@media screen and (${devices.lg}) {
		padding: 1rem;
		background-color: ${colors.buttonGray};
		height: 100%;
		min-height: 100vh;
		border-radius: ${borderRadius.m};
	}
	.mb-8 {
		margin-bottom: 4px;
	}
`;

const Styled = {
	Navigation,
	NavigationWrapper,
};

export default Styled;
