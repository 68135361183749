import styled from 'styled-components';

const TitleWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
`;

const Styled = {
	TitleWrapper,
	IconWrapper,
};

export default Styled;
