import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';

import { InfluencerModel } from 'api-models';
import Checkbox from 'components/Checkbox';
import { Heading } from 'components/Heading';
import InfluencerAvatar from 'components/InfluencerAvatar';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import { AssignmentStatus } from 'shared/Types/Assignment';
import { PALETTE } from 'styles/variables/original-colors';
import IRepository from 'utils/Repository/IRepository';

import AssignmentList from './Components/AssignmentList';
import ConfirmMessage from './Components/ConfirmMessage';
import Styled from './Summary.style';
import useSummaryData from './hooks';

/**
 * Summary
 * Component to show the summary of the selected campaign
 * Managers are able to approve/decline assignments with this component.
 * @param {SummaryProps} props
 * @returns {JSX.Element}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const Summary = ({
	selectedCIO,
	CIOAS,
	refresh,
	repository,
	hasJoined,
}: {
	selectedCIO: Model;
	CIOAS: Model[];
	refresh: () => Promise<void>;
	repository: IRepository;
	hasJoined: boolean;
}): JSX.Element => {
	const [commission, setCommission] = useState<Model | undefined>(undefined);

	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [approveWithoutCommission, setApproveWithoutCommission] = useState<boolean>(false);

	const [approveState, setApproveState] = useState<'approve' | 'disapprove'>('approve');
	const [postUrl, setPostUrl] = useState<string>('');

	const influencer = selectedCIO && (repository?.findOneByRelation(selectedCIO, 'influencer') as InfluencerModel);
	const commissionForThisCampaign = selectedCIO && repository?.findOneByRelation(selectedCIO, 'campaignInstagramOwnerCommission');

	const { approve, disapprove } = useSummaryData();

	const openModal = (type: 'approve' | 'disapprove', url: string) => {
		setModalIsOpen(!modalIsOpen);
		setApproveState(type);
		setPostUrl(url);
	};

	const resetActions = () => {
		setPostUrl('');
		setIsLoading(false);
		setModalIsOpen(false);
		setApproveState('approve');
	};

	const onClickApprove = (url: string) => openModal('approve', url);
	const onClickDisapprove = (url: string) => openModal('disapprove', url);

	const approveAssignment = (url: string) => {
		setIsLoading(true);
		approve(url)
			.then(() => refresh().finally(resetActions))
			.catch((e) => {
				toast.error(getErrorMessageOnPost('approving the assignment'));
				resetActions();
				console.error(e);
			});
	};

	const disApproveAssignment = (url: string) => {
		setIsLoading(true);
		disapprove(url)
			.then(() => refresh().finally(resetActions))
			.catch((e) => {
				toast.error(getErrorMessageOnPost('declining the assignment'));
				resetActions();
				console.error(e);
			});
	};

	const onClickSubmit = () => {
		if (approveState === 'approve') approveAssignment(postUrl);
		else if (approveState === 'disapprove') disApproveAssignment(postUrl);
	};

	useEffect(() => {
		if (selectedCIO) {
			setCommission(commissionForThisCampaign);
		}
	}, [selectedCIO]);

	// Separate assignments based on approval status
	const awaitingApprovalAssignments = CIOAS.filter((assignment) => assignment.attributes.newFancyStatus !== AssignmentStatus.APPROVED);
	const approvedAssignments = CIOAS.filter((assignment) => assignment.attributes.newFancyStatus === AssignmentStatus.APPROVED);

	return (
		<>
			<Styled.Wrapper data-testid='summary'>
				{awaitingApprovalAssignments.length > 0 && (
					<>
						<Heading as='h5'>Awaiting approval for payment</Heading>
						<AssignmentList
							assignments={awaitingApprovalAssignments}
							onClickApprove={onClickApprove}
							onClickDisapprove={onClickDisapprove}
							hasJoined={hasJoined}
						/>
					</>
				)}

				{approvedAssignments.length > 0 && (
					<>
						<Heading as='h5'>Approved for payment</Heading>
						<AssignmentList assignments={approvedAssignments} onClickApprove={onClickApprove} onClickDisapprove={onClickDisapprove} hasJoined={hasJoined} />
					</>
				)}
			</Styled.Wrapper>

			<ConfirmModal
				IconBackgroundColor={!commission ? PALETTE.channel : approveState === 'approve' ? PALETTE.aquaHaze : PALETTE.channel}
				icon={!commission ? 'alert' : approveState === 'approve' ? 'check-circle' : 'circle-cross'}
				buttonText={!commission ? 'Approve without commission' : approveState === 'approve' ? 'Yes, approve' : 'Yes, decline'}
				isModalOpen={modalIsOpen}
				toggleModal={() => setModalIsOpen(!modalIsOpen)}
				title={!commission ? 'Approve without setting a commission?' : approveState === 'approve' ? 'Approve assignment' : 'Decline assignment'}
				isFetching={isLoading}
				action={onClickSubmit}
				confirmButtonAsDanger={approveState === 'disapprove'}
				disabled={!commission && !approveWithoutCommission}
			>
				<>
					{!commission ? (
						<Styled.NoCommissionWrapper>
							<Styled.WarningText>
								This profile does not have a commission set. Approving for payment without a commission means{' '}
								<strong>this profile will not receive any payment</strong>.
							</Styled.WarningText>
							<InfluencerAvatar
								displayNetwork={true}
								profileImageUrl={influencer?.attributes?.profilePictureUrl || ''}
								userName={influencer?.attributes?.username || ''}
								displayName
							/>
							<Styled.CheckboxWrapper>
								<Checkbox
									label={'I understand that approving without a commission means this profile will not receive any payment.'}
									checked={approveWithoutCommission}
									onChange={() => setApproveWithoutCommission(!approveWithoutCommission)}
								/>
							</Styled.CheckboxWrapper>
						</Styled.NoCommissionWrapper>
					) : (
						<ConfirmMessage approveState={approveState} />
					)}
				</>
			</ConfirmModal>
		</>
	);
};

export default Summary;
