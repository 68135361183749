import styled from 'styled-components';

import colors from 'styles/theme/colors';

const InfoText = styled.p<{ backgroundColor?: string }>`
	margin-bottom: 16px;
	font-size: 1rem;
	color: ${colors.steel};
	background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
	padding: 1rem;
	border-radius: ${({ theme }) => theme.radius.default};
`;

export default {
	InfoText,
};
