import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Wrapper = styled.div`
	max-height: 350px;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
`;

const FlexDiv = styled.div<CSSProperties>`
	display: flex;
	flex-direction: ${(props) => props.flexDirection};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
	flex: ${(props) => props.flex};
	flex-wrap: ${(props) => props.flexWrap};
	row-gap: ${(props) => props.rowGap};
	max-height: ${(props) => props.maxHeight};
	text-align: ${(props) => props.textAlign};

	&.divide-line {
		position: relative;
		&:after {
			position: absolute;
			top: 2px;
			bottom: 2px;
			right: 0;
			width: 1px;

			content: ' ';
			border-right: 1px solid ${colors.discovery.border};
		}
	}

	&.influencer-info {
		margin-bottom: ${guttersWithRem.s};
		& > div {
			flex: 1;
			text-align: center;
		}
	}

	&.categories-wrapper {
		max-height: 80px;
		flex-wrap: wrap;
		row-gap: 0.2rem;
	}

	&.light-gray-bg {
		background-color: #f2f2f2;
	}
`;

const Div = styled.div<CSSProperties>`
	margin-bottom: ${(props) => props.marginBottom};

	&.light-gray-bg {
		background-color: #f2f2f2;
	}
	&.divide-line {
		position: relative;
		&:after {
			position: absolute;
			top: 2px;
			bottom: 2px;
			right: 0;
			width: 1px;

			content: ' ';
			border-right: 1px solid ${colors.discovery.border};
		}
	}
`;

const DataText = styled.span`
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5;
	color: ${colors.mainBlack};
	margin-top: 5px;
`;

const LabelText = styled.span`
	font-size: 0.875rem;
	color: ${colors.mainBlack};
	text-align: center;
	line-height: 1;
`;

const InfluencerData = styled.div`
	&.value {
		font-family: ${typography.BaseFontFamiliy};
		font-weight: 600;

		&::first-letter {
			text-transform: capitalize;
		}
	}

	&.label {
		font-family: ${typography.label.fontFamily};
		font-size: ${typography.label.small.fontSize};
		color: ${colors.labelColor};
	}
`;

const Item = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 24px;
	margin-bottom: 8px;
	div {
		display: flex;
		flex-direction: column;
	}
`;

const IconContainer = styled.div`
	background-color: ${newColors.lightMist};
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Styled = {
	IconContainer,
	Item,
	Wrapper,
	InnerWrapper,
	CustomAvatar,
	FlexDiv,
	Div,
	InfluencerData,
	DataText,
	LabelText,
};

export default Styled;
