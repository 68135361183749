import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const CropperToolbarWrapper = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	z-index: 1;

	border-radius: ${borderRadius.m};

	width: fit-content;

	& .cropper-blurred-background {
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translate(-50%, 0);
		backdrop-filter: blur(3px);
		width: 100%;
		border-radius: ${borderRadius.m};
	}

	& .cropper-toolbar {
		padding: 0.5rem 1.5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		gap: 24px;

		background-color: rgb(51, 51, 51);
		box-shadow: 0 2px 3px rgba(51, 51, 51, 0.5);
		border-radius: ${borderRadius.m};
		opacity: 0.8;

		& .divider {
			content: '';
			display: inline-block;
			width: 1px;
			height: 30px;
			background-color: ${colors.mainBlack};
		}

		& p {
			color: ${colors.white};
			margin: 0;
		}
		& button > i > svg path {
			fill: ${colors.white};
		}

		& button {
			background-color: rgb(51, 51, 51);
			padding: 0;
			color: ${colors.white};
			font-size: 1rem;
			margin-top: 2px;
			&.save-crop {
				color: ${colors.skyBlue};
				width: fit-content;
			}
		}
	}
`;

const Styled = {
	CropperToolbarWrapper,
};

export default Styled;
