import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	height: calc(100vh - 2rem);
	background-color: ${colors.snow};
	padding: 1rem;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
`;

const ImageWrapper = styled.div`
	width: 50%;
	height: calc(100vh - 2rem);
	background-color: ${colors.snow};
	border-radius: ${borderRadius.m};
	display: none;
	position: relative;
	img {
		border-radius: ${borderRadius.m};
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media screen and (${devices.md}) {
		display: flex;
	}
`;

const ContentWrapper = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 2rem);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${colors.snow};
	border-top-right-radius: ${borderRadius.m};
	border-bottom-right-radius: ${borderRadius.m};
	padding: 1rem;
	padding-top: 2rem;
	img {
		margin-bottom: 40px;
	}
	@media screen and (${devices.md}) {
		padding: 4rem;
		width: 50%;
	}
	@media screen and (${devices.lg}) {
		padding: 6rem 6rem 0 6rem;
		width: 50%;
	}
`;

const Name = styled.div`
	font-size: 0.875rem;
	color: ${colors.white};
	font-weight: 600;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1rem;
`;

export default {
	Wrapper,
	ImageWrapper,
	ContentWrapper,
	Name,
};
