import styled from 'styled-components';

import { MidColContainer } from 'styles/layout/three-col';
import colors from 'styles/theme/colors';
import { devices } from 'styles/variables/media-queries';

const Wrapper = styled(MidColContainer)`
	background-color: ${colors.snow};
	flex: 1;
	position: relative;
	height: 100%;
	width: calc(100% - 0.5rem);
`;

const Content = styled.div`
	display: flex;
	height: 75vh;
	flex-direction: column;
	padding: 0.5rem;
	@media screen and (${devices.lg}) {
		padding: 1rem;
	}
	& > div {
		&.input-wrapper {
			align-self: flex-end;
		}
	}
`;

const ChatInputWrapper = styled.div`
	position: absolute;
	bottom: 60px;
	right: 0;
	width: 100%;
`;

const Styled = {
	Wrapper,
	Content,
	ChatInputWrapper,
};

export default Styled;
