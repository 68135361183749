import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import { devices } from 'styles/variables/media-queries';
import { PALETTE } from 'styles/variables/original-colors';

const SummaryWrapper = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	margin: 0 auto;
	display: block;
	background-color: ${newColors.white};
	padding: 1rem;
	border-top-left-radius: ${borderRadius.m};
	border-top-right-radius: ${borderRadius.m};
	box-shadow: 0 -2px 4px rgba(51, 51, 51, 0.1);
	z-index: 20;

	&.collapse {
		height: 0;
		overflow: hidden;
	}
	@media screen and (${devices.lg}) {
		display: none;
		overflow: hidden;
	}
`;

const Wrapper = styled.div`
	background-color: ${PALETTE.concret};
	display: flex;
	gap: 8px;

	button {
		width: 100%;
	}
`;

const CampaignSummary = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};
	color: ${colors.mainBlack};

	& div > i {
		display: flex;
		line-height: 0;
		& > svg {
			& > path {
				fill: ${colors.mainBlack};
			}
		}
	}

	&.detail-open {
		width: 100%;
		flex-direction: column;
		row-gap: ${guttersWithRem.xl};
	}
`;

const Buttons = styled.div`
	display: flex;
	column-gap: ${guttersWithRem.m};

	&.mobile-view {
		flex-direction: column;
		row-gap: ${guttersWithRem.m};
		margin-bottom: ${guttersWithRem.m};
	}
`;

const DetailButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const PublisherLogotype = styled.div`
	margin-bottom: 8px;

	img {
		max-width: 150px;
	}

	&.logo--small {
		padding-left: 1.75rem;

		img {
			max-width: 100px;
		}
	}
`;

const CampaginName = styled.div`
	margin-bottom: 16px;
	font-weight: ${({ theme }) => theme.fontWeights.semi};
`;

const Styled = {
	Wrapper,
	CampaignSummary,
	Buttons,
	DetailButtonWrapper,
	PublisherLogotype,
	SummaryWrapper,
	CampaginName,
};

export default Styled;
