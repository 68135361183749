import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HeaderDropdown from 'components/Header/Components/HeaderDropdown';
import Icon from 'components/Icon';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { getInitials } from 'shared/User/User.helpers';

import Styled from './UserMenu.style';
/**
 * User Menu
 * @returns {JSX.Element}
 */
const UserMenu = (): JSX.Element => {
	const userDispatch = useAppDispatch();
	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;

	const navigate = useNavigate();
	const logoutService = useInjection<LogoutService>(LogoutService);

	const logOut = async () => {
		await logoutService.logout(userDispatch);
		navigate('/login');
	};

	return (
		<>
			<HeaderDropdown
				isUserMenu
				actionButtonIcon={<span data-testid='initials'>{getInitials(user.name || '')}</span>}
				userDropdownOpen={userDropdownOpen}
				setUserDropdownOpen={setUserDropdownOpen}
			>
				<>
					<Styled.AccountMenu>
						<div data-testid='profiles-menu'>
							<Styled.UserAvatarWrapper>
								<UserAvatar fullName={user.name} />
							</Styled.UserAvatarWrapper>
						</div>
					</Styled.AccountMenu>

					<Styled.ActionDropDownLinks>
						{isInfluencer && (
							<Styled.ActionDropdownMenuItem id='add-profile'>
								<Styled.MenuLink data-testid='add-social-account' to='/influencer/settings/instagram' onClick={() => setUserDropdownOpen(false)}>
									<Icon name='add-account' size='20' /> <span>Add social account</span>
								</Styled.MenuLink>
							</Styled.ActionDropdownMenuItem>
						)}
						<Styled.ActionDropdownMenuItem>
							<Styled.MenuLink
								data-testid='account-settings-link'
								to={isInfluencer ? '/influencer/settings/account' : '/settings/account'}
								onClick={() => setUserDropdownOpen(false)}
							>
								<Icon name='settings' size='20' /> <span>Go to settings</span>
							</Styled.MenuLink>
						</Styled.ActionDropdownMenuItem>

						<Styled.ActionDropdownMenuItem noBorder>
							<Styled.MenuLink data-testid='sign-out-link' to='' onClick={() => logOut()}>
								<span>Sign out</span>
							</Styled.MenuLink>
						</Styled.ActionDropdownMenuItem>
					</Styled.ActionDropDownLinks>
				</>
			</HeaderDropdown>
		</>
	);
};

export default UserMenu;
