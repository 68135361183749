import styled from 'styled-components';

const SkeletonLoaderList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 24px;
`;

export default {
	SkeletonLoaderList,
};
