import { inject, injectable } from 'inversify';

import { BrandModel } from 'api-models';
import { CreateBrandInvitePayload, CreateBrandPayload, UpdateBrandLogoPayload, UpdateBrandPayload, UpdateBrandPrivilegePayload } from 'api-payloads';
import { CreateBrandQuery, ListBrandPrivilegesQuery, ListBrandsQuery, UpdateBrandLogoQuery, UpdateBrandNewQuery, UpdateBrandPrivilegeQuery } from 'api-queries';
import { BrandCollectionResponse, BrandPrivilegeResponse } from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig } from './types';

import type BrandApiClientInterface from 'services/ApiClient/BrandApiClientInterface';

type Repository = ModelRepository<BrandModel>;

@injectable()
class BrandManager extends ApiManager<Repository> {
	private readonly client: BrandApiClientInterface;

	constructor(@inject(ApiCacheManager) cacheManager: ApiCacheManager, @inject(ApiClientService.BrandApiClientInterface) client: BrandApiClientInterface) {
		super(cacheManager, new ModelRepository<BrandModel>('brand'));
		this.client = client;
	}

	public listBrands(queryBuilder = RequestQueryBuilder.create<ListBrandsQuery>()) {
		const key = `listBrands::${queryBuilder.toHash()}`;

		const fetcher = () => this.client.listBrands(queryBuilder.toQuery());

		return this.swr<BrandCollectionResponse>(key, fetcher);
	}

	public create(
		payload: CreateBrandPayload,
		queryBuilder = RequestQueryBuilder.create<CreateBrandQuery>(),
		config: CreateConfig<BrandModel> = {},
	): Promise<BrandModel> {
		return this.createModel<BrandModel>(this.client.create(payload, queryBuilder.toQuery()), config);
	}

	public update(
		id: string,
		payload: UpdateBrandPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateBrandNewQuery>(),
		config: UpdateConfig<BrandModel> = {},
	): Promise<BrandModel> {
		return this.updateModel<BrandModel>(this.client.updateNew(id, payload, queryBuilder.toQuery()), config);
	}

	public delete(id: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.deleteNew(id), 'brand', id, config);
	}

	public deleteUser(brandId: string, userId: string): Promise<void> {
		return this.client.deletePrivilege(brandId, userId);
	}

	public deleteInvite(brandId: string, inviteId: string): Promise<void> {
		return this.client.deleteInvite(inviteId).then(() => {
			this.refetch('brand', brandId);
		});
	}

	public listUsersAndInvitations(id: string) {
		const key = `listUsersAndInvitations::${id}`;
		const fetcher = () => {
			return Promise.all([
				this.client.listInvites(id, { include: ':hateoas(false)' }),
				this.client.listPrivileges(id, RequestQueryBuilder.create<ListBrandPrivilegesQuery>(['createInvitation']).withInclude('user').toQuery()),
			]);
		};

		return this.swr(key, fetcher, { multipleApiResponses: true });
	}

	public inviteUsers(brandId: string, payload: CreateBrandInvitePayload): Promise<void> {
		return this.client.createInviteNew(brandId, payload, { include: ':hateoas(false)' }).then(() => {
			this.refetch('brand', brandId);
		});
	}

	public updateRole(
		brandId: string,
		userId: string,
		payload: UpdateBrandPrivilegePayload,
		queryBuilder = RequestQueryBuilder.create<UpdateBrandPrivilegeQuery>(),
	): Promise<BrandPrivilegeResponse> {
		return this.client.updatePrivilege(brandId, userId, payload, queryBuilder.toQuery());
	}

	public uploadLogo(brandId: string, payload: UpdateBrandLogoPayload): Promise<string | undefined> {
		const qb = RequestQueryBuilder.create<UpdateBrandLogoQuery>(['logo']);

		return this.client.updateLogo(brandId, payload, qb.toQuery()).then((response) => {
			this.refetch('brand', brandId);

			return response.data.links?.logo;
		});
	}

	public deleteLogo(brandId: string): Promise<void> {
		return this.client.deleteLogo(brandId);
	}
}

export default BrandManager;
