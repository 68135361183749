import { JsonApiDocument, Model, Store } from 'json-api-models';
import { isEmpty } from 'lodash';

import { Deadline } from 'components/NewBriefPage/types';
import { ICollabsResponse } from 'services/Response.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProperDataFromResponse = (res: ICollabsResponse) => {
	const models = new Store();
	const data = models.sync(res as JsonApiDocument);
	const campaign = models.findAll('campaign')[0];
	const commissions = data.campaignInstagramOwner.campaignInstagramOwnerCommissions ?? [];
	const pdfFiles = models.findAll('userUpload');
	const moodBoardImages = models.findAll('campaignImage');
	const segment = models.findAll('segment')[0];

	const newCampaign = {
		id: data.campaignInstagramOwner.campaign.id,
		pdfFiles: !isEmpty(pdfFiles) ? pdfFiles : undefined,
		moodBoardImages: moodBoardImages,
		links: campaign.links,
		...data.campaignInstagramOwner.campaign.attributes,
	};

	const newCampaignInstagramOwner = {
		joined: data.campaignInstagramOwner.joined,
		declined: data.campaignInstagramOwner.attributes.declined,
		campaign: {
			assignments: data.campaignInstagramOwner.campaignInstagramOwnerAssignments
				? data.campaignInstagramOwner.campaignInstagramOwnerAssignments.map((campaignInstagramOwnerAssignment: Model) => {
						const assignment = campaignInstagramOwnerAssignment.assignment;

						return {
							id: assignment.id,
							type: assignment.kind,
							name: assignment.name,
							dos: assignment.dos,
							donts: assignment.donts,
							cta: assignment.cta,
							description: assignment.description,
							details: assignment.details,
							startTime: campaignInstagramOwnerAssignment.assignmentGroup.start,
							endTime: campaignInstagramOwnerAssignment.assignmentGroup.end,
							deadlines: campaignInstagramOwnerAssignment.assignmentGroup.deadlines.map((deadline: Deadline) => {
								return {
									id: deadline.id,
									name: deadline.name,
									date: deadline.date,
								};
							}),
						};
					})
				: [],
			assignmentsCount: data.campaignInstagramOwner.campaignInstagramOwnerAssignments.length,
			campaignImages: {
				moodBoardImages: moodBoardImages,
				coverImage: data.campaignInstagramOwner.campaign?.links?.horizontalCoverPhoto ?? '',
				croppedImages: {
					original: data.campaignInstagramOwner.campaign?.links?.originalCoverPhoto ?? '',
					large: data.campaignInstagramOwner.campaign?.links?.largeCoverPhoto ?? '',
					medium: data.campaignInstagramOwner.campaign?.links?.mediumCoverPhoto ?? '',
					small: data.campaignInstagramOwner.campaign?.links?.smallCoverPhoto ?? '',
				},
			},
			compensationsTotalValue: [
				...(data.campaignInstagramOwner?.campaignInstagramOwnerProducts?.map((product: Model) => +product.product.value?.amount) ?? []),
				...commissions.map((commission: Model) => commission.commission.fixedAmount),
			].reduce((prev, current) => prev + current, 0),
			currency: commissions.length > 0 ? commissions[0].commission.fixedAmountCurrency : '',
			details: campaign.details,
			invitesCloseAt: data.campaignInstagramOwner.campaign.inviteClosedAt,
			invoices: commissions.map((commission: Model) => {
				return {
					id: commission.id,
					invoice: {
						value: commission.commission.fixedAmount,
						currency: commission.commission.fixedAmountCurrency,
					},
				};
			}),
			products: data.campaignInstagramOwner.campaignInstagramOwnerProducts
				? data.campaignInstagramOwner.campaignInstagramOwnerProducts.map((product: Model) => {
						return {
							id: product.id,
							quantity: 1,
							product: {
								productImage: product.product.links.image,
								name: product.product.name,
								link: product.product.link ? product.product.link : null,
								description: product.product.description,
								value: {
									amount: +product.product.value?.amount,
									currency: product.product.value?.currency,
								},
							},
						};
					})
				: [],
			spotsLeftOnSegment: segment.attributes.availableSeats,
			campaignInstagramOwnerAssignments: [],
			id: campaign.id,
			shortId: campaign.shortId,
		},
		influencer: {
			id: data.influencer.id,
			username: data.influencer.username,
			hasUser: data.influencer.hasUser,
		},
		publisher: models.findAll('publisher')[0],
		brand: models.findAll('brand')[0],
		invite: data,
		links: data.campaignInstagramOwner.links,
	};

	return { campaign: newCampaign, campaignInstagramOwner: newCampaignInstagramOwner };
};
