import { useCallback, useEffect, useState } from 'react';

import { BrandModel, ClientModel, PublisherModel } from 'api-models';
import CustomDropZone from 'components/Form/Elements/CustomDropZone/CustomDropZone';
import { DELETE_LOGO } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';

type Props = {
	uploadFn: (file: File) => void;
	deleteImageFn: () => void;
	item: PublisherModel | BrandModel | ClientModel;
	canEdit: boolean;
};

/**
 * @returns {JSX.Element}
 */
const LogoUpload = ({ uploadFn, deleteImageFn, item, canEdit }: Props): JSX.Element => {
	const [imageUrl, setImageUrl] = useState<string | undefined>(item.links?.logo);

	const { userCan } = useFeaturePermissions(item.links);

	const handleDelete = () => {
		try {
			deleteImageFn();
			setImageUrl(undefined);
		} catch (e) {
			console.error(e);
			toast.error('Failed to delete logo');
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (!uploadFn || !acceptedFiles.length) return;
			try {
				uploadFn(acceptedFiles[0]);
			} catch (e: unknown) {
				console.error(e);
				toast.error(getErrorMessageOnPost('uploading the logo'));
			}
		},
		[uploadFn],
	);

	useEffect(() => {
		setImageUrl(item.links?.logo ?? undefined);
	}, [item.links?.logo]);

	return (
		<CustomDropZone
			deleteImage={handleDelete}
			remove={() => setImageUrl(undefined)}
			handleOnDrop={onDrop}
			imageUrl={imageUrl}
			title='Drop your logo here'
			isOnSettings
			canEdit={canEdit}
			canDelete={userCan(DELETE_LOGO)}
		/>
	);
};

export default LogoUpload;
