import styled, { DefaultThemeV2 } from 'styled-components';

import Accordion from 'components/Accordion';
import { PrimaryButton } from 'components/Button';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import boxShadow from 'styles/variables/box-shadows';
import typography from 'styles/variables/typography';

type P = { theme: DefaultThemeV2 };

const Wrapper = styled.div`
	width: 100%;
	overflow-x: auto;
	min-height: 500px;
	padding-bottom: 5rem;
`;

const EmptyResultImgWrapper = styled.div`
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	background-color: ${colors.mist};
	padding: 3rem;
	border-radius: ${borderRadius.m};
	p strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const FetchLaterPhrase = styled.div`
	text-align: center;
	font-size: ${typography.headings.h5.fontSize};
	font-weight: ${typography.headings.h5.fontWeight};
`;

const ResultAccordion = styled(Accordion)`
	margin-top: 64px;

	[data-testid='accordion-header'] {
		width: max-content;

		span {
			margin-top: 0;
			padding-right: 1rem;
		}
	}
`;

const ResultWrapper = styled.div`
	width: 100%;
	background-color: ${(props: P) => props.theme.colors.successBackground};
	padding: 1rem;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	border-radius: ${(props: P) => props.theme.radius.small};

	ul li {
		list-style-type: disc;
		margin-left: 1rem;
		margin-bottom: 8px;
	}
`;

const CTAButton = styled(PrimaryButton)`
	margin: 0 auto;
	height: 36px;
	max-width: 330px;
	font-size: 0.9375rem;
`;

const OptionButton = styled.h5`
	color: ${colors.ash};
	font-weight: ${({ theme }) => theme.fontWeights.medium};
	font-size: 1rem;
	border-radius: ${({ theme }) => theme.radius.small};
	padding: 0.5rem 1rem;
	margin-bottom: 0 !important;
`;
const FyzzyOption = styled.div`
	border-radius: ${borderRadius.m};
	height: 187px;
	min-width: 170px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	gap: 16px;
	padding: 1rem;
	transition: all ease-in-out 0.2s;
	background-color: ${colors.mist};
	box-shadow: ${boxShadow.small};
	span {
		max-width: 160px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
	button {
		min-height: 35px !important;
		height: 35px;
	}
	&:hover {
		${OptionButton} {
			background-color: ${colors.smoke};
			color: ${colors.white};
		}
	}
`;

const FuzzyContainer = styled.div`
	width: 100%;
	justify-content: start;
	display: flex;
	gap: 16px;
	overflow-x: auto;
`;

const Network = styled.span`
	text-transform: capitalize;
`;

const FuzzyWrapper = styled.div`
	text-align: center;
	width: 70%;
	margin: auto;
	margin-top: 80px;
	overflow-x: auto;
	padding: 0 1rem;
	margin-bottom: 80px;
	h5 {
		color: ${colors.slate};
		margin-bottom: 40px;
	}
`;

const Styled = {
	Wrapper,
	EmptyResultImgWrapper,
	FetchLaterPhrase,
	ResultAccordion,
	ResultWrapper,
	CTAButton,
	FyzzyOption,
	FuzzyContainer,
	Network,
	FuzzyWrapper,
	OptionButton,
};

export default Styled;
