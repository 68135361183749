import { Form, Formik } from 'formik';
import { Model } from 'json-api-models';
import { useState } from 'react';
import { toast } from 'sonner';
import styled from 'styled-components';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import CountrySelector from 'components/Form/Elements/CountrySelector/CountrySelector';
import { InputText } from 'components/Form/FormikElements';
import { Heading } from 'components/Heading';
import { createClient } from 'shared/ApiClient/ApiClient';
import { isVatRequired } from 'utils/countries_utils';
import errorHandler from 'utils/formik_error_handler';

interface BillingInformationProps {
	publisherEdit: string | undefined;
	onSubmit(): void;
	onDashboard?: boolean;
	publisher?: Model;
}

/**
 */
const StyledForm = styled(Form)`
	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	legend {
		margin-bottom: 8px;
	}
`;

const SubmitButton = styled(PrimaryButton)`
	float: right;
	display: flex;
	margin-top: 16px;
`;

const VisuallyHidden = styled.span`
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
`;

const BillingInformation = ({ publisherEdit, onSubmit, onDashboard, publisher }: BillingInformationProps) => {
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);

	const validationSchema = Yup.object().shape({
		invoiceEmail: Yup.string().email('Billing email must be a valid email address').required('Please provide an email address for the invoice recipient.'),
		invoiceCountryCode: Yup.string().required('Required'),
		vatNumber: Yup.string().when('invoiceCountryCode', {
			is: (countryCode: string) => isVatRequired(countryCode),
			then: () => Yup.string().required('Please enter a valid VAT number for your business.'),
		}),
	});

	return (
		<Formik
			onSubmit={async (values, { setErrors }) => {
				setFormError(null);
				if (values.invoiceCountryCode === 'Select Country') {
					setFormError('Please select a country');
					return toast.error('Please select a country');
				}
				try {
					if (publisherEdit) {
						await createClient()
							.patch(publisherEdit, values)
							.then(() => {
								onSubmit();
							})
							.then(() => {
								toast.success('You are up and running');
							});
					}
				} catch (e) {
					const errorMessage = e.response?.data?.errors?.[0]?.detail || 'Error updating billing information';
					setFormError(errorMessage);
					toast.error(errorMessage);
					errorHandler(e, setErrors);
				}
			}}
			validationSchema={validationSchema}
			validateOnBlur={validateAfterSubmit}
			validateOnChange={validateAfterSubmit}
			initialValues={{
				invoiceEmail: publisher?.attributes?.invoiceEmail || '',
				invoiceCountryCode: publisher?.attributes?.invoiceCountryCode || 'SWE',
				vatNumber: publisher?.attributes?.vatNumber || '',
			}}
		>
			{({ isSubmitting, errors }) => (
				<StyledForm aria-label='Billing Information Form' aria-describedby='form-description'>
					<VisuallyHidden id='form-description'>
						Please provide your company billing information including email, country, and VAT number if applicable.
					</VisuallyHidden>

					{formError && (
						<VisuallyHidden role='alert' aria-live='polite'>
							Form submission error: {formError}
						</VisuallyHidden>
					)}

					<fieldset>
						<legend>
							<Heading as='h5' id='company-info-heading'>
								Company information
							</Heading>
						</legend>
						<div role='group' aria-labelledby='company-info-heading'>
							<InputText
								label='Billing email'
								name='invoiceEmail'
								type='email'
								required
								placeholder='Used for all billings'
								aria-describedby='email-hint'
								aria-invalid={!!errors.invoiceEmail}
							/>
							<CountrySelector name='invoiceCountryCode' aria-label='Select your country' required aria-invalid={!!errors.invoiceCountryCode} />
							<InputText
								label='VAT number'
								name='vatNumber'
								required
								placeholder='SE112233445501'
								aria-describedby='vat-hint'
								aria-invalid={!!errors.vatNumber}
							/>
						</div>
					</fieldset>

					<SubmitButton
						isLoading={isSubmitting}
						type='submit'
						data-testid='save-button'
						disabled={isSubmitting}
						onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}
						aria-label={isSubmitting ? 'Submitting form...' : 'Submit billing information'}
					>
						{onDashboard ? 'Save' : 'Get started'}
					</SubmitButton>
				</StyledForm>
			)}
		</Formik>
	);
};

export default BillingInformation;
