import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InfluencerListFolderModel } from 'api-models';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import DeleteFolder from 'components/InfluencerManagement/Folders/Delete/DeleteFolder';
import UpdateFolder from 'components/InfluencerManagement/Folders/Update/UpdateFolder';
import { ListFoldersProps } from 'components/InfluencerManagement/Folders/types';
import Styled from 'components/InfluencerManagement/Lists/Lists.style';
import { FileType, Filter } from 'components/InfluencerManagement/types';
import TableComponent, { Column, GenericData } from 'components/Table/V2/Table';
import Tooltip from 'components/Tooltip';
import { DELETE_LIST_FOLDER, SHARE_LIST_FOLDER } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { pathInfluencerManagementFolder } from 'routing/PathLookup';

type Row<T> = {
	original: T;
};

/**
 * List folders component
 * @param FolderListProps
 * @returns {JSX.Element}
 */
const ListFolders = ({
	folders,
	setSelectedTab,
	setSelectedFilter,
	setSidebarIsOpen,
	setFolderToShare,
	folderToEdit,
	setFolderToEdit,
	setListToShare,
}: ListFoldersProps): JSX.Element => {
	const [itemToDelete, setItemToDelete] = useState<InfluencerListFolderModel | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const columns: Column<InfluencerListFolderModel>[] = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'attributes',
				Cell: ({ row }) => {
					const getIcon = () => {
						if (row.original.attributes.shared && !row.original.attributes.createdByMe) {
							return 'folder-shared-with-me';
						} else if (row.original.attributes.shared && row.original.attributes.createdByMe) {
							return 'folder-shared';
						} else {
							return 'folder';
						}
					};
					const getTooltipText = () => {
						if (row.original.attributes.shared && !row.original.attributes.createdByMe) {
							return 'Folder is shared with me';
						} else if (row.original.attributes.shared && row.original.attributes.createdByMe) {
							return 'Folder shared with others';
						} else {
							return 'Private folder';
						}
					};
					return (
						<Styled.Item className={classNames({ canEdit: row.original.links?.delete })}>
							<Tooltip content={getTooltipText()}>
								<Styled.IconContainer>
									<Icon name={getIcon()} />
								</Styled.IconContainer>
							</Tooltip>
							{row.original.id === folderToEdit?.id ? (
								<Styled.InputWrapper>
									<UpdateFolder
										forwardRef={InputRef}
										onSaved={() => {
											setFolderToEdit(null);
										}}
										item={folderToEdit}
									/>
								</Styled.InputWrapper>
							) : (
								<Styled.Name>{row.original.attributes.name}</Styled.Name>
							)}
						</Styled.Item>
					);
				},
				sortType: (rowA: Row<InfluencerListFolderModel>, rowB: Row<InfluencerListFolderModel>) => {
					return rowA.original.attributes.name.localeCompare(rowB.original.attributes.name, undefined, { sensitivity: 'base', ignorePunctuation: true });
				},
			},
			{
				Header: '',
				accessor: 'id',
				disableSortBy: true,
				Cell: ({ row }) => {
					const { userCan } = useFeaturePermissions(row.original.links);
					return (
						<Styled.Icons>
							{row.original.attributes.createdByMe ? (
								<Styled.Options>
									<Dropdown icon='options' size='16'>
										<DropdownMenu>
											{row.original.attributes.createdByMe && (
												<DropdownItem
													onClick={(e) => {
														e.stopPropagation(), setFolderToEdit(row.original);
													}}
												>
													Edit
												</DropdownItem>
											)}
											{userCan(SHARE_LIST_FOLDER) && (
												<DropdownItem
													onClick={(e) => {
														e.stopPropagation(), setSidebarIsOpen(true), setFolderToShare(row.original), setListToShare(null);
													}}
												>
													Share
												</DropdownItem>
											)}
											{userCan(DELETE_LIST_FOLDER) && row.original.links?.delete && (
												<DropdownItem
													onClick={(e) => {
														e.stopPropagation(), setItemToDelete(row.original), setIsDeleteModalOpen(true);
													}}
												>
													Delete
												</DropdownItem>
											)}
										</DropdownMenu>
									</Dropdown>
								</Styled.Options>
							) : (
								<Styled.EmptyDiv />
							)}
							<Styled.ArrowContainer>
								<Icon name='arrow-right' size='12' />
							</Styled.ArrowContainer>
						</Styled.Icons>
					);
				},
				width: 30,
				maxWidth: 30,
			},
		],
		[folderToEdit],
	);

	const InputRef = useRef<HTMLInputElement | null>(null);

	const handleClickOutside = (e: MouseEvent): void => {
		if (InputRef.current && !InputRef.current.contains(e.target as Node)) {
			setFolderToEdit(null);
		}
	};

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (InputRef && InputRef.current && e.key === 'Escape') {
			setFolderToEdit(null);
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			window.removeEventListener('click', handleClickOutside, true);
			document.addEventListener('keyup', handleEscapeKey, true);
		};
	}, []);

	const navigate = useNavigate();

	const handleSelectedRow = (values: GenericData) => {
		setSelectedTab(FileType.ALL);
		setSelectedFilter(Filter.ALL);
		navigate(pathInfluencerManagementFolder(values.id as string));
	};

	return folders.length > 0 ? (
		<div>
			<h3>Folders</h3>
			<TableComponent
				dataTestId='folders-table'
				className='influencer-management-table'
				columns={columns}
				data={folders}
				enableSorting={true}
				getSelectedRow={(selectedRow) => handleSelectedRow(selectedRow)}
			/>
			<DeleteFolder itemToDelete={itemToDelete} setIsDeleteModalOpen={setIsDeleteModalOpen} isDeleteModalOpen={isDeleteModalOpen} />
		</div>
	) : (
		<></>
	);
};

export default ListFolders;
