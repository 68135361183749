import { Form, Formik } from 'formik';
import { PropsWithChildren } from 'react';
import * as Yup from 'yup';

import { DropdownAndInput } from 'components/Form/FormikElements';
import Styled from 'components/Settings/Settings.style';
import { OrganizationHierarchyRole } from 'components/Settings/types';
import toast from 'services/Toast';
import errorHandler from 'utils/formik_error_handler';

import { InviteTeamMembersProps } from './types';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const translateRole = (role: OrganizationHierarchyRole) => {
	switch (role) {
		case OrganizationHierarchyRole.ADMINISTRATOR:
			return 'Manager';
		case OrganizationHierarchyRole.CAMPAIGN_MANAGER:
			return 'Editor';
		case OrganizationHierarchyRole.BRAND_MANAGER:
			return 'Viewer';
		default:
			return '';
	}
};

const InviteTeamMembers = ({ inviteFn, formRef, availableRoles, keepDrawerOpen }: PropsWithChildren<InviteTeamMembersProps>) => {
	const displayText = (role: OrganizationHierarchyRole) => {
		switch (role) {
			case OrganizationHierarchyRole.ADMINISTRATOR:
				return (
					<div>
						<p>The manager can:</p>
						<ul>
							<li>Create and edit campaigns</li>
							<li>Invite to campaigns</li>
							<li>Manage influencer commissions</li>
							<li>Approve assignments</li>
							<li>Manage Data Library</li>
							<li>Invite managers</li>
						</ul>
						<p>
							<span>Note: A manager needs to be added to a campaign to have editing access.</span>
						</p>
					</div>
				);
			case OrganizationHierarchyRole.CAMPAIGN_MANAGER:
				return (
					<div>
						<p>The editor can:</p>
						<ul>
							<li>Create and edit campaigns</li>
							<li>Invite to campaigns</li>
							<li>Manage influencer commissions</li>
							<li>Approve assignments</li>
							<li>Manage Data library</li>
						</ul>
						<p>
							<span>Note: An editor needs to be added to a campaign to have editing access.</span>
						</p>
					</div>
				);
			case OrganizationHierarchyRole.BRAND_MANAGER:
				return (
					<div>
						<p>The viewer can:</p>
						<ul>
							<li>View assigned campaigns</li>
							<li>Approve and comment on profile assignments</li>
							<li>Download profile content</li>
						</ul>
					</div>
				);
			default:
				return '';
		}
	};

	const getOptions = () => {
		return availableRoles
			? availableRoles.filter((role) => role !== OrganizationHierarchyRole.DATA_ANALYST).map((role) => ({ value: role, label: translateRole(role) }))
			: [];
	};
	return (
		<>
			<Formik
				innerRef={formRef}
				initialValues={{ emails: '', role: OrganizationHierarchyRole.BRAND_MANAGER }}
				validateOnChange
				onSubmit={(values, { setErrors, resetForm }) => {
					const emails = values.emails
						.split(',')
						.map((email) => email.trim())
						.filter((email) => EMAIL_REGEX.test(email));

					if (0 === emails.length) {
						setErrors({ emails: 'No valid e-mail addresses were found.' });

						return;
					}
					inviteFn(emails, values.role)
						.then(() => {
							toast.success(`Member${emails.length > 1 ? 's' : ''} added`);
							resetForm();
						})
						.catch((e) => {
							errorHandler(e, setErrors);
							keepDrawerOpen();
							toast.error(`Unable to add member${emails.length > 1 ? 's' : ''}`);
						});
				}}
				validationSchema={Yup.object({
					emails: Yup.array()
						.transform(function (value, originalValue) {
							if (this.isType(value) && value !== null) {
								return value;
							}
							return originalValue ? originalValue.split(/[\s,]+/) : [];
						})
						.of(Yup.string().email(({ value }) => `${value} is not a valid email. `)),
				})}
			>
				{({ values }) => (
					<Form>
						<Styled.CreateWrapper>
							<DropdownAndInput
								label='Add member'
								inputType='email'
								options={getOptions()}
								size=''
								selectName='role'
								selectId='role'
								selectValue={translateRole(values.role)}
								name='emails'
								id='emails'
								value={values.emails}
								placeholder='Enter emails'
								helpText='Enter emails, separated by a comma'
							/>
						</Styled.CreateWrapper>
						<Styled.InfoText>
							{/* <Icon name='info-circle' size='16' /> */}
							{displayText(values.role)}
						</Styled.InfoText>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default InviteTeamMembers;
