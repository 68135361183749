import { JsonApiDocument, Store } from 'json-api-models';
import { useEffect, useState } from 'react';

import { convertDateFormat } from 'components/Discovery/Components/InfluencerDetailCardDiscovery/types';
import SkeletonCard from 'components/Discovery/Components/InfluencerListItem/Components/SkeletonCard/SkeletonCard';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import InfluencerAvatar from 'components/InfluencerAvatar';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { ICollabsResponse } from 'services/Response.types';

import Styled from './WhyDoIGetThisResult.style';

type Post = {
	date: string;
	id: string;
	score: number;
	url: string;
	image: string;
};

type WhyDoIGetThisResultProps = {
	isLoading: boolean;
	reasons: ICollabsResponse | null;
	influencerData: InfluencerListItemType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
};

const WhyDoIGetThisResult = ({ isLoading, reasons, influencerData, data }: WhyDoIGetThisResultProps) => {
	const [posts, setPosts] = useState<Post[]>([]);
	const [hoveredPostId, setHoveredPostId] = useState<string | null>(null); // Track hovered post
	const reasonStore = new Store();

	useEffect(() => {
		if (reasons) {
			reasonStore.sync(reasons as JsonApiDocument);
			const explainData = reasonStore.findAll('explainResult').length > 0 ? reasonStore.findAll('explainResult')[0] : null;
			if (explainData !== null) {
				setPosts(explainData.posts);
			}
		}
	}, [reasons]);

	const renderSkeletonCards = (count: number) => {
		return Array.from({ length: count }).map((_, index) => <SkeletonCard key={index} />);
	};

	return (
		<Styled.ContentWrapper>
			<Heading as='h5'>Why do I get this result?</Heading>
			<Styled.Reason>
				<Styled.PostItemWrapper>
					{isLoading
						? renderSkeletonCards(6)
						: posts.length > 0
							? posts.map((post) => {
									const isHovered = hoveredPostId === post.id;
									return (
										<Styled.PostItem
											key={post.id}
											href={post.url}
											target='_blank'
											onMouseEnter={() => setHoveredPostId(post.id)} // Set hover state
											onMouseLeave={() => setHoveredPostId(null)} // Clear hover state
										>
											<Styled.LinkToPost img={post.image} />
											<Styled.DateWrapper>
												<InfluencerAvatar userName={influencerData.username} profileImageUrl={data.profileImageUrl ?? ''} displayNetwork={false} />
												<Styled.DateInfo>
													{isHovered ? (
														'View original post'
													) : (
														<>
															Posted at <strong>{convertDateFormat(post.date)}</strong>
														</>
													)}
												</Styled.DateInfo>
												<Icon name='external-link' size='14' />
											</Styled.DateWrapper>
										</Styled.PostItem>
									);
								})
							: null}
				</Styled.PostItemWrapper>
			</Styled.Reason>
		</Styled.ContentWrapper>
	);
};

export default WhyDoIGetThisResult;
