import axios, { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { Model, Store } from 'json-api-models';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import * as Yup from 'yup';

import AcceptTermsAndConditions from 'components/AcceptTermsAndConditions/AcceptTermsAndConditions';
import InputText from 'components/Form/FormikElements/Text';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import MessageBox from 'components/MessageBox/MessageBox';
import ConfirmModal from 'components/Modals/ConfirmModal';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setPermissions } from 'reducers/UserReducers/UserPermissionsSlice';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import ReferralUrlService from 'services/ReferralUrlService';
import { ErrorResponse } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';
import colors from 'styles/theme/colors';

import Styled from './LoginForm.style';

/**
 * LoginForm
 * @returns {JSX.Element}
 */
const LoginForm = (): JSX.Element => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [errorMessage, setErrorMessage] = useState('');
	const [infoMessage, setInfoMessage] = useState('');
	const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
	const [isTermsAndConditionsAgreed, setIsTermsAndConditionsAgreed] = useState(false);
	const [userType, setUserType] = useState('');
	const [displayPassword, setDisplayPassword] = useState(false);
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const emailRef = useRef<HTMLInputElement>(null);
	const models = new Store();
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);

	const VALIDATION_SCHEMA = Yup.object().shape({
		email: Yup.string().email().required('This email looks wrong'),
		password: Yup.string().required(),
	});

	const logoutService = useInjection<LogoutService>(LogoutService);

	const setWrongUsernamePasswordMessage = () => {
		setErrorMessage('We are unable to match the email and password you entered. Please check and try again.');
	};

	const clearWrongUsernamePasswordMessage = () => {
		setErrorMessage('');
	};

	const hasRoles = (user: Model): boolean => {
		return (
			Object.keys(user.attributes.permissions.entities).length > 0 ||
			user.attributes.permissions.is_influencer ||
			user.attributes.permissions.is_agent ||
			user.attributes.permissions.is_super_admin
		);
	};

	const closeTermsAndConditionModalHandler = () => {
		logoutService.panicLogout();
	};

	const agreeTermsAndConditionHandler = async () => {
		try {
			setIsLoading(true);
			await authService.agreeTermsAndCondition(userType === 'publisher' ? 'publisher' : 'influencer');

			// Get fresh user data after T&C acceptance
			const result = await authService.me(authService.getGlobalUserIncludes());
			const user = models.sync(result) as Model;

			// Set user data in Redux
			dispatch(setUser(user));
			dispatch(setPermissions(user));

			// Determine redirect URL based on user roles
			let url = '/not-connected';
			if (hasRoles(user)) {
				url = user.permissions.is_influencer ? '/influencer/dashboard' : '/dashboard';
			}

			// Clean up modal state
			setIsLoading(false);
			setIsTermsAndConditionsAgreed(false);
			setShowTermsAndConditionsModal(false);

			// Redirect to appropriate dashboard
			ReferralUrlService.redirect(navigate, url);
		} catch (e) {
			console.error('Error accepting T&C:', e);
			logoutService.panicLogout();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		emailRef && emailRef.current && emailRef.current.focus();
	}, [emailRef]);

	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/login/reset/expired') {
			setIsForgotPassword(true);
			setErrorMessage('The link you used is no longer valid. Please request a new link.');
		}
	}, [location.pathname]);

	useEffect(() => {
		// Check for existing token only if we're on the login page
		const token = authService.getCollabsToken();
		const isLoginPage = location.pathname === '/login';
		if (token && isLoginPage && (user.publisherTermsOfConditionsAcceptedAt || user.influencerTermsOfConditionsAcceptedAt)) {
			const url = user.permissions.isInfluencer ? '/influencer/dashboard' : '/dashboard';
			ReferralUrlService.redirect(navigate, url);
		}
	}, [location.pathname]); // Add location.pathname as dependency

	return (
		<>
			<Styled.FormCard>
				<Styled.FormWrapper>
					<Styled.HeaderWrapper>
						<Heading as='h4' data-testid='login-title' id='login-form-title'>
							{isForgotPassword ? 'Reset Password' : 'Log in'}
						</Heading>
						<Styled.ButtonLink
							onClick={() => {
								setIsForgotPassword(!isForgotPassword);
								setErrorMessage('');
							}}
							aria-label={isForgotPassword ? 'Switch to login form' : 'Switch to forgot password form'}
						>
							{isForgotPassword ? 'Login instead' : 'Forgot password?'}
						</Styled.ButtonLink>
					</Styled.HeaderWrapper>
					<Formik
						initialValues={{
							email: '',
							password: '',
						}}
						onSubmit={async ({ email, password }) => {
							clearWrongUsernamePasswordMessage();

							if (isForgotPassword) {
								try {
									await createClient().post('/public/reset-password-tokens', { email });
									setInfoMessage("We'll send a recovery link if the account exists.");
									toast.success("We'll send a recovery link if the account exists.");
								} catch (e) {
									setErrorMessage('Something went wrong, please try again later.');
								}
								return;
							}

							document.dispatchEvent(new Event('userLoggedInAction'));

							try {
								await authService.requestCollabsToken({ username: email, password });
								const result = await authService.me(authService.getGlobalUserIncludes());
								const user = models.sync(result) as Model;
								const isInfluencer = user.attributes.permissions.is_influencer;
								const userType = isInfluencer ? 'influencer' : 'publisher';

								setUserType(userType);
								// Check if the user has accepted the TOC or not
								const hasAcceptedTOC = !!user.attributes[`${userType}TermsOfConditionsAcceptedAt`];

								if (!hasAcceptedTOC) {
									setShowTermsAndConditionsModal(true);
									return;
								}

								// dispatch setUser in redux
								dispatch(setUser(user));
								dispatch(setPermissions(user));

								if (user.attributes.permissions) {
									const userHasRoles: boolean = hasRoles(user);
									let url = '/not-connected';
									if (userHasRoles) {
										url = user.permissions.is_influencer ? '/influencer/dashboard' : '/dashboard';
									}
									ReferralUrlService.redirect(navigate, url);
								}
							} catch (e) {
								if (axios.isAxiosError(e)) {
									if (e.response?.status === 400) {
										setWrongUsernamePasswordMessage();
									} else {
										const errors = (e.response as AxiosResponse<ErrorResponse>).data.errors?.map(({ source }) => source.message) ?? [];
										setErrorMessage(errors.join(', '));
										authService.clearAll();
									}
								}
							}
						}}
						validationSchema={
							isForgotPassword
								? Yup.object({
										email: Yup.string().email().required('This email looks wrong'),
									})
								: VALIDATION_SCHEMA
						}
						validateOnBlur={false}
						validateOnChange={false}
					>
						{({ isSubmitting, isValid, errors, touched }) => (
							<Form aria-labelledby='login-form-title' noValidate>
								<InputText
									label='Email'
									className={errors.email && touched.email ? 'error' : ''}
									innerRef={emailRef}
									id='email'
									autoComplete='username'
									name='email'
									type='email'
									placeholder=''
									required
									aria-required='true'
									aria-invalid={errors.email && touched.email ? 'true' : 'false'}
									aria-describedby={errors.email && touched.email ? 'email-error' : undefined}
								/>
								{!isForgotPassword && (
									<InputText
										action={
											<Styled.IconWrapper
												onClick={() => setDisplayPassword(!displayPassword)}
												aria-label={displayPassword ? 'Hide password' : 'Show password'}
												role='button'
												tabIndex={0}
											>
												<Icon name={!displayPassword ? 'hide' : 'unhide'} aria-hidden='true' size='18' />
											</Styled.IconWrapper>
										}
										label='Password'
										className={errors.password && touched.password ? 'error' : ''}
										id='password'
										autoComplete='current-password'
										name='password'
										type={displayPassword ? 'text' : 'password'}
										required
										aria-required='true'
										aria-invalid={errors.password && touched.password ? 'true' : 'false'}
										aria-describedby={errors.password && touched.password ? 'password-error' : undefined}
									/>
								)}
								{infoMessage && (
									<Styled.MessageBoxWrapper>
										<MessageBox bgColor={colors.paleGreenTint} textColor={colors.forestGreen}>
											{infoMessage}
										</MessageBox>
									</Styled.MessageBoxWrapper>
								)}
								{errorMessage && (
									<Styled.MessageBoxWrapper>
										<MessageBox bgColor={colors.rose} textColor={colors.burgundy} testId='error-message'>
											{errorMessage}
										</MessageBox>
									</Styled.MessageBoxWrapper>
								)}
								<Styled.StyledPrimaryButton
									isLoading={isSubmitting}
									type='submit'
									disabled={isSubmitting || !isValid}
									data-testid='submit-btn'
									aria-disabled={isSubmitting || !isValid}
								>
									{isForgotPassword ? 'Email me a recovery link' : 'Login'}
								</Styled.StyledPrimaryButton>
							</Form>
						)}
					</Formik>
				</Styled.FormWrapper>
			</Styled.FormCard>
			<ConfirmModal
				testId='terms-and-conditions-modal'
				isModalOpen={showTermsAndConditionsModal}
				title='Terms and conditions'
				toggleModal={closeTermsAndConditionModalHandler}
				action={agreeTermsAndConditionHandler}
				buttonText='Agree'
				disabled={!isTermsAndConditionsAgreed || isLoading}
				isFetching={isLoading}
			>
				<AcceptTermsAndConditions
					testId='terms-and-conditions-checkbox'
					termsAccepted={isTermsAndConditionsAgreed}
					setTermsAccepted={setIsTermsAndConditionsAgreed}
					text='terms and conditions'
					link='https://www.collabs.se/terms-of-service'
				/>
			</ConfirmModal>
		</>
	);
};

export default LoginForm;
