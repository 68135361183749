import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { UserModel } from 'api-models';
import useContentManagementData from 'components/ContentManagement/hooks';
import IconButton from 'components/IconButton';
import ConfirmModal from 'components/Modals/ConfirmModal';
import UserAvatar from 'components/Settings/MyAccount/UserAvatar';
import { ENABLE_FOR_CLIENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import { useAppSelector } from 'hooks/useUserAppSelector';
import { AssignmentReviewType } from 'shared/Types/Assignment';
import colors from 'styles/theme/colors';

import Styled from './ShareContent.style';
type ShareContentProps = {
	review?: AssignmentReviewType;
	refresh: () => void;
	CIOArefresh: () => Promise<void>;
	brandManagerUsers?: Array<UserModel>;
	brandName: string;
};

const ShareContent = ({ review, refresh, CIOArefresh, brandManagerUsers, brandName }: ShareContentProps) => {
	const { userCan } = useFeaturePermissions(review?.links || {});

	const CAN_ENABLE_FOR_CLIENT = userCan(ENABLE_FOR_CLIENT);
	const user = useAppSelector((state) => state.user);
	const { campaignId } = useParams();

	useEffect(() => {
		if (!review) return;
		setShareDisabled(Boolean(review?.enabledForClientAt) || Boolean(review?.approvedByClientAt));
	}, [review]);

	const { postEnableToClient } = useContentManagementData();

	const [shareConfirm, setShareConfirm] = useState<boolean>(false);
	const [shareDisabled, setShareDisabled] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleClose = () => setShareConfirm(!shareConfirm);
	const onClickShare = () => {
		if (!review?.links[ENABLE_FOR_CLIENT]) {
			return;
		}

		setIsLoading(true);
		postEnableToClient(review?.links[ENABLE_FOR_CLIENT])
			.then(() => {
				return Promise.all([refresh(), CIOArefresh()]);
			})
			.then(() => {
				toast.success('Content is shared');
			})
			.catch((e) => {
				toast.error(getSomethingWentWrongMessage());
				console.error(e);
				setShareDisabled(true);
			})
			.finally(() => {
				setShareConfirm(!shareConfirm);
				setIsLoading(false);
			});
	};

	return (
		<>
			<Styled.ShareAssignment>
				<Styled.ShareInner>
					{CAN_ENABLE_FOR_CLIENT && !shareDisabled ? (
						<IconButton
							backgroundColor={colors.ash}
							helpText={`Share content with ${brandName}`}
							onClick={handleClose}
							testId='share'
							iconName='share'
							title='Share'
						/>
					) : null}
				</Styled.ShareInner>
			</Styled.ShareAssignment>
			{!user.permissions.isInfluencer && (
				<Styled.ShareTimestamps data-testid='cm-share-timestamp'>
					{review?.enabledForClientAt && !review?.approvedByClientAt && (
						<>
							<IconButton
								iconColor={colors.emerald}
								backgroundColor={colors.ash}
								helpText={`Content is shared with: ${brandManagerUsers?.map((user) => user.attributes.name).join(', ')}`}
								testId='shared'
								iconName='share'
								title='Shared'
							/>
							<IconButton
								iconColor={colors.spice}
								backgroundColor={colors.butter}
								helpText={`Content not approved by ${brandName} yet`}
								testId='approved'
								iconName='pending'
								title='Pending'
							/>
						</>
					)}
					{review?.approvedByClientAt && (
						<IconButton
							iconColor={colors.emerald}
							backgroundColor={colors.ash}
							helpText={`Content approved by ${brandName} ${moment(review?.approvedByClientAt).format('D MMM')}`}
							testId='approved'
							iconName='check-circle'
							title='Approved'
						/>
					)}
				</Styled.ShareTimestamps>
			)}
			<ConfirmModal
				icon='share'
				IconBackgroundColor={colors.iceBlue}
				isModalOpen={shareConfirm}
				toggleModal={() => handleClose()}
				action={() => onClickShare()}
				title={`Share content with ${brandName}`}
				isFetching={isLoading}
				buttonText='Share'
			>
				<>
					{brandManagerUsers && brandManagerUsers?.length > 0 ? (
						<>
							<Styled.BrandManagerList>
								{brandManagerUsers.map((user) => {
									return (
										<li key={user.id}>
											<UserAvatar small fullName={user.attributes.name} displayRole displayThisRole={user.attributes.email ?? ''} />
										</li>
									);
								})}
							</Styled.BrandManagerList>

							<hr />
							<p>
								Content will be shared with the above recipients. Manage collaborators <Link to={`/campaigns/${campaignId}/edit`}>here</Link>.
							</p>
						</>
					) : (
						<>
							<hr />
							<p>
								Manage collaborators <Link to={`/campaigns/${campaignId}/edit`}>here</Link>
							</p>
						</>
					)}
				</>
			</ConfirmModal>
		</>
	);
};

export default ShareContent;
