import { CampaignModel } from 'api-models';
import CMStartImage from 'assets/img/app/CM-start.svg';
import { CampaignItemType } from 'components/ContentManagement/types';
import { useAppSelector } from 'hooks/useUserAppSelector';

import Styled from './EmptyState.style';

type EmptyStateItem = {
	headline: JSX.Element;
	steps?: { headline: string; text: string }[];
	text?: JSX.Element;
	image: string;
};

/**
 * EmptyState
 * @param {EmptyStateProps} props
 * @returns {JSX.Element}
 */
const EmptyState = (props: { campaignSelected?: CampaignItemType | CampaignModel }): JSX.Element => {
	const user = useAppSelector((state) => state.user);

	const content: EmptyStateItem = !user.permissions.isInfluencer
		? {
				headline: <h2>All your influencers content in one place</h2>,
				text: <p className='text-center'>{props.campaignSelected ? 'Select assignment to review' : 'Select campaign'}</p>,
				image: CMStartImage,
			}
		: {
				headline: <h2>All your content in one place</h2>,
				steps: [{ headline: 'Select your assignment', text: '' }],
				image: CMStartImage,
			};

	return (
		<Styled.Wrapper>
			{
				<>
					<img src={CMStartImage} alt='Add your content in one place' />
					<Styled.Headline>{content.headline}</Styled.Headline>
				</>
			}
			{content.steps && (
				<Styled.Steps>
					{content.steps.map((step, index) => (
						<Styled.Step key={index}>
							<Styled.Number>
								<span>{(index + 1).toString().padStart(2, '0')}</span>
							</Styled.Number>
							<Styled.StepBox>
								<Styled.StepHeadline>
									<span>{step.headline}</span>
								</Styled.StepHeadline>
								<Styled.StepText>
									<p>{step.text}</p>
								</Styled.StepText>
							</Styled.StepBox>
						</Styled.Step>
					))}
				</Styled.Steps>
			)}
			{content.text !== undefined && content.text}
		</Styled.Wrapper>
	);
};

export default EmptyState;
