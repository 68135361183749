import { FormikErrors } from 'formik/dist/types';
import moment from 'moment';
import { useEffect, useState } from 'react';

import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import Field from 'components/Forms/Field';
import Input from 'components/Forms/Input';
import { Heading } from 'components/Heading';
import Grid from 'styles/grid/grid';

const SelectDate = ({
	setSelectedDate,
	setFieldValue,
	selectedDate,
	disabled,
	campaignStartDate,
	useStorySelector,
	selectedPostingDate,
}: {
	selectedDate?: [Date, Date];
	setSelectedDate: (date?: [Date, Date]) => void;
	profileConnected: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
	disabled?: boolean;
	campaignStartDate: string;
	useStorySelector?: boolean;
	selectedPostingDate?: Date;
}): JSX.Element => {
	const [startDate, setStartDate] = useState<string>(selectedPostingDate ? moment(selectedPostingDate).format('YYYY-MM-DD') : '');
	const [endDate, setEndDate] = useState<string>(selectedDate ? moment(selectedDate[1]).format('YYYY-MM-DD') : '');

	const renderHelpText = () => {
		if (useStorySelector) {
			return 'Find and select your Story frames by adding the date or date range of posting.';
		} else {
			return 'Add the date of posting.';
		}
	};

	useEffect(() => {
		setStartDate(selectedPostingDate ? moment(selectedPostingDate).format('YYYY-MM-DD') : '');
	}, [selectedPostingDate]);

	const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const date = e.target.value;
		setStartDate(date);
		setSelectedDate([new Date(date), endDate ? new Date(endDate) : new Date()]);
		setFieldValue('postedAt', date);
	};

	const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const date = e.target.value;
		setEndDate(date);
		setSelectedDate([new Date(startDate), new Date(date)]);
	};

	return (
		<Styled.DateSection>
			{!disabled && <Heading as='h5'>{renderHelpText()}</Heading>}
			<Grid.Container>
				<Grid.Column lg={5} md={6} sm={12}>
					<Field label={useStorySelector ? 'Start Date' : 'Date posted'} disabled={disabled}>
						<Input
							type='date'
							disabled={disabled}
							value={startDate}
							onChange={handleStartDateChange}
							min={moment(campaignStartDate).format('YYYY-MM-DD')}
							max={moment().format('YYYY-MM-DD')}
							placeholder={moment(campaignStartDate).format('YYYY-MM-DD')}
						/>
					</Field>
				</Grid.Column>
				{useStorySelector && (
					<Grid.Column lg={5} md={6} sm={12}>
						<Field label='End Date' disabled={disabled}>
							<Input
								type='date'
								disabled={disabled}
								value={endDate}
								onChange={handleEndDateChange}
								min={startDate || moment(campaignStartDate).format('YYYY-MM-DD')}
								max={moment().format('YYYY-MM-DD')}
								placeholder={moment().format('YYYY-MM-DD')}
							/>
						</Field>
					</Grid.Column>
				)}
			</Grid.Container>
			<hr />
		</Styled.DateSection>
	);
};

export default SelectDate;
