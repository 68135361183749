import { useRef } from 'react';

import { HoverButton } from 'components/Button';
import Styled from 'components/ContentManagement/Components/Views/Post/Post.style';

const AddItem = (props: { onChangeFiles: (e: React.FormEvent<HTMLInputElement>) => void }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	return (
		<Styled.AddMediaItemWrapper>
			<HoverButton
				data-testid='add-item'
				useAddIcon
				onClick={() => {
					if (inputRef.current) {
						inputRef.current.click();
					}
				}}
			>
				Add item
			</HoverButton>
			<Styled.InvisibleInput ref={inputRef} type='file' accept='image/*, video/*' multiple onChange={props.onChangeFiles} />
		</Styled.AddMediaItemWrapper>
	);
};

export default AddItem;
