import styled from 'styled-components';

import Comments from 'components/ContentManagement/Components/Comments';
import { useCommentsHandler } from 'components/ContentManagement/Components/Comments/hooks/useCommentsHandler';
import { selectedTabType } from 'components/ContentManagement/Components/Comments/types';
import { useCampaignInstagramOwnerAssignments } from 'components/ContentManagement/hooks';
import { ReviewType } from 'components/ContentManagement/types';
import { Heading } from 'components/Heading';
import { CREATE_COMMENT, CREATE_INTERNAL_COMMENT, CREATE_MANAGER_INTERNAL_COMMENT, ENABLE_FOR_CLIENT } from 'constants/hateoas-keys';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { UserType } from 'reducers/UserReducers/types';
import colors from 'styles/theme/colors';

type CommentsSectionProps = {
	user: UserType;
	influencerName?: string;
	brandName: string;
	CIOAId: string;
	review: ReviewType;
};

const Container = styled.div`
	padding-bottom: 6rem;
	h5 {
		margin-bottom: 24px;
	}
	p {
		font-size: 0.875rem;
		color: ${colors.slate};
	}
`;

const CommentsContainer = ({ user, influencerName, brandName, CIOAId, review }: CommentsSectionProps) => {
	const { userCan } = useFeaturePermissions({ ...review?.links });

	const CAN_COMMENT = userCan(CREATE_COMMENT);
	const CAN_COMMENT_INTERNAL = userCan(CREATE_INTERNAL_COMMENT);
	const CAN_CREATE_MANAGER_INTERNAL_COMMENT = userCan(CREATE_MANAGER_INTERNAL_COMMENT);
	const CAN_ENABLE_CONTENT_FOR_CLIENT = userCan(ENABLE_FOR_CLIENT);

	const { comments, internalComments, managerInternalComments, postCommentHandler, activeCommentTab, setActiveCommentTab } = useCommentsHandler({
		CIOAId: CIOAId,
		reviewLinks: {
			comments: review?.links?.comments,
			internalComments: review?.links?.internalComments,
			managerInternalComments: review?.links?.createManagerInternalComment,
			listManagerInternalComments: review?.links?.listManagerInternalComments,
			createManagerInternalComment: review?.links?.createManagerInternalComment,
		},
		getComments: useCampaignInstagramOwnerAssignments().getComments,
		postComment: useCampaignInstagramOwnerAssignments().postComment,
		canComment: CAN_COMMENT,
		canCommentInternal: CAN_COMMENT_INTERNAL,
		canCreateManagerInternalComment: CAN_CREATE_MANAGER_INTERNAL_COMMENT,
		isInfluencer: user.permissions.isInfluencer,
	});
	return (
		<Container>
			<Heading as='h5'>{user?.permissions?.isInfluencer ? 'Feedback' : 'Feedback'}</Heading>
			<Comments
				user={user}
				canUseTabs={CAN_COMMENT && (CAN_COMMENT_INTERNAL || CAN_CREATE_MANAGER_INTERNAL_COMMENT)}
				comments={
					activeCommentTab === selectedTabType.Influencer ? comments : activeCommentTab === selectedTabType.Team ? managerInternalComments : internalComments
				}
				activeTab={activeCommentTab}
				onClickTab={setActiveCommentTab}
				onSend={(text) => postCommentHandler(text, activeCommentTab)}
				isNotSharedWithClient={!review.enabledForClientAt}
				canShareContent={CAN_ENABLE_CONTENT_FOR_CLIENT}
				influencerName={influencerName}
				brandName={brandName}
			/>
		</Container>
	);
};

export default CommentsContainer;
