import { useState } from 'react';

import { SecondaryButton } from 'components/Button';
import DeleteModal from 'components/Modals/DeleteModal';

import { DangerZoneProps } from './types';

const DangerZone = ({ infoText, isLoading, deleteModalTitle, deleteButtonText, action, disableDelete }: DangerZoneProps) => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	return (
		<>
			<SecondaryButton onClick={() => setModalIsOpen(true)} icon='trash-bin' isLoading={isLoading} disabled={disableDelete}>
				{deleteButtonText}
			</SecondaryButton>
			<DeleteModal toggleModal={() => setModalIsOpen(!modalIsOpen)} title={deleteModalTitle} isModalOpen={modalIsOpen} isFetching={isLoading} action={action}>
				<p>{infoText}</p>
			</DeleteModal>
		</>
	);
};

export default DangerZone;
