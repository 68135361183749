import { Link } from 'react-router-dom';
import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { PALETTE } from 'styles/variables/original-colors';

const ConnectSection = styled.div`
	display: flex;
	flex-direction: column;
	p {
		text-align: center;
		margin-top: 0;
		margin-bottom: 24px;
	}
	button {
		width: fit-content;
		margin: auto;
	}
`;

const CustomLink = styled(Link)`
	display: flex;
	color: ${newColors.smoke} !important;
	align-items: center;
	border-bottom: transparent !important;
	text-decoration: underline;
	margin: 24px 0;
	font-size: 0.875rem;
	.icon {
		margin-right: 16px;
		path {
			fill: ${newColors.smoke};
		}
	}
`;

const ToggleTitleWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 16px;

	h5 {
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 0;
	}
`;

const ConnectedUser = styled.div`
	background-color: ${PALETTE.aquaHaze};
	border-radius: 10px;
	padding: 1rem;

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

const NotConnectedPages = styled.div`
	border-top: 1px solid ${colors.settings.avatarBackground};
	border-bottom: 1px solid ${colors.settings.avatarBackground};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.5rem;
	cursor: pointer;
	p {
		font-size: 0.875rem;
		color: ${colors.black1};
		margin-top: 18px;
	}
`;

const Text = styled.p`
	font-size: 0.875rem;
	margin-bottom: 0;
`;

const PagesWrapper = styled.div`
	margin: 24px 0;
`;

const Info = styled.p`
	font-size: 0.875rem;
`;

const Styled = {
	Info,
	Text,
	ConnectSection,
	CustomLink,
	ToggleTitleWrapper,
	ConnectedUser,
	NotConnectedPages,
	PagesWrapper,
};

export default Styled;
