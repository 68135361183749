import styled from 'styled-components';

import { InputFieldContainerWithIconV2, InputFieldV2 } from 'styles/formElements/input';
import { InputType } from 'styles/formElements/types';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	max-width: 200px;
`;

const IconWrapper = styled.button`
	width: 32px;
	height: 32px;
	background-color: transparent;
	border: none;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	.icon {
		display: flex;
	}
`;

const InputFieldContainer = styled.div<InputType>`
	${InputFieldContainerWithIconV2};
	background-color: ${newColors.white};
	overflow: hidden;
	color: ${newColors.black};
	display: flex;
	justify-content: space-between;
	align-items: center;
	input {
		background-color: ${newColors.white} !important;
		padding: 0;
	}
`;

const InputField = styled.input`
	${InputFieldV2};
	width: 80px;
	height: 44px;
	border-left: none;
	padding: 1.5rem 0.25rem;
	font-size: 1rem;

	background-color: ${colors.AffiliateMarketplaceCreation.inputFocusedBackground};
	text-align: center;
	font-weight: 500;

	&::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type='number'] {
		appearance: textfield;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Styled = {
	Wrapper,
	IconWrapper,
	InputFieldContainer,
	InputField,
	InputWrapper,
};

export default Styled;
