import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgChangeSegment(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.40114 1.17647C5.42478 1.17647 4.63328 1.96656 4.63328 2.94118V6.47059C4.63328 6.79546 4.36945 7.05882 4.044 7.05882C3.71854 7.05882 3.45471 6.79546 3.45471 6.47059V2.94118C3.45471 1.31681 4.77387 0 6.40114 0H17.0083C18.6356 0 19.9547 1.31681 19.9547 2.94118V17.0588C19.9547 18.6832 18.6356 20 17.0083 20H6.40114C4.77387 20 3.45471 18.6832 3.45471 17.0588V13.5294C3.45471 13.2045 3.71854 12.9412 4.044 12.9412C4.36945 12.9412 4.63328 13.2045 4.63328 13.5294V17.0588C4.63328 18.0334 5.42478 18.8235 6.40114 18.8235H17.0083C17.9846 18.8235 18.7761 18.0334 18.7761 17.0588V2.94118C18.7761 1.96656 17.9846 1.17647 17.0083 1.17647H6.40114Z'
				fill='#575556'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.7356 10.4506L7.84401 12.8419C7.5899 13.0527 7.16929 13.0527 6.91518 12.8419C6.78374 12.7329 6.7224 12.5948 6.7224 12.4567C6.7224 12.3186 6.78374 12.1805 6.91518 12.0715L8.68919 10.6H5.17751L5.14278 10.6C4.28847 10.6003 2.27277 10.6012 1.05857 8.83279C0.869253 8.55708 0.945987 8.18456 1.22996 8.00074C1.51393 7.81693 1.8976 7.89144 2.08692 8.16715C2.91581 9.37436 4.29416 9.39997 5.17751 9.39997H8.68912L6.91518 7.92853C6.66107 7.71775 6.66107 7.36887 6.91518 7.15809C7.16929 6.9473 7.5899 6.9473 7.84401 7.15809L10.7356 9.5639C10.797 9.61478 10.8496 9.69473 10.8934 9.78195C10.9284 9.85463 10.9547 9.92732 10.9547 10.0073V10.0218C10.9547 10.0945 10.9372 10.1599 10.9021 10.2253C10.8583 10.3125 10.8057 10.3925 10.7356 10.4506Z'
				fill='#575556'
			/>
		</svg>
	);
}
