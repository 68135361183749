import { Form, Formik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import useInjection from 'hooks/useInjection';
import { useAppSelector } from 'hooks/useUserAppSelector';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import { StatusCode } from 'services/Response.types';
import { createClient } from 'shared/ApiClient/ApiClient';
import { silentRefreshUser } from 'shared/User/User.helpers';
import errorHandler from 'utils/formik_error_handler';

const StyledPrimaryButton = styled(PrimaryButton)`
	float: right;
	display: flex;
	margin-top: 16px;
`;

const PersonalInformation = ({
	userId,
	isNewOrganization,
	onSubmit,
}: {
	publisherEdit: string | undefined;
	onSubmit(): void;
	userId: number | string;
	isNewOrganization: boolean;
}) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const user = useAppSelector((state) => state.user);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

	const { firstName, lastName } = user;

	const INITIAL_VALUES = {
		firstname: firstName,
		surname: lastName,
	};

	return (
		<Formik
			onSubmit={async ({ firstname, surname }, { setErrors }) => {
				try {
					const client = createClient();
					const fullName = `${firstname} ${surname}`;

					if (!isNewOrganization) {
						await client.patch(`/users/${userId || user.id}`, { name: fullName, firstName: firstname, lastName: surname });
						onSubmit();
					} else {
						await client
							.patch(`/users/${userId || user.id}`, { name: fullName, firstName: firstname, lastName: surname })
							.then((res) => {
								if (res.status === StatusCode.OK) {
									if (!user.publisherTermsOfConditionsAcceptedAt) {
										authService.agreeTermsAndCondition('publisher');
									} else {
										silentRefreshUser();
									}
									onSubmit();
								}
							})
							.catch((e) => {
								errorHandler(e, setErrors);
							});
					}
				} catch (e) {
					errorHandler(e, setErrors);
				}
			}}
			validationSchema={Yup.object().shape({
				firstname: Yup.string().required('Please add your first name.'),
				surname: Yup.string().required('Please add your last name.'),
			})}
			initialValues={INITIAL_VALUES}
			validateOnBlur={validateAfterSubmit}
			validateOnChange={validateAfterSubmit}
		>
			{({ isSubmitting, isValid, errors, touched }) => (
				<Form noValidate aria-label='Personal Information Form' role='form'>
					<fieldset data-testid='personal-information'>
						<div role='group' aria-labelledby='personal-info-fields'>
							<InputText
								name='firstname'
								label='First name'
								required
								aria-required='true'
								aria-invalid={touched.firstname && errors.firstname ? 'true' : 'false'}
								aria-describedby={touched.firstname && errors.firstname ? 'firstname-error' : undefined}
							/>
							<InputText
								name='surname'
								label='Surname'
								required
								aria-required='true'
								aria-invalid={touched.surname && errors.surname ? 'true' : 'false'}
								aria-describedby={touched.surname && errors.surname ? 'surname-error' : undefined}
							/>
						</div>
					</fieldset>
					<StyledPrimaryButton
						isLoading={isSubmitting}
						type='submit'
						disabled={isSubmitting || !isValid}
						onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}
						aria-busy={isSubmitting}
						aria-disabled={isSubmitting || !isValid}
					>
						<span>{isNewOrganization ? 'Next' : 'Get started'}</span>
					</StyledPrimaryButton>
				</Form>
			)}
		</Formik>
	);
};

export default PersonalInformation;
