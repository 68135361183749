import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Icon from 'components/Icon';

import { ExpandableContentLeft } from './Components/ExpandableContentLeft';
import { ExpandableContentRight } from './Components/ExpandableContentRight';
import Styled from './SideDrawer.style';
import { SideDrawerProps } from './types';

/**
 * Sidebar component
 * This component can have 2 different states when the isExpandable prop is set to true.
 * drawerIsExpanded will expanded the Drawer to reveal additional content.
 * @returns {JSX.Element}
 */
const SideDrawer = ({
	title,
	children,
	sidebarIsOpen,
	onClose,
	dataTestId,
	blockClickOutside,
	isExpandable,
	expandedContent,
	expandedTitle,
	onSave,
	saveButtonText,
	isDisabled,
	isExpanded,
	setNotExpanded,
	actionComponent,
	isSubmitting,
	canOnlyCloseModal,
	customButtons,
}: SideDrawerProps): JSX.Element => {
	const [drawerIsExpanded, setDrawerIsExpanded] = useState(isExpanded ?? false);
	const detailDrawerRef = useRef<HTMLDivElement>(null);
	const scrollPositionRef = useRef(0);
	const hasAppliedScrollLock = useRef(false);

	useEffect(() => {
		setDrawerIsExpanded(isExpanded ?? false);
	}, [isExpanded]);

	// Handle scroll prevention and restoration
	useEffect(() => {
		const layoutDiv = document.querySelector('.layout');

		if (sidebarIsOpen && !hasAppliedScrollLock.current) {
			scrollPositionRef.current = window.scrollY;

			// Apply styles to prevent scrolling
			layoutDiv?.classList.add('disable-scroll');
			document.body.style.position = 'fixed';
			document.body.style.top = `-${scrollPositionRef.current}px`;
			document.body.style.width = '100%';
			hasAppliedScrollLock.current = true;
		} else if (!sidebarIsOpen && hasAppliedScrollLock.current) {
			layoutDiv?.classList.remove('disable-scroll');
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.width = '';

			// Restore the scroll position
			window.scrollTo(0, scrollPositionRef.current);
			hasAppliedScrollLock.current = false;
		}

		// Cleanup on unmount or state change
		return () => {
			if (hasAppliedScrollLock.current) {
				layoutDiv?.classList.remove('disable-scroll');
				document.body.style.position = '';
				document.body.style.top = '';
				document.body.style.width = '';
				window.scrollTo(0, scrollPositionRef.current);
				hasAppliedScrollLock.current = false;
			}
		};
	}, [sidebarIsOpen]);

	const handleEscapeKey = (e: KeyboardEvent): void => {
		if (detailDrawerRef.current && e.key === 'Escape' && !blockClickOutside) {
			const overlays = document.querySelectorAll('.modal, .overlay');
			const noOverlayVisible = Array.from(overlays).every((overlay) => !overlay.classList.contains('show'));
			if (noOverlayVisible) {
				onClose();
			}
		}
	};

	// Listen for escape key to close the drawer
	useEffect(() => {
		document.addEventListener('keyup', handleEscapeKey, true);
		return () => {
			document.removeEventListener('keyup', handleEscapeKey, true);
		};
	}, [blockClickOutside, onClose]);

	return createPortal(
		<CSSTransition in={sidebarIsOpen} timeout={200} unmountOnExit>
			<Styled.SideBarWrapper
				className={classNames({ show: sidebarIsOpen })}
				onClick={() => {
					if (!blockClickOutside) {
						onClose();
					}
				}}
			>
				<Styled.InnerWrapper className={classNames({ show: sidebarIsOpen })} data-testid={dataTestId}>
					<Styled.DetailDrawer
						ref={detailDrawerRef}
						className={classNames({ drawerIsExpanded: drawerIsExpanded })}
						onClick={(e) => {
							e.stopPropagation();
						}}
						tabIndex={-1}
					>
						<Styled.DrawerHeader>
							<div className='drawer-title'>{title}</div>
							<Styled.CloseAction aria-label='Close' data-testid='close-drawer' onClick={() => onClose()}>
								<span>Close</span>
								<span className='icon-wrapper'>
									<Icon name='cross' size='14' />
								</span>
							</Styled.CloseAction>
						</Styled.DrawerHeader>
						{isExpandable ? (
							<Styled.ExpandableBody data-testid='is-expandable'>
								<ExpandableContentLeft
									drawerIsExpanded={drawerIsExpanded}
									onCancel={() => onClose()}
									onSave={() => onSave && onSave()}
									saveButtonText={saveButtonText}
									expandedTitle={expandedTitle}
									isDisabled={isDisabled}
									actionComponent={actionComponent}
									isSubmitting={isSubmitting}
									canOnlyCloseModal={canOnlyCloseModal}
									customButtons={customButtons}
								>
									{children}
								</ExpandableContentLeft>
								<ExpandableContentRight
									drawerIsExpanded={drawerIsExpanded}
									onCancel={() => {
										setDrawerIsExpanded(false);
										setNotExpanded && setNotExpanded();
									}}
								>
									<>{expandedContent}</>
								</ExpandableContentRight>
							</Styled.ExpandableBody>
						) : (
							<Styled.DrawerBody>{children}</Styled.DrawerBody>
						)}
					</Styled.DetailDrawer>
				</Styled.InnerWrapper>
			</Styled.SideBarWrapper>
		</CSSTransition>,
		document.body,
	);
};

export default SideDrawer;
