/* eslint-disable unused-imports/no-unused-vars */
import moment from 'moment';

import { SecondaryButton } from 'components/Button';
import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import { Heading } from 'components/Heading';
import { AssignmentType } from 'shared/helpers/Assigment/types';
import Grid from 'styles/grid/grid';

/**
 * Statistics for approved assignment av read only overview
 * @returns {JSX.Element}
 */
const StatisticsOverview = ({
	contentType,
	values,
	LastInsightDataUpdateAt,
	isManagedByApi,
	setIsInEditMode,
	isInEditMode,
}: {
	contentType: AssignmentType;
	values: StatisticsFormValues;
	LastInsightDataUpdateAt?: string;
	isManagedByApi?: boolean;
	setIsInEditMode: (isInEditMode: boolean) => void;
	isInEditMode: boolean;
}): JSX.Element => {
	const IS_STORY = contentType === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = contentType === AssignmentType.TIKTOK_VIDEO;
	const IS_REEL = contentType === AssignmentType.INSTAGRAM_REEL;

	const renderInfo = () => {
		return (
			<Styled.InfoSection>
				<p data-testid='date-posted'>
					Date posted: <strong>{moment(values.postedAt).format('YYYY-MM-DD')}</strong>
				</p>
				{values.url && (
					<a href={values.url} target='_blank' rel='noreferrer'>
						{values.url}
					</a>
				)}
			</Styled.InfoSection>
		);
	};

	return (
		<>
			<Heading as='h5'>Statistics</Heading>
			<Styled.AutomaticStats>
				{IS_STORY ? (
					<>
						<Grid.Column lg={12}>{renderInfo()}</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (first frame)</p>
								<span>{values.reach}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Reach (last frame)</p>
								<span>{values.reachLastFrame}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Impressions (first frame)</p>
								<span>{values.impressions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Total sticker link clicks</p>
								<span>{values.stickerLinkClicks}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Total sticker taps</p>
								<span>{values.stickerTaps}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue>
								<p>Other interactions</p>
								<span>{values.otherInteractions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
					</>
				) : (
					<>
						<Grid.Column lg={12}>{renderInfo()}</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-reach'>
								<p>Reach</p>
								<span>{values.reach}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-impressions'>
								<p>{IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'}</p>
								<span>{values.impressions}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-comments'>
								<p>Comments</p>
								<span>{values.comments}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-likes'>
								<p>Likes</p>
								<span>{values.likes}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							<Styled.AutomaticStatsValue data-testid='ov-saves'>
								<p>Saves</p>
								<span>{values.saves}</span>
							</Styled.AutomaticStatsValue>
						</Grid.Column>
						<Grid.Column lg={4} sm={12}>
							{IS_TIKTOK && (
								<Styled.AutomaticStatsValue>
									<p>Shares</p>
									<span>{values.shares}</span>
								</Styled.AutomaticStatsValue>
							)}
						</Grid.Column>
					</>
				)}
				<Grid.Column lg={12}>
					{isManagedByApi && LastInsightDataUpdateAt && (
						<p className='gray-text'>Statistics last updated: {moment(LastInsightDataUpdateAt).format('YYYY-MM-DD HH:mm')}</p>
					)}
				</Grid.Column>
				{!isInEditMode && (
					<SecondaryButton
						data-testid='edit-stats'
						onClick={() => {
							setIsInEditMode(!isInEditMode);
						}}
					>
						{isInEditMode ? 'Cancel' : 'Edit'}
					</SecondaryButton>
				)}
			</Styled.AutomaticStats>
		</>
	);
};

export default StatisticsOverview;
