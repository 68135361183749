import styled, { DefaultThemeV2 } from 'styled-components';

import Accordion from 'components/Accordion';
import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const StyledAccordion = styled(Accordion)`
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 1.5rem;
	.accordion__title {
		font-weight: 600;
		padding-left: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.accordion__header {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
	}
`;

const InviteWrapper = styled.div`
	margin-top: 40px;
`;

const UserList = styled.ul`
	padding-left: 0 !important;
	li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.icon {
			margin-right: 6px;
		}
	}
`;

const Select = styled.select`
	background-color: transparent;
	border: transparent;
	line-height: 1.5;
`;

const ItemAvatar = styled.div`
	display: flex;
	flex-direction: column;
	span {
		max-width: 300px;
		&.role {
			color: ${colors.oceanBlue};
			font-size: 0.875rem;
			margin-left: 50px;
		}
	}
`;

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 0 0.5rem;
	&:hover {
		background-color: ${colors.ash};
	}
`;

export default {
	StyledAccordion,
	InviteWrapper,
	UserList,
	Select,
	ItemAvatar,
	ListItem,
};
