/* eslint-disable import/no-named-as-default-member */
import { isNil } from 'lodash';

import { useAppSelector } from 'hooks/useUserAppSelector';

import Utils from './Utils';
import { ROLE_ADMINISTRATOR, ROLE_PROJECT_MANAGER, SecurityRole } from './types';

export default function usePermissions() {
	const currentUser = useAppSelector((state) => state.user);
	const permissions = currentUser?.permissions;
	const links = currentUser?.links ?? {};

	// Permissions
	const userCan = Utils.can(links);

	const userCanAll = Utils.canAll(links);

	// Roles
	const isSuperAdmin = Utils.isSuperAdmin(permissions);

	const isImpersonating = Utils.isImpersonating(permissions);

	const isInfluencer = Utils.isInfluencer(permissions);

	const isAgent = Utils.isAgent(permissions);

	const isPublisher = Utils.isPublisher(permissions);

	const isBrandManager = Utils.isBrandManager(permissions);

	/**
	 * This is the one and only permission check. This is all you need to do.
	 * We must first rely on HATEOAS links if they are available. After that we can
	 * check if the user is granted a specific role.
	 *
	 * One should prefer to use brand id, then client id and last publisher id.
	 *
	 * 	if (isGranted(ROLE_PROJECT_MANAGER, campaign.brand.id)) {
	 * 		// render publish button
	 * 	}
	 *
	 *
	 * @param expectedRole Valid roles are: ROLE_ADMINISTRATOR, ROLE_PROJECT_MANAGER, ROLE_BRAND_MANAGER, ROLE_DATA_ANALYST,
	 * @param entityId the UUID of the Brand, Client or Publisher
	 */
	const isGranted = (expectedRole: SecurityRole, entityId?: string): boolean => {
		for (const id in permissions.entities) {
			if (!isNil(entityId) && id !== entityId) {
				continue;
			}
			const { role } = permissions.entities[id];
			if (expectedRole === role) {
				return true;
			}

			// Look at hierarchy
			if (expectedRole === ROLE_PROJECT_MANAGER && role === ROLE_ADMINISTRATOR) {
				return true;
			}
		}
		return false;
	};

	return {
		isGranted,
		userCan,
		userCanAll,
		isSuperAdmin,
		isImpersonating,
		isInfluencer,
		isAgent,
		isPublisher,
		isBrandManager,
		currentUser,
	};
}
