import { AxiosResponse } from 'axios';
import { Model } from 'json-api-models';
import { useState, useEffect } from 'react';
import { toast } from 'sonner';

import BlastMessageContent from 'components/IntegratedInbox/Components/BlastMessageContent';
import { SideDrawer } from 'components/SideDrawer';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import { createClient } from 'shared/ApiClient/ApiClient';

type CreateBlastRequest = {
	subject: string | null;
	message: string;
	influencers: string[];
	campaign?: string;
};

/**
 * Blast message is sending the same message to multiple selected influencers.
 * So it is for campaign managers not for influencers.
 *
 * @todo test and change createBlastMessage function
 */
const BlastMessageModal = (props: {
	open: boolean;
	onClose: () => void;
	selectedCampaign: Model | null;
	selectedCampaignInfluencers: Model[];
	selectedCampaignConversations: Model[] | null;
	getConversationMessages: (conversationId: string) => Promise<AxiosResponse>;
}) => {
	const [joinedInfluencers, setJoinedInfluencers] = useState<Model[]>([]);
	const [notJoinedInfluencers, setNotJoinedInfluencers] = useState<Model[]>([]);
	const [selectedInfluencers, setSelectedInfluencers] = useState<Model[]>([]);
	const [isSending, setIsSending] = useState<boolean>(false);

	const [message, setMessage] = useState('');
	const [subject, setSubject] = useState<string | null>(null);

	const Client = createClient();

	/**
	 * This sets selectedInfluencers state depending on type parameter.
	 * type parameter's type is string but it should be one of the following. 'joined', 'not-joined', 'all', 'single' and 'remove'
	 */
	const selectInfluencerHandler = (type: string, targetInfluencer?: Model) => {
		switch (type) {
			case 'joined':
				setSelectedInfluencers(joinedInfluencers);
				break;
			case 'not-joined':
				setSelectedInfluencers(notJoinedInfluencers);
				break;
			case 'all':
				setSelectedInfluencers([...joinedInfluencers, ...notJoinedInfluencers]);
				break;
			case 'single':
				if (targetInfluencer) {
					setSelectedInfluencers((prev) => {
						if (prev.some((prevCampaignInstagramOwner) => prevCampaignInstagramOwner.id === targetInfluencer.id)) {
							return prev;
						} else {
							return [...prev, targetInfluencer];
						}
					});
				}
				break;
			case 'remove':
				if (targetInfluencer) {
					setSelectedInfluencers((prev) => {
						return prev.filter((campaignInstagramOwner) => campaignInstagramOwner.id !== targetInfluencer.id);
					});
				}
				break;
			default:
				break;
		}
	};

	/**
	 * temp createBlastConversation
	 * It will create a conversation or/and send a message to each influencer
	 */
	const createBlastConversation = async () => {
		if (!props.selectedCampaign || selectedInfluencers.length === 0 || 0 === message.trim().length) {
			return false;
		}

		const body: CreateBlastRequest = {
			subject,
			message,
			campaign: props.selectedCampaign.id,
			influencers: selectedInfluencers.map(({ influencer }) => influencer.id),
		};
		setIsSending(true);

		try {
			await Client.post('/conversations/blasts', body).then(() => {
				toast.success('Message sent');
				setIsSending(false);
				modalCloseHandler();
			});
		} catch {
			toast.error(getErrorMessageOnPost('sending this message'));
			setIsSending(false);
		}
	};

	const modalCloseHandler = () => {
		setJoinedInfluencers([]);
		setNotJoinedInfluencers([]);
		setSelectedInfluencers([]);
		props.onClose();
	};

	useEffect(() => {
		if (props.selectedCampaignInfluencers.length > 0) {
			const validInfluencers = props.selectedCampaignInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.invite !== null);
			setJoinedInfluencers(validInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.joined));
			setNotJoinedInfluencers(validInfluencers.filter((campaignInstagramOwner) => !campaignInstagramOwner.joined));
		} else {
			setJoinedInfluencers([]);
			setNotJoinedInfluencers([]);
		}
	}, [props.selectedCampaignInfluencers]);

	return (
		<SideDrawer
			sidebarIsOpen={props.open}
			onClose={() => modalCloseHandler()}
			dataTestId={''}
			title={`${props.selectedCampaign?.name}`}
			isExpandable
			isSubmitting={isSending}
			isDisabled={message.trim().length === 0}
			saveButtonText='Send'
			onSave={createBlastConversation}
		>
			<BlastMessageContent
				campaign={props.selectedCampaign}
				campaignInstagramOwners={props.selectedCampaignInfluencers.filter((campaignInstagramOwner) => campaignInstagramOwner.invite !== null)}
				selectedInfluencers={selectedInfluencers}
				onSelectInfluencer={selectInfluencerHandler}
				getMessage={(subject, message) => {
					setMessage(message), setSubject(subject);
				}}
			/>
		</SideDrawer>
	);
};

export default BlastMessageModal;
