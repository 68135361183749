import styled from 'styled-components';

import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const EmptyStateWrapper = styled.div`
	background-color: ${colors.campaignInvite.emptyState.background};
	padding: 1rem 2rem;
	height: 100%;
	min-height: 100vh;
	display: flex;
	${scrollbarY};
`;

const LinkAlreadyUsedWrapper = styled.div`
	height: 100%;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${mediaQueries.medium`
		width: 60%;
	`};
	${mediaQueries.xl`
		width: 60%;
	`};
`;

const AlignCenter = styled.div`
	display: flex;
	flex-direction: column-reverse;
	${mediaQueries.xl`
		align-items: center;
 		flex-direction: row;
	`};
`;

const EmptyStateImage = styled.img`
	display: block;
	height: 80px;
	width: auto;
	margin: auto;
	margin-bottom: 32px;
	${mediaQueries.medium`
		margin-bottom: 40px;
	`};
	${mediaQueries.xl`
		margin-left: 64px;
		margin-bottom: 200px;
		height: 160px;
	`};
`;

const Title = styled.h2`
	font-size: 1.75rem;
	font-family: ${typography.BaseFontFamiliy};
	font-weight: 500;
	${mediaQueries.medium`
		font-size: 2.5rem;
	`};
	${mediaQueries.xl`
		font-size: 3rem;
	`};
`;

const Text = styled.p`
	font-size: 0.938;
	margin: 32px 0;
	${mediaQueries.xl`
		font-size: 1.125rem;
	`};
`;

const TextContainer = styled.div`
	width: 100%;
	${mediaQueries.xl`
		width: 70%
	`};
	button {
		margin: auto;
		margin-bottom: 64px;
		width: 280px;
	}
	${mediaQueries.xl`
		button {
			margin: unset;
			margin-bottom: 0;
			width: auto;
		}
	`};
`;

const ImgContainer = styled.div`
	width: 100%;
	${mediaQueries.xl`
		width: 30%
	`};
`;

const Styled = {
	TextContainer,
	ImgContainer,
	EmptyStateWrapper,
	LinkAlreadyUsedWrapper,
	EmptyStateImage,
	Title,
	AlignCenter,
	Text,
};

export default Styled;
