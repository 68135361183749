import AuthContainer from 'views/Auth/AuthContainer/AuthContainer';

import LoginForm from './components/LoginForm';

/**
 * LoginContainer
 * @returns {JSX.Element}
 */
const LoginContainer = (): JSX.Element => (
	<AuthContainer>
		<LoginForm />
	</AuthContainer>
);

export default LoginContainer;
