import { ErrorMessage, Form, Formik } from 'formik';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import AcceptTermsAndConditions from 'components/AcceptTermsAndConditions/AcceptTermsAndConditions';
import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import useInjection from 'hooks/useInjection';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import { createClient } from 'shared/ApiClient/ApiClient';
import { silentRefreshUser } from 'shared/User/User.helpers';
import errorHandler from 'utils/formik_error_handler';

/**
 */
const AccountCreation = ({
	inviteToken,
	email,
	onCreate,
	setTermsAccepted,
	termsAccepted,
}: {
	email?: string;
	inviteToken: string | undefined;
	onCreate: (userId: number | string) => void;
	setTermsAccepted: (termsAccepted: boolean) => void;
	termsAccepted: boolean;
}) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
	const [user, setUser] = useState<Model>();

	useEffect(() => {
		if (user && authService.getCollabsToken()) {
			silentRefreshUser();
			onCreate(user.id);
		}
	}, [user]);

	return (
		<Formik
			onSubmit={async (values, { setErrors }) => {
				try {
					const { data } = await createClient().post<JsonApiDocument>('/public/users', values);

					const user = new Store().sync(data) as Model;
					authService.setCollabsToken(user.userToken.token);
					if (!user.publisherTermsOfConditionsAcceptedAt) {
						authService.agreeTermsAndCondition('publisher');
					} else {
						silentRefreshUser();
					}

					setUser(user);
				} catch (e) {
					errorHandler(e, setErrors);
				}
			}}
			initialValues={{
				email: email || '',
				password: '',
				inviteToken,
			}}
			validationSchema={Yup.object({
				email: Yup.string().email('Invalid email address. Please check and try again'),
				password: Yup.string().required('Password is required'),
			})}
			validateOnBlur={validateAfterSubmit}
			validateOnChange={validateAfterSubmit}
		>
			{({ isSubmitting, isValid }) => (
				<Form data-testid='account-creation'>
					<InputText label='Email' name='email' type='email' id='user-email' required />
					<ErrorMessage name='campaignInviteToken' className='validation-error' component='p' />
					<ErrorMessage name='inviteToken' className='validation-error' component='p' />
					<InputText label='Password' name='password' type='password' id='user-pwd' required />
					<AcceptTermsAndConditions
						testId='terms-and-conditions-checkbox'
						termsAccepted={termsAccepted}
						setTermsAccepted={setTermsAccepted}
						text='Collabs Terms of Service'
						link='https://www.collabs.se/terms-of-service'
					/>
					<PrimaryButton
						style={{ float: 'right', display: 'flex' }}
						isLoading={isSubmitting}
						type='submit'
						data-testid='next-button'
						disabled={isSubmitting || !isValid || !termsAccepted}
						onClick={() => setValidateAfterSubmit(!validateAfterSubmit)}
					>
						Next
					</PrimaryButton>
				</Form>
			)}
		</Formik>
	);
};

export default AccountCreation;
