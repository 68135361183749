import { Form, Formik } from 'formik';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { PrimaryButton } from 'components/Button';
import InputText from 'components/Form/FormikElements/Text';
import Styled from 'components/Settings/Settings.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useModels from 'hooks/useModels';
import usePermissions from 'hooks/usePermissions';
import { ROLE_ADMINISTRATOR, ROLE_BRAND_MANAGER, ROLE_DATA_ANALYST, ROLE_PROJECT_MANAGER, SecurityRole } from 'hooks/usePermissions/types';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import { updateUser } from 'shared/User/User.helpers';
import handleErrors from 'utils/formik_error_handler';

import { IPersonalFormProps, PersonalFormValues } from './types';

const ROLE_WITH_TITLE: SecurityRole[] = [ROLE_ADMINISTRATOR, ROLE_PROJECT_MANAGER, ROLE_BRAND_MANAGER, ROLE_DATA_ANALYST];

/**
 * PersonalForm
 * @param {IPersonalFormProps} props
 * @returns {JSX.Element}
 */
const PersonalForm = (props: IPersonalFormProps): JSX.Element => {
	const { currentUser } = props;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [shouldValidate, setShouldValidate] = useState<boolean>(false);
	const [userData, setUserData] = useState<PersonalFormValues | null>(null);

	const { models } = useModels(`/me`);
	const { isGranted } = usePermissions();
	let showTitle = false;
	for (const role of ROLE_WITH_TITLE) {
		if (isGranted(role)) {
			showTitle = true;
			break;
		}
	}

	useEffect(() => {
		const user = models.find('user', currentUser.id);
		if (user && !isNil(user.mobilePhoneNumber)) {
			const initialData: PersonalFormValues = {
				email: user.email,
				title: user.title ?? '',
				firstName: user.firstName,
				lastName: user.lastName,
			};
			setUserData(initialData);
		} else {
			const initialData: PersonalFormValues = {
				email: currentUser.email,
				title: currentUser.title ?? '',
				firstName: currentUser.firstName,
				lastName: currentUser.lastName,
			};
			setUserData(initialData);
		}
	}, [currentUser]);

	return (
		<Formik
			initialValues={
				userData || {
					email: '',
					title: '',
					firstName: '',
					lastName: '',
				}
			}
			enableReinitialize
			validateOnBlur={shouldValidate}
			validateOnChange={shouldValidate}
			onSubmit={async (values, { setErrors }) => {
				try {
					setIsLoading(true);
					await createClient().patch(`/users/${currentUser.id}`, {
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						mobilePhoneNumber: null,
						title: values.title.trim().length > 0 ? values.title.trim() : null,
					});

					await updateUser();
					toast.success('Your information has been successfully updated');
				} catch (e) {
					toast.error(getErrorMessageOnPost('updating your information'));
					handleErrors(e, setErrors);
				}
				setIsLoading(false);
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email('Invalid email').required('Required'),
				firstName: Yup.string().required('Required'),
				lastName: Yup.string().required('Required'),
			})}
		>
			{({ isSubmitting, dirty, handleSubmit }) => (
				<Form>
					<InputText label='First name' name='firstName' placeholder='First name' required />
					<InputText label='Last name' name='lastName' placeholder='Last name' required />
					<InputText label='email' type='email' id='email' name='email' placeholder='you@collabs.app' required />
					{showTitle && (
						<InputText
							data-testid='form-control-title'
							id='fc-title'
							helpText='This title will be used as a signature in all outgoing emails to influencers and Brand Managers.'
							label='Email signature title'
							name='title'
							placeholder='Ex. Project Manager'
						/>
					)}
					<Styled.ButtonGroup>
						<PrimaryButton
							size='sm'
							type='submit'
							isLoading={isLoading}
							disabled={isSubmitting || !dirty}
							data-testid='submit-btn'
							onClick={() => {
								if (!shouldValidate) {
									setShouldValidate(true);
								}
								handleSubmit();
							}}
						>
							Save
						</PrimaryButton>
					</Styled.ButtonGroup>
				</Form>
			)}
		</Formik>
	);
};

export default PersonalForm;
