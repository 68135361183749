import { useState, useEffect } from 'react';

import Summary from 'components/DataLibrary/Graphs/Categories/Summary';
import { INFLUENCERS, PAID_MEDIA } from 'constants/data-library';
import Grid from 'styles/grid/grid';
import { useAppSelector } from 'views/DataLibrary/hooks';
import { DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './ContentBody.style';
import useGetChart from './useGetChart';

type DashboardItem = {
	id: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	metaData: Array<any>;
	size: string;
	kind: string;
};
/**
 * This component is displayed in DataLibrary and the dashboard preview. It contains the summary and all of the graphs.
 * @returns {JSX.Element}
 */
const ContentBodyPublicDashboard = (): JSX.Element => {
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);
	const [getChart] = useGetChart();

	const [firstSection, setFirstSection] = useState<DashboardItem[]>([]);
	const [secondSection, setSecondSection] = useState<DashboardItem[]>([]);
	const [thirdSection, setThirdSection] = useState<DashboardItem[]>([]);
	const [summaryTableItems, setSummaryTableItems] = useState<DashboardItem[]>([]);

	const splitArray = (array: DashboardItem[], parts: number) => {
		const result: Array<Array<DashboardItem>> = [];
		for (let i = parts; i > 0; i--) {
			result.push(array.splice(0, Math.ceil(array.length / i)));
		}
		return result;
	};
	useEffect(() => {
		if (dashboard.items?.length > 0) {
			const SummaryTableGraphs = dashboard.items.filter((item) => item.kind === INFLUENCERS.SUMMARY || item.kind === PAID_MEDIA.SUMMARY);
			setSummaryTableItems(SummaryTableGraphs);

			const rest = dashboard.items.filter((item) => item.kind !== INFLUENCERS.SUMMARY && item.kind !== PAID_MEDIA.SUMMARY);
			const graphs = splitArray([...rest], 3);
			setFirstSection(graphs[0]);
			setSecondSection(graphs[1]);
			setThirdSection(graphs[2]);
		}
	}, [dashboard.items]);

	return (
		<Styled.Container>
			<Grid.Container>
				<Grid.Column lg={12}>{dashboard.id && <Summary />}</Grid.Column>
				<Grid.Column lg={12}>{dashboard.id && <Styled.Title style={{ marginTop: '40px' }}>Insights</Styled.Title>}</Grid.Column>
				{summaryTableItems.map((item: { kind: string; size: string; id: string }, index: number) => {
					return (
						<Grid.Column key={index} lg={12}>
							{getChart({
								type: item.kind,
								chartId: item.id,
							})}
						</Grid.Column>
					);
				})}
				<Grid.Column xs={12} lg={4}>
					<Grid.Container>
						{firstSection.map((item: { kind: string; size: string; id: string }, index: number) => {
							return (
								<Grid.Column key={index} xxl={12}>
									{getChart({
										type: item.kind,
										chartId: item.id,
									})}
								</Grid.Column>
							);
						})}
					</Grid.Container>
				</Grid.Column>
				<Grid.Column xs={12} lg={4}>
					<Grid.Container>
						{thirdSection.map((item: { kind: string; size: string; id: string }, index: number) => {
							return (
								<Grid.Column key={index} xxl={12}>
									{getChart({
										type: item.kind,
										chartId: item.id,
									})}
								</Grid.Column>
							);
						})}
					</Grid.Container>
				</Grid.Column>
				<Grid.Column xs={12} lg={4}>
					<Grid.Container>
						{secondSection.map((item: { kind: string; size: string; id: string }, index: number) => {
							return (
								<Grid.Column key={index} xxl={12}>
									{getChart({
										type: item.kind,
										chartId: item.id,
									})}
								</Grid.Column>
							);
						})}
					</Grid.Container>
				</Grid.Column>
			</Grid.Container>
		</Styled.Container>
	);
};
export default ContentBodyPublicDashboard;
