import React, { lazy } from 'react';
import { Navigate, RouteObject, useParams } from 'react-router-dom';

import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import JoinedCampaignContainer from 'components/NewBriefPage/Components/JoinModal/JoinedCampaign/JoinedCampaignContainer';
import SettingsContainer from 'components/Settings';
import FacebookPages from 'components/Settings/Profiles/Components/FacebookPages';
import InfluencerLayout from 'layouts/influencer';
import InfluencerDashboard from 'views/influencer/InfluencerDashboard/InfluencerDashboardContainer';
import InvitationBriefPage from 'views/influencer/InvitationBriefPage/InvitationBriefPage';

import { LoadingContainer } from './CampaignRoutes';
import { ConnectGuard } from './layout-routes/ConnectGuard';
import { ProtectedRoute } from './layout-routes/ProtectedRoute';
import { lazyRetry } from './utils';

//@ts-ignore
const DeauthorizeInstagram = lazy(() => lazyRetry(() => import('views/Deauthorize/Instagram/DeauthorizeInstagram')));

//@ts-ignore
const IntegratedInbox = lazy(() => lazyRetry(() => import('views/influencer/IntegratedInbox/IntegratedInbox')));

//@ts-ignore
const Payment = lazy(() => lazyRetry(() => import('views/influencer/Payment/PaymentContainer')));

/**
 * Influencer Routes
 * Routes for influencers
 * @returns {Array<RouteObject>}
 */

const buildRedirectPath = (campaignId: string, ownerId: string, assignmentId: string) => {
	let path = '/influencer/campaigns';
	if (campaignId) path += `/${campaignId}`;
	if (ownerId) path += `/${ownerId}`;
	if (assignmentId) path += `/assignments/${assignmentId}`;
	return path;
};

const ContentManagementRedirect = () => {
	const { campaignId, ownerId, assignmentId } = useParams();
	const targetPath = buildRedirectPath(campaignId!, ownerId!, assignmentId!);

	return <Navigate to={targetPath} replace />;
};

const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<React.Suspense
				fallback={
					<LoadingContainer>
						<LoadingSpinner />
					</LoadingContainer>
				}
			>
				<ProtectedRoute>
					<InfluencerLayout isNoPaddingBottom={false} />
				</ProtectedRoute>
			</React.Suspense>
		),
		children: [
			{ index: true, element: <Navigate to='dashboard' /> },
			{
				path: 'dashboard',
				element: (
					<ConnectGuard>
						<InfluencerDashboard />
					</ConnectGuard>
				),
			},
			{
				path: 'settings',
				children: [
					{ index: true, element: <SettingsContainer /> },
					{ path: 'account', element: <SettingsContainer /> },
					{ path: 'profiles', element: <SettingsContainer /> },
					{ path: 'instagram', element: <SettingsContainer /> },
					{ path: 'delivery-info', element: <SettingsContainer /> },
					{ path: 'facebook-pages', element: <FacebookPages /> },
					{ path: '*', element: <PageNotFound /> },
				],
			},
			{
				path: 'payment',
				element: (
					<ConnectGuard>
						<Payment />
					</ConnectGuard>
				),
				children: [
					{ index: true, element: <Payment /> },
					{ path: 'collaborations', element: <Payment /> },
					{ path: 'affiliate', element: <Payment /> },
					{ path: 'settings', element: <Payment /> },
					{ path: 'settings/:type', element: <Payment /> },
					{ path: 'settings/:type/verified', element: <Payment /> },
					{ path: 'settings/:type/verification-failed', element: <Payment /> },
					{ path: '*', element: <PageNotFound /> },
				],
			},
			{
				path: 'content-management/:campaignId?/:ownerId?/:assignmentId?',
				element: <ContentManagementRedirect />,
			},
			{
				path: 'campaigns/:campaignId/:ownerId/assignments/:assignmentId',
				element: (
					<ConnectGuard>
						<InfluencerDashboard />
					</ConnectGuard>
				),
			},
			{
				path: 'inbox',
				children: [
					{
						path: ':campaignId?/:conversationId?',
						element: (
							<ConnectGuard>
								<IntegratedInbox />
							</ConnectGuard>
						),
					},
					{
						path: 'direct/:conversationId',
						element: (
							<ConnectGuard>
								<IntegratedInbox />
							</ConnectGuard>
						),
					},
					{ path: '*', element: <PageNotFound /> },
				],
			},
			{
				path: 'campaigns/:campaignId/brief/:influencerId',
				element: (
					<ConnectGuard>
						<InvitationBriefPage />
					</ConnectGuard>
				),
			},
			{
				path: 'campaigns/:campaignId/joined',
				element: (
					<ConnectGuard>
						<JoinedCampaignContainer />
					</ConnectGuard>
				),
			},
			{ path: 'instagram/deauthorize', element: <DeauthorizeInstagram /> },
		],
	},
	{ path: '*', element: <PageNotFound /> },
];

export default routes;
