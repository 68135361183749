import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'components/Button';
import Grid from 'styles/grid/grid';
import { scrollbarX } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const cm = colors.contentManagement;

const Wrapper = styled.div``;

const Topbar = styled.div`
	display: flex;
`;

const ButtonGroup = styled.div`
	display: flex;
	align-items: baseline;
	gap: 16px;
	margin-left: auto;
`;

const Form = styled.form`
	margin-bottom: 32px;
`;

const InputGroup = styled.div`
	${mediaQueries.large`
		width: 50%;
	`};
	&.input--url {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: -20px;
		.icon {
			margin-left: 8px;
			path {
				fill: ${colors.contentManagement.tabMenu.color};
			}
		}
	}

	&.input--date {
		width: 100%;
		${mediaQueries.medium`
			width: 33%;
		`};
	}

	input[disabled] {
		color: ${colors.black1};
		border-color: ${colors.black1};
	}
`;

const InputGroupRow = styled.div`
	width: 100%;
	gap: 16px;
	display: flex;
	flex-direction: column;
	.half {
		@media screen and (${devices.md}) {
			width: calc(50% - 8px);
		}
	}
	${mediaQueries.large`
		flex-direction: row;
		display: flex;
		gap: 16px;
	`};
`;

const Label = styled.label`
	display: block;
	font-family: ${typography.BaseFontFamiliy};
	font-size: 0.875rem;
`;

const ScreenhotsContainer = styled.div``;

const Screenshots = styled.div`
	position: relative;

	${mediaQueries.large`
		display: flex;
	`};
`;

const Screenshot = styled.div`
	position: relative;
	padding: 0.5rem;

	${mediaQueries.large`
		width: 25%;
		padding: 0 0.5rem;
	`};
`;

const ScreenshotImage = styled.img``;

const ConnnectedMesssage = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;
	.icon-div {
		margin-bottom: 3px;
		height: 20px;
		width: 20px;
		background-color: #d3e5e0;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		.icon {
			path {
				fill: #4ca867;
			}
		}
	}
	span {
		font-weight: 700;
	}
`;

const ConnectInfoAlert = styled.div`
	display: block;
	padding: 1rem;
	background-color: ${cm.statistics.connectInfoAlertBackground};
	border-radius: 10px;
	margin-bottom: 32px;
	color: ${cm.statistics.color};
	line-height: 1.3;
	font-size: 1rem;

	@media screen and (${devices.md}) {
		display: inline-block;
		line-height: 1;
	}

	.d-flex {
		display: block;
		align-items: center;

		@media screen and (${devices.lg}) {
			display: flex;
		}
	}

	.text {
		display: block;
		margin-bottom: 8px;
		@media screen and (${devices.lg}) {
			margin-bottom: 0;
		}
	}

	.divider {
		display: none;
		position: relative;
		width: 1px;
		height: 24px;
		background-color: ${colors.black1};
		margin: 0 16px;

		@media screen and (${devices.lg}) {
			display: block;
		}
	}
`;

const FacebookConnectLink = styled(Link)`
	min-width: 190px;
	&,
	&:hover {
		color: ${cm.statistics.color};
		text-decoration: underline;
	}
`;

const StoryListWrapper = styled.div`
	margin-bottom: 32px;
`;

const InsightsText = styled.div`
	margin-top: 32px;
	margin-bottom: 16px;

	h3 {
		margin-bottom: 8px;
	}

	h5 {
		font-size: 1rem;
	}

	p {
		margin-top: 0;
	}
`;

const SubmitSection = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: ${colors.mainBlack};
	z-index: 10;
	bottom: 0;
	width: 100%;
	padding: 1rem;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ErrorMessage = styled.p`
	font-size: 0.875rem;
	color: ${colors.error};
`;

const ErrorMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const HeadingWrapper = styled.div`
	margin-bottom: 24px;
`;

const CommentsButton = styled(Button)`
	border: none;
	background-color: ${colors.buttonGray};
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	.icon {
		margin-top: 5px;
	}
	&:hover {
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
`;

const FieldsWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 300px;
	gap: 16px;
	display: flex;
	flex-direction: column;
	.help {
		margin-bottom: 40px;
	}
	@media screen and (${devices.md}) {
		margin-bottom: 100px;
	}
`;

const AutomaticStats = styled(Grid.Container)`
	width: 100%;
	padding: 2rem 2rem 2rem;
	background-color: ${colors.buttonGray};
	border-radius: 10px;
	margin-bottom: 120px;
	.gray-text {
		color: ${colors.contentManagement.gray};
		margin-top: 20px;
		font-size: 0.875rem;
		margin-bottom: 0;
	}
`;

const AutomaticStatsValue = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	@media screen and (${devices.md}) {
		margin-bottom: 0;
	}
	p {
		font-size: 0.75rem;
		color: ${colors.contentManagement.helpText};
		margin-bottom: 8px;
	}
	span {
		font-weight: 600;
		font-size: 1.2rem;
	}
`;

const AddScreenShotsSection = styled.div`
	width: auto;
	margin-bottom: 24px;
`;

const Image = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 12px;
	padding: 0.1875rem;
	transition:
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
`;

const ImageWrapper = styled.div`
	display: block;
	overflow: hidden;
	padding-bottom: 100%;
	position: relative;
	margin-bottom: 18px;
	border: 2px solid transparent;
	border-radius: 13px;
	transition:
		border 200ms ease-in-out,
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
	overflow: hidden;
	width: 150px;

	&.tall {
		padding-bottom: 0;
	}
`;

const StoryWrapper = styled.div`
	margin: 0 8px;
	transition:
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	&.deleted {
		width: 0;
		margin: 0;
		opacity: 0;
		transition: 1s all ease-out;
	}
	&:hover:not(.disabled),
	&.selected {
		${ImageWrapper} {
			box-shadow: 0 4px 3px ${colors.StoryList.selected.boxShadow};
			${Image} {
				transform: scale(1.05);
			}
		}
	}

	&.disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

const ShowFullSizeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: ${colors.contentReview.fullscreenButtonBackground};
	cursor: pointer;
	border-radius: 4px;
	padding: 0.5rem;
	z-index: 10;
	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const UrlWrapper = styled.div`
	margin-top: 40px;
	hr {
		margin-top: 80px;
	}
`;

const DropZone = styled.div`
	cursor: pointer;
	background: ${colors.form.dropzone.background};
	width: 200px;
	height: 100%;
	border-radius: 10px;
	height: 342px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const HelpText = styled.p`
	font-size: 0.625rem;
	color: ${colors.contentManagement.grayText};
`;

const RemoveFile = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 8px;
	p {
		&.name {
			font-size: 0.75rem;
			max-width: 130px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-top: 0;
		}
		&.remove {
			margin-top: 0;
			color: ${colors.info};
			font-size: 0.75rem;
		}
	}
`;

const Title = styled.p`
	margin-bottom: 0;
`;

const DisputeWrapper = styled.div`
	display: flex;
	justify-content: end;
`;

const DropZoneText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	.icon {
		margin-bottom: 8px;
	}
`;

const ScreenShotSection = styled.div`
	display: flex;
	width: 87vw;
	${scrollbarX};
	@media screen and (${devices.md}) {
		width: 100%;
		${scrollbarX};
	}
`;

const ListScreenShots = styled.div`
	display: flex;
`;

const DateSection = styled.div`
	hr {
		margin-top: 40px;
	}

	.rs-input-group-lg.rs-input-group > .rs-input,
	.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
		height: auto;
	}
`;

const Overview = styled.div``;

const ScreenshotWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 40px;
`;

const InvalidText = styled.p``;

const InfoSection = styled.div`
	margin-bottom: 24px;
	width: 100%;
	a {
		overflow-wrap: break-word;
	}
`;

const ManualEditButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const ManualEditButton = styled.button<{ editMode?: boolean }>`
	background-color: transparent;
	border: none;
	transition: transform 0.2s ease-in-out;

	&.edit {
		transform: ${({ editMode }) => (editMode ? 'translateX(0px)' : 'translateX(120px)')};
		color: ${({ editMode }) => (editMode ? colors.gray8 : colors.info)};
		&:hover {
			font-weight: ${({ editMode }) => !editMode && 600};
		}
	}

	&.cancel {
		transform: ${({ editMode }) => (editMode ? 'translateX(0)' : 'translateX(160px)')};
		color: ${({ editMode }) => (editMode ? colors.info : colors.gray8)};
	}

	&.done {
		transform: ${({ editMode }) => (editMode ? 'translateX(0)' : 'translateX(100px)')};
		color: ${({ editMode }) => (editMode ? colors.info : colors.gray8)};
	}
`;

const SpinnerWrapper = styled.div`
	margin-left: 8px;
`;

const ApprovedWithNoStatsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2.5rem;
	height: 100%;

	p {
		margin: 0;
		font-size: 1.25rem;
	}
`;

const Styled = {
	SpinnerWrapper,
	InfoSection,
	InvalidText,
	ScreenshotWrapper,
	Overview,
	DateSection,
	ListScreenShots,
	ScreenShotSection,
	DropZoneText,
	DisputeWrapper,
	Title,
	RemoveFile,
	HelpText,
	DropZone,
	UrlWrapper,
	StoryWrapper,
	Image,
	ImageWrapper,
	AddScreenShotsSection,
	AutomaticStatsValue,
	AutomaticStats,
	FieldsWrapper,
	HeadingWrapper,
	ErrorMessage,
	ErrorMessageContainer,
	SubmitSection,
	Wrapper,
	Topbar,
	ButtonGroup,
	Form,
	InputGroup,
	InputGroupRow,
	Label,
	ScreenhotsContainer,
	Screenshot,
	Screenshots,
	ScreenshotImage,
	ConnnectedMesssage,
	ConnectInfoAlert,
	FacebookConnectLink,
	StoryListWrapper,
	InsightsText,
	CommentsButton,
	ShowFullSizeButton,
	ManualEditButtonWrapper,
	ManualEditButton,
	ApprovedWithNoStatsWrapper,
};

export default Styled;
