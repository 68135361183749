import { BackendError } from 'components/Payment/types';

import { DetailItem } from './types';

export const DefaultItem = {
	title: 'Background',
	placeholder: 'Write here...',
	content: '',
};

export const DefaultItems = [
	DefaultItem,
	{
		title: 'Purpose',
		placeholder: 'Write here...',
		content: '',
	},
	{
		title: 'Target / KPI',
		placeholder: 'Write here...',
		content: '',
	},
];

export const NewItem = {
	title: 'New text field',
	placeholder: 'Write here...',
	content: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInitals = (name: string) => {
	if (!name) {
		return '';
	}

	if (name.length === 2) {
		return name;
	} else if (name?.indexOf(' ') >= 0) {
		return name
			.split(' ')
			.map((word: string) => word[0])
			.join('')
			.slice(0, 2);
	} else {
		return name.charAt(0);
	}
};

export const compare = (a: string, b: string) => {
	const nameA = a?.toUpperCase();
	const nameB = b?.toUpperCase();

	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
};

interface RectangleXY {
	x: number;
	y: number;
	height: number;
	width: number;
}

interface RectangleTopLeft {
	height: number;
	width: number;
	top: number;
	left: number;
}

export const isOverlapping = (isMobileView: boolean, rect1: RectangleXY, rect2: RectangleXY) => {
	return isMobileView
		? rect1.y < rect2.y + rect2.height / 2 && rect1.y + rect1.height / 2 > rect2.y
		: rect1.x < rect2.x + rect2.width / 2 && rect1.x + rect1.width / 2 > rect2.x;
};

export const createDiv = (width: number, height: number) => {
	const div = document.createElement('div');
	div.id = 'temp-div';
	div.style.width = width + 'px';
	div.style.height = height + 'px';
	div.style.pointerEvents = 'none';
	return div;
};

export const addStyle = (itemBeingDragged: HTMLDivElement, dragBouncingRect: RectangleTopLeft) => {
	itemBeingDragged.style.position = 'fixed';
	itemBeingDragged.style.zIndex = '500';
	itemBeingDragged.style.width = dragBouncingRect.width + 'px';
	itemBeingDragged.style.height = dragBouncingRect.height + 'px';
	itemBeingDragged.style.top = dragBouncingRect.top + 'px';
	itemBeingDragged.style.left = dragBouncingRect.left + 'px';
	itemBeingDragged.style.cursor = 'grabbing';
	return itemBeingDragged;
};

export const getSpace = (items: HTMLDivElement[]) => {
	return items[1].getBoundingClientRect().top - items[0].getBoundingClientRect().bottom;
};

export const getTitle = (error: BackendError, details: DetailItem[]) => {
	if (error.source?.parameter?.includes('details')) {
		const regex = /\d+/g;
		const index: string | undefined = error.source.parameter?.match(regex)?.toString();
		const title = index ? details[parseInt(index)]?.title : 'Details';
		return title;
	} else if (error.source?.parameter?.includes('hashtag')) {
		return 'Hashtags';
	} else if (error.source?.parameter?.includes('mention')) {
		return 'Mentions';
	} else {
		return error.source?.parameter;
	}
};

export const getMessage = (error: BackendError) => {
	if (error.source?.parameter?.indexOf('details') > -1) {
		return 'details';
	} else if (error.source?.parameter === 'name') {
		return 'You need to add a campaign title.';
	} else if (error.source?.parameter === 'brand') {
		return 'You need to select a brand for your campaign.';
	} else if (error.source?.parameter === 'influencerTargetCount') {
		return 'You need to add how many spots this campaign will have.';
	} else {
		return error.source?.message;
	}
};

export const getScrollId = (error: BackendError) => {
	if (error.source?.parameter.includes('details')) {
		return 'details';
	} else if (error.source?.parameter.includes('hashtag')) {
		return 'spots';
	} else if (error.source?.parameter.includes('mention')) {
		return 'spots';
	} else {
		return error.source?.parameter;
	}
};

export const mapParams = (param: string) => {
	switch (param) {
		case 'influencerTargetCount':
			return 'Influencer spots';
		case 'name':
			return 'Campaign title';
		case 'brand':
			return 'Brand';
		default:
			return param;
	}
};

export const mapMessages = (message: string) => {
	switch (message) {
		case 'details':
			return "This section can't be empty, please remove it if you don't need it.";
		default:
			return message;
	}
};
