import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

import { BlueButton } from 'components/Button/Button';
import Icon from 'components/Icon';
import SPCStyled from 'components/SocialProfileConnector/SocialProfileConnector.style';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useLogger from 'hooks/useLogger';
import FacebookAuthService from 'services/FacebookApi/Facebook-auth.service';
import { ErrorType } from 'services/FacebookApi/types';
import toast from 'services/Toast';
import { login } from 'shared/facebook/facebook-sdk';
import { loginResponse } from 'shared/facebook/types';
import { gtagEvent } from 'utils/ga';

import Styled from './FacebookConnector.style';

type FacebookConnectorProps = {
	connectionSucceeded: (getPages: boolean) => void;
};

/**
 * FacebookConnector
 * Component to connect with Facebook (Meta)
 * @param {FacebookConnectorProps} props
 * @returns {JSX.Element}
 *
 */
const FacebookConnector = ({ connectionSucceeded }: FacebookConnectorProps): JSX.Element => {
	const { log } = useLogger();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [instagramToken, setInstagramToken] = useState<string>('');

	const connectWithInstagram = () => {
		login()
			.then((data: loginResponse) => {
				if (isNil(data.authResponse) && data.status === 'unknown') {
					gtagEvent('FB-connect-failed-no-page');
				}
				if (!data.authResponse) {
					setIsLoading(false);
				} else {
					setInstagramToken(data.authResponse.accessToken);
				}
			})
			.catch((e) => {
				toast.error(getSomethingWentWrongMessage());
				log('window.FB.login error', e);
			});
	};

	useEffect(() => {
		if (instagramToken !== '') {
			setIsLoading(true);
			FacebookAuthService.tokens(instagramToken)
				.then(() => {
					connectionSucceeded(true);
					setIsLoading(false);
				})
				.catch((e) => {
					const response = e.response;
					if (response.data.errors && response.data.errors.length) {
						response.data.errors.map((error: ErrorType) => {
							if (error.code === '2900-101') {
								toast.error('We are currently experiencing issues with Facebook. Please try again later.');
								setIsLoading(false);
							} else if (error.code === '2010') {
								toast.error('You need a business account to be able to connect');
								connectionSucceeded(false);
							} else {
								// Render toast if we don't know what happend.
								toast.error(getSomethingWentWrongMessage());
								log('facebook/tokens', error);
							}
						});
						setIsLoading(false);
					}
					setIsLoading(false);
				});
		}
	}, [instagramToken]);

	return (
		<SPCStyled.ConnectSection>
			<p>Connect your Instagram business account to unlock promotional insights.</p>
			<BlueButton isLoading={isLoading} data-testid='connect-button' size='lg' onClick={() => connectWithInstagram()}>
				Connect with Instagram
			</BlueButton>
			<div>
				<Styled.Text>To access insights, your Instagram account must be linked to a Facebook page.</Styled.Text>
				<hr />
				<Styled.CustomLink target='_blank' rel='noreferrer noopener' to='https://www.facebook.com/business/learn/lessons/create-an-instagram-business-profile'>
					<Icon name='external-link' size='16' /> How to create an Instagram business account
				</Styled.CustomLink>
				<Styled.CustomLink target='_blank' rel='noreferrer noopener' to='https://www.facebook.com/help/instagram/570895513091465'>
					<Icon name='external-link' size='16' /> How to connect an Instagram business account to a Facebook page
				</Styled.CustomLink>
			</div>
		</SPCStyled.ConnectSection>
	);
};
export default FacebookConnector;
