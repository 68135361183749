import classNames from 'classnames';

import Icon from 'components/Icon';
import { UserUploadStatus } from 'shared/Types/Assignment';

import Styled from './MediaStatusMessage.style';

type MediaStatusProps = {
	uploadStatus: UserUploadStatus;
};

/**
 * MediaStatus
 * @param {MediaStatusProps} props
 * @returns {JSX.Element}
 */
const MediaStatusMessage = (props: MediaStatusProps): JSX.Element => {
	// Let us combine these until we need a specific error message for each error
	const errorStatuses = [UserUploadStatus.ERROR, UserUploadStatus.PROCESS_FAILED, UserUploadStatus.UPLOAD_FAILED];
	const hasError = errorStatuses.includes(props.uploadStatus);
	return (
		<Styled.MediaStatus className={classNames({ error: hasError })}>
			{props.uploadStatus === UserUploadStatus.PROCESSING && (
				<span>
					File is uploaded. We are currently processing it.
					<br /> It will be ready in a few minutes.
				</span>
			)}

			{props.uploadStatus === UserUploadStatus.INIT && (
				<span>
					File is uploading.
					<br /> It will be ready in a few minutes.
				</span>
			)}

			{hasError && (
				<>
					<Icon name='circle-cross' />
					<p className='text-strong'>Something went wrong</p>
					<p>Sorry, but you need to upload the file again.</p>
				</>
			)}
		</Styled.MediaStatus>
	);
};

export default MediaStatusMessage;
