import styled from 'styled-components';

import colors from 'styles/variables/colors';
import { devices, mediaQueries } from 'styles/variables/media-queries';

const cm = colors.contentManagement;

const Wrapper = styled.div`
	background-color: ${cm.contentSection.backgroundColor};
	padding: 0 1rem;
	padding-bottom: 15rem;
	&.is-uploading {
		height: 80vh;
		overflow: hidden;
	}
`;

const Header = styled.div`
	display: flex;
	position: relative;
	border-bottom: 1px solid ${cm.wizard.header.borderBottomColor};
	min-height: 56px;
	align-items: center;
	justify-content: center;

	h2 {
		font-size: 1.25rem;
		margin-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 390px;
		text-align: center;
		cursor: pointer;

		${mediaQueries.xl`
				width: 730px;
		`};
	}

	.toggle-right {
		margin-left: auto;
	}

	${mediaQueries.xl`
		display: none;
	`};
`;

const BackButton = styled.button`
	position: absolute;
	top: 8px;
	left: 16px;
	background-color: transparent;
	border: 0;
	padding: 0;
	height: 32px;
	cursor: pointer;

	${mediaQueries.xl`
		display: none;
	`};
`;

const BriefInfoButton = styled.button`
	position: absolute;
	top: 8px;
	right: 16px;
	background-color: transparent;
	border: 0;
	padding: 0;
	height: 32px;
	cursor: pointer;

	${mediaQueries.xl`
		display: none;
	`};
`;

const FormWrapper = styled.div`
	padding: 1rem;

	${mediaQueries.large`
			padding: 1rem 2rem;
	`};
`;

const ContentList = styled.div``;

const BreadCrumbs = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	line-height: 2;
	margin-bottom: 16px;
	@media screen and (${devices.md}) {
		font-size: 1.2rem;
	}
	font-weight: 600;
	a {
		width: fit-content;
		white-space: nowrap;
		color: ${colors.mainBlack} !important;
		border-bottom: 2px solid ${colors.mainBlack} !important;
	}
	div {
		&.title {
			width: fit-content;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: ${colors.mainBlack};
		}
	}
	a:visited {
		color: ${colors.mainBlack} !important;
	}
`;

const Divider = styled.div`
	margin: 0 16px;
`;
const Styled = {
	BreadCrumbs,
	Divider,
	Wrapper,
	Header,
	BackButton,
	FormWrapper,
	ContentList,
	BriefInfoButton,
};

export default Styled;
