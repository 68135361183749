import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const InfluencerNameWrapper = styled.div`
	width: auto;

	button {
		padding: 0;
	}
`;

const InfluencerName = styled.div`
	display: flex;
	border-radius: ${borderRadius.m};
	padding: 0.2rem 0.4rem 0.3rem 0.9rem;
	transition: background-color 0.2s ease-in-out;

	span {
		display: block;
		max-width: 170px;
		font-weight: ${({ theme }) => theme.fontWeights.semi};
		font-size: 1rem;
		line-height: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&:hover {
		background-color: ${colors.ash};
	}
	.dropdown-button {
		.icon {
			padding-top: 5px !important;
			width: 20px;
			height: 20px;
		}
	}
`;

const InfluencerSubText = styled.div`
	font-weight: ${({ theme }) => theme.fontWeights.medium};
	white-space: nowrap;
	display: flex;
	align-items: center;
	width: fit-content;
	margin-left: 14px;
	font-size: 0.875rem;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3125rem;

	.dropdown-button {
		.icon {
			padding-top: 0.3rem;
			width: 20px;
			height: 20px;
		}
	}
`;

const LinkToNetwork = styled.a`
	display: flex !important;
	align-items: center;
	gap: 0.5rem;
	color: ${colors.ash};
	padding: 0.5rem;
	font-weight: ${({ theme }) => theme.fontWeights.medium};
	border-bottom: none !important;

	span {
		font-weight: ${({ theme }) => theme.fontWeights.normal};
		font-size: 0.8438rem;
	}

	.icon {
		margin-left: auto;
	}
`;

const EmailWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.875rem;
	width: 100%;

	span {
		font-weight: ${({ theme }) => theme.fontWeights.normal};
	}
`;

const RequestButton = styled.button`
	cursor: pointer;
	background-color: transparent;
	margin: 0 0 0 auto;
	border: none;

	.icon {
		path {
			fill: ${colors.black};
		}
	}
`;

const GrayWrapper = styled.div`
	background-color: ${colors.mist};
	border-radius: ${({ theme }) => theme.radius.default};
`;

const Styled = {
	GrayWrapper,
	EmailWrapper,
	InfluencerNameWrapper,
	InfluencerName,
	InfluencerSubText,
	Container,
	LinkToNetwork,
	RequestButton,
};

export default Styled;
