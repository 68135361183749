import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	padding: 0 1rem;
	padding-top: 1rem;

	&.summary-explanation {
		padding-left: 0;
	}

	button {
		font-size: 0.875rem;
		line-height: 1;
		color: ${colors.dataLibrary.chartExplanationColor};
		font-weight: 400;
	}
`;

const InnerWrapper = styled.div`
	font-size: 0.875rem;
	line-height: 21px;
	& > span {
		display: block;
		&.title {
			font-weight: 600;
		}
	}
`;

const Styled = {
	Wrapper,
	InnerWrapper,
};

export default Styled;
