// API assignment types
export const INSTAGRAM_POST = 'instagram_post';
export const INSTAGRAM_STORY = 'instagram_story';
export const INSTAGRAM_REEL = 'instagram_reel';
export const TIKTOK_VIDEO = 'tiktok_video';

// Dashboard MetaData key
export const DASHBOARD_CAMPAIGNS = 'dashboard-campaigns';
export const DASHBOARD_PERIOD = 'dashboard-period';
export const DASHBOARD_ASSIGNMENTS = 'dashboard-assignments';
export const DASHBOARD_FILTER = 'filter';

// Date filter period
export const TODAY = 'today';
export const WEEK = 'week';
export const MONTH = 'month';

// Chart size options
export const LARGE = 'large';
export const MEDIUM = 'medium';
export const SMALL = 'small';

// Chart Items
export const REACH = {
	SUMMARY: 'reach-summary',
	SUMMARY_TITLE: 'Summary',
	SUMMARY_ICON: 'sales-budget',

	TOTAL: 'reach-overall',
	TOTAL_TITLE: 'Total reach',
	TOTAL_ICON: 'sales-budget',

	GROSS: 'reach-gross',
	GROSS_TITLE: 'Total gross reach',
	GROSS_ICON: 'sales-budget',

	UNIQUE: 'reach-unique',
	UNIQUE_TITLE: 'Est. unique reach',
	UNIQUE_ICON: 'sales-budget',

	BY_SOCIAL_MEDIA: 'reach-overall-media-objects',
	BY_SOCIAL_MEDIA_TITLE: 'Total reach by channel',
	BY_SOCIAL_MEDIA_ICON: 'traffic',

	OVERTIME: 'reach-overtime-all',
	OVERTIME_TITLE: 'Reach over time',
	OVERTIME_ICON: 'impressions',

	BY_INFLUENCER: 'reach-influencer',
	BY_INFLUENCER_TITLE: 'Gross reach by influencer',
	BY_INFLUENCER_ICON: 'sales-budget',

	REACH_AND_CPR_BY_INFLUENCER: 'reach-and-cpr-by-influencer',
	REACH_AND_CPR_BY_INFLUENCER_TITLE: 'Reach and CPR by influencer',
	REACH_AND_CPR_BY_INFLUENCER_ICON: 'sales-budget',

	REACH_AND_ENGAGEMENT_PER_CAMPAIGN: 'reach-and-engagement-per-campaign',
	REACH_AND_ENGAGEMENT_PER_CAMPAIGN_TITLE: 'Reach and Engagement by campaign',
	REACH_AND_ENGAGEMENT_PER_CAMPAIGN_ICON: 'sales-budget',
};

export const IMPRESSIONS = {
	SUMMARY: 'impressions-summary',

	TOTAL: 'impressions-overall',
	TOTAL_TITLE: 'Total impressions',
	TOTAL_ICON: 'sales-budget',

	BY_ASSIGNMENT_TYPE: 'impressions-overall-media-objects',
	BY_ASSIGNMENT_TYPE_TITLE: 'Impressions by channel',
	BY_ASSIGNMENT_TYPE_ICON: 'traffic',

	OVERTIME: 'impressions-overtime-all',
	OVERTIME_TITLE: 'Impressions over time',
	OVERTIME_ICON: 'impressions',

	BY_INFLUENCER: 'impressions-influencer',
	BY_INFLUENCER_TITLE: 'Impressions by influencer',
	BY_INFLUENCER_ICON: 'sales-budget',
};

export const ENGAGEMENT = {
	SUMMARY: 'engagement-summary',
	TOTAL: 'engagement-overall-rate',
	TOTAL_TITLE: 'Total engagement',
	TOTAL_ICON: 'sales-budget',

	TOTAL_LIKES: 'engagement-overall-likes',
	TOTAL_LIKES_TITLE: 'Total likes',
	TOTAL_LIKES_ICON: 'sales-budget',

	TOTAL_COMMENTS: 'engagement-overall-comments',
	TOTAL_COMMENTS_TITLE: 'Total comments',
	TOTAL_COMMENTS_ICON: 'sales-budget',

	TOTAL_SHARES: 'engagement-overall-shares',
	TOTAL_SHARES_TITLE: 'Total shares',
	TOTAL_SHARES_ICON: 'sales-budget',

	TOTAL_SAVES: 'engagement-overall-saves',
	TOTAL_SAVES_TITLE: 'Total saves',
	TOTAL_SAVES_ICON: 'sales-budget',

	BY_INFLUENCER: 'engagement-influencer',
	BY_INFLUENCER_TITLE: 'Engagement by influencer',
	BY_INFLUENCER_ICON: 'list',

	ENGAGEMENT_AND_CPE_BY_INFLUENCER: 'engagement-cpe-by-influencer',
	ENGAGEMENT_AND_REACH_BY_INFLUENCER: 'engagement-and-reach-by-influencer',
	ENGAGEMENT_AND_REACH_BY_INFLUENCER_TITLE: 'Engagement and Reach by influencer',

	ENGAGEMENT_CPE: 'engagement-an-cpe',
	ENGAGEMENT_CPE_TITLE: 'Engagement and CPE by influencer',
	ENGAGEMENT_CPE_ICON: 'sales-budget',

	BY_ASSIGNMENT_TYPE: 'engagement-by-assignment-type',
	BY_ASSIGNMENT_TYPE_TITLE: 'Engagement by channel',
	BY_ASSIGNMENT_TYPE_ICON: 'traffic',
};

export const TRAFFIC = {
	SUMMARY: 'traffic-summary',

	TOTAL_CLICKS: 'traffic-overall-link',
	TOTAL_CLICKS_TITLE: 'Total Clicks',
	TOTAL_CLICKS_ICON: 'sales-budget',

	CLICKS_OVERTIME: 'traffic-overtime-link',
	CLICKS_OVERTIME_TITLE: 'Clicks over time',
	CLICKS_OVERTIME_ICON: 'impressions',
	CTR: 'traffic-ctr',
	CTR_TITLE: 'CTR',
	CTR_ICON: 'sales-budget',

	BY_INFLUENCER: 'traffic-by-influencer',
	BY_INFLUENCER_TITLE: 'Clicks by influencer',
	BY_INFLUENCER_ICON: 'list',

	CTR_BY_INFLUENCER: 'ctr-by-influencer',
	CTR_BY_INFLUENCER_TITLE: 'CTR by influencer',
	CTR_BY_INFLUENCER_ICON: 'list',
};

export const AUDIENCE = {
	AGE: 'audience-age',
	AGE_TITLE: 'Audience by age',
	AGE_ICON: 'traffic',

	GENDER: 'audience-gender',
	GENDER_TITLE: 'Audience by gender',
	GENDER_ICON: 'audience',

	COUNTRY: 'audience-country',
	COUNTRY_TITLE: 'Audience by country',
	COUNTRY_ICON: 'traffic',
};

export const BUDGET = {
	SUMMARY: 'budget-summary',

	TOTAL: 'budget-overall',
	TOTAL_TITLE: 'Total budget',
	TOTAL_ICON: 'sales-budget',

	BY_COUNTRY: 'budget-overall-market',
	BY_COUNTRY_TITLE: 'Budget spent by country',
	BY_COUNTRY_ICON: 'traffic',

	CPM: 'overall-cpm',
	CPM_TITLE: 'CPM',
	CPM_ICON: 'sales-budget',

	CPC: 'overall-cpc',
	CPC_TITLE: 'CPC',
	CPC_ICON: 'sales-budget',

	OVERTIME: 'budget-overtime',
	OVERTIME_TITLE: 'Budget over time',
	OVERTIME_ICON: 'impressions',
};

export const SALES = {
	SUMMARY: 'sales-summary',

	TOTAL: 'sales-overall',
	TOTAL_TITLE: 'Total order value',
	TOTAL_ICON: 'sales-budget',

	OVERTIME: 'sales-overtime',
	OVERTIME_TITLE: 'Sales over time',
	OVERTIME_ICON: 'impressions',

	TOTAL_CONVERSIONS: 'sales-total-conversions',
	TOTAL_CONVERSIONS_TITLE: 'Total conversions',
	TOTAL_CONVERSIONS_ICON: 'sales-budget',

	CONVERSIONS_BY_INFLUENCER: 'sales-conversions-by-influencer',
	CONVERSIONS_BY_INFLUENCER_TITLE: 'Conversions per influencer',
	CONVERSIONS_BY_INFLUENCER_ICON: 'dl-influencers',

	ORDER_VALUE_BY_INFLUENCER: 'order-value-by-influencer',
	ORDER_VALUE_BY_INFLUENCER_TITLE: 'Order value per influencer',
	ORDER_VALUE_BY_INFLUENCER_ICON: 'dl-influencers',
};

export const INFLUENCERS = {
	SUMMARY: 'influencers-summary',
	SUMMARY_TITLE: 'Influencer summary',
	SUMMARY_ICON: 'dl-influencers',
};

export const PAID_MEDIA = {
	SUMMARY: 'paid-media-summary',
	SUMMARY_TITLE: 'Paid media summary',
	SUMMARY_ICON: 'dl-influencers',
};

export const MEDIA_OBJECTS = {
	NETWORK_TITLE: 'Activations by channel',
	NETWORK_BAR: 'media-objects-network-bar',
	NETWORK_BAR_ICON: 'traffic',

	NETWORK_CIRCLE: 'media-objects-network-circle',
	NETWORK_CIRCLE_ICON: 'audience',

	CAMPAIGN_TITLE: 'Activations by campaign',
	CAMPAIGN_BAR: 'media-objects-campaign-bar',
	CAMPAIGN_BAR_ICON: 'traffic',

	CAMPAIGN_CIRCLE: 'media-objects-campaign-circle',
	CAMPAIGN_CIRCLE_ICON: 'audience',

	COUNTRY_TITLE: 'Activations by country',
	COUNTRY_BAR: 'media-objects-country-bar',
	COUNTRY_BAR_ICON: 'traffic',

	COUNTRY_CIRCLE: 'media-objects-country-circle',
	COUNTRY_CIRCLE_TITLE: 'media-objects-country-circle',
	COUNTRY_CIRCLE_ICON: 'audience',

	BY_INFLUENCER_TITLE: 'Activations by influencer',
	BY_INFLUENCER: 'media-objects-by-influencer',
	BY_INFLUENCER_ICON: 'dl-influencers',
};

/**
 */
export const EXPLANATIONS = {
	REACH: {
		TOTAL:
			'Total reach refers to the unique number of people who’ve seen an influencer’s content, including the influencer’s own followers and those who might come across the content through shares and explore pages.',
		ACTUAL:
			"Actual reach refers to the total number of people who've been exposed to an influencer's content, including both unique and duplicate views. It takes into account every instance of a piece of content being viewed, regardless of whether the viewer is a unique individual or has viewed the content multiple times.",
		BY_FORMAT:
			'Total reach by format refers to the unique number of people who saw a particular type of content format, such as posts, stories, videos etc., that an influencer shared on social media. This metric helps to identify which formats were most effective and drove the highest engagement in this campaign.',
		OVERTIME:
			"Reach over time refers to how many people have seen an influencer's content over a specific period of time. By analyzing reach over time, influencers can determine the optimal posting frequency, time, and content type to maximize their audience reach and engagement.",
		BY_INFLUENCER: 'Total conversions per influencer refers to the total number of conversions made in the campaign due to a specific influencer.',
		REACH_CPR_BY_INFLUENCER:
			"Reach and CPR (Cost Per Reach) by influencer refers to the number of people who have been exposed to an influencer's content and the cost associated with reaching those people, respectively. By tracking reach and CPR by influencer, you can identify which influencers are able to reach the largest audience for the lowest cost, maximizing the ROI of their influencer marketing efforts.",
		REACH_ENGAGEMENT_BY_CAMPAIGN:
			'Reach and engagement by campaign refers to the number of people who have been exposed to a specific influencer marketing campaign and the level of engagement that campaign has generated, respectively.',
	},
	IMPRESSIONS: {
		TOTAL:
			'Total impression refers to the number of times a content form has been viewed or displayed, regardless of whether it has been viewed by a unique account or the same person multiple times.',
		BY_CHANNEL:
			'Impressions by channel refers to the number of times a piece of content has been viewed or displayed on a specific social media channel, such as Instagram or TikTok.',
		OVERTIME:
			'Impressions over time refers to the change in the number of times a piece of content has been viewed over a specified period of time. This metric helps to measure the effectiveness of content over time and can be useful for identifying trends and patterns in audience engagement.',
		BY_INFLUENCER:
			'Impressions by influencer refers to the number of times an influencer’s content has been viewed, distributed between all profiles in a campaign. This metric helps to identify which influencers are most effective at reaching and engaging their audiences in a specific campaign.',
	},
	SALES_AND_BUDGET: {
		BUDGET_TOTAL:
			'Total budget refers to the overall amount of money allocated for your campaigns, including the cost of influencer fees and other any additional expenses.',
		BUDGET_OVERTIME:
			'Budget over time refers to the change in the amount of money allocated for your campaigns over a specific period of time. By tracking budget over time, you can adjust your spending to optimize ROI and ensure that your campaigns are meeting your goals within budget.',
		SALE_TOTAL: 'Total sales refers to the overall revenue generated from an influencer marketing campaign.',
		SALE_OVERTIME: 'Sales over time refers to the change in revenue generated from an influencer marketing campaign over a specific period of time.',
		BUDGET_PER_COUNTRY:
			'Budget spent by country refers to the amount of money allocated for your campaign in each specific country where the campaign is being run. This metric helps identify which countries generate the highest ROI.',
		CPC: "CPC (Cost per click) is a metric that measures the cost of each click on your influencer campaign. It's calculated by dividing the total cost of the campaign by the number of clicks generated.",
		CPM: 'CPM (Cost per mille or a thousand impressions) is a metric that measures the cost of every thousand impressions of your campaign. It’s calculated by dividing the total cost of the campaign by the number of impressions generated, then multiplying by 1,000.',
		CONVERSIONS_BY_INFLUENCER:
			'Total sales per influencer refers to the total number of items that have been purchased in the campaign due to a specific influencer.',
		ORDER_VALUE_BY_INFLUENCER: 'Total order value refers to the total amount of all conversations due to a specific influencer.',
		TOTAL_CONVERSIONS: 'Total conversion refers to the total number of conversions made in the campaign.',
	},
	TRAFFIC: {
		CLICKS_TOTAL: 'Total clicks refers to the number of clicks generated from your campaigns, measured by the number of clicks on a link or call-to-action.',
		CTR: 'CTR (Click-through rate) is a metric that measures the percentage of clicks on your campaigns compared to the total number of impressions the campaign received. A high CTR indicated that the campaign is resonating well with the target audience. While a lower CTR can indicate that the campaign needs to be optimized or that the target audience needs to be adjusted.',
		CLICKS_BY_INFLUENCER: 'Clicks by influencer refers to the number of clicks generated from each individual influencer.',
		CTR_BY_INFLUENCER:
			'CTR (Click-through rate) by influencer refers to the CTR for each individual influencer in your campaign. This metric helps to identify which influencers are most effective in driving clicks and engagement with the campaign, and can be used to optimize future campaigns by working with those influencers that have the highest CTR.',
	},
	AUDIENCE: {
		BY_AGE: 'Audience by age breaks down the age group of the audience that has been reached by your campaign.',
		BY_COUNTRY: 'Audience by country breaks down the countries where the audience in your campaign are located.',
		BY_GENDER: 'Audience by gender breaks down the male and female gender of the audience that has been reached by your campaign.',
	},
	ENGAGEMENT: {
		ENGAGEMENT_TOTAL:
			'Total Engagement refers to the overall number of interactions that your campaign received from its audience. This metric takes into account all the likes, comments, shares, and saves generated by the campaign, and provides an overall measure of the level of engagement that the campaign has driven.',
		LIKES_TOTAL: 'Total Likes refers to the number of likes that your campaign has received from its audience.',
		COMMENTS_TOTAL: 'Total comments refers to the number of comments that your campaign has received from its audience.',
		SHARES_TOTAL: 'Total Shares refers to the number of shares that your campaign has received from its audience.',
		SAVES_TOTAL: 'Total Saves refers to the number of saves that your campaign has received from its audience.',
		ENGAGEMENT_BY_CHANNEL:
			'Engagement by channel refers to the level of interaction your campaign has received divided on each social media channel used in the campaign. This metric can be used to identify which channels are driving the most engagement, and can be used to optimize future campaigns by focusing on those channels that are most effective.',
		ENGAGEMENT_BY_INFLUENCER: 'Engagement by influencer refers to the level of interaction that each influencer in a campaign received from their audience.',
		ENGAGEMENT_CPE_BY_INFLUENCER:
			'Engagement and CPE by influencer refers to the level of interaction that each influencer in a campaign received from their audience, as well as the cost per engagement (CPE) for each influencer. This metric can be used to identify which influencers are providing the highest level of engagement at the most cost-effective price, and can be used to optimize future campaigns by working with those influencers that have the highest engagement rates and lowest CPE.',
		ENGAGEMENT_REACH_BY_INFLUENCER:
			'Engagement and reach by influencer refers to the level of interaction that each influencer in a campaign received from their audience, as well as the total reach generated by each influencer. This metric can be used to identify which influencers are providing the highest level of engagement while also reaching the most people, and can be used to optimize future campaigns by working with those influencers that have the highest engagement rates and largest reach.',
	},
	ACTIVATIONS: {
		BY_COUNTRY:
			'Activations by country refers to the number of activations by influencers that has been done in a campaign in each country where the campaign was run.',
		BY_CAMPAIGN: 'Activations by campaign refers to the number of activations by influencers that has been done in all your different campaigns.',
		BY_CHANNEL: 'Activations by channel refers to the number of activations divided on each social media channel used in your campaign.',
		BY_INFLUENCER: 'Activations by influencer refers to the number of activations divided on each social media channel used in your campaign.',
	},
	SUMMARY: {
		INFLUENCER:
			"A summary of each influencer's statistics that has participated in all your campaigns. This summary provides you an overview of influencers that have performed best in your campaigns, giving you the information that can be used to optimize future campaigns by working with those influencers that have performed the best.",
	},
};

export const CALCULATION_FORMULA = {
	ENGAGEMENT_TOTAL: ' Likes + Comments + Shares + Saves ',
	CPM: ' Cost per impression',
	CPC: ' Cost per Click ',
	CTR: ' Clicks per reach',
	CPE: 'Cost per engagement',
	SUMMARY: {
		REACH_RATE: 'Reach per followers',
		ACTUAL_ENGAGEMENT_RATE: 'Engagement per reach',
	},
};
