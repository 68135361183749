import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const cm = colors.contentManagement;

const NameContainer = styled.div`
	display: block;
	flex: 1;
	width: 100%;
	align-items: center;
	padding: 0 0.25rem;
	font-weight: 600;
	line-height: 1.5;
	transition:
		border-color 200ms ease-in-out,
		border-color 200ms ease-in-out;
`;

const Name = styled.div`
	display: flex;
	flex-direction: column;
	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875rem;
		width: 90%;
		&.type {
			font-size: 12px;
			font-weight: 400;
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	align-items: start;
	width: 100%;
	padding: 1rem;
	cursor: pointer;
	color: ${colors.black1};
	border-radius: ${borderRadius.s};
	border: 1px solid transparent;

	transition:
		background-color 200ms ease-in-out,
		color 200ms ease-in-out;

	.icon {
		line-height: 0;

		path {
			transition: fill 200ms ease-in-out;
		}
	}

	&.selected {
		background-color: ${newColors.iceBlue};
		border: 1px solid ${newColors.skyBlue};
	}
	&:hover {
		background-color: ${newColors.mist};
		border: 1px solid ${newColors.skyBlue};
		color: ${newColors.black};

		& > div:nth-child(2) {
			border-bottom-color: transparent;
		}
	}
`;

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: 8px;
	margin-top: 3px;
	& > figure {
		margin: 0;
	}
`;

const ListItem = styled.div`
	display: flex;
	border-radius: ${borderRadius.s};

	&.disabled {
		opacity: 0.5;

		${Wrapper} {
			cursor: default;
			&.selected {
				margin-bottom: 24px;
			}

			&.selected,
			&:hover {
				background-color: transparent;
				color: inherit;

				.icon path {
					fill: ${colors.black1};
				}

				& > div:nth-child(2) {
					border-bottom-color: ${cm.assignmentTypeList.listItem.borderColor};
				}
			}
		}
	}
`;

const ReviewStatusLabel = styled.span`
	font-weight: 400;
`;

const StatusText = styled.span`
	display: flex;
`;

const AssignmentTypeLabel = styled.span`
	font-weight: 400;
	margin-left: auto;
`;

const Styled = {
	ListItem,
	Wrapper,
	ImageWrapper,
	NameContainer,
	Name,
	ReviewStatusLabel,
	AssignmentTypeLabel,
	StatusText,
};

export default Styled;
