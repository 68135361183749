import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { SecondaryButton, TertiaryButton } from 'components/Button';
import Copy from 'components/Copy/Copy';
import { Heading } from 'components/Heading';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import useInjection from 'hooks/useInjection';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import toast from 'services/Toast';
import { ClientUser, InfluencerUser } from 'shared/Types/User';
import Styled from 'views/admin/Components/Table.style';
import { renderInfo } from 'views/admin/hooks';

import UserKindEditor from './UserKindEditor';
import { PaymentInfo } from './types';

import type GenericApiClientInterface from 'services/ApiClient/GenericApiClientInterface';

/**
 * @returns {JSX.Element}
 */
const UserDetails = (props: {
	payments?: Array<PaymentInfo>;
	item: ClientUser | InfluencerUser;
	setIsExpanded?: (isExpanded: boolean) => void;
}): JSX.Element => {
	const [editField, setEditField] = useState<string>('');
	const [editFieldValue, setEditFieldValue] = useState<string>('');
	const [hasError, setHasError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const client = useInjection<GenericApiClientInterface>(ApiClientService.GenericApiClientInterface);

	const { userCan } = useFeaturePermissions(props.item?.links);

	const onClickSave = (info?: { hateoas: string; fieldName: string }) => {
		if (!info || !userCan(info.hateoas)) return;
		setIsLoading(true);

		const url = props.item.links![info.hateoas];
		client
			.patch(url, { [info.fieldName]: editFieldValue })
			.then(() => {
				setEditField('');
				setEditFieldValue('');
				toast.success('The value has been successfully updated');
			})
			.finally(() => {
				setIsLoading(false);
			})
			.catch((e: Error) => {
				console.error(e);
				setHasError(true);
			});
	};

	const getFieldKey = (title: string) => {
		return `edit-${title.replace(/\s+/g, '-').toLowerCase()}`;
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditFieldValue(e.target.value);
		setHasError(false);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, props?: { hateoas: string; fieldName: string }) => {
		if (e.key === 'Enter' && !isLoading) {
			if (!props || !userCan(props.hateoas)) return;
			onClickSave(props);
		}
	};

	const onClickCancel = () => {
		setEditField('');
		setEditFieldValue('');
		setHasError(false);
	};

	useEffect(() => {
		return () => {
			setEditField('');
			setEditFieldValue('');
			setHasError(false);
			setIsLoading(false);
		};
	}, []);

	const renderItem = (info: { title: string; value: null | string | JSX.Element; copy?: boolean; edit?: { hateoas: string; fieldName: string } }) => (
		<>
			<Styled.CustomColumn>
				<Styled.ValueWrapper>
					<label>{info.title}</label>
					{getFieldKey(info.title) === editField ? (
						<Styled.InputContainer>
							<Styled.Input
								disabled={isLoading}
								autoFocus
								className={classNames({ error: hasError })}
								name={getFieldKey(info.title)}
								value={editFieldValue || info.value?.toString() || ''}
								onChange={onChange}
								onKeyDown={(e) => onKeyDown(e, info.edit)}
							/>
							<TertiaryButton width='40px' icon='checkmark' disabled={isLoading} onClick={() => onClickSave(info.edit)} isLoading={isLoading} />
							<TertiaryButton width='40px' icon='cross' disabled={isLoading} onClick={onClickCancel} />
						</Styled.InputContainer>
					) : (
						<strong>{info.value ?? '-'}</strong>
					)}
				</Styled.ValueWrapper>
				<div className='d-flex'>
					{info.edit && userCan(info.edit.hateoas) && !editField && (
						<Styled.EditButton title={`Edit ${info.title}`} onClick={() => setEditField(getFieldKey(info.title))}>
							Edit
						</Styled.EditButton>
					)}
					{info.copy && info.value && <Copy description={info.title} value={typeof info.value === 'string' ? info.value : ''} />}
				</div>
			</Styled.CustomColumn>
		</>
	);

	return (
		<>
			{props.item &&
				renderInfo(props.item).map((data, index) => {
					return (
						<React.Fragment key={index}>
							<Heading as='h5'>{data.title}</Heading>
							{data.title === 'Payment info' && (
								<Styled.DisplayPaymentsWrapper>
									<UserKindEditor currentValue={props.item.kind!} editUrl={props.item.links.edit} />
								</Styled.DisplayPaymentsWrapper>
							)}
							<Styled.DetailsList>
								{data.data.map((info: { title: string; value: null | string | JSX.Element; copy?: boolean }, i: number) => (
									<li key={`${i}-${info.title}`}>{renderItem(info)}</li>
								))}
							</Styled.DetailsList>
						</React.Fragment>
					);
				})}
			{props.setIsExpanded && props.payments && props.payments?.length > 0 && (
				<Styled.DisplayPaymentsWrapper>
					<SecondaryButton onClick={() => props.setIsExpanded && props.setIsExpanded(true)}>Display payments ({props.payments?.length})</SecondaryButton>
				</Styled.DisplayPaymentsWrapper>
			)}
		</>
	);
};

export default UserDetails;
