import { Model } from 'json-api-models';

import { CampaignModel, UserModel } from 'api-models';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';
import IRepository from 'utils/Repository/IRepository';

export type AssignmentProps = {
	type: ASSIGNMENT_TYPE;
	id: string;
	CIOAssignment: Model;
	selectedCampaign: CampaignModel;
	selectedCIO: Model;
	CIOAS: Model[];
	review: Model;
	heading: string;
	refresh: () => void;
	CIOArefresh: () => Promise<void>;
	brandManagerUsers?: Array<UserModel>;
	campaignCode: string | undefined;
	repository: IRepository;
	brandName: string;
};

export enum TabTypes {
	CONTENT = 'Manage Content',
	STATS = 'Statistics',
	SUMMARY = 'Approve for payout',
	SUMMARY_INFLUENCER = 'Overview',
	INSTRUCTIONS = 'Instructions',
	SUMMARY_BRAND_MANAGER = 'Summary',
}

export type AssignmentFormProps = {
	assignmentType: ASSIGNMENT_TYPE;
	CIOAssignment: Model;
	selectedCampaign: CampaignModel;
	CIOArefresh: () => Promise<void>;
	goToStats: () => void;
	campaignRefresh: () => void;
	hasJoined: boolean;
	brandManagerUsers?: Array<UserModel>;
	brandName: string;
	setActiveTab: (tab: TabTypes) => void;
	activeTab: TabTypes;
};
