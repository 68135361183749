import { ISvgProps } from 'components/Icon/types';

const defaultProps: ISvgProps = {
	viewBox: '0 0 20 20',
};

export function SvgJoinProfile(props: ISvgProps) {
	props = { ...defaultProps, ...props };
	const { ...svgProps } = props;

	return (
		<svg {...svgProps}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.50006 9C10.9853 9 13.0001 6.98528 13.0001 4.5C13.0001 2.01472 10.9853 0 8.50006 0C6.01478 0 4.00006 2.01472 4.00006 4.5C4.00006 6.98528 6.01478 9 8.50006 9ZM8.50006 7.5C10.1569 7.5 11.5001 6.15685 11.5001 4.5C11.5001 2.84315 10.1569 1.5 8.50006 1.5C6.84321 1.5 5.50006 2.84315 5.50006 4.5C5.50006 6.15685 6.84321 7.5 8.50006 7.5Z'
				fill='#4D4B4C'
			/>
			<path
				d='M2.73056 14.2781C1.70832 15.5322 1.43976 17.0778 1.51386 18.1853L1.51552 18.2102V19.2332C1.51552 19.6466 1.1792 19.9817 0.764328 19.9817C0.349455 19.9817 0.0131345 19.6466 0.0131345 19.2332V18.2592C-0.0739978 16.8733 0.255877 14.9396 1.56437 13.3342C2.90362 11.6911 5.17875 10.5006 8.76137 10.5H8.7638C11.3897 10.5 12.87 10.9937 14.2493 11.9971C14.5844 12.2409 14.6578 12.7092 14.4131 13.0431C14.1685 13.377 13.6986 13.4501 13.3635 13.2063C12.2936 12.4281 11.1427 11.9971 8.76387 11.9971C5.53717 11.9971 3.72982 13.0521 2.73056 14.2781Z'
				fill='#4D4B4C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.8686 15.1941C20.0598 15.4324 20.0401 15.7971 19.8246 16.0085L15.9036 19.8547C15.7142 20.0405 15.4315 20.0491 15.2331 19.8751L12.1976 17.2123C11.9718 17.0143 11.934 16.6514 12.1131 16.4018C12.2921 16.1522 12.6203 16.1104 12.8461 16.3084L15.5386 18.6702L19.1321 15.1453C19.3476 14.9339 19.6774 14.9557 19.8686 15.1941Z'
				fill='#575556'
			/>
		</svg>
	);
}
