import classNames from 'classnames';
import { useRef } from 'react';

import Checkbox from 'components/Checkbox';

import Styled from './AcceptTermsAndConditions.style';

const AcceptTermsAndConditions = ({
	termsAccepted,
	setTermsAccepted,
	text,
	link,
	testId,
	tabIndex,
}: {
	termsAccepted: boolean;
	setTermsAccepted: (termsAccepted: boolean) => void;
	text: string;
	link: string;
	testId?: string;
	tabIndex?: number;
}) => {
	const agreeBoxRef = useRef<HTMLDivElement | null>(null);

	return (
		<Styled.AgreeSection
			ref={agreeBoxRef}
			data-testid={testId || 'agree-chk'}
			className={classNames({ isActive: termsAccepted })}
			onClick={() => {
				setTermsAccepted && setTermsAccepted(!termsAccepted);
			}}
			tabIndex={-1}
		>
			<Styled.CheckBoxContainer>
				<Checkbox
					checked={termsAccepted}
					readOnly
					tabIndex={tabIndex}
					onKeyPress={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							setTermsAccepted(!termsAccepted);
						}
					}}
				/>
			</Styled.CheckBoxContainer>
			<Styled.AgreeSectionText>
				I agree to the {'  '}
				<a href={link} target='_blank' rel='noreferrer' tabIndex={-1}>
					{text}
				</a>
				.
			</Styled.AgreeSectionText>
		</Styled.AgreeSection>
	);
};

export default AcceptTermsAndConditions;
