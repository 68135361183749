import { Model } from 'json-api-models';
import { isNil } from 'lodash';

import { UserType } from 'reducers/UserReducers/types';

export const sendMessageHandler = async (
	selectedInfluencerId: string,
	user: UserType,
	onSend: (conversationId: string, message: string, files?: Array<File>, influencerId?: string) => Promise<boolean>,
	message: string,
	options: { attachments?: Array<File>; conversationId?: string },
) => {
	if (options.conversationId) {
		return await onSend(
			options.conversationId,
			message,
			options.attachments,
			user.permissions.isInfluencer && selectedInfluencerId ? selectedInfluencerId : undefined,
		);
	} else {
		return false;
	}
};

export const startNewConversationHandler = async (
	selectedInfluencerId: string,
	onStartChat: (createConversationData: {
		message: string;
		userIds?: string[];
		influencerId: string;
		campaignId: string;
		files?: Array<File>;
	}) => Promise<boolean | undefined>,
	message: string,
	options: { attachments?: Array<File>; campaign: Model | null; campaignInstagramOwner?: Model | null; isInfluencer?: boolean },
) => {
	if (isNil(options.campaign)) return false;
	if (options.isInfluencer)
		return await onStartChat({ message: message, influencerId: selectedInfluencerId, campaignId: options.campaign.id, files: options.attachments });
	if (isNil(options.campaignInstagramOwner)) return false;
	return await onStartChat({
		message: message,
		influencerId: options.campaignInstagramOwner.influencer.id,
		campaignId: options.campaign.id,
		files: options.attachments,
	});
};
