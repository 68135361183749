import DOMPurify from 'dompurify';
import { isNaN } from 'lodash';
import React from 'react';

export function formatAmount(amount: string | number | null | undefined, currency?: string, locale = 'en'): string {
	// Validate the amount
	if (typeof amount === 'string') {
		amount = /^\d+(\.\d+)?$/.test(amount) ? parseFloat(amount) : 0;
	} else if (amount === null || amount === undefined || isNaN(amount)) {
		amount = 0;
	}

	// Ensure currency is either undefined or a valid string
	const formattedCurrency = currency || undefined;

	// Format the amount
	try {
		return (amount / 100).toLocaleString(locale, {
			currency: formattedCurrency,
			style: formattedCurrency ? 'currency' : 'decimal',
			currencyDisplay: 'code',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	} catch (error) {
		return 'invalid';
	}
}

/**
 * Little "hommage" to the PHP function.
 */
export function nl2br(text: string | null | undefined) {
	if (text === null || text === undefined) {
		return <React.Fragment />;
	}
	return text.split(/\n/).map((item: string, i) => {
		return (
			<React.Fragment key={i}>
				{makeLinkClickable(item)}
				<br />
			</React.Fragment>
		);
	});
}

type AudienceDetails = {
	name: string | null;
	count: number | null;
	ratio: number | null;
};

export function formatAudience(audience: AudienceDetails | null | undefined) {
	if (!audience) {
		return '-';
	}
	const { name, ratio } = audience;
	return name != null && ratio != null ? `${name.toUpperCase()} ${ratio.toLocaleString('en', { style: 'percent' })}` : '-';
}

export function newLineToBulletList(text: string) {
	const bulletList = splitTextByEnter(text);
	return (
		<ul>
			{bulletList.map((item: string, i: number) => (
				<li key={i} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }} />
			))}
		</ul>
	);
}

export function isLink(text: string) {
	const checkLinkReg = new RegExp('^(http|https)://', 'i');
	return checkLinkReg.test(text);
}

export function linkSeparator(text: string | null | undefined) {
	if (!text) {
		return [''];
	}
	const newText = text.replace(/\b((?:https?:\/\/|www\.)\S+)\b/, '{link: $1}');
	const separatedLinkPattern = /\{link:\s*(.*?)\}/;
	return newText.split(separatedLinkPattern);
}

export const makeLinkClickable = (text: string) => {
	if (!text) {
		return '';
	}

	const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
	return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

export function newLinesToParagraphs(text: string | null | undefined) {
	if (!text) {
		return '';
	}
	const texts = splitTextByEnter(text);
	const paragraphs = texts.map((paragraph) => {
		return `<p>${makeLinkClickable(paragraph)}</p>`;
	});
	return paragraphs.join('');
}

export function splitTextByEnter(text: string) {
	if (text) {
		return text.split(/\n/).map((item: string) => item);
	} else {
		return [];
	}
}

export function calculateDaysDifference(targetDate: Date | null | undefined) {
	if (!targetDate) {
		return NaN;
	}
	const currentDate = new Date();
	const utcCurrentDate = Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
	const utcTargetDate = Date.UTC(targetDate.getUTCFullYear(), targetDate.getUTCMonth(), targetDate.getUTCDate());

	const timeDifference = utcTargetDate - utcCurrentDate;
	return Math.ceil(timeDifference / (1000 * 3600 * 24));
}

export const pluralize = (count: number, singular: string, plural: string) => (count === 1 ? `${count} ${singular}` : `${count || 0} ${plural}`);
