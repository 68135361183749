import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	color: ${colors.gray7};
	background-color: ${colors.gray9};
	padding: 1rem;
	border: 2px dotted ${colors.gray7};
	border-radius: ${borderRadius.s};
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		background-color: ${colors.mainBlack};
		color: ${colors.mainWhite};
		.icon path {
			fill: ${colors.mainWhite};
		}
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.buttonGray};
	border-radius: ${borderRadius.m};
	padding: 1.5rem;
`;

const Title = styled.h3`
	font-weight: 500;
	font-size: 1.2rem;
`;

const Styled = {
	Wrapper,
	Button,
	Title,
};

export default Styled;
