import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

import { BlueButton } from 'components/Button/Button';
import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';

const Form = styled(FormikForm)`
	margin-bottom: 24px;
	height: 57vh;
	overflow-y: auto;
	padding-right: 8px;
	${scrollbarY}
	.visually-hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border: 0;
	}
`;

const Info = styled.div`
	border-radius: ${borderRadius.m};
	background-color: ${colors.paleGreenTint};
	padding: 1rem;
	display: flex;
	gap: 8px;
	margin-bottom: 16px;
	.icon {
		margin-top: 8px;
	}
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

// ... existing code ...

export const NameFieldsGroup = styled.div`
	display: flex;
	gap: 16px;
`;

export const Divider = styled.hr`
	margin: 40px 0;
	border: 1px solid ${colors.ash};
`;

export const TermsContainer = styled.div`
	margin: 24px 0;
`;

export const SubmitButton = styled(BlueButton)`
	float: right;
`;

export default {
	Form,
	Info,
	IconWrapper,
	NameFieldsGroup,
	Divider,
	TermsContainer,
	SubmitButton,
};
