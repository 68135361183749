import styled from 'styled-components';

import { guttersWithRem } from 'styles/variables/gutter';

const Wrapper = styled.div`
	width: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 40px 0;

	& > div {
		text-align: center;
		p {
			margin-bottom: 0;
		}
		&.image-wrapper {
			& > img {
				max-height: 150px;
			}
		}
		font-weight: 700;
		margin-bottom: ${guttersWithRem.m};
	}
`;

const Styled = {
	Wrapper,
};

export default Styled;
