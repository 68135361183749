import styled from 'styled-components';

import colors from 'styles/variables/colors';

const Wrapper = styled.div`
	max-height: calc(100vh + -200px);
	overflow-y: auto;
	div {
		&.date-messages {
			display: flex;
			flex-direction: column;
		}
	}
`;

const FirstLineWrapper = styled.div`
	width: 100%;
	text-align: center;
	color: ${colors.integratedInbox.chatSection.date.color};
	margin-bottom: 40px;
	line-height: 1.5;
	font-size: 0.875rem;
`;

const DateWrapper = styled.div`
	width: 100%;
	text-align: center;
	color: ${colors.integratedInbox.chatSection.date.color};
	font-size: 0.75rem;
`;

const Styled = {
	Wrapper,
	FirstLineWrapper,
	DateWrapper,
};

export default Styled;
