import { CSSProperties } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Avatar';
import newColors from 'styles/theme/colors';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Wrapper = styled.tr`
	width: 100%;
	overflow-x: auto;
	background-color: #fafafa;
	border: 1px solid ${newColors.ash};
	border-left: none;
	border-right: none;
	border-bottom: none;
	transition: background-color 0.2s ease-in-out;
	cursor: pointer;
	&:nth-child(even) {
		background-color: #f2f2f2;
	}

	&:hover {
		background-color: #c5c5c5;
	}
	&.isSelected {
		background-color: ${newColors.iceBlue};
	}
`;

const Td = styled.td<CSSProperties>`
	padding: 0.5rem 0.75rem;
	text-align: ${(props) => props.textAlign || 'left'};
	max-width: ${(props) => props.maxWidth};
	height: ${(props) => props.maxHeight || '50px'};
	vertical-align: middle;
`;

const ExpendWrapper = styled(Wrapper)`
	background-color: ${colors.transparent};
	border-radius: 4px;

	& > ${Td} {
		padding: 0;
	}
`;

const ExpendInnrWrapper = styled.div`
	padding: ${guttersWithRem.s};
	border-radius: 4px;
	background-color: ${colors.mainWhite};
`;

const Div = styled.div<CSSProperties>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	column-gap: ${(props) => props.columnGap};
	row-gap: ${(props) => props.rowGap};
	font-family: ${typography.list.fontFamily};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
`;

const Data = styled(Div)`
	font-weight: 400;
	font-size: 1rem;
	white-space: nowrap;
	text-transform: capitalize;
	&.location {
		text-transform: uppercase;
	}
`;

const IconContainerWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	position: relative;
	cursor: pointer;
	width: 32px;
	max-height: 32px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.1s ease-in-out;

	& > i {
		height: 32px;
	}

	&.dropdown {
		& > div > div > button {
			&:hover,
			&.show {
				background-color: transparent !important;
			}
		}
	}

	&.detail-button {
		svg {
			transform: rotate(-45deg);
		}
	}

	&.show-detail {
		svg {
			transform: rotate(0deg);
		}
	}
`;

const CustomAvatar = styled(Avatar)`
	margin: 0;
`;

const GrayText = styled.span`
	margin-left: ${guttersWithRem.xxs};
	font-size: 0.875rem;
	color: ${colors.discovery.grayText};
`;

const ProfileNameContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	padding-bottom: 1rem;
	h5 {
		margin-bottom: 0;
	}
`;

const ReactionsContainer = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: flex-end;
	align-items: center;

	> div {
		display: flex;
		gap: 1rem;
		justify-content: flex-end;
		align-items: center;
		transition: opacity 200ms ease-in-out;
	}
`;

const Styled = {
	ReactionsContainer,
	Wrapper,
	ExpendWrapper,
	Td,
	Div,
	Data,
	IconContainer,
	IconContainerWrapper,
	CustomAvatar,
	ExpendInnrWrapper,
	GrayText,
	ProfileNameContainer,
};

export default Styled;
