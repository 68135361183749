import { Link } from 'react-router-dom';
import styled, { DefaultThemeV2 } from 'styled-components';

import { RegularLink } from 'styles/utils/Link';
import { scrollbarY } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import fontSize from 'styles/variables/font-size';
import { mediaQueries } from 'styles/variables/media-queries';

type P = { theme: DefaultThemeV2 };
interface ActionDropdownMenuItem {
	noBorder?: boolean;
}
/**
 * References: Dropdown
 */
const ActionDropdownMenuItem = styled.div<ActionDropdownMenuItem>`
	position: relative;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	border-bottom: ${(props) => (props.noBorder ? 'none' : `1px solid ${colors.userDropdown.menuItemBorderColor}`)};
`;

const ActionDropdownUser = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 16px;
	padding: 0 0.25rem 1.5rem 0.25rem;
	border-bottom: 1px solid ${colors.userDropdown.menuItemBorderColor};
`;

const Username = styled.div`
	span {
		display: block;
		line-height: 1.6;
	}
`;

const Name = styled.span`
	display: block;
	font-weight: 900;

	&.read {
		font-weight: 400;
		color: ${colors.gray6};
	}
`;

const UserAvatar = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px ${colors.avatar.borderColor} solid;
	background-color: ${colors.avatar.backgroundColor};
	padding: 0.25rem;
	border-radius: 60px;
	width: 48px;
	height: 48px;
	margin-right: 16px;
	> span {
		padding-top: 2px;
		font-weight: 900;
	}
`;

const MenuLink = styled(Link)`
	display: flex;
	align-items: center;
	transition: background-color 0.2s ease-in-out;
	padding: 0.5rem;
	color: ${colors.link.color};
	margin: 8px 0;
	border-radius: 4px;
	min-height: 41px;
	position: relative;
	z-index: 2;
	span {
		font-weight: 500;
		font-size: ${fontSize.sMobile};
		${mediaQueries.large`
			font-size: 0.875rem;
		`}
		margin-bottom: 0;
	}
	&:hover {
		background-color: ${colors.userDropdown.menuItemHover};
	}
	.icon {
		margin-right: 8px;
		z-index: 1;
		position: relative;
	}
`;

const HashMenuLink = styled(Link)`
	${RegularLink};

	&:hover:not(:disabled) {
		border-color: transparent;

		&:after {
			opacity: 0;
		}
	}
`;
const NotConnected = styled.div`
	font-size: ${fontSize.sMobile};
	font-weight: 500;
	color: ${colors.userDropdown.secondaryGray};
	height: 20px;
	${mediaQueries.large`
		font-size: fontSize.s
	`}
`;

const ConnectionInfo = styled.div`
	display: flex;
	align-items: center;
	a {
		margin-left: 5px;
		color: ${({ theme }: P) => theme.colors.userMenu.link.normal};
		font-weight: 500;
		height: 20px;
		font-size: ${fontSize.sMobile};
		transition: border-bottom 0.3s;
		border-bottom: ${({ theme }: P) => `1px solid ${theme.colors.userMenu.link.normal}00`};
		${mediaQueries.large`
			font-size: fontSize.s
		`}

		&:hover {
			color: ${({ theme }: P) => theme.colors.userMenu.link.normal};
			border-bottom: ${({ theme }: P) => `1px solid ${theme.colors.userMenu.link.normal}`};
		}
	}
`;

const AccountMenu = styled.div`
	width: 100%;
	height: auto;
	background-color: ${colors.userDropdown.white};
	border-radius: 10px;
	padding: 1rem 0;

	& > div {
		max-height: 40vh;
		overflow-y: auto;
		${scrollbarY};
	}
`;

const AccountMenuList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const AccountMenuListItem = styled.li`
	> a {
		font-size: 1rem;
		display: block;
		padding: 0.5rem;
		&:hover {
			background-color: ${colors.userDropdown.accountMenuListItemHoverColor};

			&:after {
				border-color: transparent;
			}
		}
	}
`;

const ActionDropDownLinks = styled.div`
	width: 102%;
	padding: 0.5rem 1rem;
`;

interface Avatar {
	img?: string | undefined;
}

const ImgAvatar = styled.div<Avatar>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(${(props) => props.img ?? ''});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: ${colors.settings.avatarBackground};
	border-radius: 50%;
	width: 40px;
	height: 40px;
`;

const SocialProfile = styled.div`
	display: flex;
	align-items: center;
`;

const UserName = styled.h5`
	margin-bottom: 0;
	font-size: ${fontSize.sMobile};
	width: 100%;
	display: flex;
	justify-content: space-between;
	${mediaQueries.large`
		font-size: fontSize.s
	`}
`;

const NameWrapper = styled.div`
	margin-left: 16px;
	margin-top: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.icon path {
		fill: ${colors.settings.green};
	}
`;

const BlankStateWrapper = styled.div`
	text-align: center;
	padding: 1rem 2rem 0.5rem 2rem;
	h3 {
		font-size: 1.063rem;
		font-weight: 600;
		line-height: 150%;
	}
	p {
		margin-bottom: 16px;
		font-size: 0.938rem;
	}
	a {
		font-size: 0.938rem;
		border-bottom: solid 2px ${colors.mainBlack};
		width: 100%;
	}
`;

const UserAvatarWrapper = styled.div`
	padding: 0 1rem;
`;

const ProfileItem = styled.div`
	padding: 0.5rem 1rem;
	&.selected {
		background-color: ${colors.mainBlack};
		h5 {
			color: ${colors.mainWhite};
		}
		${NotConnected} {
			color: ${colors.gray7};
		}
		${ConnectionInfo} {
			a {
				color: ${({ theme }: P) => theme.colors.userMenu.link.selected};
				&:hover {
					border-bottom: ${({ theme }: P) => `1px solid ${theme.colors.userMenu.link.selected}`};
				}
			}
		}
	}
	&.pointer {
		cursor: pointer;
	}
`;

const ButtonText = styled.div`
	font-size: 0.875rem;
`;

const ButtonContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Styled = {
	ButtonText,
	ProfileItem,
	ActionDropdownMenuItem,
	ActionDropdownUser,
	ActionDropDownLinks,
	UserAvatar,
	ImgAvatar,
	MenuLink,
	Username,
	Name,
	AccountMenu,
	AccountMenuList,
	AccountMenuListItem,
	HashMenuLink,
	SocialProfile,
	UserName,
	NotConnected,
	NameWrapper,
	ConnectionInfo,
	BlankStateWrapper,
	UserAvatarWrapper,
	ButtonContent,
};

export default Styled;
