import { useLocation } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import { FacebookPage } from 'components/Settings/Profiles/types';
import Styled from 'components/Settings/Settings.style';

/**
 * List not connected facebook pages
 * @returns {JSX.Element}
 */
const FacebookPages = (): JSX.Element => {
	const { state } = useLocation();

	return (
		<Styled.Wrapper>
			<PageHeader
				className='bold-home-text'
				homeText='Not connected accounts'
				homeUrl='/influencer/settings/instagram'
				showBreadcrumb={true}
				showCurrentDate={false}
			/>
			<div>
				<h4>Not connected Facebook pages</h4>
				<p>Your Facebook pages that’s not connected to your Collabs profile.</p>
				<ul>
					{state?.map((page: FacebookPage, i: number) => {
						return (
							<li key={i}>
								<span>Facebook/{page.attributes.name}</span>
								<p>Not connected</p>
							</li>
						);
					})}
				</ul>
			</div>
		</Styled.Wrapper>
	);
};

export default FacebookPages;
