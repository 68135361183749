import { isEqual } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientModel } from 'api-models';
import InlineEditButton from 'components/InlineEditButton/InlineEditButton';
import Pill from 'components/Pill';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { pathSettingsClients } from 'routing/PathLookup';
import ClientManager from 'services/ApiManager/Client.manager';
import toast from 'services/Toast';

import Styled from './EditClientName.style';

type EditClientNameProps = {
	client: ClientModel;
	canEdit: boolean;
	publisher: PublisherModel;
};

const EditClientName = ({ client, canEdit, publisher }: EditClientNameProps) => {
	const manager = useInjection<ClientManager>(ClientManager);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [newClientName, setNewClientName] = useState<string>('');

	const navigate = useNavigate();

	const updateClientName = () => {
		const placeholder = structuredClone(client);
		placeholder.attributes.name = newClientName;

		return manager
			.update(client.id, { name: newClientName }, undefined, {
				optimisticModel: placeholder,
			})
			.then(() => {
				setIsEditMode(false);
				!isEqual(client.attributes.name, newClientName) && toast.success(`Client name updated`);
				setNewClientName('');
			})
			.catch(() => {
				toast.error(getErrorMessageOnPost('updating the client'));
			});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewClientName(e.target.value);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			updateClientName();
		}
		if (e.key === 'Escape') {
			setIsEditMode(false);
			setNewClientName('');
		}
	};

	return (
		<Styled.ClientDetails>
			<Styled.ClientNameWrapper>
				<Styled.Name
					className='border-bottom'
					onClick={() => {
						navigate(pathSettingsClients());
					}}
				>
					<h4>{publisher?.attributes?.name}</h4>
				</Styled.Name>
				<Styled.Margin>/</Styled.Margin>
				{!isEditMode ? (
					<>
						<Styled.Name className='client-name' data-testid='client-name-heading'>
							<h4>{client.attributes?.name} </h4>
							<Pill title='Client' className='purple-light' />
						</Styled.Name>
					</>
				) : (
					<Styled.InlineInput
						autoFocus={true}
						value={newClientName}
						onChange={(e) => handleChange(e)}
						onKeyDown={(e) => handleOnKeyDown(e)}
						data-testid='client-name-input'
					/>
				)}
			</Styled.ClientNameWrapper>
			{canEdit && (
				<Styled.EditButtonWrapper>
					<InlineEditButton
						label='Edit name'
						editMode={isEditMode}
						setEditMode={() => {
							setIsEditMode(true);
							setNewClientName(client.attributes?.name);
						}}
						saveChanges={updateClientName}
					/>
				</Styled.EditButtonWrapper>
			)}
		</Styled.ClientDetails>
	);
};
export default EditClientName;
