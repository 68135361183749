import styled from 'styled-components';

import colors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';

const ShareAssignment = styled.div`
	display: flex;
	margin-left: auto;

	button {
		font-size: 0.875rem;

		&:disabled {
			border: 0;
			padding-right: 0;
		}
	}
`;

const ShareTime = styled.span`
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 0.75rem;
	text-align: right;
	background-color: ${colors.mist};
	color: ${colors.slate};
	padding: 0.5rem 1rem;
	border-radius: ${borderRadius.m};
	.icon path {
		fill: ${colors.emerald};
	}
`;

const ShareInner = styled.div``;

const ShareTimestamps = styled.div`
	display: flex;
	gap: 8px;
`;

const ModalContent = styled.div`
	padding-top: 2rem;

	ul {
		li {
			padding: 0.2rem 0;
		}
	}
`;

const Link = styled.a`
	color: ${colors.oceanBlue};
	cursor: pointer;
`;

const BrandManagerList = styled.ul`
	max-height: 150px;
	margin-bottom: 24px;
	${scrollbarY};
`;

const Styled = {
	BrandManagerList,
	Link,
	ModalContent,
	ShareAssignment,
	ShareTime,
	ShareInner,
	ShareTimestamps,
};

export default Styled;
