/*
 * This file was generated by .github/tools/create-api-clients.php
 * Do not edit this file manually.
 */
import { AxiosInstance } from 'axios';
import { injectable, inject } from 'inversify';

import { UpdateUserPayload } from 'api-payloads';
import {
	AcceptInfluencerTermsOfConditionsQuery,
	CurrentUserQuery,
	AcceptPublisherTermsOfConditionsQuery,
	ListUsersQuery,
	ViewUserQuery,
	UpdateUserQuery,
	DisableQuery,
	SendEmailVerificationEmailQuery,
	VerifyAddressQuery,
} from 'api-queries';
import { CurrentUserResponse, SecurityTokenResponse, UserCollectionResponse, UserResponse, PinebucketExternalAccountResponse } from 'api-responses';
import ServiceIdentifier from 'services/ApiClient/ServiceIdentifier';

import UserApiClientInterface from './UserApiClientInterface';

@injectable()
export default class UserApiClient implements UserApiClientInterface {
	private readonly client: AxiosInstance;

	constructor(@inject(ServiceIdentifier.CollabsApiHttpClient) factory: () => AxiosInstance) {
		this.client = factory();
	}

	/**
	 * Accept influencer terms of conditions
	 */
	async acceptInfluencerTermsOfConditions(params: AcceptInfluencerTermsOfConditionsQuery): Promise<CurrentUserResponse> {
		const response = await this.client.post<CurrentUserResponse>(`/influencer-terms-of-conditions`, undefined, { params });
		return response.data;
	}

	/**
	 * Current user
	 */
	async current(params: CurrentUserQuery): Promise<CurrentUserResponse> {
		const response = await this.client.get<CurrentUserResponse>(`/me`, { params });
		return response.data;
	}

	/**
	 * Create mercure token
	 */
	async createMercureToken(params: { include?: string }): Promise<SecurityTokenResponse> {
		const response = await this.client.post<SecurityTokenResponse>(`/me/mercure-tokens`, undefined, { params });
		return response.data;
	}

	/**
	 * Accept publisher terms of conditions
	 */
	async acceptPublisherTermsOfConditions(params: AcceptPublisherTermsOfConditionsQuery): Promise<CurrentUserResponse> {
		const response = await this.client.post<CurrentUserResponse>(`/publisher-terms-of-conditions`, undefined, { params });
		return response.data;
	}

	/**
	 * List users
	 */
	async listUsers(params: ListUsersQuery): Promise<UserCollectionResponse> {
		const response = await this.client.get<UserCollectionResponse>(`/users`, { params });
		return response.data;
	}

	/**
	 * View user
	 */
	async view(id: string, params: ViewUserQuery): Promise<UserResponse> {
		const response = await this.client.get<UserResponse>(`/users/${id}`, { params });
		return response.data;
	}

	/**
	 * Update user
	 */
	async update(id: string, payload: UpdateUserPayload, params: UpdateUserQuery): Promise<UserResponse> {
		const response = await this.client.patch<UserResponse>(`/users/${id}`, payload, { params });
		return response.data;
	}

	/**
	 * View bank details
	 */
	async viewBankDetails(id: string, params: { include?: string }): Promise<PinebucketExternalAccountResponse> {
		const response = await this.client.get<PinebucketExternalAccountResponse>(`/users/${id}/bank-details`, { params });
		return response.data;
	}

	/**
	 * Disable
	 */
	async disable(id: string, params: DisableQuery): Promise<UserResponse> {
		const response = await this.client.post<UserResponse>(`/users/${id}/disable`, undefined, { params });
		return response.data;
	}

	/**
	 * Send email verification email
	 */
	async sendEmailVerificationEmail(id: string, params: SendEmailVerificationEmailQuery): Promise<UserResponse> {
		const response = await this.client.post<UserResponse>(`/users/${id}/email-verification-emails`, undefined, { params });
		return response.data;
	}

	/**
	 * Verify address
	 */
	async verifyAddress(id: string, params: VerifyAddressQuery): Promise<UserResponse> {
		const response = await this.client.post<UserResponse>(`/users/${id}/verify-address`, undefined, { params });
		return response.data;
	}
}
