import { Model } from 'json-api-models';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChatHeader from 'components/IntegratedInbox/Components/ChatHeader';
import ChatInput from 'components/IntegratedInbox/Components/ChatInput';
import ChatMessages from 'components/IntegratedInbox/Components/ChatMessages';
import { DirectConversationData } from 'components/IntegratedInbox/Components/DirectConversationList/DirectConversationData';
import EmptyChat from 'components/IntegratedInbox/Components/EmptyChatv2';
import { FileItem } from 'components/IntegratedInbox/types';
import { useAppSelector } from 'hooks/useUserAppSelector';
import Grid from 'styles/grid/grid';
import SkeletonLoader from 'views/Campaign/Dashboard/ContentManagement/components/SkeletonLoaders/SkeletonLoaders';

import Styled from './ChatSection.style';
import { sendMessageHandler, startNewConversationHandler } from './utils';

type Props = {
	displaySkeletonLoader?: boolean;
	publisher?: Model;
	isFetchingCampaigns?: boolean;
	campaign: Model | null;
	campaignInstagramOwner?: Model | null;
	messages: Model[];
	selectedDirectConversation?: DirectConversationData | null;
	isDirectConversation?: boolean;
	noCampaigns: boolean;
	isNoConversation: boolean;
	onSend: (conversationId: string, message: string, files?: Array<File> | undefined, influencerId?: string) => Promise<void>;
	onRead: (conversationMessageId: string) => Promise<void>;
	onStartChat: (createConversationData: {
		message: string;
		userIds?: string[];
		influencerId: string;
		campaignId: string;
		files?: Array<File> | undefined;
	}) => Promise<boolean | undefined>;
	onBack: () => void;
	fileUploadProgress: { [key: string]: number };
	tempUserMessageValue: { message: string; files: Array<FileItem> };
	isInfluencer?: boolean;
	isDisabled: boolean;
	isFetchingConversationsMessages: boolean;
	selectedConversation: Model | null;
};

/**
 * It renders chat history or different type of empty states depending on the conditions (no campaign, no conversation or no influencer)
 *
 */
const ChatSection = ({
	campaign,
	campaignInstagramOwner,
	messages,
	selectedDirectConversation,
	isDirectConversation,
	isNoConversation,
	onSend,
	onRead,
	onStartChat,
	onBack,
	fileUploadProgress,
	tempUserMessageValue,
	isDisabled,
	isFetchingConversationsMessages,
	selectedConversation,
	publisher,
}: Props) => {
	const messageEndRef = useRef<HTMLDivElement>(null);
	const messagesRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLDivElement>(null);

	const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>();

	const user = useAppSelector((state) => state.user);
	const isInfluencer = user.permissions.isInfluencer;
	const params = useParams();

	const directConversationForPublisher = !isInfluencer && isDirectConversation;
	const directConversationForInfluencer = isInfluencer && isDirectConversation;
	const campaignForInfluencer = isInfluencer && campaign;

	const publisherNoCampaignInstagramOwner = !isInfluencer && campaignInstagramOwner !== null && campaign;

	const readMessageHandler = (conversationMessageId: string) => {
		return onRead(conversationMessageId);
	};

	const scrollToBottom = () => {
		if (messagesRef.current && inputRef.current) {
			messagesRef.current.scrollTop = messageEndRef.current ? messageEndRef.current.offsetTop : 0;
			inputRef.current.scrollTop;
		}
	};

	useEffect(() => {
		params.influencerId && setSelectedInfluencerId(params.influencerId);
	}, [params.influencerId]);

	useEffect(() => {
		if (campaignInstagramOwner) {
			setSelectedInfluencerId(campaignInstagramOwner?.influencer.id);
		}
	}, [campaignInstagramOwner]);

	useEffect(() => {
		if (selectedConversation && isInfluencer) {
			setSelectedInfluencerId(selectedConversation.influencer.id);
		}
	}, [selectedConversation]);

	const getCampaignName = () => {
		if (directConversationForPublisher || directConversationForInfluencer) {
			return 'Direct message';
		} else if (campaignForInfluencer) {
			return campaign.name;
		} else if (publisherNoCampaignInstagramOwner) {
			return campaign.name;
		} else return '';
	};

	const getRecipientName = () => {
		if (directConversationForPublisher) {
			return selectedDirectConversation!.name;
		} else if (directConversationForInfluencer) {
			return 'Direct message';
		} else if (campaignForInfluencer) {
			return campaign.name;
		} else if (publisherNoCampaignInstagramOwner) {
			return campaignInstagramOwner?.influencer.username;
		} else return '';
	};

	const getProfilePictureUrl = () => {
		if (directConversationForPublisher) {
			return selectedDirectConversation!.profilePictureUrl;
		} else if (directConversationForInfluencer) {
			return '';
		} else if (campaignForInfluencer) {
			return campaign.links.smallCoverPhoto;
		} else if (publisherNoCampaignInstagramOwner) {
			return campaignInstagramOwner?.influencer.links.profilePictureUrl;
		} else return '';
	};

	const onSendHandler = async (message: string, attachments?: File[]) => {
		if (selectedInfluencerId) {
			if (isNewConversation()) {
				return await startNewConversationHandler(selectedInfluencerId, onStartChat, message, {
					attachments: attachments,
					campaign: campaign,
					campaignInstagramOwner: campaignInstagramOwner,
					isInfluencer: isInfluencer,
				});
			}
			return await sendMessageHandler(selectedInfluencerId, user, onSend, message, {
				attachments: attachments,
				conversationId: params.conversationId,
			});
		}
	};

	const isNewConversation = () => {
		return !directConversationForPublisher && isNoConversation;
	};

	return (
		<Grid.Column md={6} className='three-col hidden h-full'>
			<Styled.Wrapper data-testid='inbox-chat-section'>
				{isFetchingConversationsMessages ? (
					<div style={{ paddingLeft: '40px' }}>
						<SkeletonLoader variant='inbox' />
					</div>
				) : !getCampaignName() && messages.length === 0 ? (
					<EmptyChat />
				) : (
					<>
						<ChatHeader campaignName={getCampaignName()} recipientName={getRecipientName()} profilePictureUrl={getProfilePictureUrl()} goBack={onBack} />
						<Styled.Content>
							{/* {messages.length === 0 && !tempUserMessageValue.message && <EmptyChat isNew />} */}
							<ChatMessages
								messages={messages}
								onRead={readMessageHandler}
								tempValue={tempUserMessageValue}
								messagesRef={messagesRef}
								messageEndRef={messageEndRef}
								onScrollToBottom={scrollToBottom}
								fileUploadProgress={fileUploadProgress}
								publisher={publisher}
							/>
							<ChatInput
								owners={campaign?.campaignInstagramOwners}
								selectedInfluencerId={selectedInfluencerId}
								setSelectedInfluencerId={setSelectedInfluencerId}
								onSend={onSendHandler}
								onScrollToBottom={scrollToBottom}
								isDisabled={isDisabled}
								messageEndRef={inputRef}
								isInfluencer={isInfluencer}
							/>
						</Styled.Content>
					</>
				)}
			</Styled.Wrapper>
		</Grid.Column>
	);
};

export default ChatSection;
