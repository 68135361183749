import styled from 'styled-components';

import { SecondaryButton } from 'components/Button';
import { scrollbarX } from 'styles/utils/scrollbar';
import colors from 'styles/variables/colors';
import { mediaQueries } from 'styles/variables/media-queries';

const Wrapper = styled.div`
	display: flex;
	gap: 16px;
	grid-template-columns: repeat(1, 1fr);
	max-height: 542px;
	margin-bottom: 16px;
	${mediaQueries.medium`
		grid-template-columns: repeat(2, 1fr);
	`};

	${mediaQueries.large`
		grid-template-columns: repeat(4, 1fr);
	`};
`;

const ImageWrapper = styled.div`
	display: block;
	overflow: hidden;
	padding-bottom: 100%;
	position: relative;
	margin-bottom: 18px;
	border: 2px solid transparent;
	border-radius: 13px;
	transition:
		border 200ms ease-in-out,
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
	overflow: hidden;
	width: 150px;

	&.tall {
		padding-bottom: 0;
	}
`;

const Image = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 12px;
	padding: 0.1875rem;
	transition:
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
`;

const StoryWrapper = styled.div`
	transition:
		transform 200ms ease-in-out,
		box-shadow 200ms ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	&:hover:not(.disabled),
	&.selected {
		${ImageWrapper} {
			box-shadow: 0 4px 3px ${colors.StoryList.selected.boxShadow};
			${Image} {
				transform: scale(1.05);
			}
		}
	}

	&.disabled {
		cursor: default;
		opacity: 0.5;
	}
`;

const PostedAt = styled.div`
	font-size: 0.75rem;
	color: ${colors.StoryList.postedAt};
`;

const StoryInfoWrapper = styled.div``;

const Iframe = styled.iframe`
	width: 100%;
	height: 500px;
`;

const ModalHeader = styled.div`
	display: flex;

	button {
		background: none;
		border: none;
		padding: 0;
		margin-left: auto;
		cursor: pointer;
	}
`;

const Label = styled.span`
	display: block;
	font-weight: 700;
	font-size: 0.938rem;
`;

const StoryDataWrapper = styled.div``;

const StoryDataGroup = styled.div`
	margin-bottom: 16px;
`;

const StoryStats = styled.div``;

const StoryStatsList = styled.ul`
	margin: 0;
	padding: 0;
`;

const StoryStatsListItem = styled.li`
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 0.75rem;

	${Label} {
		font-size: 0.75rem;
	}

	&:not(:last-child) {
		margin-bottom: 8px;
	}
`;

const ErrorMessage = styled.p`
	color: ${colors.error};
`;

const ImageInner = styled.div`
	overflow: hidden;
	width: 270px;
	height: 424px;
`;

const StorySelector = styled.div`
	margin-top: 40px;
	margin-bottom: 40px;
	overflow: hidden;
	overflow-y: hidden;
	${scrollbarX};
`;

const SelectButton = styled(SecondaryButton)`
	width: 130px;
	padding: 0.25rem;
	min-height: 30px;
	height: 32px;
	margin: auto;
	margin-top: 24px;
`;

const Styled = {
	SelectButton,
	StorySelector,
	Wrapper,
	StoryWrapper,
	PostedAt,
	StoryInfoWrapper,
	Iframe,
	ImageWrapper,
	Image,
	ModalHeader,
	Label,
	StoryDataWrapper,
	StoryDataGroup,
	StoryStats,
	StoryStatsList,
	StoryStatsListItem,
	ErrorMessage,
	ImageInner,
};

export default Styled;
