import { Heading } from 'components/Heading';

import Styled from './EmptyState.style';

/**
 * EmptyState
 * Component to show a empty state
 * @returns {JSX.Element}
 */
const EmptyState = (): JSX.Element => {
	return (
		<Styled.Wrapper>
			<Heading as='h6'>Sorry, no Stories found within that date range.</Heading>
		</Styled.Wrapper>
	);
};

export default EmptyState;
