import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import ChartCardStyled from 'components/DataLibrary/Graphs/GraphComponents/Card/ChartCard/ChartCard.style';
import { Tr } from 'styles/table';
import colors from 'styles/theme/colors';
import _colors from 'styles/variables/colors';
import typography from 'styles/variables/typography';

const Wrapper = styled.div``;

const borderStyle = css`
	background-image: linear-gradient(to right, ${_colors.TableV2.borderDotsColor} 13%, rgba(136, 136, 136, 0) 0%);
	background-position: bottom;
	background-size: 8px 1px;
	background-repeat: repeat-x;
`;

const ListTable = styled.table`
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
`;

const ListTableHead = styled.thead`
	width: 100%;
	position: sticky;
	top: 0;
	background-color: ${colors.mist} !important;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	z-index: 20;

	td,
	th {
		background-color: ${colors.mist} !important;
	}

	&,
	tr {
		background-color: ${({ theme }) => theme.table.thHeadBackground} !important;
	}
`;

const ListTableBody = styled.tbody`
	overflow-y: auto;
	max-height: 500px;
	padding-bottom: 5rem;

	tr {
		${borderStyle};
		border: transparent;
	}
`;

const ListTableTh = styled.th<CSSProperties>`
	font-size: ${typography.list.medium.fontSize};
	font-weight: ${typography.list.accessory.fontWeight};
	text-align: ${(props) => props.textAlign || 'left'};
	padding: 0.5rem;
	white-space: nowrap;
`;

const ListTableTr = styled.tr`
	${Tr};
`;

const ListTableTd = styled.td`
	padding: 1rem;
`;

const TableHeaderInnerWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
`;

const Label = styled.div`
	margin-right: 5px;
`;

const LabelWrapper = styled.p`
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	line-height: 1;
`;

const LabelTextWrapper = styled.span`
	&.text-sm {
		font-size: 0.85rem;
	}
	line-height: 1;
`;

const SortIconWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	row-gap: 0.1rem;

	& > div {
		&.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid ${_colors.campaignOverview.transparent};
			border-right: 5px solid ${_colors.campaignOverview.transparent};
			border-bottom: 5px solid ${_colors.campaignOverview.gray};
		}

		&.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid ${_colors.campaignOverview.transparent};
			border-right: 5px solid ${_colors.campaignOverview.transparent};
			border-top: 5px solid ${_colors.campaignOverview.gray};
		}

		&.isActive {
			border-color: ${_colors.campaignOverview.black};
			border-left: 5px solid ${_colors.campaignOverview.transparent};
			border-right: 5px solid ${_colors.campaignOverview.transparent};
		}
	}
`;

const InnerCenteredWrapper = styled.div`
	max-height: 48vh;
	position: relative;
`;

const CustomDropDownMenuItem = styled(ChartCardStyled.CustomDropDownMenuItem)``;

const IconMargin = styled(ChartCardStyled.IconMargin)``;

const Styled = {
	InnerCenteredWrapper,
	Wrapper,
	ListTable,
	ListTableHead,
	ListTableBody,
	ListTableTh,
	ListTableTr,
	ListTableTd,
	TableHeaderInnerWrapper,
	SortIconWrapper,
	Label,
	LabelWrapper,
	LabelTextWrapper,
	CustomDropDownMenuItem,
	IconMargin,
};

export default Styled;
