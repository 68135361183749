import classNames from 'classnames';
import { useState, forwardRef, useImperativeHandle, useRef, FocusEvent } from 'react';

import Icon from 'components/Icon';

import Styled from './Select.style';
import { SelectProps } from './types';

const Select = forwardRef<HTMLSelectElement, SelectProps>(
	(
		{ scale = 'medium', isWarning, isError, disabled, readOnly = false, onFocus, isCustom, onBlur, contentBefore, contentAfter, ...props },
		externalRef,
	): JSX.Element => {
		const [isFocused, setIsFocused] = useState(false);
		const internalRef = useRef<HTMLSelectElement>(null);

		useImperativeHandle(externalRef, () => internalRef.current!, [internalRef]);

		const handleFocus = (e: FocusEvent<HTMLSelectElement>) => {
			setIsFocused(true);
			onFocus?.(e);
		};

		const handleBlur = (e: FocusEvent<HTMLSelectElement>) => {
			setIsFocused(false);
			onBlur?.(e);
		};

		const handleContainerClick = (e: React.MouseEvent<HTMLSpanElement>) => {
			if (!disabled && !readOnly && internalRef.current && e.target !== internalRef.current) {
				e.preventDefault();
				internalRef.current.focus();

				if ('showPicker' in HTMLSelectElement.prototype) {
					(internalRef.current as HTMLSelectElement).showPicker();
				} else {
					const event = new MouseEvent('mousedown', { bubbles: true, cancelable: true, view: window });
					internalRef.current.dispatchEvent(event);
				}
			}
		};

		return (
			<Styled.InputContainer
				className={classNames({
					'has-warning': isWarning,
					'has-error': isError,
					disabled: disabled,
					readonly: readOnly,
					'has-focus': isFocused,
					isCustom: isCustom,
				})}
				scale={scale}
				onClick={handleContainerClick}
			>
				{contentBefore && <Styled.ContentBefore>{contentBefore}</Styled.ContentBefore>}
				<Styled.Select
					data-testid='select-field'
					scale={scale}
					onFocus={handleFocus}
					onBlur={handleBlur}
					disabled={disabled || readOnly}
					ref={internalRef}
					{...props}
				>
					{props.children}
				</Styled.Select>
				<Styled.ContentAfter>{contentAfter || <Icon name='arrow-down' size={isCustom ? '12' : '14'} />}</Styled.ContentAfter>
			</Styled.InputContainer>
		);
	},
);

Select.displayName = 'Select';

export default Select;
