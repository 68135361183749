import Party from 'assets/img/PartyPopper.svg';
import Waving from 'assets/img/WavingHand.svg';

import Styled from './Intro.style';

/**
 */
const Intro = (props: { id: string; hasJoined: boolean }) => {
	return (
		<Styled.Wrapper id={props.id}>
			<Styled.IntroTextWrapper>
				<Styled.WelcomeText>
					<h1>
						{props.hasJoined ? "We're excited you're onboard!" : 'Hi, there!'} <img src={props.hasJoined ? Party : Waving} height='40px' />
					</h1>
					{props.hasJoined ? (
						<p>
							Below, you'll find your assignments, campaign details, and product information (if available). Good luck, and feel free to reach out if you have
							any questions or need assistance along the way!
						</p>
					) : (
						<p>We&apos;re very excited you came here. You&apos;ve been invited to our campaign as we&apos;d like to collaborate with you.</p>
					)}
				</Styled.WelcomeText>
			</Styled.IntroTextWrapper>
		</Styled.Wrapper>
	);
};

export default Intro;
