import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TeamMember } from 'views/Campaign/CreateCampaign/types';

type SliceState = {
	brandUsers: TeamMember[];
};

const initialState: SliceState = {
	brandUsers: [],
};

const campaignBrandUsersSlice = createSlice({
	name: 'brandUsers',
	initialState,
	reducers: {
		setBrandUsers(state, action: PayloadAction<TeamMember[]>) {
			state.brandUsers = action.payload;
		},
		clearBrandUsers: () => initialState,
	},
});

export const { setBrandUsers, clearBrandUsers } = campaignBrandUsersSlice.actions;
export default campaignBrandUsersSlice.reducer;
