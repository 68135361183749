import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const StyledLink = styled(Link)`
	display: flex;
	min-width: 100px;
	width: fit-content;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 44px;
	padding: 0.25rem 1rem;
	border: solid 2px ${colors.smoke};
	background-color: ${colors.snow};
	cursor: pointer;
	font-size: 1rem;
	white-space: nowrap;
	color: ${colors.smoke};
	border-radius: ${borderRadius.m};
	.icon path {
		fill: ${colors.snow};
	}
	&.blue {
		border: solid 2px ${colors.oceanBlue};
		background-color: ${colors.oceanBlue};
		color: ${colors.snow};
		.icon path {
			fill: ${colors.snow};
		}
	}
	transition:
		background-color 150ms ease-in-out,
		color 150ms ease-in-out,
		border-color 150ms ease-in-out;
	&:hover {
		background-color: ${colors.black};
		color: ${colors.snow};
		.icon path {
			fill: ${colors.snow};
		}
	}
	span {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
`;

const Styled = {
	StyledLink,
};

export default Styled;
