import styled from 'styled-components';

import { PrimaryButton } from 'components/Button';
import colors from 'styles/theme/colors';

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	margin-top: 24px;
	h4 {
		margin-bottom: 0;
	}
`;

const FormCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
`;

const ButtonLink = styled.button`
	padding: 0;
	border: 0 none;
	color: ${colors.oceanBlue};
	background: none;
	cursor: pointer;
	margin-top: 0 !important;
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;

// ... existing code ...

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MessageBoxWrapper = styled.div`
	margin-bottom: 16px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
	float: right;
`;

const Styled = {
	IconWrapper,
	FormWrapper,
	FormCard,
	ButtonLink,
	HeaderWrapper,
	MessageBoxWrapper,
	StyledPrimaryButton,
};

export default Styled;
