import { Model, Store } from 'json-api-models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GHOST_TOKEN, GHOST_USER, GHOST_USER_UUID, GHOST_USER_START_URL } from 'constants/localStorage-keys';
import useInjection from 'hooks/useInjection';
import usePermissions from 'hooks/usePermissions';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';

import { Wrapper } from './ImpersonateBar.style';

/**
 * ImpersonateBar
 */
const ImpersonateBar = () => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const storage = new BrowserStorage();
	const user = useAppSelector((state) => state.user);

	const { isInfluencer } = usePermissions();

	const getName = () => {
		if (isInfluencer() && user?.influencer?.username) {
			return user.influencer.username;
		}
		if (user?.firstName && user?.lastName) {
			return `${user.firstName} ${user.lastName}`;
		}
		return user?.id ?? 'Unknown';
	};
	return (
		<Wrapper>
			<span>
				You&apos;re impersonating{' '}
				<span>
					<strong>{getName()}</strong> (ID: {user.id})
				</span>
			</span>
			<button
				disabled={loading}
				className='no-style-btn text-strong cursor-pointer ml-16'
				onClick={async () => {
					const ghostStartUrl = storage.getItem(GHOST_USER_START_URL);
					storage.removeItem(GHOST_TOKEN);
					storage.removeItem(GHOST_USER_START_URL);
					storage.removeItem(GHOST_USER);
					storage.removeItem(GHOST_USER_UUID);
					storage.removeItem('user');

					setLoading(true);
					const data = await authService.me(authService.getGlobalUserIncludes());
					const user = new Store().sync(data) as Model;
					dispatch(setUser(user));
					setLoading(false);
					const destination = ghostStartUrl ?? '/admin/dashboard';
					if ('test' === process.env.NODE_ENV) {
						navigate(destination);
					} else {
						location.replace(destination);
					}
				}}
			>
				{loading ? '...' : 'Logout'}
			</button>
		</Wrapper>
	);
};

export default ImpersonateBar;
