import { CSSProperties } from 'react';
import styled, { DefaultThemeV2 } from 'styled-components';

import Accordion from 'components/Accordion';
import Dropdown from 'components/Dropdown';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { fontWeights } from 'styles/variables/font-weights';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const dataContainerPadding = `${guttersWithRem.s} ${guttersWithRem.xs}`;

const Wrapper = styled.div`
	padding-top: 1rem;
	width: '100%';
	display: flex;
	flex-direction: column;
	gap: 16px;
	button {
		width: fit-content;
	}
`;

const FlexDiv = styled.div<CSSProperties>`
	font-family: ${typography.BaseFontFamiliy};
	display: flex;
	flex: ${(props) => props.flex};
	padding: ${(props) => props.padding};
	height: ${(props) => props.height};
	position: ${(props) => props.position};
	column-gap: ${(props) => props.columnGap};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};

	&.influencer-info {
		padding-bottom: 1rem;
		border-bottom: 1px solid ${colors.gray11};
		width: 100%;
	}

	&.header {
		margin-bottom: ${guttersWithRem.xs};
	}
	&.coming-soon-icons {
		padding: 0 1.25rem;
		position: relative;
		cursor: not-allowed;

		&:hover:after {
			content: 'coming soon';
			white-space: nowrap;
			font-size: 0.5rem;
			padding: 0.25rem 0.5rem;
			position: absolute;
			top: -15px;
			left: 40px;
			z-index: 10;
		}
	}

	&.brand-affiliation-item {
		justify-content: space-between;
		align-items: center;
		background-color: ${colors.buttonGray};
		line-height: 1;
		padding: 1rem;
		border-radius: ${borderRadius.m};
	}
	div {
		&.brand-affiliation-item-username {
			width: 60%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: ${({ theme }: { theme: DefaultThemeV2 }) => theme.fontWeights.bold};
		}
	}

	&.header-buttons {
		column-gap: ${guttersWithRem.xs};
	}

	.missing-link {
		font-size: 0.875rem;
		color: ${colors.labelColor};
	}
`;

const DataContainer = styled.div<CSSProperties>`
	width: 100%;
	min-height: 230px;
	min-width: ${(props) => props.minWidth};
	max-height: ${(props) => props.maxHeight};
	max-width: ${(props) => props.width};
	padding: ${dataContainerPadding};
	overflow-x: ${(props) => props.overflowX};
	overflow-y: ${(props) => props.overflowY};
	&.brand-affiliations {
		padding-top: 3rem;
		border-top: 1px solid ${colors.gray11};
	}
`;

const GenderDataContainer = styled.div<CSSProperties>`
	display: flex;
	align-items: center;
	position: relative;
	min-width: 320px;
`;

const DataBlockTitle = styled.div`
	font-weight: 600;
	font-size: 0.875rem;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	gap: 8px;
	div {
		margin-left: 0;
	}
	&.brand-affiliations {
		margin-bottom: 0;
	}
`;

const CustomDropdown = styled(Dropdown)`
	& button {
		padding: 0;

		&:hover,
		&.show {
			background-color: unset;
		}
	}
`;

const DropdownMenuWrapper = styled.div`
	position: absolute;
	top: -8px;
	right: -10px;
`;

const AgeSpanDataWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.9rem;
	line-height: 1;
	border-radius: ${({ theme }) => theme.radius.default};
	&.odd {
		background-color: ${newColors.mist};
	}
	span {
		&.value {
			font-weight: ${({ theme }) => theme.fontWeights.bold};
		}
	}
`;

const LinkToPost = styled.a`
	&.post-link {
		color: ${newColors.oceanBlue};

		&:hover {
			border: none;
		}
	}
`;

const DateInfo = styled.div`
	font-size: 0.75rem;
	color: ${colors.labelColor};
	display: flex;
	justify-content: flex-end;
	margin-bottom: 3px;
`;

const Div = styled.div`
	width: 100%;
	text-align: center;
	&.light-gray-bg {
		background-color: ${colors.buttonGray};
	}
	&.divide-line {
		position: relative;
		&:after {
			position: absolute;
			top: 2px;
			bottom: 2px;
			right: 0;
			width: 1px;

			content: ' ';
			border-right: 1px solid ${colors.gray11};
		}
	}
`;

const DataText = styled.span`
	font-family: ${typography.headings.fontFamily};
	font-weight: ${typography.headings.h5.fontWeight};
	font-size: ${typography.headings.h5.fontSize};
	padding-top: 0.75rem;
	line-height: 1;
`;

const InfluencerData = styled.div`
	&.value {
		font-weight: ${({ theme }) => theme.fontWeights.bold};

		&::first-letter {
			text-transform: capitalize;
		}
	}

	&.label {
		font-size: 0.875rem;
		line-height: 1.5;
		color: ${colors.labelColor};
	}
`;

const LinkText = styled.div`
	margin-top: 16px;
	color: ${newColors.oceanBlue};
	font-weight: ${fontWeights.bold};
	cursor: pointer;
`;

const ActionButton = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	cursor: pointer;
	border-radius: ${({ theme }) => theme.radius.default};
	padding: 0.5rem;
	transition: background-color 0.2s ease-in-out;

	i {
		height: 20px;
	}

	&:hover {
		background-color: ${({ theme }) => theme.colors.inputs};
	}
`;

const Section = styled.div`
	display: flex;
	width: 100%;
	&.brand-affiliations {
		padding-bottom: 1rem;
	}
	&.influencer-info {
		padding-bottom: 1rem;
		border-bottom: 1px solid ${colors.gray11};
		width: 100%;
	}
`;

const Content = styled.div`
	border-radius: ${borderRadius.m};
	background-color: ${newColors.mist};
	padding: 1rem;
	margin-bottom: 80px;
	max-height: 600px;
	overflow-y: auto;
`;

const CustomAccordion = styled(Accordion)`
	.accordion__header {
		padding: 1rem;
		border-radius: ${borderRadius.m};
		&:hover {
			background-color: ${newColors.mist};
		}
	}
`;

const Styled = {
	Content,
	InfluencerData,
	LinkText,
	Div,
	DataText,
	Wrapper,
	DataContainer,
	GenderDataContainer,
	DataBlockTitle,
	CustomDropdown,
	DropdownMenuWrapper,
	FlexDiv,
	AgeSpanDataWrapper,
	DateInfo,
	LinkToPost,
	ActionButton,
	Section,
	CustomAccordion,
};

export default Styled;
