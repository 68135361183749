import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import ContentCard from 'components/ContentCard';
import { StatisticsFormValues } from 'components/ContentManagement/Components/Views/Statistics/helpers';
import { InstagramUserStory } from 'components/ContentManagement/types';
import { Heading } from 'components/Heading';
import LoadingSpinner from 'components/LoadingSpinner';

import EmptyState from './EmptyState';
import Styled from './StoryList.style';

type StoryListProps = {
	frames: Array<InstagramUserStory>;
	errorMessage?: string;
	isLoading: boolean;
	setFormValues?: (values: StatisticsFormValues) => void;
	selectedStoryItems?: Array<InstagramUserStory>;
	setSelectedStoryItems?: (selectedStoryItems: Array<InstagramUserStory>) => void;
	framesConnectedToOtherAssignments?: Array<InstagramUserStory>;
	values: StatisticsFormValues;
	noResult: boolean;
	assignmentApproved: boolean;
};

/**
 * StoryFrameList
 * Component to display and (de)select frames from Instagram for the influencer.
 * @param props {StorListProps}
 * @returns {JSX.Element}
 */
const StoryFrameList = ({
	assignmentApproved,
	setSelectedStoryItems,
	selectedStoryItems,
	setFormValues,
	values,
	isLoading,
	frames,
	errorMessage,
	noResult,
	framesConnectedToOtherAssignments,
}: StoryListProps): JSX.Element => {
	const isFrame = 'instagramStoryFrame';
	const onClickStory = (item: InstagramUserStory) => {
		const isAlreadySelected = selectedStoryItems?.some((selectedItem: { id: string }) => selectedItem.id === item.id);
		const theSelectedItems = isAlreadySelected
			? selectedStoryItems?.filter((s: { id: string }) => s.id !== item.id)
			: selectedStoryItems && [...selectedStoryItems, item];
		setSelectedStoryItems && theSelectedItems && setSelectedStoryItems(theSelectedItems);

		const selectedFrames = theSelectedItems?.filter((item) => item.type === isFrame);
		const framesSortedByPosted = _.orderBy(selectedFrames, 'postedAt', 'asc');

		setFormValues &&
			setFormValues({
				...values,
				impressions: Number(framesSortedByPosted[0]?.impressions),
				reach: Number(framesSortedByPosted[0]?.reach),
				reachLastFrame: Number(framesSortedByPosted[framesSortedByPosted?.length - 1]?.reach),
				frames: selectedStoryItems && selectedStoryItems.map((item) => item.id),
				postedAt: framesSortedByPosted[0]?.postedAt,
			});
	};

	return (
		<>
			<Styled.StorySelector>
				<Heading as='h5'>Select frames</Heading>
				{isLoading && <LoadingSpinner position='center' />}
				{!isLoading && noResult && <EmptyState />}
				{errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
				<Styled.Wrapper>
					{!isLoading &&
						_.orderBy(frames, 'postedAt', 'asc').map((item) => {
							const hideFrame = framesConnectedToOtherAssignments ? framesConnectedToOtherAssignments?.some((frame) => frame?.id === item?.id) : false;
							const selected = selectedStoryItems ? selectedStoryItems?.some((selectedStory) => selectedStory.id === item.id) : false;
							return hideFrame ? (
								<></>
							) : (
								<Styled.StoryWrapper
									key={item.id}
									data-id={item.id}
									data-testid={`story-${item.id}`}
									onClick={() => onClickStory(item)}
									className={classNames({
										selected: selected,
									})}
								>
									<ContentCard
										imageUrl={item.links.screenshot}
										altText={`Instagram Story image`}
										videoUrl={item.vimeoPlaybackUrl}
										active={selectedStoryItems ? selectedStoryItems?.some((selectedStory) => selectedStory?.id === item?.id) : false}
										disabled={false} // Can be used if frames belonging to other assignments should be displayed.
										type='tall'
									>
										<Styled.StoryStats>
											<Styled.StoryStatsList>
												<Styled.StoryStatsListItem>
													<Styled.PostedAt>
														{isFrame ? 'Posted' : 'Screenshot, added'}: {moment(item.postedAt).format('YYYY-MM-DD')}
													</Styled.PostedAt>
												</Styled.StoryStatsListItem>
												{((isFrame && selected) || assignmentApproved) && (
													<>
														<Styled.StoryStatsListItem>
															<Styled.Label>Reach </Styled.Label> {item.reach ? new Intl.NumberFormat('en').format(item.reach) : 0}
														</Styled.StoryStatsListItem>
														<Styled.StoryStatsListItem>
															<Styled.Label>Impressions </Styled.Label> {item.impressions ? new Intl.NumberFormat('en').format(item.impressions) : 0}
														</Styled.StoryStatsListItem>
													</>
												)}
											</Styled.StoryStatsList>
											{!selected && !assignmentApproved && (
												<Styled.SelectButton size='sm'>
													<span>Select frame</span>
												</Styled.SelectButton>
											)}
										</Styled.StoryStats>
									</ContentCard>
								</Styled.StoryWrapper>
							);
						})}
				</Styled.Wrapper>
			</Styled.StorySelector>
		</>
	);
};

export default StoryFrameList;
