import { Model } from 'json-api-models';
import { useState, useMemo, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CampaignModel } from 'api-models';
import { ListCampaignsQuery } from 'api-queries';
import { SearchInput } from 'components';
import Tabs from 'components/Tabs/Tabs';
import useInjection from 'hooks/useInjection';
import CampaignManager from 'services/ApiManager/Campaign.manager';
import GenericManager from 'services/ApiManager/Generic.manager';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';
import Campaigns from 'views/influencer/InfluencerDashboard/Components/Campaigns/Campaigns';
import NoValueState from 'views/influencer/InfluencerDashboard/Components/NoValueState';

import Styled from './CampaignSection.style';
import { groupByCampaignInstagramOwnerId } from './helpers';
import { InfluencerAssignmentOverview } from './types';

enum TabType {
	ACTIVE_CAMPAIGNS = 'Active campaigns',
	ARCHIVED_CAMPAIGNS = 'Archived campaigns',
}

const CampaignSection = (): JSX.Element => {
	const [search, setSearch] = useState('');
	const [selectedTab, setSelectedTab] = useState<string>(TabType.ACTIVE_CAMPAIGNS);
	const [filteredCampaigns, setFilteredCampaigns] = useState<InfluencerAssignmentOverview[]>([]);
	const [archivedCampaigns, setArchivedCampaigns] = useState<InfluencerAssignmentOverview[]>([]);
	const [activeCampaigns, setActiveCampaigns] = useState<InfluencerAssignmentOverview[]>([]);

	const queryBuilder = useMemo(() => {
		return RequestQueryBuilder.create<ListCampaignsQuery>(['smallCoverPhoto', 'mediumCoverPhoto', 'largeCoverPhoto'])
			.withInclude('assignments.reviews')
			.withInclude('commissions')
			.withInclude('campaignInstagramOwnerAssignments.assignment')
			.withInclude('campaignInstagramOwners', ['createInstagramStory', 'createAssignmentReview'])
			.withInclude('campaignInstagramOwners.campaignInstagramOwnerAssignments.assignment')
			.withInclude('campaignInstagramOwners.influencer', ['instagramStories']);
	}, []);

	const manager = useInjection<CampaignManager>(CampaignManager);
	const { repository, isLoading } = manager.listCampaigns(queryBuilder);
	const campaigns = repository.findAll<CampaignModel>('campaign') ?? [];

	const gmanager = useInjection<GenericManager>(GenericManager);
	const qb = RequestQueryBuilder.create<GenericManager>(['smallCoverPhoto', 'mediumCoverPhoto', 'largeCoverPhoto']);
	const { repository: overviewRepository, isLoading: isLoadingOverview } = gmanager.get('/influencer-assignment-overviews', qb);
	const overview = (overviewRepository.findAll('influencerAssignmentOverview') as Model[]) ?? [];
	const groupedCampaigns = groupByCampaignInstagramOwnerId(overview);

	useEffect(() => {
		const allCampaigns = [...activeCampaigns, ...archivedCampaigns];
		const filtered = allCampaigns.filter(
			(item) => item.campaignName.toLowerCase().includes(search.toLowerCase()) || item.username.toLowerCase().includes(search.toLowerCase()),
		);

		if (search.length > 0) {
			const activeResults = filtered.filter((item) => !item.archived);
			const archivedResults = filtered.filter((item) => item.archived);

			if (activeResults.length > 0 && archivedResults.length === 0) {
				setSelectedTab(TabType.ACTIVE_CAMPAIGNS);
				setFilteredCampaigns(activeResults);
			} else if (archivedResults.length > 0 && activeResults.length === 0) {
				setSelectedTab(TabType.ARCHIVED_CAMPAIGNS);
				setFilteredCampaigns(archivedResults);
			} else {
				setFilteredCampaigns(filtered);
			}
		} else {
			const campaignsToShow = selectedTab === TabType.ACTIVE_CAMPAIGNS ? activeCampaigns : archivedCampaigns;
			setFilteredCampaigns(campaignsToShow);
		}
	}, [search, activeCampaigns, archivedCampaigns]);

	useEffect(() => {
		setFilteredCampaigns(selectedTab === TabType.ACTIVE_CAMPAIGNS ? activeCampaigns : archivedCampaigns);
	}, [selectedTab]);

	useEffect(() => {
		const activeCampaigns = groupedCampaigns.filter((item) => !item.archived);
		const archivedCampaigns = groupedCampaigns.filter((item) => item.archived);
		setActiveCampaigns(activeCampaigns);
		setArchivedCampaigns(archivedCampaigns);
	}, [groupedCampaigns.length]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	return (
		<Styled.Wrapper>
			<Styled.FilterSection>
				<Tabs
					tabs={[
						{ title: TabType.ACTIVE_CAMPAIGNS, amount: activeCampaigns.filter((item) => !item.archived).length },
						{ title: TabType.ARCHIVED_CAMPAIGNS, amount: archivedCampaigns.filter((item) => item.archived).length },
					]}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				/>
				<Styled.InputWrapper>
					<SearchInput value={search} onChange={handleSearchChange} placeholder='Search for campaign or username' onReset={() => setSearch('')} />
				</Styled.InputWrapper>
			</Styled.FilterSection>
			<Styled.CampaignsWrapper>
				{isLoadingOverview || isLoading ? (
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<Skeleton width='100%' height='300px' />
						<Skeleton width='100%' height='300px' />
					</div>
				) : filteredCampaigns.length > 0 ? (
					<Campaigns displaySkeletonLoader={false} campaignItems={filteredCampaigns} campaigns={campaigns} repository={repository} />
				) : (
					<NoValueState
						type={
							search.length > 0 && filteredCampaigns.length === 0
								? 'no-results'
								: selectedTab === TabType.ACTIVE_CAMPAIGNS
									? 'no-campaign-active'
									: 'no-campaign-archived'
						}
					/>
				)}
			</Styled.CampaignsWrapper>
		</Styled.Wrapper>
	);
};

export default CampaignSection;
