import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: -8px;
	width: 100%;
	height: 30px;
	padding: 0.25rem;
	font-size: 0.875rem;
	background-color: ${colors.ruby};
	vertical-align: middle;
	color: ${colors.white};
	text-align: center;
	border-radius: ${borderRadius.m};
`;
