import styled from 'styled-components';

import Dropdown from 'components/Dropdown';
import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

const TextAreaWrapper = styled.div`
	padding: 1rem 0 0 1rem;
	display: flex;
	flex: 1;
	max-height: 200px;
	${scrollbarY};
`;

const TextArea = styled.textarea`
	border: none;
	color: ${colors.integratedInbox.chatSection.chatInput.color};
	outline: none;
	resize: none;
	width: 100%;
	line-height: 1.5;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
`;

const MessageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const MessageBar = styled.div`
	display: flex;
	flex-grow: 1;
	position: relative;
	background-color: ${colors.integratedInbox.chatSection.chatInput.background};
	flex-direction: row;
	border: 1px solid ${colors.lightGray};
	border-radius: ${borderRadius.m};
	flex-direction: column;
`;

const MessageBarControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const Send = styled.button`
	background-color: transparent;
	border: none;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;

	&:not(:disabled) {
		background-color: ${newColors.oceanBlue};
		path {
			fill: ${colors.mainWhite};
		}
		cursor: pointer;
	}

	.icon {
		margin-top: 3px;
	}
`;

const MessageBarControls = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`;

const CustomDropdown = styled(Dropdown)`
	margin-left: -8px;

	.show {
		button {
			background-color: transparent !important;
		}
	}

	button {
		&:hover {
			background-color: transparent !important;
		}

		&:disabled {
			opacity: 0.4;
		}
	}

	span {
		display: flex;
		align-items: center;
	}
`;

const InvisibleInput = styled.input`
	display: none;
`;

const AttachedFilesWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const Attachment = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
	background-color: ${newColors.mist};
	border-radius: ${borderRadius.m};
	padding: 0.5rem;
`;

const AttachmentList = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;

	.attachment__name {
		display: inline-block;
		max-width: 150px;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
		line-height: 1.5;
	}

	.attachment__delete {
		cursor: pointer;
	}
`;

const Info = styled.div`
	font-size: 0.875rem;
	border-radius: ${borderRadius.m};
`;

const Styled = {
	Info,
	MessageWrapper,
	MessageBar,
	TextAreaWrapper,
	TextArea,
	MessageBarControlsWrapper,
	MessageBarControls,
	Send,
	CustomDropdown,
	InvisibleInput,
	AttachedFilesWrapper,
	Attachment,
	AttachmentList,
};

export default Styled;
