import Styled from 'components/ContentManagement/Components/Views/Statistics/Statistics.style';
import { InputText } from 'components/Form/FormikElements';
import Field from 'components/Forms/Field';
import { Heading } from 'components/Heading';
import { AssignmentType } from 'shared/helpers/Assigment/types';

/**
 * @returns {JSX.Element}
 */
const StatisticFields = ({ contentType }: { contentType: AssignmentType }): JSX.Element => {
	const IS_STORY = contentType === AssignmentType.INSTAGRAM_STORY;
	const IS_TIKTOK = contentType === AssignmentType.TIKTOK_VIDEO;
	const IS_REEL = contentType === AssignmentType.INSTAGRAM_REEL;

	const onWheel = (e: React.WheelEvent) => {
		const target = e.target as HTMLElement | null;
		target?.blur();
	};

	const preventMinus = (e: React.KeyboardEvent) => {
		if (e.code === 'Minus') {
			e.preventDefault();
		}
	};

	return (
		<Styled.FieldsWrapper>
			{IS_STORY ? (
				<>
					<Heading as='h5' data-testid='manual-for-story'>
						Frame statistics
					</Heading>
					<Styled.InputGroupRow>
						<Field label={`Reach ${IS_STORY ? '(first frame)' : ''}`}>
							<InputText min={0} name='reach' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} onKeyPress={preventMinus} />
						</Field>
						<Field label='Reach (last frame)'>
							<InputText min={0} type='number' name='reachLastFrame' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						<Field label={`${IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'} ${IS_STORY ? ' of the first frame' : ''}`}>
							<InputText min={0} name='impressions' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
					</Styled.InputGroupRow>
					<Heading as='h5'>Interaction statistics</Heading>
					<Styled.InputGroupRow>
						<Field label='Total sticker link clicks'>
							<InputText min={0} type='number' name='stickerLinkClicks' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						<Field label='Total sticker taps'>
							<InputText min={0} type='number' name='stickerTaps' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						<Field label='Other interactions'>
							<InputText min={0} type='number' name='otherInteractions' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
					</Styled.InputGroupRow>
				</>
			) : (
				<>
					<Heading as='h5' data-testid='manual-for-not-story'>
						Add statistics
					</Heading>
					<Styled.InputGroupRow>
						<Field label='Reach'>
							<InputText min={0} name='reach' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						<Field label={`${IS_REEL || IS_TIKTOK ? 'Plays' : 'Impressions'}`}>
							<InputText min={0} name='impressions' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
					</Styled.InputGroupRow>
					<Styled.InputGroupRow>
						<Field label='Comments'>
							<InputText min={0} name='comments' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						<Field label='Likes'>
							<InputText min={0} name='likes' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
					</Styled.InputGroupRow>
					<Styled.InputGroupRow>
						<Field label='Saves'>
							<InputText min={0} name='saves' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
						</Field>
						{IS_TIKTOK && (
							<Field label='Shares'>
								<InputText min={0} name='shares' type='number' placeholder='Leave empty if value is 0' onWheel={onWheel} />
							</Field>
						)}
					</Styled.InputGroupRow>
				</>
			)}
		</Styled.FieldsWrapper>
	);
};

export default StatisticFields;
