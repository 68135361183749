import { useParams } from 'react-router-dom';

import AuthContainer from 'views/Auth/AuthContainer/AuthContainer';

import ResetPasswordForm from './components/ResetPasswordForm';

/**
 * LoginResetView
 * @todo i18n
 * @returns {JSX.Element}
 */
const ResetPasswordContainer = (): JSX.Element => {
	const { token } = useParams();
	return (
		<AuthContainer>
			<ResetPasswordForm token={token || ''} />
		</AuthContainer>
	);
};

export default ResetPasswordContainer;
