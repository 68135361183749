import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DataLibrary from 'components/DataLibrary';
import useInjection from 'hooks/useInjection';
import { UserType } from 'reducers/UserReducers/types';
import { pathDashboard } from 'routing/PathLookup';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import toast from 'services/Toast';

import Styled from './DataLibrary.style';
import { setDashboardFilter } from './reducers/DashboardFilterSlice';
import { setDashboard } from './reducers/DashboardSlice';
import { setDashboards } from './reducers/DashboardsSlice';
import { IsFetchingDashboards } from './reducers/FetchingDashboardsSlice';
import { DashboardType } from './reducers/types';
import { store } from './store';

/**
 * The new Data library uses its own state with the dashboards (one and all) and the dashboard filter which is applied to all graphs.
 *
 * Create, Copy, Edit, Delete and Share Dashboard are handled in the ContentHeader.
 * Get all dashboards are called here. Search in the ContentHeader is made in the Dashboards state.
 * Every Graph can be displayed in the sidebar.
 * Add new graphs to dashboard are handled in the ChartCard on every graph.
 * Remove graph from dashboard is handled in the ContentBody and passed through useGetChart to every CartCard.
 *
 * @returns {JSX.Element}
 */
const DataLibraryContainer = ({ user, isBrandManager }: { user: UserType; isBrandManager: boolean }): JSX.Element => {
	const navigate = useNavigate();
	const { dashboardId } = useParams();

	const [selectedDashboardId, setSelectedDashboardId] = useState<string | undefined>();
	const dashboardHelper = useInjection<DashboardHelper>(DashboardHelper);

	useEffect(() => {
		if (isBrandManager) {
			navigate(pathDashboard());
		}

		store.dispatch(IsFetchingDashboards(true)); // Global fetching state
		dashboardHelper
			.getDashboards()
			.then((dashboards) => {
				store.dispatch(setDashboards(dashboards));

				if (dashboards?.length > 0) {
					// If url contains Id, select that dashboard, else last created.
					const selectedDashboard = dashboardId
						? dashboards.slice().filter((dashboard: DashboardType) => dashboard.id === dashboardId)
						: dashboards.slice().sort((a: DashboardType, b: DashboardType) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

					// Set selected dashboard in redux state and if it has dashboard filter saved, set that in redux state.
					if (selectedDashboard.length > 0) {
						store.dispatch(setDashboard(selectedDashboard[0]));
						if (selectedDashboard[0].metaData.dashboardFilter) {
							store.dispatch(setDashboardFilter(selectedDashboard[0].metaData.dashboardFilter));
						}

						// If no id is in the url, set the id in state and then navigate to url with that id to make sure params and state is synced.
						if (!dashboardId) {
							setSelectedDashboardId(selectedDashboard[0]?.id);
						}
					}
				}
			})
			.catch((error: AxiosError) => {
				console.error(error);
				toast.error(`${error.message}`);
			})
			.finally(() => {
				store.dispatch(IsFetchingDashboards(false));
			});
	}, []);

	useEffect(() => {
		if (selectedDashboardId) {
			navigate(`/data-library/${selectedDashboardId}`);
		}
	}, [selectedDashboardId]);

	return (
		<Styled.Content>
			<DataLibrary user={user} />
		</Styled.Content>
	);
};
export default DataLibraryContainer;
