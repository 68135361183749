import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { Model, Store } from 'json-api-models';
import parsePhoneNumber from 'libphonenumber-js';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

import { PrimaryButton } from 'components/Button';
import CountrySelector from 'components/Form/Elements/CountrySelector/CountrySelector';
import InputText from 'components/Form/FormikElements/Text';
import Styled from 'components/Settings/Settings.style';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppSelector, useAppDispatch } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import toast from 'services/Toast';
import UserService from 'services/User';
import { createClient } from 'shared/ApiClient/ApiClient';
import { updateUser } from 'shared/User/User.helpers';
import Grid from 'styles/grid/grid';
import handleErrors from 'utils/formik_error_handler';

export type UserFormValues = {
	mobilePhoneNumber: string;
	mobilePrefix: string;
	address: string;
	postalCode: string;
	city: string;
	countryCode: string;
};

/**
 * DeliveryInfo
 * @returns {JSX.Element}
 */
const DeliveryInfoSection = (): JSX.Element => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [changeButtonText, setChangeButtonText] = useState<boolean>(false);

	const user = useAppSelector((state) => state.user);
	const Client = createClient();
	const dispatch = useAppDispatch();

	const defaultValues: UserFormValues = {
		address: user.address ?? '',
		postalCode: user.postalCode ?? '',
		city: user.city ?? '',
		countryCode: user.countryCode ?? '',
		mobilePhoneNumber: '',
		mobilePrefix: '+46',
	};

	if (user && !isNil(user.mobilePhoneNumber)) {
		const phoneNumber = parsePhoneNumber(user.mobilePhoneNumber)!;
		defaultValues.mobilePrefix = `+${phoneNumber.countryCallingCode}`;
		defaultValues.mobilePhoneNumber = phoneNumber.nationalNumber;
	}

	useEffect(() => {
		if (user?.links?.verifyAddress) {
			setChangeButtonText(true);
		}
		return () => {
			setChangeButtonText(false);
		};
	}, []);

	return (
		<Styled.Content>
			<h4>Delivery Info</h4>
			<Grid.Container>
				<Grid.Column lg={8} xs={12}>
					<Formik
						initialValues={defaultValues}
						enableReinitialize
						onSubmit={({ mobilePhoneNumber, mobilePrefix, ...values }, { setErrors }) => {
							const mobileNumber = `${mobilePrefix}${mobilePhoneNumber}`;
							setIsLoading(true);
							try {
								UserService.updateUser(user.id, {
									mobilePhoneNumber: mobileNumber || null,
									...values,
								})
									.then(() => {
										updateUser();
										toast.success('Information has been successfully updated');
									})
									.catch((e) => {
										console.error(e);
										handleErrors(e, setErrors);
									});

								authService.me(authService.getGlobalUserIncludes()).then((res) => {
									const user = new Store().sync(res) as Model;
									dispatch(setUser(user));
									if (user?.links?.verifyAddress) {
										Client.post(user?.links?.verifyAddress)
											.then(() => {
												setChangeButtonText(false);
												toast.success('Address verified');
											})
											.catch((error: AxiosError) => {
												console.error(error);
											});
									}
								});
							} catch (error) {
								console.error(error);
								toast.error(getErrorMessageOnPost('updating the information'));
								handleErrors(error, setErrors);
							} finally {
								setIsLoading(false);
							}
						}}
					>
						{({ isSubmitting, isValid, dirty }) => (
							<Form>
								<Styled.FormSubTitle>Delivery information</Styled.FormSubTitle>
								<InputText label='Address' id='address' name='address' placeholder='Street address 12' />
								<Styled.ZipAndCity>
									<div className='left'>
										<InputText label='ZIP/postal code' id='postalCode' name='postalCode' placeholder='111 11' />
									</div>
									<div className='right'>
										<InputText label='City' id='city' name='city' placeholder='City' />
									</div>
								</Styled.ZipAndCity>
								<CountrySelector name='countryCode' />
								<Styled.ButtonGroup>
									<PrimaryButton isLoading={isLoading} type='submit' disabled={!isValid || isSubmitting || !dirty} data-testid='submit-btn'>
										{user.links?.verifyAddress && changeButtonText ? 'Confirm address' : 'Update information'}
									</PrimaryButton>
								</Styled.ButtonGroup>
							</Form>
						)}
					</Formik>
				</Grid.Column>
			</Grid.Container>
		</Styled.Content>
	);
};

export default DeliveryInfoSection;
