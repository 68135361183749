import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';

interface MessageBoxProps {
	bgColor?: string;
	textColor?: string;
	children?: string | JSX.Element;
	testId?: string;
}

const StyledMessageBox = styled.div<MessageBoxProps>`
	padding: 1rem;
	border-radius: ${borderRadius.m};
	font-size: 0.875rem;
	line-height: 1.5;
	background-color: ${(props) => props.bgColor || '#ffffff'};
	color: ${(props) => props.textColor || '#000000'};
`;

const MessageBox = ({ bgColor, textColor, children, testId }: MessageBoxProps) => {
	return (
		<StyledMessageBox bgColor={bgColor} textColor={textColor} data-testid={testId}>
			{children}
		</StyledMessageBox>
	);
};

export default MessageBox;
