import styled from 'styled-components';

const AdminInvoices = styled.div`
	padding-bottom: 2.5rem;
`;

const MainContainerInner = styled.div`
	padding-top: 2rem;
`;

const Styled = {
	AdminInvoices,
	MainContainerInner,
};

export default Styled;
