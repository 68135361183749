import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DefaultItems } from 'views/Campaign/CreateCampaign/helpers';
import { DetailItem } from 'views/Campaign/CreateCampaign/types';

type SliceState = {
	details: DetailItem[];
	termsAndConditions: string;
};

export const initialState: SliceState = {
	details: DefaultItems,
	termsAndConditions: '',
};

const campaignDetailsSlice = createSlice({
	name: 'details',
	initialState,
	reducers: {
		setDetails(state, action: PayloadAction<DetailItem[]>) {
			state.details = action.payload;
		},
		setTermsAndConditions(state, action: PayloadAction<string>) {
			state.termsAndConditions = action.payload;
		},
		clearDetails: () => initialState,
	},
});

export const { setDetails, setTermsAndConditions, clearDetails } = campaignDetailsSlice.actions;

export default campaignDetailsSlice.reducer;
