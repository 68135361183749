import { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import ProgressBar from 'components/Progressbar';

import Styled from './FileUploadProgress.style';
import { FileUploadProgressProps } from './types';

/**
 * FileUploadProgress
 * @param {FileUploadProgressProps} FileUploadProgressProps
 * @returns {JSX.Element}
 */
const FileUploadProgress = ({ files, getIsUploadingFiles, isCompleted, setIsCompleted }: FileUploadProgressProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);
	const [averageProgress, setAverageProgress] = useState(0);

	useEffect(() => {
		const fileValues = Object.values(files);
		const totalFiles = fileValues.length;
		const totalProgress = fileValues.reduce((acc, progress) => acc + progress, 0);
		const avgProgress = totalProgress / totalFiles;

		setAverageProgress(avgProgress);
		setIsLoading(avgProgress < 100);
		setIsCompleted(avgProgress === 100);
	}, [files]);

	useEffect(() => {
		getIsUploadingFiles && getIsUploadingFiles(isLoading);
	}, [isLoading]);

	function renderProgressText(progress: Record<string, number>) {
		const files = progress || {};
		const totalFiles = Object.keys(files).length;
		const completedFiles = Object.values(files).filter((progress) => progress === 100).length;
		const filesLeft = totalFiles - completedFiles;

		if (filesLeft === 0) {
			return (
				<Styled.UploadingText>
					Upload complete!
					<Styled.Done data-testid='completed'>
						<Icon name='checkmark' size='16' />
					</Styled.Done>
				</Styled.UploadingText>
			);
		} else {
			return (
				<Styled.UploadingText>
					Uploading file... (<span className='text-strong'>{completedFiles + 1}</span> of <span className='text-strong'>{totalFiles}</span>)
				</Styled.UploadingText>
			);
		}
	}

	return (
		<Styled.Wrapper>
			<Styled.Inner>
				<Styled.Content id='completed-assignment'>
					{!isCompleted && isLoading && (
						<>
							<Styled.Title>
								<span>Uploading, don&apos;t leave this window</span>
							</Styled.Title>
							<p className='help'>
								Keep this window open until
								<br /> the upload is complete.
							</p>
						</>
					)}
					<Styled.LoadingContainer>
						{renderProgressText(files)}
						{!isCompleted && (
							<ProgressBar hidePercentageLabel={true} percentage={averageProgress} progressColor='#104cb6' borderColor='#c5c5c5' backgroundColor='#c5c5c5' />
						)}
					</Styled.LoadingContainer>
				</Styled.Content>
			</Styled.Inner>
		</Styled.Wrapper>
	);
};

export default FileUploadProgress;
