import styled from 'styled-components';

import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';

const EditableImageWrapper = styled.div`
	position: relative;
	width: 100%;
	picture {
		width: 100%;
	}
	&.moodboardImage {
		flex: 1;
		@media screen and (${devices.lg}) {
			max-width: 316px;
		}
	}
	&.fadeOut {
		-webkit-animation: fadeout 0.5s linear forwards;
		animation: fadeout 1.3s linear forwards;
		animation-delay: 0.3s;

		@-webkit-keyframes fadeout {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}

		@keyframes fadeout {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}
`;

const EditableImageSpinner = styled.div`
	position: absolute;
	display: flex;
	gap: 16px;
	justify-content: center;
	align-items: center;

	background-color: rgba(51, 51, 51, 0.5);
	color: ${colors.white};
	border-radius: ${borderRadius.s};

	top: 50%;
	left: 50%;
	margin-top: 10px;
	width: 100%;
	max-width: 250px;
	padding: 8px;
	transform: translate(-50%, -50%);

	z-index: 1;
`;

const EditableImageContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	width: 100%;
	min-height: 174px;
	min-width: 316px;

	& .cropper {
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

interface EditableImageImageProps {
	shouldHide: boolean;
	onlyOne?: boolean;
}

const EditableImageImage = styled.img<EditableImageImageProps>`
	visibility: ${(props) => (props.shouldHide ? 'hidden' : 'visible')};
	width: 100%;
	height: auto;
	border-radius: ${borderRadius.m};
	aspect-ratio: 4/5;
	object-fit: cover;
	&.moodboardImage {
		aspect-ratio: 1/1;
		width: 100%;
	}
	aspect-ratio: 16/9;
	@media screen and (${devices.lg}) {
		&.moodboardImage {
			max-width: 316px;
		}
	}
`;

const Layer = styled.div`
	position: absolute;
	top: 37px;
	right: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 40px);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	background-color: #0000001c;
	border-radius: ${borderRadius.m};
`;

const ClickArea = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 60px;
	cursor: pointer;

	.cta-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 32px;
	}
`;

const Fallback = styled.div`
	height: 600px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.85);
	filter: blur(2px);
	border-radius: ${borderRadius.m};
`;

const ShowFullSizeButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	position: absolute;
	bottom: 12px;
	right: 10px;
	background-color: ${colors.mainBlack};
	border: transparent;
	cursor: pointer;
	border-radius: 4px;
	padding: 0.5rem;
	z-index: 10;
	.icon {
		line-height: 0;

		svg path {
			fill: ${colors.white};
		}
	}
`;

const Styled = {
	EditableImageWrapper,
	EditableImageSpinner,
	EditableImageContentWrapper,
	EditableImageImage,
	Layer,
	ClickArea,
	Fallback,
	ShowFullSizeButton,
};

export default Styled;
