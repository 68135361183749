import { Formik } from 'formik';
import { JsonApiDocument, Model, Store } from 'json-api-models';
import { first, isNil } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

import AcceptTermsAndConditions from 'components/AcceptTermsAndConditions/AcceptTermsAndConditions';
import InputText from 'components/Form/FormikElements/Text';
import Icon from 'components/Icon';
import SocialProfileConnector from 'components/SocialProfileConnector';
import { FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api/Collabs-auth.service';
import ReferralUrlService from 'services/ReferralUrlService';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import errorHandler from 'utils/formik_error_handler';
import { gtagEvent } from 'utils/ga';

import Styled from './AccountCreation.style';
import { useAccountValidation } from './useAccountValidation';

const AccountCreation = ({
	currentStep,
	setCurrentStep,
	inviteToken,
	email,
	setTermsAccepted,
	termsAccepted,
}: {
	currentStep: number;
	setCurrentStep: (step: number) => void;
	email?: string;
	inviteToken?: string;
	onCheckToken?: (isNewOrganization: boolean) => void;
	setTermsAccepted: (termsAccepted: boolean) => void;
	termsAccepted: boolean;
}) => {
	const authService = useInjection<CollabsAuthService>(CollabsAuthService);

	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.user);
	const [isEnabled] = useFeatureToggle();
	const navigate = useNavigate();

	const { displayPassword, setDisplayPassword, shouldValidate, setShouldValidate, refs, handleKeyDown } = useAccountValidation();

	const INITIAL_VALUES = {
		firstName: '',
		lastName: '',
		email: email || '',
		password: '',
		inviteToken: inviteToken === 'agent' ? undefined : inviteToken,
		reCaptchaToken: undefined,
	};

	return (
		<Formik
			onSubmit={async ({ ...values }, { setErrors }) => {
				const formData = { influencerTermsOfServiceAccepted: termsAccepted, ...values };
				try {
					if (!termsAccepted) {
						toast.error('Please accept the terms and conditions');
						return;
					}

					if (!values.reCaptchaToken && !inviteToken) {
						toast.error('Please complete the reCAPTCHA verification');
						return;
					}

					const models = new Store();
					const { data } = await createClient().post<JsonApiDocument>('/public/users', formData);
					if (!data) {
						throw new Error('No response from server');
					}

					models.sync(data) as Model;
					const userToken = first(models.findAll('userToken'))!;
					authService.setCollabsToken(userToken.attributes.token);
					const token = authService.getCollabsToken();
					if (token) {
						try {
							const res = await authService.me(authService.getGlobalUserIncludes());
							const user = models.sync(res) as Model;
							dispatch(setUser(user));
							setCurrentStep(1);
							gtagEvent('sign_up_step_completed', { step: 'account_creation' });
						} catch (e) {
							toast.error(getSomethingWentWrongMessage());
						}
					}
				} catch (e) {
					console.error('Submission error:', e);
					refs.reCaptcha.current?.reset();
					errorHandler(e, setErrors);
				}
			}}
			validateOnBlur={shouldValidate}
			validateOnChange={shouldValidate}
			initialValues={INITIAL_VALUES}
		>
			{({ isSubmitting, isValid, setFieldValue, values }) => {
				return (
					<>
						{currentStep === 0 && (
							<Styled.Form data-testid='account-creation' aria-labelledby='signup-heading' noValidate>
								<h1 id='signup-heading' className='visually-hidden'>
									Create Your Account
								</h1>
								<Styled.NameFieldsGroup role='group' aria-label='Name fields'>
									<InputText
										label='First name'
										name='firstName'
										placeholder='Add your first name'
										required
										forwardRef={refs.firstName}
										onInput={handleKeyDown}
										aria-required='true'
										autoComplete='given-name'
										tabIndex={1}
									/>
									<InputText
										label='Last name'
										name='lastName'
										placeholder='Add your last name'
										required
										forwardRef={refs.lastName}
										onInput={handleKeyDown}
										aria-required='true'
										autoComplete='family-name'
										tabIndex={2}
									/>
								</Styled.NameFieldsGroup>
								<InputText
									label='Email'
									name='email'
									type='email'
									placeholder='example@mail.com'
									forwardRef={refs.email}
									required
									onInput={handleKeyDown}
									aria-required='true'
									autoComplete='email'
									tabIndex={3}
								/>
								{values.email.length > 0 && (
									<Styled.Info role='alert'>
										<Icon name='info-circle' size='16' aria-hidden='true' />
										<p>
											Invites and campaign updates will be sent to <strong>{values.email}</strong> so make sure it's correct.
										</p>
									</Styled.Info>
								)}
								<InputText
									action={
										<Styled.IconWrapper
											onClick={() => setDisplayPassword(!displayPassword)}
											data-testid='password-toggle'
											aria-label={`${displayPassword ? 'Hide' : 'Show'} password`}
											role='button'
											tabIndex={5}
											onKeyPress={(e) => {
												if (e.key === 'Enter' || e.key === ' ') {
													setDisplayPassword(!displayPassword);
												}
											}}
										>
											<Icon name={!displayPassword ? 'hide' : 'unhide'} aria-hidden='true' />
										</Styled.IconWrapper>
									}
									size='lg'
									label='Password'
									placeholder='Set a strong password'
									name='password'
									type={displayPassword ? 'text' : 'password'}
									id='user-pwd'
									required
									forwardRef={refs.password}
									onInput={handleKeyDown}
									aria-required='true'
									autoComplete='new-password'
									aria-describedby='password-requirements'
									tabIndex={4}
								/>
								<div id='password-requirements' className='visually-hidden'>
									Password must be at least 8 characters long and contain a mix of letters and numbers
								</div>

								<Styled.Divider aria-hidden='true' />

								{(isNil(inviteToken) || inviteToken === 'agent') && !isNil(process.env.VITE_APP_RECAPTCHA_SITE_ID) && (
									<ReCAPTCHA
										ref={refs.reCaptcha}
										sitekey={process.env.VITE_APP_RECAPTCHA_SITE_ID}
										onChange={(token) => {
											setFieldValue('reCaptchaToken', token);
										}}
										onExpired={() => {
											setFieldValue('reCaptchaToken', undefined);
										}}
										tabIndex={5}
									/>
								)}

								<Styled.Divider aria-hidden='true' />

								<Styled.TermsContainer>
									<AcceptTermsAndConditions
										termsAccepted={termsAccepted}
										setTermsAccepted={setTermsAccepted}
										text='Collabs Terms'
										link='https://www.collabs.se/terms-of-service'
										testId='accept-tac'
										tabIndex={6}
									/>
								</Styled.TermsContainer>

								<Styled.SubmitButton
									data-testid='submit-button'
									isLoading={isSubmitting}
									type='submit'
									disabled={isSubmitting || !isValid || !termsAccepted}
									onClick={() => setShouldValidate(true)}
									aria-busy={isSubmitting}
									tabIndex={7}
								>
									{isNil(inviteToken) ? 'Continue' : 'Create account'}
								</Styled.SubmitButton>
							</Styled.Form>
						)}
						{(currentStep === 1 || currentStep === 2) && (
							<SocialProfileConnector
								setCurrentStep={setCurrentStep}
								hasInviteToken={!isNil(inviteToken)}
								disableTikTokConnector={!isNil(inviteToken) && !isEnabled(FEATURE_FLAG_TIKTOK_CONNECTOR_IN_SETTINGS)}
								isSignUp={true}
								onClickCreateAccount={() => {
									gtagEvent('sign_up_step_completed', { step: 'connect_social' });
									ReferralUrlService.redirect(navigate, user.permissions?.isInfluencer ? '/influencer/dashboard' : '/dashboard');
								}}
							/>
						)}
					</>
				);
			}}
		</Formik>
	);
};

export default AccountCreation;
