import classNames from 'classnames';
import { memo } from 'react';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import Styled from './Tabs.style';
import { TabItem, TabsProps } from './types';

const Tabs = memo(({ tabs, selectedTab, setSelectedTab, id, className }: TabsProps) => {
	return (
		<Styled.TabContainer id={id} className={className}>
			{tabs.map((tab: TabItem) => {
				if (tab.omit) return null;

				const isSelected = selectedTab === tab.title;
				const isDisabled = tab.disabled;

				const handleClick = () => {
					if (!isDisabled) {
						setSelectedTab(tab.title);
					}
				};

				return (
					<Tooltip content={tab.tooltip} key={tab.title || tab.key}>
						<Styled.Tab data-testid={tab.testId} className={classNames({ selected: isSelected, disabled: isDisabled })} onClick={handleClick}>
							{tab.icon && <Icon name={tab.icon} size='18' />} {tab.titleToDisplay ?? tab.title} {tab.amount !== undefined && <span>({tab.amount})</span>}
						</Styled.Tab>
					</Tooltip>
				);
			})}
		</Styled.TabContainer>
	);
});

export default Tabs;
