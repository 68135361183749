import { Link, useNavigate } from 'react-router-dom';

import { HoverButton } from 'components/Button';
import Styled from 'components/InfluencerManagement/Lists/Lists.style';
import { pathInfluencerManagement } from 'routing/PathLookup';

type BreadCrumbsProps = {
	title: string | JSX.Element;
	homeText: string;
	homeUrl: string;
	isStart?: boolean;
	isLoading?: boolean;
};

const BreadCrumbs = ({ title, homeText, homeUrl, isStart, isLoading }: BreadCrumbsProps) => {
	const navigate = useNavigate();
	return (
		!isLoading && (
			<Styled.BreadCrumbsContainer>
				<Styled.BreadCrumbs>
					{!isStart && (
						<>
							{homeText !== 'All files' ? (
								<>
									<Link to={pathInfluencerManagement()}>...</Link>
									<Styled.Divider> / </Styled.Divider>
								</>
							) : null}
							<Link to={homeUrl} data-testid='home-link'>
								<span>{homeText}</span>
							</Link>
							<Styled.Divider> / </Styled.Divider>
						</>
					)}
					<div className='title'>{title}</div>
				</Styled.BreadCrumbs>
				{!isStart && (
					<HoverButton icon='arrow-left' onClick={() => navigate(homeUrl)}>
						Back
					</HoverButton>
				)}
			</Styled.BreadCrumbsContainer>
		)
	);
};

export default BreadCrumbs;
