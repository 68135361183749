import classNames from 'classnames';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { isInfluencer } from 'services/auth-service';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './ChatHeader.style';

type Props = {
	profilePictureUrl?: string;
	recipientName: string;
	campaignName: string;
	goBack: () => void;
};

/**
 */
const ChatHeader = ({ profilePictureUrl, recipientName, campaignName, goBack }: Props) => {
	const { width } = useWindowDimensions();

	const renderMobileHeader = () => {
		return (
			<Styled.HeaderMobileInner>
				<div onClick={goBack}>
					<Icon name='chevron-left' />
				</div>
				<Styled.Recipient>
					{!isInfluencer() ? (
						<>
							<Avatar imageUrl={profilePictureUrl} name={recipientName} />
							<span className='recipient'>{recipientName}</span>
						</>
					) : (
						<span className='recipient'>{campaignName}</span>
					)}
				</Styled.Recipient>
			</Styled.HeaderMobileInner>
		);
	};

	const renderDesktopHeader = () => {
		return (
			<Styled.HeaderInner className={classNames({ 'is-influencer': isInfluencer() })}>
				{!isInfluencer() ? (
					<Styled.Recipient>
						<span className='to'>To:</span>
						<Avatar imageUrl={profilePictureUrl} name={recipientName} />
						<span className='recipient desktop'>{recipientName}</span>
					</Styled.Recipient>
				) : null}
			</Styled.HeaderInner>
		);
	};

	return (
		<Styled.Wrapper data-testid='inbox-chat-section-header'>
			{width <= +breakpoints.md.split('px')[0] ? renderMobileHeader() : renderDesktopHeader()}
		</Styled.Wrapper>
	);
};

export default ChatHeader;
