import styled from 'styled-components';

import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

export const TooltipContainer = styled.div`
	display: inline-block;
	position: relative;
	.cb-tooltip {
		max-width: 350px;
		overflow-wrap: break-word;
		color: ${colors.snow};
		font-size: 0.875rem;
		border-radius: ${borderRadius.s};
		z-index: 1000;
		background: ${colors.smoke};
		padding: 1rem;
		box-shadow: 0 10px 20px 0 rgba(51, 51, 51, 0.3);

		font-size: 0.875rem;
		line-height: 1.5;
		&.react-tooltip__show {
			opacity: 1;
		}
	}
	&.hideCursor {
		cursor: auto;
	}
`;

const Styles = {
	TooltipContainer,
};

export default Styles;
