import styled, { DefaultThemeV2 } from 'styled-components';

import Avatar from 'components/Avatar';
import colors from 'styles/theme/colors';

const CommentAvatar = styled(Avatar)`
	border: none;
	margin: 0;
`;

const MessageRow = styled.div`
	display: flex;
	max-width: 690px;

	&:not(.latest) {
		margin-bottom: 32px;
	}
`;

const UserAvatar = styled.div`
	padding-right: 1rem;
`;

const UserMessage = styled.div`
	white-space: pre-line;
	word-wrap: break-word;
	line-height: 1.5;
	max-width: 680px;
`;

const MessageStatus = styled.div`
	font-size: ${({ theme }: { theme: DefaultThemeV2 }) => theme.fontSizes.small};
	color: ${colors.slate};
`;

const Styled = {
	CommentAvatar,
	MessageRow,
	UserAvatar,
	UserMessage,
	MessageStatus,
};

export default Styled;
