import { ReactNode } from 'react';

import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';

import CloseButton from './Components/CloseButton';
import Styled from './DiscoveryDropdown.style';

/**
 */
const DefaultDropdownMenuItems = (props: {
	onAddCampaign: () => void;
	onAddList: () => void;
	onClose: () => void;
	isLoadingLists: boolean;
	isLoadingCampaigns: boolean;
	children?: ReactNode;
}) => {
	return (
		<Styled.CustomMenu>
			<Styled.MenuHeader>
				<span>Add to...</span>
				<CloseButton onClose={props.onClose} />
			</Styled.MenuHeader>
			<Dropdown.Item onClick={props.onAddCampaign}>
				<Styled.FlexDiv alignItems='center' columnGap='1rem' data-testid='add-to-campaign-button'>
					<Icon name='campaign' size='18' />
					Add to campaign
					{props.isLoadingCampaigns ? <LoadingSpinner size='sm' /> : null}
				</Styled.FlexDiv>
			</Dropdown.Item>

			<Dropdown.Item onClick={props.onAddList}>
				<Styled.FlexDiv alignItems='center' columnGap='1rem' data-testid='add-to-list-button'>
					<Icon name='list-influencers' size='18' />
					Add to list
					{props.isLoadingLists ? <LoadingSpinner size='sm' /> : null}
				</Styled.FlexDiv>
			</Dropdown.Item>

			{props.children && <Dropdown.Item>{props.children}</Dropdown.Item>}
		</Styled.CustomMenu>
	);
};

export default DefaultDropdownMenuItems;
